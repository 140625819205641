$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.level-block {
  &__box {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    &.border-top {
      border-top: 1px solid $white-30;
      margin-top: 12px;
      padding-top: 12px;
    }

    &.flex-row {
      flex-direction: row;
      padding-top: 0;
    }
  }

  &__left {
    width: 50%;
    border-right: 1px solid $white-30;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__right {
    width: 50%;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__center {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  &__text {
    @include text(22px, 27px, 700, $orange-300);
    text-align: center;

    &.is-s {
      @include text(12px, 15px, 700, $white-80);
    }
  }
}
