$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
////////////////////////////////////////
// в версии v1.1 этого блока на главной нет //
////////////////////////////////////////

.landing-soon{
  height: 279px;
  position:relative;
  background-image: linear-gradient(to right, #1c417c, rgba(28, 65, 124, 0));
  text-align: center;
  padding:20px 250px 20px 20px;
  &::before{
    content:'';
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: url( $cdn + "/wazamba/landing-soon.jpg");
    background-size: cover;
    filter: grayscale(1);
    z-index: -1;
  }
  &__title{
    font-size: 64px;
    font-weight: 800;
    letter-spacing: 3.2px;
    color: $white;
    padding-top:50px;
  }
  &__text{
    padding-top:40px;
    font-size: 24px;
    font-weight: 800;
  }
  @include xl{
    height: 320px;
    &__title{padding-top:60px;}
    &__text{ padding-top:60px;}
  }
}
