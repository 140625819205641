$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dashboard-collection {
  display: grid;
  grid-template-columns: 99px 56px 56px 56px 64px 64px 300px;
  grid-template-rows: 73px;
  grid-gap: 6px;
  max-width: 732px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  @media screen and (min-width: 1280px) {
    grid-template-columns: 98px 56px 56px 56px 80px 80px 300px;
    max-width: 763px;
  }
  &__item {
    background: $black-40;
    border-radius: 12px;
    padding: 15px 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: .3s;

    &:hover {
      background: $white-26;
    }

    &-points {
      padding: 8px 16px 8px 12px;
    }

    &.is-period {
      display: flex;
      align-items: center;
      position: relative;

      .period-icon {
        flex-shrink: 0;
        position: relative;
        background-position: center;
        background-size: contain;
        width: 70px;
        height: 70px;

        background-image: url('#{$cdn}/wazamba/leagues/icon-league/closed.png');
        @media (-webkit-max-device-pixel-ratio: 2) {
          background-image: url('#{$cdn}/wazamba/leagues/icon-league/closed@2x.png');
        }
        @media (-webkit-max-device-pixel-ratio: 3) {
          background-image: url('#{$cdn}/wazamba/leagues/icon-league/closed@3x.png');
        }

        span {
          position: absolute;
          top: 50%;
          left: 55%;
          transform: translate(-50%, -50%);
          color: #D0D4D8;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
        }

        .rtl-lang & {
          transform: scaleX(-1);
        }
      }

      .description {
        color: $white;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: left;

        &-info {
          background: rgba(255, 255, 255, 0.15);
          width: 14px;
          height: 14px;
          border-radius: 50%;
          display: inline-block;
          font-size: 12px;
          text-align: center;
          margin-left: 6px;
        }
      }
    }
  }
  &__number {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    color: $white;
    margin-top: 0;
    margin-bottom: 6px;
    span {
      opacity: 0.5;
    }
  }
  &__avatar {
    background-size: contain;
    width: 102px;
    height: 102px;
    margin-top: -33px;
    margin-left: -5px;
    transform: scaleX(-1);
  }
  &__icon {
    width: 24px;
    height: 24px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__info {
    max-width: 192px;
    width: 100%;
    text-align: left;
  }
  &__text {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: $white;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__points {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #F5BC15;
    margin-top: 6px;
    margin-bottom: 0;
  }
  &__img {
    width: 60px;
    height: 68px;
    margin-top: -6px;
  }
  .league-tile {
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    cursor: pointer;
    background-position: top center;
    &:after {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
    }

    &.close-tile {
      &:after {
        opacity: 0;
      }
    }

    $leagues: '1', '2', '3', '4', '5', '6', '7', '8';

    @each $league in $leagues {
      &.league-tile-#{$league} {
        background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/closed/#{$league}.png');
        @media (-webkit-max-device-pixel-ratio: 2) {
          background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/closed/#{$league}@2x.png');
        }
        @media (-webkit-max-device-pixel-ratio: 3) {
          background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/closed/#{$league}@2x.png');
        }
      }

      &.league-tile-#{$league}:after {
        background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/open/#{$league}.png');
        @media (-webkit-max-device-pixel-ratio: 2) {
          background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/open/#{$league}@2x.png');
        }
        @media (-webkit-max-device-pixel-ratio: 3) {
          background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/open/#{$league}@2x.png');
        }
      }
    }

    &.league-tile-1 {
      background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/open/1.png');
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/open/1@2x.png');
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/open/1@2x.png');
      }
    }
  }

  .rtl-lang & {
    &__avatar {
      transform: scaleX(1);
    }
  }
}
