$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
html {
    height: 100%;
    outline:none;
    line-height: 1.15; /* 1 */
    -ms-text-size-adjust: 100%; /* 2 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -webkit-tap-highlight-color: transparent;

}
body {
  //height: 100%;
  outline:none;
  background-repeat:no-repeat;
  margin: 0;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-track-color: #070d10;
  scrollbar-arrow-color: #f5f5f5;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
  overflow-y: scroll;
}
article,
aside,
footer,
header,
nav,
section { display: block; }
h1 {
    font-size: 2em;
    margin: 0.67em 0;
}
:focus {
    outline: 0;
}
/*ng-style*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak { display: none; }

div{
    webkit-overflow-scrolling: touch;
    box-sizing: border-box;
}

a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
  text-decoration:none;
  font-size:100%;
  color:#fff;
}
svg:not(.g-var-size){
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.clearfix:after {
    content: '';
    display: table;
    clear: both;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a:hover{
    cursor:pointer;
}
svg:not(:root) {
    overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

button,
input { /* 1 */
    overflow: visible;
}

button,
select { /* 1 */
    text-transform: none;
}
button,
html [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; /* 2 */
}



input[type=checkbox].hidden{
    display:none;
}
input[type='number'] {

}


/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}
textarea {
    overflow: auto;
}
[type="checkbox"],
[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
    appearance: none;
}
[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

ul{
    padding:0;
    margin-top:0;
	margin-bottom:0;
    list-style-type:none;
}

img{
    max-width:100%;
    height:auto;
    border-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
  max-width: 100%;
}

td,
th {
    padding: 0;
}


h1{
    font-size:300%;
    font-weight:300;

}
h2{
  font-size:200%;

  font-weight: 300;
}

select{border: none;
  border: transparent;
  line-height: 2em;
}
option{
  line-height: 2em;
}

//.asb-application * {
//  all: revert;
//}
