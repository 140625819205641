$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.missed-data {
  @include text(14px, 140%, null, $brown-300);

  &__container {
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    padding: 60px;
  }

  .g-board {
    max-width: 756px;
    width: 100%;
    margin: auto;
  }

  &__content {
    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 58px 50px;
  }

  &__logo {
    position: absolute;
    display: block;
    width: 222px;
    height: 55px;
    margin: 0 auto;
    background:
            url($cdn + '/wazamba/desktop/img/logo.svg') 50% 50% / contain no-repeat,
            url($cdn + "/wazamba/leaf.png") center bottom / contain no-repeat;
    top: -33px;
    z-index: 2;
    filter: drop-shadow(0 0 5px $black-70);
  }

  &__terms {
    position: relative;
    max-width: 100%;
    box-sizing: border-box;
    line-height: 165%;


    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__info-section {
    max-height: 380px;
    padding-inline-end: 16px;
    overflow-y: auto;
    overflow-x: hidden;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__info-inner {
    display: block;
    max-height: 140px;
    overflow: hidden;

    .is-open & {
      max-height: fit-content;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__head {
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__title {
    font-size: 36px;
    line-height: 44px;
    font-weight: 800;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .g-form-box {
    width: 100%;
    max-width: 407px;
    margin: 0;
  }

  &__decline {
    outline: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    font-size: 13px;
    line-height: 120%;
  }

  h1, h2, p {
    margin: 0;
  }

  .info {
    padding-top: 0;

    p {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    h1 {
      font-size: 18px;
      line-height: 115%;
      font-weight: 700;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 16px;
      line-height: 115%;
      font-weight: 700;
      margin: 20px 0 8px;
    }

    .text2 strong {
      font-weight: 400;
      color: #ffde00;
    }

    div:has(> table) {
      overflow: auto;
    }

    table {
      width: 100%;
      text-align: center;

      thead td,
      thead th {
        background-color: $white-5;
        border: 1px solid rgba(255, 255, 255, 0.18);
        padding: 6px 8px 7px;
      }

      td {
        border: 1px solid rgba(255, 255, 255, 0.18);
        padding: 10px 8px;
        vertical-align: middle;
      }
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .g-check-text {
    color: $brown-300;
  }

  .rtl-lang & {
    direction: rtl;
  }
}

.dropdown {
  @include text(14px, 120%, 700, $brown-300);
  display: flex;
  align-items: center;
  width: max-content;
  margin: 0 auto;
  letter-spacing: 0.03em;

  &__btn {
    &:not(:last-child) {
      margin-inline-end: 9px;
    }

    &_less {
      display: none;

      .is-open & {
        display: block;
      }
    }

    &_more {
      .is-open & {
        display: none;
      }
    }
  }

  &__arrow {
    width: 6px;
    height: 9px;
    transform: rotate(90deg);

    .is-open & {
      transform: rotate(-90deg);
    }
  }
}

button.missed-data__btn {
  width: 100%;
  max-width: 260px;
  margin-bottom: 20px;
}
