$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.sport-race {
  height: 100vh;
}

.sportpage {
  &.horsr-page {
    background-image: none;
  }
}
