$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.registration-promo {
    position: relative;
    background:rgba(193, 157, 102, .7);
    box-shadow: inset 0 0 6px 1px $black-20;
    border-radius: 16px;
    display: flex;
    min-height: 84px;
    align-items: center;
    padding: 12px;
    cursor: pointer;

    &__desc {
        @include text(18px, 160%, 900, $brown-300);
        text-align: center;
        margin-bottom: 12px;
    }

    &__img {
        flex-shrink: 0;
        width: 56px;
        height: 60px;

        &.is-step-3{
            width: 83px; 
            height: 83px;
        }
    }

    &__label {
        cursor: inherit;
        width: 100%;
        display: flex;
        gap: 8px;

        .registration-promo__promocode:not(.hide) + & {
            display: none;
        }
    }

    &__content { 
        width: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
        letter-spacing: .05em;
    }

    &__name {
        @include text(12px, 1.3, 700);
    }

    &__text {
        @include text(16px, 1.2, 800);
    }

    &.is-active {
        background-color: rgba(58, 20, 4, .7);
        box-shadow: inset 0 0 6px 1px $black-70;

        a {
            display: block; 
        }
    }

    &.is-step-3 {
        margin: 20px 0 22px;
        pointer-events: none;
    }

    &__promocode {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;

        &.hide {
            display: none;
        }
    }

    &__promocode-choosed {
        @include text(16px, 1.2, 800, $white);
        @include ellipsis;
    }

    &__promocode-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__input-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 8px;
    }

    &__input-box {
        width: 100%;
    }

    &__input {
        @include text(12px, 1.6, 600, #555);
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        padding: 14px 12px;
        background: $white;
        border-radius: 8px;
        border: 1px solid $blue-500;
        font-family: Montserrat, sans-serif;
    }

    &__input-label {
        min-height: unset;

        > .g-form-error {
            padding-left: 8px;
        }
    }

    &__btn-apply-wrapper {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;

        .g-btn {
            padding: 0 15px;

            &:disabled {
                opacity: 0.3;
                pointer-events: none;
            }
        }
    }
}
