$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.shop {
  text-align: center;

  .rtl-lang & {
    direction: rtl;
  }

  &-back {
    top: 28px;
    left: 20px;
    position: absolute;
  }

  &__header-bg {
    background-image: url($cdn + '/wazamba/league/header/back-hide.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 114px;
    width: 566px;
    min-height: auto;
    margin: 0 auto;
  }

  &__wrapper {
    width: 100%;
    min-height: 300px;
    position: relative;

    background-image:
            url($cdn + '/wazamba/shop/shop_bg1024_left.png'),
            url($cdn + '/wazamba/shop/shop_bg1024_right.png'),
            url($cdn + '/wazamba/league/bg-pattern.png'),
            linear-gradient(90deg, #07192D 0%, #0B2F53 50%, #06182E 100%);
    background-repeat: no-repeat, no-repeat, repeat, no-repeat;
    background-position: top left, top right, top center, center;
    background-size: 422px, 478px, 800px, contain;
  }

  .page__container {
    max-width: 1164px;
    margin: -114px auto 0;
    padding-bottom: 40px;

    @include xl {
      padding-left: 0;
      padding-right: 0;
    }

  }

  &__top {
    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-title {
      font-size: 36px;
      line-height: 1;
      @include title-gold-shadow(4px, 1px);
      cursor: pointer;
    }

    &-link {
      display: block;
      width: 100%;
      max-width: 508px;
      border-radius: 12px;
      border: 1px solid rgb(30, 69, 112);
      background: rgba(14, 51, 88, 0.80);

      &:hover {
        background-color: rgba(19, 65, 111, 0.9);
      }

      span {
        line-height: 54px;
        position: relative;
        letter-spacing: initial;

        &::before {
          content: '';
          position: absolute;
          width: 68px;
          height: 68px;
          bottom: -17px;
          inset-inline-start: -72px;
          background-image: url($cdn + '/wazamba/shop/pot-coins.png');
          background-repeat: no-repeat;
          background-size: contain;
        }

        &::after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          inset-inline-end: -20px;
          bottom: -1px;
          background-image: url($cdn + "/wazamba/shop/shop_arrow-right.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
        .rtl-lang & {
          &:after {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  &__coins {
    &-balance {
      @include text(16px, 40px, 500);

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 12px;
      position: relative;
      flex: 0 0;

      width: fit-content;
      min-height: 40px;
      margin-inline: auto;
      padding-inline: 15px;
      border-radius: 20px;
      background: rgba(6, 26, 46, 0.8);

      span {
        white-space: nowrap;
      }

      &.hidden {
        visibility: hidden;
      }
    }

    &-amount {
      display: flex;
      flex-direction: row;
      gap: 4px;

      .rtl-lang & {
        direction: ltr;
      }

      @include text(20px, 40px, 800, rgb(255, 230, 0));

      & .icon-coin {
        width: 20px;
      }
    }
  }

  &-segment {

    &--with-margin-bottom {
      margin-bottom: 28px;
    }

    &__title {
      @include shop-title-white(20px, 24px);

      margin-bottom: 16px;

      //@include breakpoint($bp1) {
        //@include shop-title-white(24px, 29px);
      //}
    }
  }

  &__preloader {
    margin-inline: auto;
  }
}
