$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  &__exchange {
    &-arrows-prev {
      .rtl-lang & {
        svg {
          transform: scaleX(-1);
        }
      }
    }

    &-arrows-next svg {
      transform: rotate(180deg);

      .rtl-lang & {
        transform: none;
      }
    }
  }
}
