$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  &__crab {
    margin-inline: var(--shop-margin-inline);

    &-banner {
      min-height: unset;
      padding: 12px 12px 56px;
      border-radius: 16px;
      border: 1px solid $white-20;
      box-shadow: 0 2px 3px 0 rgba(255, 162, 109, 0.4) inset, 0 -4px 12px 0 rgba(39, 11, 5, 0.2) inset, 0 4px 8px 0 $black-20;

      .is-on-tab {
        padding-bottom: 40px;
      }
    }

    &-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0;
      align-items: center;
      padding: 0 18px 137px;
      border-radius: 8px 8px 16px 16px;
      border: 1px solid rgba(40, 93, 168, 0.4);
      box-shadow: 0 2px 6px 0 rgba(255, 195, 78, 0.4), 0 4px 24px 0 rgba(157, 29, 29, 0.4) inset;
      background-image: url("#{$cdn}/wazamba/shop/bc_art_bg.png"), url("#{$cdn}/wazamba/shop/bc_small_bg.png");
      background-repeat: no-repeat, no-repeat;
      background-size: 388px 189px, cover;
      background-position: bottom -51px left 50%, center;

      .is-on-tab & {
        padding-bottom: 40px;
        background-image: url("#{$cdn}/wazamba/shop/bc_small_bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }

    //- Full class names are used to increase selectors specificity

    .game-page__crab-cta-btn {
      font-family: Montserrat, sans-serif;
      text-transform: uppercase;
      padding-inline: 40px;
      position: absolute;
      bottom: -22px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;

      img {
        width: 20px;
      }

      div {
        direction: ltr;
        display: inline-flex;
      }
    }

    .game-page__crab-offer {
      margin-top: 18px;
      display: block;

      .bonus-item-tile__offer-amount::after {
        top: 5px;
      }
    }

    .game-page__crab-prize-list {
      display: flex;
      margin-top: 12px;
      text-align: start;
    }
  }
}
