$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-banner {
  background-image: url($cdn + '/wazamba/renovation/new-main-banner/banner_bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 440px;
  position: relative;

  @media (-webkit-max-device-pixel-ratio: 2) {
    background-image: url($cdn + '/wazamba/renovation/new-main-banner/banner_bg@2x.jpg');
  }
  @include xxl {
    height: 560px;
  }

  &__l-side {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before, &:after {
      position: absolute;
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      bottom: 0;
      left: 0;
      transition: .2s all;
    }

    &:before {
      background-image: url($cdn + '/wazamba/renovation/new-main-banner/l_tree.png');
      width: 603px;
      height: 100%;

      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url($cdn + '/wazamba/renovation/new-main-banner/l_tree@2x.png');
      }

      @include xxl {
        width: 827px;
      }
    }
    &:after {
      background-image: url($cdn + '/wazamba/renovation/new-main-banner/l_box.png');
      width: 303px;
      height: 187px;
      z-index: 4;
      transform: translateX(-45%);

      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url($cdn + '/wazamba/renovation/new-main-banner/l_box@2x.png');
      }

      body.linda-hide-sidebar & {
        transform: translateX(0);
      }

      @include l {
        transform: translateX(0);
      }

      @include xxl {
        width: 470px;
        height: 300px;
        transform: translateX(-25%);
      }
    }
  }

  &__r-side {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    &:before, &:after {
      position: absolute;
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      bottom: 0;
      right: 0;
      transition: .2s all;
    }

    &:before {
      background-image: url($cdn + '/wazamba/renovation/new-main-banner/r_tree.png');
      width: 672px;
      height: 100%;

      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url($cdn + '/wazamba/renovation/new-main-banner/r_tree@2x.png');
      }

      @include xxl {
        width: 922px;
      }
    }
    &:after {
      background-image: url($cdn + '/wazamba/renovation/new-main-banner/r_box.png');
      width: 415px;
      height: 198px;
      z-index: 3;
      transform: translateX(16%);

      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url($cdn + '/wazamba/renovation/new-main-banner/r_box@2x.png');
      }

      body.linda-hide-sidebar & {
        transform: translateX(40%);
      }

      @include l {
        transform: translateX(-16%);

        body.linda-hide-sidebar & {
          transform: translateX(25%);
        }
      }

      @include xxl {
        width: 740px;
        height: 400px;
        bottom: -18px;
        transform: translateX(20%);
      }
    }
  }

  &__wrap {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;

    &:before {
      position: absolute;
      content: '';
      background-image: url($cdn + '/wazamba/renovation/new-main-banner/grass.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 158px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
      pointer-events: none;

      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url($cdn + '/wazamba/renovation/new-main-banner/grass@2x.png');
      }

      @include xl {
        bottom: 10px;
      }

      @include xxl {
        height: 158px;
        bottom: 8px;
        width: 80%;
      }
    }

    &:after {
      position: absolute;
      content: '';
      background-image: url($cdn + '/wazamba/renovation/new-main-banner/b_line.png');
      background-position: center;
      background-repeat: repeat-x;
      background-size: contain;
      bottom: -27px;
      left: 0;
      width: 100%;
      height: 40px;
      z-index: 10;
    }
  }

  registration-deposit-init-form, batman-promo-item2 {
    display: block;
    width: 360px;
    z-index: 34;
    position: relative;
    left: calc(50% - 100px);
    transform: translateX(-50%);
    transition: .2s all;

    body.linda-hide-sidebar & {
      left: calc(50%);
    }
  }

  batman-promo-list1 {
    width: 100%;
  }

  &__description {
    background-image: url($cdn + '/wazamba/renovation/new-main-banner/plate.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 476px;
    height: 414px;
    position: absolute;
    top: 0;
    left: calc(50% - 100px);
    transform: translateX(-50%);
    z-index: 2;
    transition: .2s all;

    body.linda-hide-sidebar & {
      left: calc(50%);
    }
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/wazamba/renovation/new-main-banner/plate@2x.png');
    }

    @include xxl {
      width: 612px;
      height: 534px;
    }

  }

  &__hero {
    width: 404px;
    position: absolute;
    height: 100%;
    left: calc(50% - 100px);
    transform: translateX(-50%);
    z-index: 3;
    transition: .2s all;
    pointer-events: none;

    body.linda-hide-sidebar & {
      left: calc(50%);
    }

    @include xxl {
      width: 533px;
    }

    &:before, &:after {
      position: absolute;
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      bottom: 20px;
    }

    &:before {
      background-image: url($cdn + '/wazamba/renovation/new-main-banner/chimola.png');
      width: 280px;
      height: 344px;
      left: 0;
      transform: translateX(-90%);

      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url($cdn + '/wazamba/renovation/new-main-banner/chimola@2x.png');
      }

      @include xxl {
        width: 370px;
        height: 455px;
        transform: translateX(-110%);
      }
    }
    &:after {
      background-image: url($cdn + '/wazamba/renovation/new-main-banner/bomani.png');
      width: 303px;
      height: 394px;
      right: 0;
      z-index: 3;
      transform: translateX(90%);

      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url($cdn + '/wazamba/renovation/new-main-banner/bomani@2x.png');
      }

      @include xxl {
        width: 404px;
        height: 522px;
        transform: translateX(110%);
      }
    }
  }

  .banner-btns {
    width: fit-content;
    margin-top: auto;
  }

  .btn-wrap {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 100px);
    transform: translateX(-50%);
    z-index: 33;
    transition: .2s all;

    body.linda-hide-sidebar & {
      left: calc(50%);
    }
    @include xxl {
      bottom: 100px;
    }
  }

  &__crypto {
    background: $black-35;
    border-radius: 24px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
    margin: 20px auto 0;

    img {
      width: 22px;
    }
  }

  &__bonus {
    position: absolute;
    width: 250px;
    left: 50%;
    transform: translateX(-50%);

    @include xxl {
      width: 378px;
    }
  }
}

//.linda-has-remembered-login {
//  .entrance-banner {
//    height: 380px;
//
//    &__hero {
//      &:before {
//        height: 320px;
//      }
//
//      &:after {
//        height: 368px;
//      }
//    }
//
//    .btn-wrap {
//      bottom: 60px;
//    }
//
//    @include xxl {
//      height: 436px;
//
//      &__hero {
//        &:before {
//          height: 404px;
//          transform: translateX(-75%);
//        }
//
//        &:after {
//          height: 416px;
//          transform: translateX(75%);
//        }
//      }
//
//      &__wrap {
//        &:before {
//          height: 196px;
//        }
//      }
//    }
//
//    @include full {
//      &__l-side {
//        &:after {
//          transform: translateX(0%);
//        }
//      }
//
//      &__r-side {
//        &:after {
//          height: 354px;
//          transform: translateX(5%);
//        }
//      }
//    }
//  }
//}
