$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-page {
  padding: 0;
  width: 740px;
  margin: 0 auto;

  .rtl-lang & {
    direction: rtl;
  }

  @include l { width: 880px;}
  @include xl { width: 970px;}
  @include xxl { width: 1407px;}
}

.profile-header {
  padding-block: 40px 20px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0 -15px;
  flex-wrap: wrap;

  &__section {
    width: calc(50% - 50px);
    margin: 0 15px;

    &_board {
      margin-top: 100px;
      width: 320px;
    }

    &_shop {
      display: block;
      background: url( $cdn + '/wazamba/islands/4.png') 50% 50% no-repeat;
      background-size: contain;
      height: 375px;
      animation-name: wobbleUp;
      animation-delay: 1s;
      animation-duration: 4s;
      animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
      animation-iteration-count: infinite;
      transform: scaleX(-1);

      .rtl-lang & {
        animation: wobbleUpRtl 4s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform: scaleX(1);
      }
    }

    &--shop {
      display: none;
    }
  }

  @include l {
    &__section_board {
      width: 410px;
      margin-top: 0;
    }
  }

  @include xl {
    &__section {
      width: calc(50% - 30px);

      &_board {
        width: calc(50% - 30px);
      }
    }
  }

  @include xxl {
    &__section {
      width: calc(33.3334% - 30px);

      &--shop { display:block;}
    }

    &__section_board {
      margin-top: 85px;
    }
  }
}

.profile-hero {
  width: 295px;
  height: 400px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0 auto;
  position: relative;

  &__img {
    position:absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-size: 100% auto ;
    background-position: center top ,  center calc(100% - 10px);
    background-repeat: no-repeat , no-repeat;
    animation-name: wobbleUp;
    animation-delay: .8s;
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    animation-iteration-count: infinite;
    transform: scaleX(-1);

    .rtl-lang & {
      transform: scaleX(1);
      animation-name: wobbleUpRtl;
    }
  }

  &__btn {
    position: relative;
    z-index: 11;
  }

  @include l {
    height: 450px;
    width: 330px;
  }
}

.user-bar {
  padding-top: 44px;
  width: 290px;
  margin: 0 auto;

  &__top {
    position: absolute;
    top: -94px;
    left: 50%;
    transform: translateX(-50%);
    height: 166px;
    width: 345px;
    text-align: center;
    background-image: url($cdn + '/wazamba/hero-room/hero_room.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url('#{$cdn}/wazamba/hero-room/hero_room@2x.png');
    }

    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url('#{$cdn}/wazamba/hero-room/hero_room@3x.png');
    }
  }

  &__top-lvl {
    text-shadow: 0 1px 5px rgba(117, 76, 41, 0.6);
    font-size: 36px;
    font-weight: 800;
    padding-block: 30px 10px;
  }

  &__top-login {
    text-shadow: 0 2px 5px rgba(117, 76, 41, 0.6);
    font-size: 28px;
    font-weight: 800;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  &__item {
    position: relative;
    margin: 26px auto;

    &:first-child { margin: 15px auto 26px; }
    &:last-child { margin: 26px auto 15px; }
  }

  @include xxl {
    padding-block: 40px 10px;

    &__top-lvl {
      padding-block: 42px 25px;
    }
  }
}

.balance-item {
  height: 28px;
  background-color: rgba(110, 57, 35, 0.5);
  width: 256px;
  display: flex;
  align-items: center;

  &.is-blue {
    background-color: #0094E6;
    border-top: solid 3px #37B9FD;
    border-bottom: solid 3px #34D2D6;
    box-shadow: 0 1px 0 0 #3D8BE2 , inset 0 0 3px $black-20 , 0 0 20px $blue-500;
  }

  &__icon {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin-inline-start: -20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .rtl-lang & {
      margin-inline-start: -32px;
    }

    &_total,
    &_balance,
    &_coin,
    &_bonus {
      .rtl-lang & {
        transform: scaleX(-1);
      }
    }

    &_total {
      background-image: url( $cdn + '/wazamba/hero-room/icon-total-balance.png');
    }

    &_balance {
      background-image: url( $cdn + '/wazamba/hero-room/icon-real-balance.png');
    }

    &_coin {
      background-image: url( $cdn + '/wazamba/hero-room/icon-coin.png');
    }

    &_bonus {
      background-image: url( $cdn + '/wazamba/hero-room/icon-bonus-money.png');
    }

    &_crab {
      background-image: url( $cdn + '/wazamba/hero-room/icon-cash-crab.png');
    }
  }

  &__sum {
    @include text(16px, null, 800, $white);
    width: calc(100% - 60px);
    position: relative;
    flex-grow: 1;
    text-shadow: 0 0 10px $black-25;
    letter-spacing: 0.9px;
    text-align: center;

    &_small {
      width: calc(100% - 130px);
    }

    & * {
      @include ellipsis;
      text-align: center;
    }
  }

  &__sum-label {
    @include text(12px, null, 900, $brown-300);
    position: absolute;
    top: -20px;
    text-shadow: 0 4px 4px $black-25;
    letter-spacing: 0.6px;
    inset-inline: -15px;
  }

  &__btn {
    margin-inline-end:-15px;
    flex-shrink: 0;

    .icon-refresh {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url($cdn + '/wazamba/hero-room/refresh-icon.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 25px;
        height: 25px;
      }
    }

    .bonus-icon {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url($cdn + '/wazamba/hero-room/bonus-icon.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 25px;
        height: 25px;
      }
    }

    .g-btn {
      font-size: 16px;
    }

    &_big {
      width: 122px;
      min-width: 122px;
    }
  }
}

.money-hero-room {
  background-image: url($cdn + '/wazamba/icon-burger/icon-money.png');
  background-size: contain;
  width: 48px;
  height: 48px;
  top: -4px;
  inset-inline-start: -8px;
}

.profile-title {
  @include text(36px, null, 800, $yellow-200);
  padding: 30px 0;
  text-align: center;
  text-shadow: 0 2px 0 #f4901b;
  letter-spacing: 1.8px;
}

.profile-tabs {
  margin-top: 33px;
}
