$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-label{
  position:absolute;
  top: 0;
  inset-inline-end: 0;
  height: 36px;
  padding: 0 12px ;
  background-image: radial-gradient(circle at 0 0, $violet-500, $violet-700);
  border-radius: 18px;
  font-size: 18px;
  font-weight: 800;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: $white;
  z-index: 20;
  &::after{
    content:'';
    width: 21px;
    height: 21px;
    margin-inline-start: 5px;
    background-image: url( $cdn + '/wazamba/sprite-tournament-page.png');
    background-position: 0 -41px;
    background-size: 144px;
  }

  &_bottom{
    top: inherit;
    bottom: 0;
    inset-inline-end: 50%;
    transform: translate(50% , 50%);
  }

  &_0{
    background-image: radial-gradient(circle at 0 0, #22063f, #340754);
    color: $white-60;
    &::after{ opacity:0.6;}
  }
  &_3{
    display:none !important;
  }

}
