$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-page{
  width: 680px;
  margin:0 auto;


  &__header{
    margin-inline-start:-40px;
    width: calc(100% + 40px);
    height: 380px;
    padding-block: 30px;
    padding-inline: 350px 0;
    display:flex;
    flex-direction: column;
    justify-content: center;

    background-size: 320px 320px;
    background-position: left center;
    background-repeat: no-repeat;
  }
  &__title{
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 1.2px;
    color: $white;
    padding:10px 0;
    margin: 0;
  }
  &__prize{
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 1.8px;
    color: $white;
    padding:10px 0;
    text-wrap: balance;
  }
  &__terms-title{
    padding-top:30px;
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 1.2px;
    color: $white;
    text-align: center;

  }
  &__btns{
    padding-top: 20px;
  }
  &__terms-content{
    text-shadow: 0 0 10px $black-25;
    font-size: 16px;
    line-height: 2;
    letter-spacing: 0.8px;
    color: $white;
    & a {
      color: $yellow-700;
    }

    table {
      width: 100%;

      thead {
        tr {
          td,
          th {
            background-color: $white-5;
            border-bottom: 1px solid rgba(255, 255, 255, 0.18);
            padding: 6px 8px 7px;
            text-align: start;
          }
          th {
            flex: 1;
          }
        }
      }
      tr:nth-child( 2n + 1) {
        background-color: $white-5;
      }
      td,th {
        padding: 10px 8px;
        vertical-align: top;
        text-align: start;
      }
    }

    ul {
      padding-inline-start: 40px;
      li {
        padding-bottom: 4px;
        position: relative;

        &:last-child {
          padding: 0;
        }

        &:before {
          background-color: $white;
          border-radius: 50%;
          content: "";
          display: inline-block;
          height: 8px;
          margin-inline: 0 12px;
          margin-block: 9px 0;
          min-width: 8px;
          width: 8px;
          position: absolute;
          top: 4px;
          inset-inline-start: -15px;
        }
      }
    }
  }

  &__steps-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0 0;

  }

  &__steps-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;

    border-radius: 10px;
    background: $white-10;
    box-shadow: 0 0 6px 0 rgba(167, 167, 167, 0.10) inset;
    cursor: pointer;

    &:hover {
      background: $white-26;
    }

    &.is-no-action {
      pointer-events: none;

      .promo-page__steps-arr {
        display: none !important;
      }

      ~ .is-unlogged {
        pointer-events: none;
      }
    }

  }

  &__steps-number {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;

    font-weight: 800;
    font-size: 32px;
    text-shadow: 0 3px $orange-1000;
    color: $yellow-200;
  }

  &__steps-col {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__steps-subtitle {
    font-weight: 900;
    font-size: 14px;
    line-height: 1.2;
    color: $white;
  }

  &__steps-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.2;
    color: $white;
  }

  &__steps-arr {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;
    margin-inline-start: auto;

    svg {
      width: 10px;
      height: 7px;
      fill: $white;
    }
  }

  .rtl-lang & {
    direction: rtl;

    &__header {
      background-position: right center;
    }

    &__steps-arr {
      transform: scale(-1,1);
    }
  }
}

.promo-optout {
  @include text(14px, 18px, 700, $white);
  text-align: center;
  font-style: normal;
  letter-spacing: 0.7px;
  border-radius: 10px;
  background: $white-10;
  box-shadow: 0 0 6px 0 rgba(167, 167, 167, 0.10) inset;
  padding: 12px;
  width: 100%;

  &__text {
    display: flex;
    gap: 6px;
    justify-content: center;
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;

    &._weakly {
      @include text(10px, 140%, 700, $white-70);
      font-style: normal;
      margin-top: 4px;
      letter-spacing: 0.5px;
    }

    &:hover {
      color: $white;
    }
  }
}

.promo-page .is-has-opt {
  .is-step-active {
    .promo-page__steps-subtitle,
    .promo-page__steps-text {
      @include title-gold-shadow(0, 0);
    }

    .promo-page__steps-text  {
      font-weight: 500;
    }

    .promo-page__steps-number {
      text-shadow: unset;
      @include title-gold-shadow(2px, 1px);
    }

    .promo-page__steps-arr {
      display: flex;
    }
  }

  // Step is not starte
  .promo-step-2:not(.is-step-active),
  .promo-step-3:not(.is-step-active) {
    .promo-page__steps-number,
    .promo-page__steps-subtitle,
    .promo-page__steps-text {
      text-shadow: none;
      color: $white-40;
      box-shadow: unset;
    }

    .promo-page__steps-arr {
      display: none;
    }
  }

  // Step is finished
  .promo-step-1:not(.is-step-active),
  .promo-step-1:not(.is-step-active) ~ .promo-step-2:not(.is-step-active) {
    .promo-page__steps-subtitle,
    .promo-page__steps-text {
      color: $white;
    }

    .promo-page__steps-number {
      color: $white;
      text-shadow: 0 2px $orange-1000;
    }
  }
}
