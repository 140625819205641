$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.header-collection {
  position: relative;
  height: 200px;
  padding: 0 230px 0 0;
  transition: .2s padding;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(8px);
    background-image: url($cdn + '/wazamba/masks-collection/bunner-bg.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }

  body.linda-hide-sidebar & {
    padding: 0 115px 0;
  }

  &__info {
    position: absolute;
    top: 35%;
    left: 1%;
  }
  &__logo {
    position: relative;
    bottom: 30px;
    z-index: 51;
    width: 771px;
    height: 316px;
    margin: 0 auto;
  }
  &__logo2 {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -440px;
    width: 222px;
    height: 264px;
    transform: rotate(-24deg) translateY(-50px);
    z-index: 51;
    background-size: cover, 0, 0;
    transition: margin .2s;

    body.linda-hide-sidebar & {
      margin-left: -330px;
    }
  }
  &__logo3 {
    position: relative;
    z-index: 51;
    width: 394px;
    height: 269px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: 400px 178px, contain;
    background-position: top 43px center, top -5px center;
    transform: translateY(-36px);
  }
  &__close {
    position: absolute;
    top: 29%;
    left: 1%;
  }

  &__label {
    position: relative;
    bottom: 108px;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 16px;
    line-height: 12px;
    text-transform: uppercase;
    color: $white;
    text-shadow: 0 1px 0 $black-50;
    z-index: 51;
    width: 115px;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: drop-shadow(0 4px 2px $black-25);
  }

  .rtl-lang & {
    &__info {
      transform: scaleX(-1);

    &.g-btn:active:not(.g-btn-noclick):not(.shop-btn) {
      transform: scaleX(-1) translateY(2px);
    }
    }
  }
}
