$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
nav {
  display: grid;
  grid-auto-flow: column;
  margin-bottom: 24px;
  gap: 20px;
  place-content: center;
}

.sport-nav {
  &__link {
    cursor: pointer;
    position: relative;
    min-width: 165px;
    width: max-content;
    height: 48px;
    padding: 0 22px 0 34px;
    font-weight: 800;
    font-size: 14px;
    text-indent: 32px;
    text-align: center;
    line-height: 48px;
    color: $white;
    text-shadow: 0 0 10px $black-25;
    transition: color 200ms;
    direction: ltr;

    background-image: url($cdn + '/wazamba/sport/categories/bg-center.svg'), url($cdn + '/wazamba/sport/categories/bg-left.svg'), url($cdn + '/wazamba/sport/categories/bg-right.svg');
    background-size: contain;
    background-position: left center, left center, right center;
    background-repeat: repeat-x, no-repeat, no-repeat;
    background-clip: content-box, padding-box, padding-box;

    &::before{
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      opacity: 0;
      inset: 4px 5px;
      box-shadow: 0 0 18px 10px #5C46AC;
      border-radius: 16px;
      transition: .3s;
    }

    &:hover {
      &:before {
        opacity: 1;
        box-shadow: 0 0 18px 4px #f79afd;
        border-radius: 50%;
      }
    }

    &.is-active{
      color: #fddc76;
      pointer-events: none;
      &::before{ opacity: 1;}
    }

    &::after {
      content: '';
      width: 65px;
      height: 44px;
      left: 7px;
      bottom: 6px;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain; 
      background-position: left bottom;
    }

    &--sportbook::after { 
      background-image: url($cdn + '/wazamba/sport/categories/sport.svg');
    }
    &--virtual::after { 
      background-image: url($cdn + '/wazamba/sport/categories/virtuals.svg'); 
    }
    &--live::after { 
      background-image: url($cdn + '/wazamba/sport/categories/live.svg'); 
    }
    &--horse::after { 
      background-image: url($cdn + '/wazamba/sport/categories/horseracing.svg'); 
      left: 3px;
    }

  }
}
