$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.grid-ach-box2 {
  display: grid;
  grid-template-columns: repeat(5, 130px);
  grid-gap: 24px;
  padding: 20px 0;

  .ach-item {
    .rtl-lang & {
      @media screen and (max-width: calc(#{$l} - 1px)) {
        &:nth-of-type(3n+3) {
          @include reversed-tooltip;
        }
      }

      @media screen and (min-width: #{$l}) and (max-width: #{$xxl}) {
        &:nth-of-type(4n+4) {
          @include reversed-tooltip;
        }
      }
    }
  }

  &__big {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }

  @include l {
    grid-gap: 26px;
    grid-template-columns: repeat(6, 140px);
  }
}
