$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.gamebox-promos {
  display: flex;
  position: relative;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  background-image: url($cdn + '/wazamba/page-bg-1.jpg');
  background-position: bottom 0 right -90px;
  background-size: cover;
  background-repeat: no-repeat;
  &__wrap {
    max-width: 100%;
    border-image: url($cdn + '/wazamba/game-item.png') round;
    border-image-slice: calc(50 / 184 * 100%) calc(80 / 284 * 100%) fill;
    border-image-width: 45px 72px;
  }
  .slide {
    height: 100%;
    &__wrap {
      height: 100%;
      display: grid;
      grid-template-columns: 2fr 3fr;
      grid-template-rows: 1fr 72px;
      gap: 0 16px;
      padding: 36px 12px 18px;
      overflow: hidden;
    }
    &__left {
      display: flex;
      align-items: center;
    }
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    &__bottom {
      grid-column: 1 / -1;
    }
    &__img {
    }
    &__title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 800;
      color: $white;

      @include xxl {
        font-size: 20px;
        line-height: 24px;
      }
    }
    &__sum {
      font-size: 20px;
      line-height: 24px;
      font-weight: 800;
      color: #f5e76b;
      white-space: break-spaces;

      @include xxl {
        font-size: 24px;
        line-height: 29px;
      }
    }
    &__btn {
      align-self: end;
      justify-self: center;
      margin-bottom: 26px;
      grid-column: 1 / -1;
      &:active:not(.g-btn-noclick) {
        transform: translateY(-2px);
      }
    }
  }
  .slick-slider {
    display: flex;
    height: 100%;
    .slick-slide {
      display: grid;
    }
    .slick-arrow {
      position: absolute;
      bottom: 44px;
      z-index: 10;
      &:active:not(.g-btn-noclick) {
        transform: translateY(-2px);

        .rtl-lang & {
          transform: translateY(-2px) scale(-1 , 1);
        }
      }

      &.slick-prev {
        inset-inline-start: 12px;

        .rtl-lang & {
          transform: scale(-1 , 1);
        }
      }

      &.slick-next {
        inset-inline-end: 12px;

        .rtl-lang & {
          transform: scale(-1 , 1);
        }
      }
      &[aria-disabled='true'] {
        pointer-events: none;
        filter: grayscale(1);
        cursor: default;
      }
    }
    .slick-track {
      height: 100%;
    }
    .slick-dots {
      position: absolute;
      display: flex;
      bottom: 18px;
      left: 50%;
      transform: translateX(-50%);
      li {
        font-size: 0;

        button {
          all: unset;
          width: 12px;
          height: 12px;
          border-radius: 12px;
          margin: 0 4px;
          background-color: rgba($color: $white, $alpha: 0.5);
          cursor: pointer;
        }
        &.slick-active button {
          background-color: rgba($color: $white, $alpha: 1);
        }
      }
    }
  }
}
    
.small-font {
  letter-spacing: 0;
  & .slide__title { @include text(14px, 28px) }
  & .slide__sum { @include text(20px, 30px) }

  @include l {
    & .slide__title { font-size: 12px; }
    & .slide__sum { @include text(18px, 24px) }
  }
  @include xl {
    & .slide__title { font-size: 14px; }
    & .slide__sum { @include text(21px, 28px) }
  }
  @include xxl {
    & .slide__title { @include text(18px, 35px) }
    & .slide__sum { @include text(27px, 40px) }
  }
  @include full {
    & .slide__title { font-size: 15px; }
    & .slide__sum { @include text(23px, 36px) }
  }
}

