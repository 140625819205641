$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.reg-slider {
	width: 100vw;
	margin-inline: 50% 0;
	margin-block: 0;
	transform: translateX(-50vw);
	overflow: hidden;

	&__item {
		transition: 0.6s;
		transform: scale(0.57);

		&.swiper-slide-active {
			transform: scale(1);
		}
	}

	&__item-wrap {
		width: 400px;
		background-image: url($cdn + '/wazamba/hero/island.png');
		background-position: 50% 320px;
		background-repeat: no-repeat;
		background-size: 380px auto;
	}

	&__img {
		width: 400px;
		height: 400px;
		margin: 0 auto;
		background-repeat: no-repeat;
		background-position: center top;
		background-size: 400px auto;
	}

	&__name {
		@include text(36px, 46px, 800, $white);
		@include ellipsis;
		width: 280px;
		margin: 115px auto 0;
		text-align: center;
		letter-spacing: 0.05em;

		.swiper-slide-active & {
			margin-top: 110px;
			text-shadow: 0 0 10px $black-25;
		}
	}

	&__arrow {
		z-index: 50;
		position: absolute;
		bottom: 5px;
		transition: 0.1s;

		&_prev {
			right: calc(50% + 140px);
		}

		&_next {
			left: calc(50% + 140px);
		}

		&_disabled {
			filter: grayscale(100%);
			cursor: default;
			pointer-events: none;
		}
	}

	&__btn-over {
		position: absolute;
		top: 105px;
		left: calc(50% - 190px);
		width: 380px;
		height: 510px;
		z-index: 100;
		background: none;
		box-shadow: none;
		cursor: pointer;
		border: none;

		&.is-top-0 {
			top: 0;
		}
	}

	.slick-prev {
		position: absolute;
		bottom: 5px;
		left: calc(50% - 140px);
		z-index: 1;

		&.slick-disabled {
			filter: grayscale(100%);
			cursor: default;
			pointer-events: none;
		}
	}

	.slick-next {
		position: absolute;
		bottom: 5px;
		right: calc(50% - 140px);
		z-index: 1;
		&.slick-disabled {
			filter: grayscale(100%);
			cursor: default;
			pointer-events: none;
		}
	}

	.rtl-lang & {
		transform: translateX(50vw);
	}
}

.slick-current {
	.reg-slider__item {
		transform: scale(1);
	}
}
