$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.search {

    $search-item-width: 161px;
    @include l { $search-item-width: 143px; }
    @include xl { $search-item-width: 169px; }
    @include xxl { $search-item-width: 210px; }
    @include full { $search-item-width: 179px; }

    height: 76px;
    padding-top: 30px;

    .landing-games & { margin-bottom: 30px; }

    .rtl-lang & {
        direction: rtl;
    }

    &__bg {
        background-color: $brown-400;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 120%;
        width: 0;
        z-index: 20;
        opacity:0;
        transition-property: opacity , left ;
        transition-duration: 0.2s , 0s;
    
            .is-search-mode & {
                left: 0;
                opacity:1;
                width: 100%;
            }
        }

    h3 {
        @include text(18px, 22px, 800, transparent);
        text-align: center;
        margin-top: 2em;
        margin-bottom: 1em;
        letter-spacing: 0.05em;
        background: linear-gradient(180deg, $white 13.03%, $yellow-200 18.73%, $yellow-500 96.19%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__wrapper {
        margin-inline: 15px;
        z-index: 25;

        & > * {
            z-index: inherit;
            position: relative;
        }
    }

    &__form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;

        &-input-container {
            position: relative;

            .search {
                &__input {
                    @include text(15px, 48px, null, $white);
                    box-sizing: border-box;
                    width: 380px;
                    height: 48px;
                    padding:0 25px;
                    border-radius: 60px;
                    background-color: $black;
                    border: 2px solid $white;

                    &:focus {
                        background: $white;
                        opacity:1;
                        color: $brown-300;
                    }

                    &::placeholder {
                        @include text(14px, null, 600, inherit);
                        letter-spacing: 0.05em;
                    }
                }

                &__label {
                    position: absolute;
                    inset-inline-end: 0;
                    top: 0;

                    .is-search-mode & { display:none; }
                }

                &__close {
                    display: none;
                    position: absolute;
                    inset-inline-end: 0;
                    top: 0;

                    .is-search-mode & { display:block; }
                }
            }
        }
    }

    &__results {
        display: none;

        .is-search-mode & { display: block; }
    }

    &__noresult {
        width: 100%;
        text-align: center;
        padding:45px 0;
        font-weight:500;
        font-size: 16px;
        display: none;

        .is-search-mode & { display: block; }
    }

    &__providers {
        &-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            height: 72px;
            width: $search-item-width;
            margin: 10px 10px;
            background: radial-gradient(50% 50% at 50% 50%, rgba(149, 71, 233, 0.3) 0%, rgba(114, 29, 172, 0.3) 100%);
            border-radius: 5px;
            box-sizing: border-box;
            flex-grow: 0;
            flex-shrink: 0;
            padding-inline: 5px;
        }

        &-title {
            @include text(14px, 17px, 800, $white);
            @include ellipsis;
            text-align: center;
            width: 100%;
            margin: 0;
        }

        &-img {
            height: 30px;
            width: 30px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }

    &__providers-btn {
        @include text(14px, 48px, 800, $white);
        position: relative;
        display: block;
        min-width: 170px;
        width: max-content;
        height: 48px;
        padding-inline-start: 34px;
        padding-inline-end: 22px;
        box-sizing: border-box;
        text-align: end;

        background-image: url($cdn + '/wazamba/sport/categories/bg-center.svg'), url($cdn + '/wazamba/sport/categories/bg-left.svg'), url($cdn + '/wazamba/sport/categories/bg-right.svg');
        background-size: contain;
        background-position: left center, left center, right center;
        background-repeat: repeat-x, no-repeat, no-repeat;
        background-clip: content-box, padding-box, padding-box;
    
        &:before {
            display: block;
            content: "";
            position: absolute;
            inset-inline-start: 16px;
            top: 0;
            width: 48px;
            height: 48px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-image: url("#{$cdn}/wazamba/wazamba_provider_icon.png");

            .rtl-lang & {
                transform: scaleX(-1);
            }
        }
    
        &-img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 49px;
          z-index: -1;
        }
    
        &:hover { filter: drop-shadow(0 0 15px #f79afd); }
        &:active { transform: scale(.97); }

        span {
            padding-inline-start: 34px;
        }
    }

    &__games-flex-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .game-box-flex .game-item,
    .search__games-flex-box .game-item {
        width: $search-item-width;
        margin: 10px;
    }

    &__default-top-list {
        display: none;

        .is-search-mode & { display: block; }
        .game-box-flex { margin: 0; }
    }
}

