$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dash-bonus {
  color: $white;
  padding: 0 16px 20px;
  background-image: url($cdn + "/wazamba/dashboard/cards/bonus/Stone_gold.png");
  background-position: center;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 180px;
  height: 220px;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    width: 160px;
    height: 38px;
    left: 50%;
    transform: translateX(-50%) translateY(21px);
    top: 0;
    background-image: url($cdn + "/wazamba/leaf.png");
    background-size: 100% 40px;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
  &__info {
    margin: auto 0;
  }
  &__text {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
  }
  &__amount {
    font-weight: 800;
    font-size: 26px;
    line-height: 26px;
  }
  &__img {
    width: 88px;
    height: 77px;
    object-fit: contain;
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
  }
}
