$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-history{
  margin:0 auto;
  width: 768px;

  &__wrap{
    padding:0 25px;
  }
}
