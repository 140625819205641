$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.sidebar {
  &__easter-reminder {
    position: relative;
    font-weight: 800;
    font-size: 12px;
    line-height: 28px;
    color: $white;
    text-transform: capitalize;

    padding-left: 8px;
    padding-right: 16px;

    filter: drop-shadow(0 2px 2px $black-20);
    background-color: $red-200;
    clip-path: polygon(
                    0 0,
                    calc(100% - 8px) 0,
                    calc(100% - 8px) 6px,
                    100% 50%,
                    calc(100% - 8px) calc(100% - 6px),
                    calc(100% - 8px) 100%,
                    0 100%
    );
    animation: shake-horizontal 2.5s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955) both;

    &-container {
      position: absolute;
      top: 10px;
      left: -20px;
      animation: reminder 10s linear infinite;
      margin-right: 20px;
      transform: translateX(-100%);
    }
  }
}

@keyframes reminder {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes shake-horizontal {
  0%,
  100% {
    left: 0;
  }

  10%,
  30%,
  50%,
  70% {
    left: -5px;
  }

  20%,
  40%,
  60% {
    left: 5px;
  }

  80% {
    left: 4px;
  }

  90% {
    left: -4px;
  }
}
