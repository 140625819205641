$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.info {
    max-width: 1020px;
    margin: 0 auto;
    padding-top: 40px;
    &__title {          
        padding: 14px 0;
        margin: 0;
        margin-bottom: 20px;    
        font-size: 24px;
        line-height: 32px;
        font-weight: 800;
        color: #fcde75;
        letter-spacing: 0.05em;
        text-align: center;
        text-shadow: 0 0 10px $black-25;
    }
    &__article {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: $white;
        p {
            margin: 0;
            margin-bottom: 10px;
        }
    }
}
