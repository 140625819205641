$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  &__overlay,
  &__overlay-crab,
  &__search {
    opacity: 0;
    z-index: -1;
    transition-property: z-index, opacity;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
  }

  &__sidebar {
    &-sideblock {
      z-index: 2;
      min-width: var(--sidebar-width);
      background-image: url($cdn + '/wazamba/game-page/header-pattern-bg.png');
      display: inline-flex;
      background-size: contain;
      background-position: top center;
      background-repeat: repeat;
    }

    &-dep,
    &-cup {
      width: 100%;
    }
  }

  &__overlay,
  &__overlay-crab {
    position: absolute;
    background-color: $black-40;
    inset: 0;
  }

  &__search {
    background-color: rgba(23, 10, 7, 0.9);
    position: absolute;
    inset: 0;
    width: 100vw;
  }
}
