$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.contact-bar {
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;

  & svg {
    fill: $white;
    height: 13px;
    width: 13px;
    margin-inline-end: 6px;
  }
}

//- Moved to separate declaration to increase specificity
a.contact-bar__item {
  margin: 4px 10px;
  font-size: 11px;

  &::after {
    inset-inline-start: 19px;
  }
}
