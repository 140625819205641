$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.promos{
  display: grid; 
  grid-template-columns: repeat(2,328px);
  gap: 130px 60px;
  justify-content: center;
  padding-top:154px;
  text-shadow: 0 0 10px $black-25;

  &__tabs {
    margin: 33px auto 10px;
  }

  &__label {
    padding: 3px 8px;
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.9px;
    border-radius: 40px;
    background: rgba(137, 105, 82, 0.50);
  }

  &__item{
    position:relative;
    display: flex; 
    flex-direction: column;
    min-height: 452px;
    width: 100%;
    padding: 130px 12px 40px;
    justify-self: end;
    text-align: center;
    background-image: url( $cdn + '/wazamba/promo_bg1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &:nth-child(2n) {
      justify-self: start;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__leaf{
    position:absolute;
    width: 368px;
    height: 91px;
    background: url( $cdn + '/wazamba/leaf.png') 50% bottom no-repeat;
    background-size: contain;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__case{
    position:absolute;
    width: 256px;
    height: 220px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    top: -90px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__sum{
    @include text(28px, null, 800, $white);
    letter-spacing: 0.05em;
    margin-bottom: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: balance;
  }
    body.rtl-lang &__sum{
      direction: rtl
    }
  &__name{
    @include text(24px, 36px, 800, $white);
    letter-spacing: 1.2px;
    margin: 0;
    align-self: center;
  }

  &__btn{
    width: 180px;
    margin:12px auto 0;
  }

  &__links{
    margin-top:20px;
  }

  &__link{
    @include text (14px, normal, 800);
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
  }

  @include l{
    grid-template-columns: repeat(2,442px);
    gap: 125px 20px;
    &__item{ background: url( $cdn + '/wazamba/promo_bg2.png') no-repeat / 100% 100% ; }
    &__leaf{ width: 405px; height: 100px; top:-65px; }
    &__case{ width: 280px; height: 241px; top:-110px; }
  }

  @include xl{
    grid-template-columns: repeat(3,328px);
    gap: 125px 60px;
    &__item{ background: url( $cdn + '/wazamba/promo_bg1.png') no-repeat / 100% 100%;}
    &__leaf{ width: 368px; height: 91px; top:-50px; }
    &__case{ width: 255px; height: 220px; top:-90px; }
  }

  @include xxl{
    grid-template-columns: repeat(3,442px);
    gap: 125px 30px;
    &__item{ background: url( $cdn + '/wazamba/promo_bg2.png')  no-repeat / 100% 100%;}
    &__leaf{ width: 405px; height: 100px; top:-65px; }
    &__case{ width: 280px; height: 241px; top:-110px; }
  }

  @include full{
    grid-template-columns: repeat(auto-fill, 328px);
    gap: 125px 60px;
    &__item{ background: url( $cdn + '/wazamba/promo_bg1.png') no-repeat / 100% 100%;}
    &__leaf{ width: 368px; height: 91px; top:-50px; }
    &__case{ width: 255px; height: 220px; top:-90px; }
  }

  .rtl-lang & {
    direction: rtl;
    
    &__tabs {
      direction: rtl;
    }
  }
}
