$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.landing-promo {
  $root: &;

  padding: 50px 290px 0 40px;
  background-image: linear-gradient(0deg, rgba(7,31,62,1) 20%, rgba(7,31,62,0.7021183473389356) 50%, rgba(7,31,62,0) 100%),
                    url($cdn + '/wazamba/wazamba-v01/backgrounds/wazamba_promovotionblock_mob.jpg');
  background-size: 100% 390px, 100% auto;
  background-position: center bottom, left top;
  background-repeat: repeat-x, no-repeat;

  @include l {
    background-position: center bottom, left -5vw;
  }

  @include xl {
    background-position: center bottom, left -12vw;
  }

  @include full {
    background-position: center bottom, left -15vw;
  }

  &__title {
    margin-bottom: 150px;
    text-align: center;
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.05em;
    color: #faef67;
    text-shadow: 0 0 10px $black-25, 0 2px 0 $orange-900;
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__block {
    margin-top: 100px;
    width: 100%;
  }

  &__text {
    position: relative;
    font-weight: 900;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    color: $white;
    text-shadow: 0 2px 2px $black-70;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    z-index: 50;

    @include xxl {
      font-size: 20px;
      line-height: 24px
    }
  }

  &__content {
    position: relative;
    width: 688px;
    min-height: 250px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &__content-head {
    position: relative;
    height: 54px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjg4IiBoZWlnaHQ9IjU2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0yMDAuNjY3Ljg1aDc1LjU5bC0uMzQ2LS43OTMgMjkuMjYxLjc5M2gzNC4xODNsOS4yMjEtLjUxNiAyLjQ3NC41MTZoMS41ODh2Mi41NzhsMS43MzItMS45ODcgMy42MjEtLjU1M2g4NC43MzVsMi44OTcuNTUzaDIuNDE1bDIuNDE0LS41NTNoMy42MjFsMy4zOCAxLjM4NGgzLjM3OWw3LjQ4NC0uODMgMi42NTYtLjgzMSA3OS40MjQuMjc3IDQuMTA0IDEuMzg0IDMuMzguODMgMS4yMDcgMS4xMDhoMi44OTdjMS43NyAxLjI5MiA1LjI2MyAzLjgyMSA1LjA3IDMuNi0uMTk0LS4yMjItNC4yNjUtMy43ODQtNi4yNzctNS41MzhWLjg4OGwxLjQ0OC0uMjc3IDkwLjU3Ni0uMjc3TDY4OCAzNC41NjR2OC43MzhsLTcuMzMtMi4wOTIgNy4zMyA1LjU4djkuMDY3SC4zMDFsLjA0LTcuNjNMMi4wNSA0NC45bDEuMDI1LTIuNzQgMS4zNjYtLjk3OXYtMi4zNDhjMS41OTMtMS40MzYgNC43MTItNC4yNjcgNC40NC00LjExLS4yNzQuMTU2LTQuNjY4IDMuNDU3LTYuODMxIDUuMDg4SC4zNDJMMCAzOC42Mzd2LTcuNjNMMzEuMDk0IDEuODQyIDk5LjU4Ljg1bDIuMzkxLjU4NyA4LjUzNy41ODdoMy4wNzRMMTEyLjg5OS44NWg3My43NjZsMy4wNzQuNTg3IDMuNDE1LjU4NyAzLjQxNS0uMTk2IDIuNzMyLS4zOTEgMS4zNjYtLjU4N3oiIGZpbGw9IiNGNEFGMzUiLz48L3N2Zz4=");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    z-index: 1;
  }

  &__content-body {
    background-color: #f4af35;
    min-height: 50px;
    position: relative;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      bottom: -35px;
      left: 0;
      height: 62px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjg4IiBoZWlnaHQ9IjYzIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0uMzY3LjY3N0wwIDE5LjgybDMuNDEzLS45NzgtMi4zODkgNi40NTh2MTAuNTgzTDI4IDYyLjA1N2wxOTMuMDQ4LS4wMTMgOC41MzMtMS41NjZoMi43MzFsMy4wNzIgMS41NjZoMTE3LjQyMXYtLjc5M2gxLjI0OGwxLjIwNi0uODNoMi4xNzJjLjE5MyAwIDEuMjA2LjU1MyAxLjY4OS44M2g0NS4zNjNsLTIuNDEzLTMuNTk5IDQuMzQzIDMuNkg1MzguNGwyLjg5NS0xLjEwOCAxLjIwNy0uODMgMS4yMDYuODMuNzI0LS44M2gxLjkzbDIuODk2LjgzaDEuOTNsMS40NDgtMS45MzggMi4xNzIgMi4yMTQgMS42ODkuODMxaDEwMy42NTFMNjg4IDM2LjQ2M1YyMi45OTdsLTEuOTU1LTExLjg1N0w2ODggMTMuMjMyVi42NzdILjM2N3oiIGZpbGw9IiNDNTgzMEYiLz48cGF0aCBkPSJNLjI2LjY3N0wwIDE0LjI1bDMuNDEzLS45NzgtMi4zODkgNi40NTd2MTAuNThMMjggNTYuNDc4bDE5My4wNDgtLjAxNCA4LjUzMy0xLjU2NmgyLjczMWwzLjA3MiAxLjU2NmgxMTcuNDIxdi0uNzkzaDEuMjQ4bDEuMjA2LS44M2gyLjE3MmMuMTkzIDAgMS4yMDYuNTUzIDEuNjg5LjgzaDQ1LjM2M2wtMi40MTMtMy41OTggNC4zNDMgMy41OThINTM4LjRsMi44OTUtMS4xMDcgMS4yMDctLjgzIDEuMjA2LjgzLjcyNC0uODNoMS45M2wyLjg5Ni44M2gxLjkzbDEuNDQ4LTEuOTM3IDIuMTcyIDIuMjE0IDEuNjg5LjgzaDEwMy42NTFMNjg4IDMwLjg5di03Ljg5MmwtMS45NTUtMTkuNTNMNjg4IDUuNTZWLjY3N0guMjZ6IiBmaWxsPSIjRjRBRjM1Ii8+PC9zdmc+");
      background-repeat: no-repeat;
      background-position: center bottom;
    }
  }

  &__content-text {
    padding-left: 90px;
    padding-right: 90px;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: $white;
    text-shadow: 0 0 4px $black-40;
    transform: translateY(-15px);
    white-space: break-spaces;

    max-height: 0;
    opacity: 0;
    overflow: hidden;

    transition-delay: .1s, 0ms;
    transition-property: opacity, max-height;
    transition-duration: .5s, .5s;

    p {
      margin: 0;
    }
  }

  &__item {
    position: relative;
    width: 200px;
    height: 220px;
    cursor: pointer;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url($cdn + '/wazamba/wazamba_promo_shining.png');
      background-size: contain;
      background-position: center 85%;
      background-repeat: no-repeat;
      transform-origin: 50% 50%;
      transform: scale(2.5);
      animation-name: spin-light;
      animation-duration: 10s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      transition-property: transform, opacity;
      transition-duration: 200ms;
      pointer-events: none;
      opacity: 0;
      z-index: -1;
    }

    &.active {
      pointer-events: none;

      &::before {
        opacity: 1;
        transform: scale(2.5);
      }

      & > #{$root}__case {
        transform: scale(1.15);
      }

      &:nth-child(1) ~ #{$root}__block #{$root}__content-text:nth-child(1),
      &:nth-child(2) ~ #{$root}__block #{$root}__content-text:nth-child(2),
      &:nth-child(3) ~ #{$root}__block #{$root}__content-text:nth-child(3) {
        max-height: 1000px;
        opacity: 1;
        transition: 1s;
        transition-delay: .5s;
      }
    }

    @include l {
      width: 220px;
      height: 240px;
      margin-left: 20px;
      margin-right: 20px;
    }

    @include full {
      width: 250px;
      height: 270px;
    }
  }

  &__case {
    position: relative;
    margin-bottom: 5px;
    height: 100%;
    background-image: url( $cdn + '/wazamba/sprite-case.png');
    background-repeat: no-repeat;
    background-size: 300%;
    transition: transform 250ms ease;
    transform-origin: center bottom;
    z-index: 10;

    &_1 { background-position: left center };
    &_2 { background-position: center center };
    &_3 { background-position: right center };
  }
}

@keyframes spin-light {
  from {
    transform: rotate(0deg) scale(3);
  }
  to {
    transform: rotate(360deg) scale(3);
  }
}
