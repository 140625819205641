$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.small-tournament {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border-bottom: solid 4px  #0e0a02;

  &__wrap {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: auto 100%;
    padding: 60px 8px 15px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
  }

  &__date {
    @include text(14px, 1.8em, bold, $yellow-700);
    text-shadow: 0 2px 10px $black-25;
  }

  &__title {
    @include text(20px, null, 800, $white);
    text-shadow: 0 0 10px $black-25;
    letter-spacing: 1px;
    margin-top: 5px;
  }

  &__prize {
    @include text(20px, null, 900, $white);
    margin-top: 5px;
    background-image: linear-gradient(to top, $white, $yellow-200 7%, $yellow-500), linear-gradient(to bottom, $white, $white);
    letter-spacing: 1px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__label {
    position: absolute;
    top: -5px;
    inset-inline-end: -10px;
    transform: rotate(20deg);

    .rtl-lang & {
      transform: rotate(340deg);
    }
  }

  &__btns {
    a {
      font-size: 18px;
    }

    &.small-font a {
      padding-inline: 5px;
      font-size: 14px;

      @include xl {
        font-size: 16px;
      }
    }
  }
}
