$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.sidebar__link-summer-promotion {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    line-height: 30px;
    gap: 10px;
    margin-bottom: 10px;
    padding: 0;
    position: relative;
  
    .sidebar__link-img {
        height: 32px;
        width: 32px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-block: auto;
        flex-shrink: 0;
        position: relative;
        &::after{
            content: attr(data-label);
            font-size: 7px;
            font-weight: 900;
            text-transform: uppercase;
            background: $red-200;
            border-radius: 4px;
            box-shadow: 0 2px 0 0 $red-300;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            max-width: 25px;
            width: 100%;
            height: 12px;
            text-align: center;
            line-height: 12px;
            display: block;
        }
    }
  
    span {
      text-transform: capitalize;
    }
  }

.popup {
    .summer-popup{
        margin: 0 auto;
    }
    .container-reward{
        max-width: 350px;
        top: 50%;
        transform: translateY(-50%);
    }
}
