$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.tournaments {
  padding:0 18px;

  @include xl {
    padding:0 38px;
  }

  &-placeholder {
    @include text(18px, 140%, 700, $white);
    background-color: $black-25;
    border: 1px solid rgba(255, 243, 120, 0.5);
    box-shadow: inset 0 0 4px $black-25;
    border-radius: 10px;
    padding: 40px;
    text-align: center;
    margin: 60px auto 0;
    max-width: 800px;
  }

  &__tabs {
    margin-block: 33px 10px;
  }
}

.tournament-card {
  position: relative;
  min-height: 312px;
  border-bottom: solid 4px $blue-1000;
  margin: 36px 0;
  display: flex;
  background-size: cover;

  .rtl-lang & {
    direction: rtl;
    margin: 65px auto 36px;
  }

  &.is-finished .tournament-card {
    &__hero,
    &__bg,
    &__prize {
      filter: grayscale(100%);
    }

    &__date,
    &__link {
      color: $white;

      &:hover {
        color: $white;
      }

      .timer,
      .date-title {
        color: $white;
      }
    }
  }

  &__bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__status {
    position: absolute;
    top: -10px;
    inset-inline-end: -20px;
    z-index: 1;
    width: 115px;
    height: 45px;
    transform: rotate(15deg);

    .rtl-lang & {
      transform: rotate(345deg);
    }
  }

  &__hero {
    position: absolute;
    bottom: 0;
    inset-inline-start: -26px;
    width: 268px;
    height: 324px;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: left center;
  }

  &__content {
    margin-top: auto;
    margin-bottom: auto;
    margin-inline-end: 10px;
    margin-inline-start: auto;
    width: 485px;
    min-height: 80% ;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    position: relative;
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: $yellow-700;

    .timer,
    .date-title {
      @include text(14px, 1.43, bold, $yellow-700);
      text-shadow: 0 2px 10px $black-25;
      letter-spacing: 0.4px;
      margin: 0;
    }

    .date-title {
      span {
        display: inline-block;
        margin: 0 2px;
      }
    }
  }

  &__name {
    @include text(36px, null, 800, $white);
    text-shadow: 0 0 10px $black-25;
    letter-spacing: 1.8px;
    margin: 12px 0 0;
  }

  &__prize {
    font-size: 48px;
    font-weight: 900;
    letter-spacing: 2.4px;
    margin: 4px auto 12px;
    display: flex;
    background: linear-gradient(#fff 13.03%, #fcfe7e 18.73%, #f4d642 96.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.has-coins::before {
      content: "";
      margin-right: 7px;
      width: 37px;
      height: 37px;
      background-image: url($cdn + "/wazamba/sprite-tournament-page.png");
      background-position: 0 -77px;
      background-size: 266px;
      flex-shrink: 0;
      margin-top: 10px;
    }
  }

  &__btn {
    margin-top:15px;
  }

  &__link {
    @include text(14px, 17px, bold);
    letter-spacing: 0.05em;
  }

  &__places {
    display:none;
    position:absolute;
    right: 0;
    width: 250px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  &__place {
    height: 65px;
    display: flex;
    align-items: flex-end;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.9px;
    color: $white;
    margin: 5px 0;
  }

  &__place-ico {
    height: 53px;
    width: 40px;
    border-radius: 10px;
    margin-right: 10px;
    background-image: url( $cdn + "/wazamba/sprite-tournament-page.png");
    text-align: center;
    padding-top: 20px;
    font-size: 24px;

    &_1{ background-position: -4px -46px; }
    &_2{ background-position: -50px -46px; }
    &_3{ background-position: -95px -46px; }
  }


  @include l {
    &__places {
      display:flex;
    }

    &__content {
      margin-top: 0;
      margin-bottom: 0;
      margin-inline-end: 0;
      margin-inline-start: auto;
    }
  }
  @include xxl {
    &__content {
      margin-top: 0;
      margin-bottom: 0;
      margin-inline-end: 80px;
      margin-inline-start: auto;
    }
  }

  @include full {
    max-width: 1200px;
    margin-inline: auto;
  }

  &__leaderboard {
    display: none;
    align-items: center;
    padding-inline-end: 24px;
    position: relative;

    @include l { display: flex; }

    &-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      padding: 10px 0;
    }

    &-price {
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: $white;
      text-shadow: 0 1px 0 $orange-900;
      position: relative;
      text-align: end;

      &.has-coins {
        width: fit-content;
        margin-inline-start: auto;
        padding-inline-start: 18px;

        &:after {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url($cdn + "/wazamba/sprite-tournament-page.png");
          background-size: 115px;
          background-position: 0 -33px;
          position: absolute;
          inset-inline-start: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &-item {
      align-items: center;
      justify-content: space-between;
      width: 278px;
      background: $black-25;
      box-shadow: inset 0 4px 4px $black-25;
      border-radius: 5px;
      padding: 0 10px;
      display: grid;
      grid-template-columns: .2fr 0.3fr .5fr .5fr;

      &.is-0,
      &.is-1,
      &.is-2 {
        .tournament-card__leaderboard-num {
          &::before {
            content: "";
            position: absolute;
            width: 76px;
            height: 40px;
            transform: rotate(-7deg);
            background-image: url($cdn + "/wazamba/wazamba-v01/tournaments/tournament_label_top3.svg");
            inset-inline-start: -30px;
            top: -4px;
            z-index: -1;
            background-size: cover;
          }

          .rtl-lang & {
            &::before {
              transform: rotate(-7deg) scale(-1, 1);
            }
          }
        }
      }

      &.is-current-user {
        background: #F4CA33;
        box-shadow: 0 0 6.84883px rgba(222, 224, 61, 0.5);

        .tournament-card__leaderboard-info {
          color: #725500;
        }
      }

      &.is-last {
        grid-template-columns: .3fr .4fr .5fr .5fr;
        margin-top: 12px;
        background-color: #F4CA33;
        background-image: url($cdn + "/wazamba/wazamba-v01/tournaments/tournaments-zigzag-myplace.svg");
        background-position: center top;
        box-shadow:
                0px -3px 0px 0px #cfac2d,
                0px -6px 0px 0px #bf9e2a,
                0px -9px 0px 0px #a08628,
                0px -12px 0px 0px #8a7422;

        .tournament-card__leaderboard-num,
        .tournament-card__leaderboard-name,
        .tournament-card__leaderboard-score,
        .tournament-card__leaderboard-price {
          color: #725500;
        }

        .tournament-card__leaderboard-price {
          text-shadow: none;
        }

        .tournament-card__leaderboard-img {
          margin-left: 0;
          bottom: 9px;
          transform: scale(1.4);
        }
      }

      &.is-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        padding: 0 10px;

        .tournament-card__leaderboard-img {
          margin-left: 0;
        }
      }
    }

    &-info {
      @include text(12px, 15px, 800, $white);
    }

    &-num {
      @include text(24px, 29px, 800, $white);
      position: relative;
      z-index: 1;
    }

    &-img {
      width: 48px;
      position: relative;
      z-index: 1;
      transform: scale(1.2);
      margin-inline-start: -20px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 48px;
    }

    &-name {
      .rtl-lang & {
        direction: ltr;
        text-align: end;
      }
    }
  }
}

.tournaments__item-wrapper.is-active .tournament-card.is-finished {
  display: none;
}

.tournaments__item-wrapper.is-finished .tournament-card.is-active {
  display: none;
}
