$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-widget {
  text-align: center;

  &__title {
    @include text(24px, null, 800, $brown-300);
    text-shadow: 0 0 10px $black-25;
    letter-spacing: 1.2px;
  }

  &__total {
    height: 163px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__total-ico {
    width: 205px;
    height: 205px;
    flex-shrink: 0;
    background-image: url($cdn + '/wazamba/wazamba-v01/hero-room/achievements_icon.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    .rtl-lang & {
      background-image: url($cdn + '/wazamba/wazamba-v01/hero-room/achievements_icon-ar.png');
    }
  }

  &__total-count {
    @include text(48px, null, 800);
    width: 120px;
    text-shadow: 0 0 10px $black-25;
    letter-spacing: 2.4px;
  }

  &__box {
    display: block;
    height: 180px;
  }

  &__last {
    @include text(14px, 1.41, 500, $white);
    background-color: rgba(110, 57, 35, 0.5);
    padding: 5px 15px 5px 120px;
    width: 310px;
    letter-spacing: 0.7px;
    margin: 30px 0 20px;
    text-align: left;
    position: relative;
  }

  &__last-item {
    position:absolute;
    left: -10px;
    top: 50%;
    width: 100px;
    transform: translateY(-50%);
  }

  &__last-desc {
    height: 76px;
    max-height: 76px;
    overflow: hidden;
  }
}
