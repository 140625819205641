$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.mask-progress{
  width: 100%;
  padding: 1px;
  border-radius: 90px;
  background: #441E12;
  border: 3px solid #B44B33;
  position: relative;
  text-align: center;
  margin-inline-end: 10px;
  box-shadow: 0 3px 0 0 rgb(69, 30, 19);
  span{
    position: absolute;
    top: 4px;
    inset-inline-start: 44%;
    color: $white;
    font-weight: 800;
    font-size: 18px;
    line-height: 100%;
    text-shadow: 1px 2px 0 $black-70;
  }
  &__line{
    padding: 12px 0;
    border-radius: 90px;
    background: linear-gradient(180deg, #F2B600 -5.33%, $orange-1000 93.92%);
    box-shadow: 0 0 1.24983px $black-30;
  }
  &__arrow{
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background-size: 100%;
    background-position: center center;
    background-image: url($cdn + "/wazamba/masks-collection/icon/icon-arrow-right-progress.png");
    background-repeat: no-repeat;
  }
}
