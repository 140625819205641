$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-earn-coins {
  &__block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: start;
    background-color: rgb(14, 51, 88);
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 12px;

    &-title {
      text-align: inherit;
      margin: 0;
      @include text( 16px, 19px, 700, $white );
    }

    &-text {
      @include text( 14px, 17px, 500, rgba(111, 173, 236, 1) );
      margin: 0;
    }

    &-btn {
      min-width: 200px;
      margin-inline-end: auto;
      margin-top: 8px;
    }
  }
}
