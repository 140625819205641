$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.crypto-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__item {
    padding: 28px 24px;
    background-color: $white;
    box-shadow: 0 2px 6px rgba(136, 136, 136, 0.3);
    border-radius: 5px;
    width: calc(100% / 2 - 4px);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    min-height: 112px;
    cursor: pointer;

    &:hover{
      background: linear-gradient(to bottom,$yellow-400,$orange-200);
      box-shadow: 0 4px 8px rgba(110, 57, 35, 0.456);
    }
  }

  &__icon {
    width: 120px;
    height: 72px;
    flex-shrink: 0;
    margin-inline-end: 24px;
    object-fit: cover;
    object-position: bottom;
  }

  &__name {
    font-size: 14px;
    line-height: 140%;
    font-weight: 500;
    color: #17182F;
    margin-bottom: 4px;
  }

  &__descr {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #7E6294;
  }
}
