$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.page-verification-alert {
  position: sticky;
  width: 100%;
  display: none;
  padding: 12px 10px 12px 18px;
  background-color: #C23838;
  color: $white;
  align-items: center;
  z-index: 100;
  top: 0;

  &.is-verified {
    background-color: #3E992F;
  }

  &.is-verification-changed {
    display: flex;
  }

  &__text {
    @include text(13px, 130%, 600, $white);
    font-style: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: calc(50% - 120px);
    transform: translateX(-50%);

    &:before {
      width: 20px;
      height: 20px;
      display: block;
      content: '';
      background: url($cdn + '/wazamba/verification/Component_need_ver.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-inline-end: 8px;
      .is-verified & {
        background: url($cdn + '/wazamba/verification/Component_done.svg') no-repeat;
      }
    }
  }

  &__link {
    font-weight: 600;
    margin: 0 4px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    margin-inline-start: 8px;
    cursor: pointer;
    transition: .3s;

    &.is-arrow {
      background-size: 10px 14px;
      background-image: url($cdn + '/wazamba/verification/Vector.svg');

      &:hover {
        background-color: $white-26;
      }
    }

    &.is-close {
      background-size: 24px;
      background-image: url($cdn + '/wazamba/verification/close.svg');

      &:hover {
        background-color: $white-26;
      }
    }
  }

  .rtl-lang & {
    &__text{ 
      direction: rtl;
    }
    &__icon {
      transform: scale(-1,1);
    }
  }
}
