$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.shop-item-money-bet {

  &__card {
    $card-shadow-color: rgba(255, 162, 109, 0.3);
    position: relative;
    height: 280px;
    min-width: 180px;
    background-image: url($cdn + '/wazamba/shop/bonus-backgrounds/freespin_wood-pattern.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 12px;
    scroll-snap-align: start;
    //width: 180px;
    box-shadow:
            inset 0 2px 3px 0 $card-shadow-color,
            inset 0 -4px 12px 0 rgba(39, 11, 5, 0.2),
            inset 0 0 0 1px $white-10,
            0 4px 8px 0 $black-10;

    @include l {
      min-width: 192px;
    }

    @include xl {
      min-width: 184px;
    }


    .is-freebet & {
      $card-shadow-color: rgba(109, 211, 255, 0.3);
      background-image: url($cdn + '/wazamba/shop/bonus-backgrounds/sport_wood-pattern.png');
    }

    .shop-button {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      bottom: 14px;
      left: 0;
      right: 0;
      min-width: 116px;
      max-width: max-content;
      margin-inline: auto;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;

      .rtl-lang & {
        direction: ltr;
      }

      img {
        width: 20px;
      }
    }
  }

  .grid-layout {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px 12px;
  }

  &__offer {
    margin-top: -30px;

    p {
      //font-size: 28px;
      line-height: 40px;

      &.currency-font {
        padding-top: 10px;

        &-default { font-size: 24px; }
        &-medium { font-size: 20px; }
        &-small { font-size: 16px; }
      }
    }
  }

  &__img {
    position: relative;
    bottom: 50px;
    width: 180px;
    aspect-ratio: 1 / 1;
    margin: 0 auto;
  }

  &__bonus-name {
    $bonus-name-bg-color: rgba(80, 6, 6, 0.25);
    @include text(10px, 20px, 800, $white);

    padding-bottom: 4px;
    margin-bottom: -4px;
    position: relative;
    top: -3px;

    background-image: url($cdn + '/wazamba/shop/bonus-backgrounds/casino_bonus-name_bg.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: 109px;
    filter: drop-shadow(0 3px 3px $bonus-name-bg-color);
    margin-block-start: 0;

    .is-freebet & {
      $bonus-name-bg-color: rgba(6, 44, 80, 0.25);
      background-image: url($cdn + '/wazamba/shop/bonus-backgrounds/sport_bonus-name_bg.png');
    }
  }

  &__bg {
    position: absolute;
    inset: 8px 8px 68px;

    background-image: url($cdn + '/wazamba/shop/bonus-backgrounds/bonus_star_bg.png'), url($cdn + '/wazamba/shop/bonus-backgrounds/brown_cropped_bg.png');
    background-size: contain, 100% 100%;
    background-repeat: no-repeat, no-repeat;
    background-position: center center;
    background-blend-mode: overlay;

    .is-freebet & {
      background-image: url($cdn + '/wazamba/shop/bonus-backgrounds/bonus_star_bg.png'), url($cdn + '/wazamba/shop/bonus-backgrounds/blue_cropped_bg.png');
    }
  }

}
