$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dashboard-progress {
  display: flex;
  align-items: center;
  &__icon {
    width: 64px;
    height: 68px;
    margin-top: -13px;
  }
  &__bar {
    width: 198px;
    height: 11px;
    background: #070F25;
    box-shadow: inset 0 1px 14px 2px #465C81;
    border-radius: 5px;
    margin-inline-start: -15px;
    margin-inline-end: -15px;
    position: relative;
  }
  &__val {
    width: 0;
    height: 11px;
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    border-radius: 15px;
  }
  &__block {
    position: relative;
    z-index: 3;
    &-inactive {
      .dashboard-progress__total {
        color: #D3D3D3;
        inset-inline-start: unset;
        inset-inline-end: 100%;
      }
      .dashboard-progress__number {
        inset-inline-end: 0;
      }
    }
  }
  &__total {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #F5BC15;
    position: absolute;
    top: 0;
    inset-inline-start: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__circle {
    width: 23px;
    height: 23px;
    position: absolute;
    inset-inline-end: -2px;
    bottom: 7px;
    &_number {
      font-size: 11px;
      font-weight: 800;
      line-height: 14px;
      width: 23px;
      height: 23px;
      box-shadow: inset 0 0 3px $black-25;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;
      box-sizing: border-box;
    }
    &:after {
      display: block;
      content: "";
      width: 23px;
      height: 23px;
      position: absolute;
      top: 1px;
      border-radius: 50%;
      z-index: 1;
    }
    &-orange {
      .dashboard-progress__circle_number {
        color: #8F6601;
        background: #ffc414;
        border: 3px solid #FFE562;
      }
      &:after {
        background: #C98E00;
      }
    }
    &-blue {
      .dashboard-progress__circle_number {
        color: #E8F5FF;
        background: #113E5E;
        border: 3px solid #648FC5;
      }
      &:after {
        background: #042247;
      }
    }
  }
  &__gift {
    position: absolute;
    left: 0;
    bottom: 5px;
    width: 25px;
    height: 25px;
  }
}
