$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-page {
  box-sizing: border-box;
  min-height: 100vh;
  padding-top:50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position:relative;

  &__hero {
    position:absolute;
    left: calc( 50% + 30px);
    top: calc( 50% - 110px);
    width: 400px;
    height: 400px;
    background-image: url( $cdn + "/wazamba/hero/1_0.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 30;
  }

  &__content {
    position:relative;
    z-index: 40;
    margin:auto 0;
    width: 590px;
  }

  &__footer {
    padding-top:20px;
  }

  &__promo {
    @include text(16px, 20px, 800, $white);
    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom:20px;
  }

  &__header {
    position:relative;
  }

  &__title {
    @include text(28px, 39px, 800, $yellow-600);
    max-width:  370px;
    text-align: center;
    margin:0 auto;
    text-shadow: 0 3px 0 $orange-900;
    letter-spacing: 0.05em;
  }

  &__back {
    position:absolute;
    top: -2px;
    left: 0;
  }

  &__select-amount {
    @include text(18px, 30px, 800, $white);
    position:absolute;
    top: 0;
    right: 0;
    max-width: 100px;
    padding:5px 0;
    text-shadow: 0 0 10px $black-25;
    white-space: nowrap;
  }

  &__page2 {
    margin-top:20px;
    min-height: 370px;
    line-height: 0;
    position: relative;
    overflow: hidden;
    z-index: 2;
    background-color: $white;
    border-radius: 10px;

    & iframe{
      width: 100%;
      min-height: 374px;

    }
  }

  &__icon{
    width: 142px;
    height: 40px;
    margin: 5px auto 0;
  }

  &__amounts{
    margin-top:15px;
    display: flex;
    justify-content: center;
  }
  &__amount{
    margin:0 10px;
  }


  &__form{
    width: 340px;
    margin:20px auto;
  }
  &__input{
    @include text(18px, 60px, 800, rgba(41, 41, 41, 0.4));
    text-align: center;
    padding:0 60px;
    -moz-appearance: textfield;
  }

  &__rules{
    @include text(12px, 150%, 500, $white);
    text-align: center;
    letter-spacing: 0.05em;
    text-shadow: 0 0 10px $black-25;

    & a{
      font-weight: bold;
      margin:0 5px;
    }
  }

  &__steps {
    margin:24px 0;
    display: flex;
    justify-content: center;
  }

  &__step {
    width: 140px;
    margin: 0 5px;
    color: $white;
    text-align: center;
    overflow: hidden;
  }

  &__step-title {
    @include text(16px, 20px, 800);
    padding-top:5px;
    opacity:0.6;

    .bankid-page__step.active & { opacity:1;}
  }

  &__step-text {
    @include text(12px, 15px, 500);
    padding-top:5px;
    opacity:0.6;

    .bankid-page__step.active & { opacity:1;}
  }

  &__step-icon {
    width: 28px;
    height: 28px;
    margin:4px auto;
    opacity:0;

    .bankid-page__step.active & { opacity:1;}
  }

  &__icons {
    padding-bottom:20px;
    display: block;
  }

  @include l {
    &__hero {
      left: calc( 50% + 140px);
    }
  }
}
