$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.preserve-3d {
  transform-style: preserve-3d;
}

.perspective {
  perspective: 1px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;


  &__banner-content{
    z-index: 28;
  }
  &__banner-logo {
    z-index: 29;
  }
  &__banner-btn {
    z-index: 30;
  }

  &__widget-events {
    transform: translateZ(0.05px) scale(0.95);
  }

  &__jackpot {
    transform: translateZ(0.1px) scale(0.9);
  }

  &__chests {
    transform: translateZ(0.1px) scale(0.9);
    z-index: 31;
  }
}

@-moz-document url-prefix() {

  .firefox-not-preserve3d {
    transform-style: initial;
  }

  .firefox-not-persp-el {
    transform: translateZ(0) scale(1);
  }

}


