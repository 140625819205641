$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
linda-registration-page {
	.popup__container {
		background-image: url($cdn + '/wazamba/regisrtation-page/bg.jpg');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: 1294px;

		@media (-webkit-max-device-pixel-ratio: 2) {
			background-image: url('#{$cdn}/wazamba/regisrtation-page/bg@2x.jpg');
		}

        @include l {background-size: 1690px;}
        @include xl {background-size: 1910px;}
        @include xxl {background-size: 2176px;}
        @include full {background-size: 2698px;}
	}
	.step-avatar {
		.popup__wrap.g-board.g-board-1.registration {
			background: none;

			&:before {
				content: none;
			}

			.popup__logo {
				display: none;
			}
		}

		.popup__container {
			padding-top: 64px;
			padding-bottom: 112px;
		}
	}

	.popup__container {
		padding-top: 124px;
		padding-bottom: 60px;
	}

	.popup__close {
		z-index: inherit;
	}

    .popup__logo{
        z-index: inherit;
    }

    .popup__wrap::before{
        z-index: inherit;
    }
}

.close-reg {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 360px;

	&__title {
		@include text(36px, 44px, 800, $white);
		text-align: center;
		letter-spacing: 0.05em;
		text-shadow: 0 0 10px $black-25;
		margin-top: 50px;
		margin-bottom: 20px;
	}

	&__text {
		@include text(14px, 150%, 700, $brown-300);
		text-align: center;
		letter-spacing: 0.05em;
		margin-bottom: 20px;
	}

	&__yes {
		@include text(14px, 22px, 700);
		letter-spacing: 0.05em;
	}

	&__no {
		margin-bottom: 12px;
		min-width: 200px;
	}
}

.popup-reg-container {
	&.hide {
		display: none;
	}
}
