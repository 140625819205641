$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-box-flex {
  display: flex;
  margin: 0 -10px;

  .rtl-lang & {
    direction: rtl;
  }

  &:not(.nowrap) {
    flex-wrap: wrap;
  }

  &_providers {
    overflow: hidden;
    max-width: 100%;
  }

  &_demogame {
    justify-content: center;
  }
}

.game-box-slider {
  position: relative;

  .slick-slide>div {
    padding: 10px;
  }

  &__prev,
  .prev {
    position: absolute;
    top: 50%;
    inset-inline-start: 0;
    margin-top: -26px;

    &.disabled { display: none; }
  }

  &__next,
  .next {
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    margin-top: -26px;

    &.disabled { display: none; }
    svg { transform: scale(-1,1); }
  }

  .rtl-lang & {
    &__next, .next svg{
      transform: scale(1,1);
    }
    &__prev, .prev svg{
      transform: scale(-1,1);
    }
  }
}

.game-box-line {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -10px;
  overflow: hidden;
}

.game-box-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  grid-auto-rows: minmax(110px, auto);
  gap: 20px;
  min-height: 420px;

  .rtl-lang & {
    direction: rtl;
  }

  &.no-min-height { min-height: initial; }

  &_full {
    & .game-item:nth-of-type(1) {
      grid-row: 3 / 5;
      grid-column: 3 / 5;
    }

    & .game-item:nth-of-type(2) {
      grid-row: 7 / 9;
      grid-column: 1 / 3;
    }

    & .game-item:nth-of-type(1),
    & .game-item:nth-of-type(2) {
      & .game-item__pic { background-size: calc(100% - 10px) calc(100% - 6px); }
      & .game-item__pic:before { padding-top: 74% }
    }
  }

  @include l {
    grid-template-columns: repeat(6, 1fr);

    &_full {
      & .game-item:nth-of-type(1) {
        grid-row: 2 / 4;
        grid-column: 5 / 7;
      }

      & .game-item:nth-of-type(2) {
        grid-row: 5 / 7;
        grid-column: 1 / 3;
      }
    }
  }

  @include full {
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: minmax(130px, auto);

    &_full {
      & .game-item:nth-of-type(1) { grid-column: 7 / 9; }
    }
  }

  @include full2k { grid-auto-rows: minmax(150px, auto); }
}

.more-game {
  grid-column: 2 / -2;
  text-align: center;
  padding-top: 40px;

  .no-more & {
    display: none !important;
  }
}

.gamebox-sidebar {
  position: relative;
  /* autoprefixer: off */
  grid-row-start: 1;
  grid-row-end: 4;
  /* autoprefixer: off */
  grid-column-start: 1;
  grid-column-end: 2;
}

.gamebox-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    flex-shrink: 0;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 70%;
    }
  }

  &__header-wrap {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 40px);
    padding-top: 90%;
    z-index: 40;
    background-image: url($cdn + '/wazamba/board/gamehall-board_element_3.png'),
      url($cdn + '/wazamba/board/gamehall-board_element_2.png');
    background-position: 50% 0%, 50% 50%;
    background-repeat: no-repeat;
    background-size: contain, contain;
  }

  &__header-logo {
    content: '';
    position: absolute;
    width: 50%;
    padding-top: 50%;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    background-image: url($cdn + '/wazamba/sprite-categories.png');
    background-size: 700%;
    background-position: 66.7% 50%;
    background-repeat: no-repeat;
    z-index: 49;

    &.slots {
      background-position: 33.33% 50%;
    }
  }

  &__content {
    position: relative;
    background: url($cdn + '/wazamba/board/board1_body.png') center bottom repeat-y;
    background-size: 100% auto;
    padding-top: 50px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:not(.active) { display: none; }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -15px;
      top: -38px;
      width: calc(100% + 30px);
      background: url($cdn + '/wazamba/board/gamehall-board_element_1.png') 50% 50% no-repeat;
      background-size: contain;
      padding-top: 40%;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 20px;
      background: $white-10;
      bottom: -5px;
      left: 0;
      width: 100%;
      background: url($cdn + '/wazamba/board/board1_bottom.png') center bottom no-repeat;
      background-size: 100% auto;
    }
  }

  &__item {
    text-align: center;
    text-shadow: 0 0 10px $black-50;
    font-weight: 800;
    color: $white;
    margin: 12px 0;

    &.active { color: $yellow-700; }
  }
}
