$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.collections-page {
  padding: 50px 230px 120px 0;
  background-position: left top;
  background-size: 549px;
  background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-bg.jpg');
  transition: .2s padding;

  @media (-webkit-max-device-pixel-ratio: 2) {
    background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-bg@2x.jpg');
  }
  @media (-webkit-max-device-pixel-ratio: 3) {
    background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-bg@3x.jpg');
  }
  &__title {
    font-weight: 800;
    font-size: 28px;
    line-height: 1.15em;
    text-align: center;
    letter-spacing: 0.05em;
    color: $white;
    margin-bottom: 52px;
  }
  &__container {
    width: 744px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 346px);
    gap: 86px;
    margin-top: 30px;
  }
  &__soon {
    position: relative;
    text-align: center;
    width: 756px;
    min-height: 340px;
    margin: 0 auto;
    background-image: url($cdn + '/wazamba/masks-collection/img-coming-soon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom -60px right -10px;
  }
  &__soon-text {
    max-width: 288px;
    word-break: break-all;
    margin: 40px auto 0;
    padding-bottom: 240px;
    span {
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      color: $yellow-400;
      text-shadow: 0 0 10px $black-25;
    }
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-top: 8px;
    }
  }

  @include xl {
    &__soon-text {
      padding-top: 0;
    }
  }
  @include xxl {
    &__container {
      width: 1170px;
      grid-template-columns: repeat(2, 540px);
    }
    &__soon {
      height: 394px;
      width: 1071px;
      h1 {
        background: none;
        padding-bottom: 20px;
      }
    }
    &__soon-text {
      padding-top: 75px;
      margin-left: initial;
      padding-bottom: 0;
    }
  }

  body.linda-hide-sidebar & {
    padding-inline: 115px;
  }

  .rtl-lang & {
    direction: rtl;
  }
}
