$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.item-collection {
  flex: 0 1 auto;
  display: flex;
  height: 179px;
  background-size: 346px;
  background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-mask-sm.png');

  @media (-webkit-max-device-pixel-ratio: 2) {
    background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-mask-sm@2x.png');
  }
  @media (-webkit-max-device-pixel-ratio: 3) {
    background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-mask-sm@3x.png');
  }
  position: relative;
  &__left {
    text-align: center;
    margin-top: -26px;
    position: relative;
  }
  &__right {
    position: absolute;
    inset-inline-end: -15px;
    width: 141px;
    height: 167px;
    transform: rotate(15deg);
    transition: 0.2s;
    img {
      transition: 0.2s;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &:hover {
      width: 146px;
      height: 175px;
      transform: rotate(22deg);
      transition: 0.2s;
    }
  }
  &__book {
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover, 0, 0;
  }

  &__label {
    position: absolute;
    top: -12px;
    left: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    color: $white;
    border-radius: 4px;
    text-shadow: 0 1px 0 $black-50;
    z-index: 1;
    width: 65px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: drop-shadow(0 4px 2px $black-25);

  }

  &__bar-block {
    width: 232px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    padding-bottom: 20px;
    z-index: 1;
    position: relative;
    text-align: center;
    display: block;
    margin-bottom: -10px;
    img {
      width: 194px;
      height: 87px;
      display: block;
      margin: 0 auto;
      object-fit: contain;
    }
    &:after {
      transition: 0.2s;
      z-index: -1;
      content: '';
      position: absolute;
      inset-inline-start: 0;
      top: -31px;
      width: 100%;
      height: 130%;
      background-size: 100%;
      background-position: center center;
      background-image: url($cdn + '/wazamba/masks-collection/glow-hover.png');
      opacity: 0.7;
    }
    &:hover::after {
      transition: 0.2s;
      opacity: 1;
    }
  }
  &__btn {
    display: none !important;
    font-family: Montserrat, sans-serif;
  }
  &__btn-wrap {
    z-index: 1;
    position: relative;
  }

  .mask-progress {
    width: 166px;
    margin-bottom: 10px;
    &__arrow {
      width: 20px;
      height: 20px;
      margin: inherit;
      margin-inline-start: 10px;
    }

    .rtl-lang & {

      &__arrow {
        transform: scale(-1,1);
      }
    }
  }

  //----Status---//

  &.status-0 {
    .mask-progress__arrow {
      background-image: url($cdn + '/wazamba/masks-collection/icon/icon-arrow-right-noprogress.png');
    }
  }
  &.status-1 {
    .item-collection__book {
      background-size: 0, cover, 0;
    }
  }
  &.status-2 {
    .item-collection__bar-block {
      display: none;
    }
    .item-collection__btn {
      display: inline-block !important;

      span {
        display: inline-block;
      }
    }
    .item-collection__book {
      background-size: 0, 0, cover;
      filter: drop-shadow(0 0 4px $blue-500) drop-shadow(0 0 4px $blue-500)
        drop-shadow(0 0 2px $blue-500) drop-shadow(0 0 2px $blue-500);
    }
  }
  &.status-3 {
    .mask-progress {
      display: none;
    }
    .reward-block {
      display: none;
    }

    .item-collection__book {
      background-size: 0, 0, cover;
    }

    .completed-block {
      display: block;
    }
  }

  @include xxl {
    height: 278px;
    background-size: 547px;
    background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-mask-lg.png');

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-mask-lg@2x.png');
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-mask-lg@3x.png');
    }

    &__title {
      img {
        width: 336px;
        height: 151px;
      }
    }

    .mask-progress {
      width: 200px;
    }

    &__right {
      width: 225px;
      height: 267px;

      &:hover {
        width: 232px;
        height: 275px;
      }
    }

    &__label {
      width: 130px;
      height: 95px;
      font-size: 15px;
      left: 0;
      top: -25px;
    }
  }
}
