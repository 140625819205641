$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.infopopup {
  width: 884px;
  border-radius: 5px;
  background-color: $orange-100;
  color: $brown-300;
  padding-block: 30px;
  padding-inline: 40px 52px;
  position:relative;

  &._missed {
    width: 425px;
    padding: 24px;

    .infopopup__title {
      font-weight: 800;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0.05em;
    }

    .missed-data-popup__decline {
      text-align: center;
      margin-top: 12px;
      display: block;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    inset-inline-end: -50px;
  }

  &__title {
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    line-height: 35px;
    padding:0 30px 10px;
    height: 45px;
  }

  &__btn {
    text-align: center;
    padding-top:10px;
  }

  &__shadow {
    background-image: linear-gradient(to bottom, rgba(255, 237, 210, 0), $orange-100);
    position:absolute;
    bottom: 88px ;
    inset-inline-start: 52px;
    width: 764px;
    height: 30px;
  }

  &__content {
    max-height: calc(100vh - 290px );
    overflow: auto;
    padding-inline-end: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6em;

    & td {
      border:solid 1px $brown-300;
      padding:10px;
    }

    & a {
      color:$brown-300;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
