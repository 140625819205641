$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.grid-ach-box1 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;

  &__item {
    margin: 12px;

    .rtl-lang & {
      @media screen and (max-width: calc(#{$l} - 1px)) {
        &:nth-child(5n+5) {
          @include reversed-tooltip;
        }
      }

      @media screen and (min-width: #{$l}) and (max-width: #{$xxl}) {
        &:nth-child(6n+6) {
          @include reversed-tooltip;
        }
      }
    }
  }

  @include l {
    margin: 0 -13px;

    &__item { margin: 13px; }
  }
}
