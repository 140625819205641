$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;

.not-found{
  height: calc( 100vh - 420px);
  min-height: 600px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__logo{ 
    width: 394px;
    height: 61px;
    background: url( $cdn + '/wazamba/desktop/img/logo.svg') no-repeat center;
    background-size: contain;
  }
  &__title{
    background-image: linear-gradient(to top, #a3deff, #00eded 95%);
    font-size: 144px;
    font-weight: 800;
    letter-spacing: 9px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top:30px;
  }
  &__subtitle{
    margin-top:30px;
    font-size: 28px;
    font-weight: 800;
  }
  &__desc{
    font-size: 16px;
    font-weight: bold;
    margin-top:30px;
  }
  &__btns{
    padding-top:25px;
  }

}



