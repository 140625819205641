$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.missed-data-popup {
  align-items: center;
  height: 100%;

  &__wrap {
    padding: 60px 80px;
    color: #111114;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  &__title {
    font-size: 24px;
    line-height: 115%;
    font-weight: 350;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__btn {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__decline {
    font-size: 13px;
    line-height: 120%;
  }

  p {
    margin: 0;
  }
}
