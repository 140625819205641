$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.shop-fs-item {
  background: radial-gradient(50% 50% at 50% 50%, $violet-500 0%, $violet-700 100%);
  box-shadow: 0 8px 0 #4D1573;
  border-radius: 12px;
  height: 273px;
  padding: 45px 22px 19px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  &:hover {
    .shop-fs-item__btn {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 230px;
  }

  &__fs-name {
    @include text(28px, 1.2, 800, $white);
    text-shadow: 0 1px 1px $black-25;
    margin-bottom: 8px;
    height: 66px;
    overflow: hidden;
  }

  &__fs-text {
    @include text(20px, 140%, 800);
    background: linear-gradient(180deg, $white 13.03%, $yellow-200 18.73%, $yellow-500 96.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 8px;
  }

  &__btn-block {
    display: flex;
    margin: 0 -4px;
  }

  &__btn-fs {
    @include text(20px, 24px, 800, $grey-200);
    background: $white;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.05em;
    height: 32px;
    width: 64px;
    margin: 0 4px;
    border: none;
    cursor: pointer;

    span {
      background-color: $grey-200;
      color: transparent;
      text-shadow: 1px 2px 3px rgba( 255, 255, 255,.5);
      -webkit-background-clip: text;
    }

    &.is-active {
      background-color: $orange-1000;
      box-shadow: 0 4px 0 $orange-1200;

      span {
        color: $white;
        text-shadow: 0 2px 0 $orange-1200;
        font-size: 24px;
      }
    }
  }

  &__btn {
    margin-top: auto;
    align-self: center;
    min-width: 200px;
    padding: 0 25px;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
    font-family: Montserrat,sans-serif;
  }

  &__price {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-33px) translateX(-50%);
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    object-fit: contain;
    height: 300px;
    width: 172px;
    z-index: -1;
  }
}
