$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-align: center;
  color: $white;
  margin-bottom: 20px;
  span {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(to top, #FD8401, #F6A501);
    -webkit-background-clip: text;
  }
}
