$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.registration {
    position: relative;

    &__title {
        padding-bottom: 12px;
    }

    &__step{
        &:not(:first-child):not(:nth-child(2)){
            padding-top: 20px;
        }
    }

    &__steps {
        position: absolute; 
        bottom: -32px; 
        left: 50%;
        transform: translateX(-50%); 
        width: 12px; 
        height: 12px; 
        border-radius: 100%;
        background-color: $white;

        &::before, &::after {
            content: '';
            position: absolute; 
            width: 12px; 
            height: 12px; 
            border-radius: 100%;
            background-color: $white;
            opacity: .5;
        }

        &::before { inset-inline-start: -20px; }
        &::after { inset-inline-end: -20px; }

        &.is-step-1 {
            background-color: rgba(225, 225, 225, .5);

            &::before { opacity: 1; }
        }

        &.is-step-3 {
            background-color: rgba(225, 225, 225, .5);

            &::after { opacity: 1; }
        }
    }

    &__current-hero {
      position: absolute;
      bottom: 0;
      inset-inline-start: -30px;
      width: 460px;
      height: 490px;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      transform: translate(0, 0);
      transition: 0.3s;
      opacity: 0;
      pointer-events: none;

      &.active {
        transform: translateX(381px);
        opacity: 1;
      }
    }

    &__btns {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &--columns {
        flex-direction: column;
      }
    }

    &__btn { 
      flex-shrink: 0;
      margin: 0 12px; 
      font-family: Montserrat,sans-serif;

      &--prev {
        position: absolute;
        top: 57px;
        inset-inline-start: -61px;
        z-index: 1;
      }
    }
  
    &__form {
        display:flex;
        flex-direction: column;
        width: 100%;
        margin: 0 -2px;

      .g-form-error_check {
        padding-inline-start: 45px;
      }
    }

    &__input-password {
      padding-inline-end: 50px;
    }

    &__gender-box{
        display:flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        .gender-item { 
            margin:5px 10px; 
        }
    }

    &__promo-btn{
        text-align: center;

        & a{
          @include text(14px, 22px, 700, $brown-300);
          text-decoration: underline;
          letter-spacing: .05em;
        }
    }
  
    &__bankid-btn{
        margin: 0 auto;
        text-align: center;
    }

    &__promo-text {
        margin: 20px 0 6px;
        @include text(14px, 22px, 700, $brown-300);
        letter-spacing: 0.05em;
        text-align: center;

        &.is-title {
            margin: 10px 0 12px;
            font-weight: 900;
        }
    }

    &__tooltip-malaysian {
      display: flex;
      gap: 10px;
      background: rgba(255, 237, 141, 1);
      box-shadow: 0 4px 5px rgba(110, 57, 35, 0.15);
      border-radius: 8px;
      padding: 12px;
      margin-bottom: 12px;

      .tooltip-icon {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        background-image: url(/img/registration-warning.svg);
      }
      .tooltip-text {
        @include text(12px, 1.2, 600, $brown-300);
        letter-spacing: 0.6px;
        opacity: 0.8;
      }
    }

    &__or {
        @include text(14px, 22px, 700, $brown-300);
        letter-spacing: 0.05em;
        margin: 8px 0;
        text-align: center;
    }

    & .g-btn-green {
        padding: 0 30px;
    }

    .rtl-lang & {
      &__btn{
        &:first-child:not(.registration__btn--primary),
        &--prev {
          transform: scaleX(-1);
        }
      }
    }
  }
