$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-league {
	&__subtitle {
		@include text(12px, 15px, 700, $white);
		padding-bottom: 10px;
	}

	&__level {
		display: flex;
		align-items: center;
		max-width: 388px;
		margin: 26px auto 25px;
		justify-content: center;
		text-align: center;
		position: relative;
		inset-inline-start: 46px;
		padding-block: 8px;
		padding-inline: 56px 22px;
		border-radius: 12px;
		background: $black-25;

		&-icon {
			transform: scale(1.3);
			position: absolute;
			inset-inline-start: -54px;
			top: -14px;

			.icon-league {
				width: 101px;
				height: 86px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		&-text {
			display: flex;
			flex-direction: column;
			text-align: start;

			span {
				&.lg {
					@include text(16px, 20px, 800, $white);
					text-shadow: 0 0 10px $black-25;
					padding-bottom: 6px;
					position: relative;
					cursor: pointer;
				}

				&.sm {
					@include text(12px, 15px, bold, $white-80);
				}
			}
		}

		&-btn {
			transform: scale(-1, 1);
			margin-inline-start: auto;

			.rtl-lang & {
				svg {
					transform: scaleX(-1);
				}
			}
		}

		&.is-full {
			display: none;

			@include xxl {
				display: flex;
			}
		}

		&.is-med {
			@include xxl {
				display: none;
			}
		}
	}

	&__benefits {
		background-image: url(#{$cdn}/wazamba/leagues/popup/wood-body.png);
		background-size: 133px;
		background-position: 40px bottom;
		border-radius: 12px;
		position: relative;
		padding: 20px 24px;

		@media (-webkit-max-device-pixel-ratio: 2) {
			background-image: url(#{$cdn}/wazamba/leagues/popup/wood-body@2x.png);
		}

		@media (-webkit-max-device-pixel-ratio: 3) {
			background-image: url(#{$cdn}/wazamba/leagues/popup/wood-body@3x.png);
		}

		&-container {
			border-radius: 12px;
			background: rgba(50, 33, 29, 0.50);
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 17px 24px;
		}

		&-text {
			@include text(18px, 22px, 800, $white);
			text-shadow: 0 0 10px $black-25;
			font-style: normal;
			letter-spacing: 0.9px;
		}

		&-withdrawals,
        &-manager {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.withdrawals-limit,
		.manager-status {
			@include title-gold-shadow(3px, 0);
			@include text(24px, normal, 700);
			text-align: center;
		}

		.manager-description,
		.withdrawals-description {
			@include text(12px, normal, 700, $white-80);
			text-align: center;
			font-style: normal;
		}
	}
}
