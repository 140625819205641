$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-popup {
  overflow: hidden;
  position: relative;

  &__slide {
    position: relative;
    flex-direction: column !important;
    justify-content: flex-start !important;
    height: 505px;
    padding: 0 24px;
  }

  &__title {
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: $white;
    margin: 26px auto 8px;
    text-shadow: 0 0 10px $black-25;

    &.modal-title {
      text-transform: none;
    }
  }

  &__description {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: $brown-300;
  }

  &__pic-first{
    margin-top:50px;
  }

  &__img1{
    margin-top:15px;
  }

  &__img2 {
    margin-top: 25px;
    width: 262px;
  }

  &__btns {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__btn {
    min-width: 180px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 67px;
  }

  &__item {
    width: 120px;
    height: 120px;
    text-align: center;
    padding-top: 90px;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: $white;
    text-shadow: 0 0 10px $black-25, 0 1px 1px rgba(85, 44, 43, 0.15);
    background-size: 82px 82px;
    background-position: center top;
    background-repeat: no-repeat;

    &.is-1 {
      background-image: url($cdn + '/wazamba/crab/icon1-min.png')
    }

    &.is-2 {
      background-image: url($cdn + '/wazamba/crab/icon2-min.png')
    }

    & span {
      display: inline-block;
      background: $red-200;
      box-shadow: 0 3.77778px 0 $red-300;
      border-radius: 4px;
      padding: 0 8px;
      height: 34px;
      font-weight: 900;
      font-size: 20px;
      line-height: 34px;
      text-transform: uppercase;
    }
  }

  &__symbol {
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    color: $white;
    margin: 0 17px;
  }

  &__timer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 50px auto;

    & span {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: $white;
      text-shadow: 0 0 10px $black-25;
      margin: 0 0 8px 0;
    }

    & strong {
      font-weight: 900;
      font-size: 42px;
      line-height: 51px;
      text-align: center;
      color: #F4F2FB;
      text-shadow: 0 1px 2px #4372DE;
    }

    & batman-countdown1 {
      & div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 273px;
        height: 65px;
        border-radius: 0;
        margin: 10px auto 0;
        background: url($cdn + '/wazamba/crab/desk.png') no-repeat;
        background-position: center !important;

        & span {
          margin: 0 0 10px 10px;
          font-weight: 800;
          font-size: 36px;
          line-height: 44px;
          background-color: #b4b4b4;
          color: transparent;
          text-shadow: 0 2px 1px rgba(256, 256, 256, 1);
          -webkit-background-clip: text;
          -moz-background-clip: text;
          background-clip: text;
          letter-spacing: 4px;
        }
      }
    }
  }

  & .swiper-pagination {
    position: absolute;
    bottom: 96px;
    height: 8px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    z-index: 1010;
  }

  & .swiper-pagination-bullet {
    display: block;
    background: $brown-300;
    opacity: 0.5;
    width: 6px;
    height: 6px;
    margin: 0 6px;
    border-radius: 50%;

    &.swiper-pagination-bullet-active {
      opacity: 1;
      width: 8px;
      height: 8px;
    }
  }
}
