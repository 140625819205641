$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-deposit {
  position: relative;
  width: 100%;
  z-index: 2;
  padding: 32px var(--shop-margin-inline);

  &__title {
    @include text(16px, 20px, 700, $white);
    margin: 0 0 16px;
  }

  &__form-row,
  &__select-row {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 16px 18px 16px 16px;
    column-gap: 10px;
    border-bottom: 1px solid $black-3;
  }

  &__select {
    background-color: #FFF8DD;
    border-radius: 20px;

    &-arrow {
      display: block;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuOSIgZD0iTTEgMS41TDYgNi41TDExIDEuNSIgc3Ryb2tlPSIjNDA0MDQwIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px;
      width: 20px;
      height: 20px;
      margin-inline-start: auto;
      transition: transform .25s ease-in-out;

      &.is-rotate {
        transform: rotate(180deg);
      }
    }

    &-window {
      padding-bottom: 10px;
    }

    &-row {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        background-color: $white;
        border: 1px solid #D2C48F7D;
        inset-inline-end: 16px;
      }

      &.is-active {
        background-color: #FFF0B0;

        &:before {
          content: '';
          position: absolute;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          background-color: #18C5FF;
          inset-inline-end: 21px;
          z-index: 1;
        }
      }
    }
  }

  &__payment {
    &-img {
      width: 31px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &-name {
      @include text(16px, 20px, 600, #404040);
      @include ellipsis;
      max-width: 80px;
      text-transform: capitalize;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__btns {
    position: absolute;
    width: 100%;
    top: 9px;

    &-btn {
      position: absolute;

      &.is-disabled {
        pointer-events: none;
        filter: grayscale(1);
      }
    }

    .is-minus {
      inset-inline-start: 13px;

      .ng-invalid-min &,
      .ng-invalid-required & {
        pointer-events: none;
        filter: grayscale(1);
      }
    }

    .is-plus {
      inset-inline-end: 13px;

      .ng-invalid-max & {
        pointer-events: none;
        filter: grayscale(1);
      }
    }
  }

  &__amount {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    min-height: 52px;

    input {
      @include text(20px, 24px, 800, $brown-300);
      min-height: 52px;
      border: 1px solid #CAA36EBF;
      box-shadow: 1px 1px 3px 0 $black-23 inset;
      padding-inline: 0;
      text-align: center;
    }
  }

  &__dep-btn {
    @include text(18px, 22px, 700, $white);
    display: flex;
    flex-direction: row;
    gap: 8px;

    svg {
      width: 23px;
      height: 100%;
      padding-left: 8px;

      .rtl-lang & {
        transform: scaleX(-1);
      }
    }

    .ng-invalid & {
      filter: grayscale(1);
      cursor: default;
      pointer-events: none;
    }
  }

  &__add-card {
    @include text(16px, 20px, 600, #6B9833);
    font-family: "Montserrat", sans-serif;
    border: none;
    background-color: transparent;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;

    &:before {
      content: '';
      background-image: var(--icon-add-new-method);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 31px;
      height: 31px;
    }
  }

  &__input-error {
    min-height: 0;

    .quick-deposit__input-error-text {
      @include text(12px, 17px, 700, $red-200);
      padding: 0;
      margin-top: 4px;
    }
  }

  &__input-error-amount {
    display: inline-flex;
    gap: 4px;
    direction: ltr;
  }
}
