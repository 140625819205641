$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.gamebox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;

  &__title {
    color: $white;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-shadow: 0 0 10px $black-25;
  }

  &__count {
    position: relative;
    padding-top: 4px;
    padding-bottom: 4px;
    color: $white;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: $white;
      transform-origin: 100% 50%;
      transform: scaleX(0);
      transition: transform 350ms;
    }

    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }

  }
}
