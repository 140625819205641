$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.shop-instructions {
  &__wrapper {
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding: 20px 20px 40px 20px;
    border-radius: 20px;
    border: 1px solid rgb(30, 69, 112);
    background-color: #0E3358;
  }

  &__header {
    &-logo {
      height: 92px;
      width: 92px;
      margin-inline: auto;

      .rtl-lang & {
        transform: scaleX(-1);
      }
    }
    &-title {
      margin: 0;
      padding-bottom: 16px;
    }
  }

  &__step {
    position: relative;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-inline: 48px 36px;
    cursor: pointer;
    transition: .25s linear;

    &:hover {
      &::before {
        background-color: #F6DF4F;
        color: #7D4826;
      }

      .shop-instructions__step-name {
        color: #F6DF4F;
      }

      .shop-instructions__step-text {
        color: #4D8ECE;
      }
    }

    &::before {
      content: attr(data-step-number);
      position: absolute;
      top: -5px;
      inset-inline-start: 0;
      min-width: 32px;
      min-height: 32px;
      text-align: center;
      background-color: #1D4C7B;
      border-radius: 50%;
      transition: .25s linear;

      @include text(20px, 32px, 700, #4D8ECE);
    }

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      inset-inline-end: 0;
      height: 20px;
      width: 20px;
      background-image: url($cdn + "/wazamba/shop/shop_arrow-right.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .rtl-lang & {
      &:after {
        transform: rotate(180deg);
      }
    }

    &-name {
      @include text(20px, 24px, 700);

      letter-spacing: -0.2px;
      text-transform: capitalize;
      margin: 0;
      transition: .25s linear;
    }

    &-text {
      @include text(16px, 19px, 500, #4579AD);
      margin: 0;
      transition: .25s linear;
    }
  }
}
