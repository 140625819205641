$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
@keyframes hero {
  50% {
    bottom:8px;
  }
}

@keyframes hero2 {
  50% {
    bottom: 4px;
  }
}

.banner-layer {
  &-bg {
    background-image: url( $cdn + "/wazamba/baner/lb_bg2.jpg");
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &-bg-front{
    background-image: url( $cdn + "/wazamba/baner/leaf1.png") ,  url( $cdn + "/wazamba/baner/leaf2.png") ,  url( $cdn + "/wazamba/baner/leaf3.png") ,  url( $cdn + "/wazamba/baner/leaf4.png");
    background-size: 168px , 223px , 350px , 290px ;
    background-position: -50px -50px , 110% -50px, right bottom , left bottom;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &-gold{
    background-image: url( $cdn + "/wazamba/baner/lb_gold.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 300px;
    height: 150px;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
  }

  &-hero1{
    background-image: url( $cdn + "/wazamba/baner/lb_hero1.png") ;
    background-position: 5% 0 ;
    background-repeat: no-repeat;
    background-size: contain;
    animation: hero2 2.5s infinite ease-in-out;

    animation-delay: -0.9s;
    position: absolute;
    bottom: 0;
    //left: 60px;
    transform: translateX(30%);
    width: 173px;
    height: 377px;
  }

  &-hero2{
    background: url( $cdn + "/wazamba/baner/lb_hero2.png");
    background-position: -8% 20% ;
    background-repeat:no-repeat;
    background-size: contain;
    animation: hero 1.5s infinite ease-in-out;
    animation-delay: -0.7s;
    position: absolute;
    bottom: 0;
    //left: -115px;
    transform: translateX(-45%);
    width: 232px;
    height: 302px;
  }

  &-hero3{
    background: url( $cdn + "/wazamba/baner/lb_hero3.png");
    background-position: 75% 7%;
    background-repeat:no-repeat;
    background-size: contain;
    animation: hero 1.8s infinite ease-in-out;
    position: absolute;
    bottom: 0;
    width: 284px;
    height: 330px;
    transform: translateX(-60%);
    right: 0;
  }

  &-content{
    padding-right:230px;
    display:flex;
    position: relative;
  }

  &-logo{
    background-image: url( $cdn + '/wazamba/desktop/img/logo.svg');
    background-repeat: no-repeat;
    background-size: 297px;
    background-position: center;
    height: 47px;
    margin: 100px auto 0;
  }
  &-btn{
    position: absolute;
    padding:0 250px 0 20px;
    display:flex;
    bottom: 30px;
    transform: translateX(-50%);
    left: 50%;
  }

  @include l{
    &-hero1{
      transform: translateX(90%);
      width: 184px;
      height: 402px;
    }
    &-hero2{
      transform: translateX(0%);
      width: 248px;
      height: 322px;
    }
    &-hero3{
      transform: translateX(-90%);
      width: 302px;
      height: 352px;
    }
    &-btn {
      bottom: 60px;
    }
  }
  @include xl{
    &-logo{
      margin: 30% auto 0;
    }

    &-hero1{
      width: 207px;
      height: 453px;
    }

    &-hero2{
      width: 280px;
      height: 364px;
    }

    &-hero3{
      width: 340px;
      height: 396px;
    }

    &-btn {
      bottom: 52px;
    }
  }
  @include xxl{
    &-logo{
      background-size: 388px;
      height: 61px;
    }
    &-hero1{
      width: 242px;
      height: 528px;
    }
    &-hero2{
      width: 326px;
      height: 424px;
    }
    &-hero3{
      width: 396px;
      height: 460px;
    }

    &-btn {
      bottom: 88px;
    }
  }

  @include full{
    &-logo{
      background-size: 420px;
      height: 66px;
    }

    &-btn {
      bottom: 54px;
    }
  }
}

.banner{
  --banner-awards-height: 64px;

  &__content-pnp {
    width: 533px;
    height: 750px;
    background-image: url( $cdn + '/wazamba/bank-id/wz_bank-id_stone.png' );
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    margin-left: auto;
    margin-right: auto;

    @include l {
      width: 676px;
      height: 952px;
    }

    @include xxl {
      width: 810px;
      height: 1140px;
    }
  }
  &__content {
    padding-top:0;
    margin: 0 auto;
    width: 375px;
    height: 395px;
    background: url( $cdn + "/wazamba/baner/lb_front.png") center -12px no-repeat;
    background-size: 100% auto;

  }
  &__promo {
    padding: 16px 43px 0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    overflow:hidden;
    text-align: center;
    text-shadow: 0 3px 0 $orange-900;
    color: $yellow-600;
    font-weight: 800;
  }

  &__title {
    font-size:24px;
  }

  &__text1 {
    font-size: 32px;
  }

  &__text2 {
    font-size: 20px;
  }

  &__btns {
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  &__btn{
    &.is-log {
      display: none;
    }
    &.g-btn-blue,
    &.g-btn-green {
      min-width: 335px;
    }
  }

  &__awards {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--banner-awards-height);
    margin-top: 50px;

    &-sbc {
      position: relative;
      display: block;
      width: 155px;
      height: 64px;
      margin-right: 32px;
    }

    &-askgamblers {
      position: relative;
      display: block;
      width: 170px;
      height: 64px;
    }
  }

  // bankid
  &_bankid {
    position: relative;

    .baner-content__promo {
      height: auto;
      padding-top: 40px;
    }
  }
  &__bankid-logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 212px;
    background-position: center bottom 20px;
  }
  &__bankid-title {
    font-size: 32px;
    font-weight: 800;
    letter-spacing: .05em;
    text-align: center;
    text-shadow: 0 0 10px $black-25,
    0 3px 1px $orange-900;
  }

  @include l {
    &__bankid-title {
      font-size: 40px;
      line-height: 49px;
    }

    &__bankid-logo {
      background-position: center bottom;
    }

    &__bankid-title {
      font-size: 40px;
    }
  }

  @include xl {
    &__content {
      width: 484px;
      height: 514px;
      margin: -20px auto 0;
    }

    &__promo {
      padding: 24px 43px 0;
      width: 416px;
      margin: 0 auto;
    }

    &__title {
      font-size: 28px;
    }

    &__text1 {
      font-size: 36px;
    }

    &__text2 {
      font-size: 28px;
    }
  }

  @include xxl {
    &__promo {
      padding: 30px 43px 0;
    }
  }
  @include full{
    &__content {
      width: 558px;
      height: 592px;
    }

    &__promo {
      padding: 32px 62px 0;
      width: 100%;
    }

    &__title{
      font-size:32px;
    }

    &__text1{
      font-size: 48px;
    }

    &__text2{
      font-size: 32px;
    }
  }
}


.banner-btns {
  margin: 0 auto;
  width: 500px;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .banner__btn {
    @include m {
      min-width: 220px;
    }

    @include xxl {
      min-width: 337px;
    }
  }
}

.linda-has-remembered-login {
  .banner__btn.is-reg {
    display: none;
  }
  .banner__btn.is-log {
    display: block;
  }
}
