$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.change-mask {
  text-align: center;
  width: 750px;
  position: relative;

  &__close {
    position: absolute;
    top: 0;
    inset-inline-end: -35px;
  }

  &__title {
    font-size: 36px;
    font-weight: 800;
  }

  &__desc {
    @include text(14px, 1.52, 500);
    letter-spacing: 0.7px;
    padding-top: 15px;
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    width: 170px;
    height: 170px;
    margin: 85px 7px 0;
    border-radius: 52%;
    border:solid 10px transparent;
    box-shadow: inset 0 0 10px 0 $brown-300;
    flex-shrink: 0;
    transition: 0.3s;
    background: $orange-100;
    background-clip: content-box ;
    background-size: 100% ;
    background-position: 50%;
    cursor: pointer;

    &.active {
      &::before {
        content: "";
        width: calc(100% + 20px);
        height: calc(100% + 20px);

        background: $orange-100, linear-gradient(to bottom, $orange-700, $orange-500 95%);
        background-clip: content-box, border-box;
        background-size: 100% , 100% 120%;
        background-position: 50% , 50%;
        border-radius: 52%;

        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;

        transform: translate(-50% , -50%);
      }
    }
  }

  &__mask {
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 152px;
    height: 195px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% bottom;
  }

  &__btns {
    padding-top: 50px;
  }

  &__link {
    padding-top: 20px;

    & a {
      @include text(14px, 1.52, bold);
      letter-spacing: 0.7px;
    }
  }
}
