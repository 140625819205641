$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page__search {
  &-wrapper {
    margin-inline: 152px;
    height: 100%;
    overflow-y: scroll;
    padding-block: 30px 50px;

    h3 {
      @include text(18px, 22px, 800, #F0D55F);
      letter-spacing: 0.05em;
      text-align: left;
      margin: 32px 0 12px;

      @include l {
        text-align: center;
        margin: 32px 0 20px;
      }
    }
  }

  &-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;

    &-input-container {
      position: relative;
    }
  }

  &-input {
    @include text(15px, 48px, null, $white);
    @include ellipsis();
    box-sizing: border-box;
    width: 380px;
    height: 48px;
    padding-block: 0;
    padding-inline: 25px 50px;
    border-radius: 60px;
    background-color: $black;
    border: 2px solid $white;

    &:focus {
      background: $white;
      opacity: 1;
      color: $brown-300;
    }

    &::placeholder {
      color: inherit;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.05em;
    }
  }

  &-label {
    position: absolute;
    inset-inline-end: 0;
    top: 0;
  }

  &-close {
    position: absolute;
    inset-inline-end: 0;
    top: 0;
  }

  &-noresult {
    width: 100%;
    text-align: center;
    padding: 45px 0;
    font-weight: 500;
    font-size: 16px;
  }

  &-provider-box-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(6, 1fr);
  }

  &-game-box-grid {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 20px;

    @include l {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &-game-box-grid,
  &-provider-box-grid {
    @include full {
      grid-template-columns: repeat(8, 1fr);
    }
  }

  &-provider-item {
    padding: 10px;
    margin: 0;
    height: unset;
    width: 100%;

    .search__providers-title {
      white-space: unset;
      overflow: visible;
      text-overflow: initial;
    }
  }
}

