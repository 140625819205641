$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  &__game {
    width: 100%;
    flex-grow: 1;

    &-wrapper { position: relative; }

    &-crab {
      position: absolute;
      inset: 0;
    }
  }

  &__preloader { width: 100vw; }
}
