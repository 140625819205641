$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.xmas-promo-tooltip{

  position:absolute;
  left: -540px;
  top: 50%;

  width: 520px;
  min-height: 180px;

  border: 2px solid #179AE4;
  box-shadow: 0 10px 20px rgba(24, 85, 183, 0.6);

  transform: translateY(-50%);
  border-radius: 10px;

  background: url( $cdn + "/wazamba/christmas-game/tooltip-bg.png") right center no-repeat;
  background-size: cover;
  z-index: 100;
  padding: 40px 0 20px;


  .sidebar.profile &{
    display: none;
  }

  &::before{
    content: '';
    display: block;
    width: 555px;
    height: 280.5px;
    position: absolute;
    left: -46px;
    top: -46px;
    background: url( $cdn + "/wazamba/christmas-game/tooltip-image-1024+.png") right center no-repeat;
    z-index: 101;

  }


  &__arr{
    position: absolute;
    top: calc( 50% - 15px);
    right: -13px;
    width: 13px;
    height: 26px;
    overflow: hidden;
    z-index: 102;
    &::before{
      position:absolute;
      top: 2px;
      right: 3px;
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 6px;
      border: 2px solid #179AE4;
      transform: rotate(45deg);
      background-color: rgb(27 , 71 , 140 );
    }
  }

  &__content{

    position:relative;
    width: 100%;
    padding:0 20px 0 180px;
    z-index: 110;
    text-align: center;

  }
  &__title{
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: $white;
  }
  &__subtitle{
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: #A1BED3;
    margin-top:4px;
  }
  &__btn{
    margin:12px auto 0 ;
    width: 200px;
  }

  &__close{
    position:absolute;
    top: 7px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 113;
    cursor:pointer;
    & svg{ fill:#149EED;}

  }



}
