$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.article-box {
  padding-bottom: 40px;
  width: 690px;
  margin: 0 auto;

  & h1 {
    text-align: center;
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 1.8px;
  }

  & h2 {
    text-align: center;
    font-size: 28px;
    font-weight: 800;
  }

  & p {
    font-weight: 500;
    line-height: 1.7;
    margin:10px 0;
  }

  & ul {
    padding-left:30px;
    list-style: disc;
  }

  & li {
    font-size: 16px;
    line-height: 1.76;
    color: #c4c4c4;
  }

  & a { text-decoration: underline; }
}
