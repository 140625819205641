$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.unlogged-masks{
  background-image: url($cdn + "/wazamba/img-gamehall-background-mob.jpg");
  padding: 0 230px 0 0;
  background-size: cover;
  transition: .2s all;

  body.linda-hide-sidebar & {
    padding: 0 115px 0;
  }

  &__container-seo,
  &__container {
    margin: 0 auto;
  }

  &__container {
    width: 790px;
    text-align: center;
  }

  &__container-seo {
    width: 562px;
  }

  &__page {
    min-height: auto;
    margin: 0 auto;
    padding: 0 230px 40px 0;
  }

  &__logo {
    position: relative;
    text-align: center;
    margin: 0 auto 8px;
    width: 380px;
    height: 156px;
    transform: translateY(25px);
    background-image: url($cdn + "/wazamba/masks-collection/logo/logo-mask-collection.png");
    background-size: contain;
  }

  &__new {
    @include text(14px, 12px, 900, $white);
    position: absolute;
    top: -8%;
    left: 50%;
    transform: translateX(-50%);
    background: $red-200;
    box-shadow: 0 2px 0 $red-300;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    padding: 8px 12px;
  }

  &__subtitle {
    @include text(18px, 22px, 800, $white);
    text-align: center;
    letter-spacing: 0.02em;
    text-shadow: 0 0 10px $black-25;
    padding-top: 15px;
  }

  &__banners {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;

    img{
      margin: 0 auto;
    }
  }

  &__desk {
    @include text(16px, 22px, 800, $white);
    width: 272px;
    min-height: 62px;
    background-image: url( $cdn +  "/wazamba/masks-collection/img-desk.png");
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px 15px;
    z-index: 1;
    margin-top: auto;
    background-size: 100% 100%;
    text-align: center;
    letter-spacing: 0.02em;
    text-wrap: balance;
  }

  &__banner {
    width: 100%;
    height: 330px;
    position: relative;
    justify-content: center;
    display: flex;

    &::before {
      content: "";
      position: absolute;
      width: 528px;
      height: 359px;
      background-size: 528px 359px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      background-position: center bottom -34px;

    }

    &.banner1 {
      &::before {
        background-image: url($cdn + "/wazamba/masks-collection/img-9-uniqum-collection.png");
      }
    }

    &.banner2 {
      &::before {
        background-image: url($cdn + "/wazamba/masks-collection/img-54-masks.png");
      }
    }

    &.banner3 {
      &::before {
        background-image: url($cdn + "/wazamba/masks-collection/img-get-real-money-rewards.png");
      }
    }
  }

  &__buttons {
    margin: auto;
    text-align: center;
    min-width: 259px;
    height: 56px;
    margin-top: 55px;
    padding-bottom: 180px;

    button{
      font-size: 22px;
      box-shadow: none;
      font-family: Montserrat,sans-serif;
    }
  }

  &__chat {
    display: block;
    margin-inline: auto;
    max-width: fit-content;

    &.g-link-chat span {
      font-size: 16px;
    }
  }

  &__container-seo {
    h1 {
      text-align: start;
    }
  }

  &__wrapper {
    @include text(16px, 25px, 500);
    position: relative;
    height: 190px;
    overflow: hidden;
    transition: 1s;
    letter-spacing: 0.05em;

    &.showtext {
      padding-bottom: 60px;
      height: auto;
    }
  }

  .showtext > &__button {
    display: none;
  }

  &__button {
    bottom: 0;
    position: absolute;
    width: 100%;
    padding-top: 150px;
    background: linear-gradient(360deg,$blue-900 19.58%,rgba(8,31,62,0) 100%);

    a {
      @include text(14px, 24px, 800, $yellow-400);
      text-shadow: 0 0 10px $black-25;
    }

    &::after {
      content: "";
      width: 12px;
      height: 12px;
      background-image: url($cdn + "/wazamba/masks-collection/icon/icon-arrow_bottom.png");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 4px;
      padding-inline-start: 10px;
    }
  }

  @include l {
    &__container{
      width: 990px;
    }

    &__container-seo{
      width: 772px;
    }

    &__banner {
      height: 339px;

      &::before {
        top: 12px;
        height: 339px;
      }
    }
  }

  @include xl {
    &__container {
      width: 1280px;
    }

    &__container-seo {
      width: 772px;
    }

    &__banner {
      &::before {
        background-size: 108%;
      }
    }
  }

  @include xxl {
    &__container {
      width: 1320px;
    }

    &__container-seo {
      width: 816px;
    }
  }

  .rtl-lang & {
    direction: rtl;
  }
}
