$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.no-favorite{
  width: 500px;
  margin:0 auto;
  padding:100px 0 130px;
  text-align: center;
  &__title{
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 1.2px;
    color: $white;
  }
  &__text{
    font-weight: 500;
    line-height: 1.52;
    letter-spacing: 0.7px;
    color: $white;
    padding:20px 0;

  }
  &__btn{
    margin:20px 0;
  }

  .rtl-lang & {
    direction: rtl;
  }
}
