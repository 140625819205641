$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-game-item{
  position:relative;
  width: 284px;
  background: url( $cdn + '/wazamba/desktop/img/ach-game_1.svg') no-repeat;
  &.is-status2,
  &.is-status3{ background: url( $cdn + '/wazamba/desktop/img/ach-game_2.svg') no-repeat; }
  &::before{
    content: '';
    padding-top:102%;
    display:block;
  }
  &__img{
    position:absolute;
    top: 6px;
    inset-inline-start: 6px;
    //background-image: linear-gradient( rgba(7,31,62,0.5) , rgba(7,31,62,0.9) ) , url('https://devwazamba.imgsrcdata.com/img/game/platooners.jpg');
    background-size: cover , cover;
    background-position: 50%;
    width: calc(100% - 12px);
    height: calc(100% - 16px);
    mask: url( $cdn + '/wazamba/desktop/img/ach-game_mask.svg') 100% 100% no-repeat;
  }
  &__ico{
    position:absolute;
    top: 75px;
    //left: calc(50% - 71px);
    inset-inline-start: calc(50% - 71px);
    width: 142px;
    height: 142px;
  }
  &__ico-img{
    width: 100% ;
    height: 100%;
    background-image: url( 'https://clipart.info/images/ccovers/1495576485lego-batman-head-png-clipart.png');
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    .is-status0 &,
    .is-status1 &{ filter: brightness(0) invert(1); }
  }
  &__ico-text{
    position:absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    color:$violet-700;
    font-size: 48px;
    font-weight: 800;
    display:flex;
    align-items: center;
    justify-content: center;
    .is-status2 &,
    .is-status3 &{ display:none !important; }

  }
  &__progress{
    position:absolute;
    top: -4px;
    inset-inline-start: -4px;
    height: 47px;
    padding:0 12px;
    box-shadow: 0 3px 6px 0 $black-40;
    background-color: $blue-900;
    border-radius: 25px;
    font-size: 18px;
    font-weight: 800;
    line-height: 47px;
    letter-spacing: 1px;
    .is-status3 &{ display:none;}
  }
  &__name{
    position: absolute;
    top: 45px;
    inset-inline-start: 0;
    width: 100%;
    text-align: center;
    text-shadow: 0 2px 4px $black-25;
    font-size: 18px;
    font-weight: 800;
    padding:0 15px;
  }
  &__btns{
    position: absolute ;
    bottom: 30px;
    inset-inline-start: 0;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  &__btn{ margin-top:10px; }
  @include l{
    width: 306px;
  }

  .rtl-lang & {
    &__ico-text{
      transform: scaleX(-1);
    }
  }

}
