$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.bankid-modal{
  position:relative;

  &__formbox{
    background: rgba( 110 , 57 , 35 , 0.7);
    margin:20px -15px ;
    padding:16px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item{
    width: calc(100% - 8px);
    margin:0 4px;
    &_50{ width: calc(50% - 8px); }
  }

  &__gender{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .gender-item { 
        margin: 5px 10px; 
    }
  }

  &__steps {
    position: absolute; 
    bottom: -84px; 
    left: 50%;
    transform: translateX(-50%); 
    width: 32px; 
    height: 12px; 
    position: relative; 
    display: flex; 
    justify-content: space-between;

    &::before, &::after {
      content: '';
      width: 12px; 
      height: 100%; 
      border-radius: 100%; 
      background-color: $white;
    }

    &::after { opacity: .5; } 

    &.is-step-2 {
      &::before { opacity: .5; }
      &::after { opacity: 1; }
    }
  }
}
