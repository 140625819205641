$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.card{
  position:relative;
  width: 100%;
  border-radius: 20px;
  padding:100px 32px 20px;
  //margin-top:90px;
  text-align: center;
  &_violet{
    box-shadow: inset 0 4px 4px 0 $black-25;
    background-image: radial-gradient(circle at 0 0, $violet-500, $violet-700);
  }
  &_red{
    box-shadow: inset 0 4px 4px 0 $black-25;
    background-image: radial-gradient(circle at 0 0, #e94751, #ac1d1d);
  }

  &__img{
    position:absolute;
    top: 0;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 180px;
    height: 180px;
    border-radius: 50%  ;
    background-image: radial-gradient(circle at 0 0, #e94747, #ac1d1d);
    //box-shadow: ; #fd9a9a
    box-shadow:  inset 0 0 0 15px $white , inset 0 0 0 20px #fd9a9a , 0 1px 4px 0 rgba(194, 77, 77, 0.5);


    &::before{
      content:'';
      display:block;
      position:absolute;
      top: 0;
      left:5px;
      width: calc(100% - 10px);
      height: 100%;
      background-image: url( $cdn + '/wazamba/sprite-icons-3.png');
      background-size: 1100px;
    }
    &_dep::before{ background-position: -550px 50%; width: calc(100% - 20px); left:10px; }
    &_bet::before{ background-position: -393px 50%; width: calc(100% - 20px); left:10px; }
    &_tourn::before{ background-position: -713px 50%; }
    &_ach::before{ background-position: -893px 50%; }


  }
  &__text{

    font-size: 24px;
    font-weight: bold;
  }
  &__btns{
    margin-top:18px;
  }


}
