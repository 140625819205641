$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.auth-with-deposit-login,
.login-wrap {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: -174px;
    left: -8px;
    width: 416px;
    height: 130px;
    background-image: url('#{$cdn}/wazamba/regisrtation-page/deposit-logo.png');

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url('#{$cdn}/wazamba/regisrtation-page/deposit-logo@2x.png');
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url('#{$cdn}/wazamba/regisrtation-page/deposit-logo@3x.png');
    }
  }

  &__title {
    @include text(20px, 24px, 800);
    font-style: normal;
    background: linear-gradient(180deg, #8F5738 0%, $brown-300 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 20px 0 12px;
  }

  .auth-with-deposit__close {
    position: absolute;
    top: -114px;
    right: 0;
  }

  &__remember-login {
    @include text(20px, 24px, 800);
    font-style: normal;
    background: linear-gradient(180deg, #8F5738 0%, $brown-300 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: start;
  }

  &__funid-btn {
    width: fit-content;
    height: 52px;
    margin: 0 auto;
  }

  &__footer {
    padding-block: 20px 0;
    padding-inline: 0 40px;
    margin-top: 24px;
    position: relative;
    cursor: pointer;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      inset-inline-start: -48px;
      width: calc(100% + 94px);
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.55);
      height: 1px;
      background-color: #B88A40;
    }

    &:after {
      position: absolute;
      content: '';
      top: 20px;
      inset-inline-end: 0;
      width: 42px;
      height: 42px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALhSURBVHgBzZm/T1NRFMe/5xU1OjVRkMTBFhZgkbEyFdCETfgLgL/Awj9A2Zzs+w+A0UnYJArUiW52giYm8EYTGHDCRPqO5z4qSl5pe+69JHySpk3zXt+n9+c55xI8UpoZydFvKsnHN/LKJV8S6sRUj+/Fq+GnRgRLCJ5Ymh59K29hp2tEuPx+92AVFmTggdKrkRUCvet6IaE4Mfw0u390sg0lzi2aSEpLae7hJk+G1UZVc08AB2wkDZShFSjpgyVL0yPzsJBsMQ4lVi2aSILWYU8WStSipddj446SMkipDiUqUSNJMfbgCFN8e6LJYp5IsrrbrsGI0IR6Le1JtLXjuEsKHNOcLE0RlHSd9f8kW1uiA8y8GFYP1d1u6Ljge5fcbazDkhu73qekzJ5VF0lDW9FSMZelC/oIT5KV3YMyHEl1fSLZ93BPZqd690jhSdKQatEg82jtrkkarokuT4+tMXgWrniWNFx1/fLUaIUJJbizUdk5XIBnkhY14dpdljT02caUKRh1js99/Nm2mBb1kzc5heDdydSOTquF4SckOU8RbgwGwf3B/eOTLdwCSXJnZF8O9ecB52VpfGJoIHcbsldZaO34dNObrGWm2Ylr6XJLtgjnrZMLE/kBSMt+hSdSU4CbD+ZsUoXU7xCXl6fG1NnmTaQKELXox6/C82cfiOIZWQ8G4YIpOHhq2baVkkvZx9tENCsPc4vqRbaQ7/8pw6oGBzoHzkWJSQOJSclDuAdeqOw0NmBJ18X+rsj2tCt5lD3j4GIy/PxdPVl73j4vZYNvUjt0zUTPuHmeD6vRmeamnndok+JyjElZd1QPaEOWgkfq4EUVSoTVg3oi6wrxPJSoY55EVlJfuJGDEqvgzKS+jrLq4WMdRSayxFb1eEE9651q+LaxrJTGF2vRaaS5x/mwQS1rqiZ7h+tQ4uVUpGdZhzTa2zmTIdkUMlRmxgux/huAR/LakvG8GX7RnYT8zx/ZgS4D0EtnMQAAAABJRU5ErkJggg==');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 14px;
      transform: rotate(180deg);
    }
  }

  &__not-user {
    font-style: normal;
    @include text(14px, 1.4, 700, $brown-300);
  }

  &__other-acount {
    @include text(12px, 1.3, 700, $brown-200);
    padding-top: 2px;

    text-decoration-line: underline;
  }

  &__wrap {
    button {
      width: 100%;
    }
  }

  &__link {
    padding-top: 24px;
    text-align: center;
    display: block;
    font-weight: 800;
  }

  .rtl-lang & {
    &__footer {
      &:after {
        transform: rotate(0deg);
      }
    }
  }
}

