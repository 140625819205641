$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.sportpage {
  min-height: 892px;

  img {
    max-width: 100%;
  }
  padding-top: 40px;
  padding-bottom: 40px;
  background-image:
    linear-gradient(0deg, rgba(7, 31, 62, 0.2), rgba(7, 31, 62, 0.2)),
    linear-gradient(180deg, rgba(7, 31, 62, 0.4) 0%, rgba(7, 31, 62, 0.8) 99.94%),
    linear-gradient(180deg, rgba(7, 31, 62, 0) 68.75%, $blue-900 100%),
    url($cdn + '/wazamba/sport/bg.png');
  background-size: auto, auto, auto, 100% auto;
  background-repeat: no-repeat;

  & ._asb_bethistory-item-cashout-confirm-popup--popup-panel,
  & ._asb_application--popup-panel._asb_popup-panel._asb_application--popup-panel._asb_popup-panel {
    position: absolute !important;
    top: 10%;
  }

  .container {
    min-height: 892px;
  }

  .rtl-lang & {
    direction: rtl;
  }
}

.linda-hide-sidebar .sportpage .container { transform: translateX(0); transition: transform .2s linear};
