$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.activation-rules {
  &__content {
    width: auto;
    padding-left: 44px;
    padding-right: 44px;
  }

  &__list {
    counter-reset: list;

    color: $brown-300;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: 0.05em;
  }

  &__list-item {
    display: flex;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      counter-increment: list;
      content: counter(list) ".";

      width: 19px;
      flex-shrink: 0;
      font-weight: 800;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
