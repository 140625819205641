$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.league-nav {
	position: fixed;
	top: 290px;
	z-index: 999;
	left: 0;

	&__item {
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		padding: 16px;
		background: $black-25;
		cursor: pointer;

		&.is-active {
			color: #FCD948;
			background: rgba(35, 80, 130, 0.7);
		}

		&:first-child {
			border-radius: 0 12px 0 0;
		}
		&:last-child {
			border-radius: 0 0 12px 0;
		}
	}
}
