$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.shop-scroll {
  display: flex;
  justify-content: center;

  &.is-block {
    .shop-scroll__wrap {
      width: 100%;
    }

    .shop-scroll__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      &.shop-fs-item-list {
        grid-template-columns: repeat(2, 1fr);

        @include l {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    .shop-scroll__arrows {
      display: none;
    }
  }

  &__wrap {
    position: relative;
    overflow: hidden;
    z-index: 19;

    ::-webkit-scrollbar {
      display: none;
    }

    &.is-favorite {
      width: 100%;

      .games-scroll-wrap {
        width: 100%;

        .game-item {
          margin: 0;
          width: calc(100% / 6 - 14px);
        }

        &--landing {
          &:after {
            content: none;
          }
        }
      }
    }
  }

  &__arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    pointer-events: none;
    z-index: 11;

    &-next {
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    &-prev, &-next {
      cursor: pointer;
      padding: 4px;
      transition: opacity .15ms;
      pointer-events: auto;
      border: none;
      background: none;
      z-index: 5;

      &.is-disable {
        opacity: 0;
        z-index: -1;
        transform: scale(0);
      }

      .prev, .next {
        z-index: 11;
        position: relative;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__list {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    padding-top: 20px;
    padding-bottom: 10px;
    gap: 12px;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    .rtl-lang & {

    }

    &.fav-list {
      gap: 12px;
      margin-inline-start: -12px;
      overflow-y: hidden;
    }
  }

  &__item {
    min-width: 79px;
    display: block;
    margin: 0 4px;
    position: relative;

    &.active::before,
    &:hover::before {
      content: '';
      position: absolute;
      left: calc( 50% - 25px);
      top: 12px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow: 0 0 30px 10px #f79afd;
      background: #f79afd;
      z-index: 0;
    }
  }

  &__ico {
    position: relative;
    z-index: 10;
    width: 75px;
    height: 75px;
    margin: 0 auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    .shop-scroll__item:active & { transform: scale(0.97); }
  }

  &__name {
    @include ellipsis;
    padding-top: 10px;
    text-align: center;
    text-shadow: 0 0 10px $black-25;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: .6px;

    .active & { color: $yellow-700; }
  }

  @include l {
    &__item {
      min-width: 83px;
    }
  }

  @include xl {
    &__item {
      min-width: 89px;
    }
  }

  @include xxl {
    &__item {
      min-width: 93px;
    }
  }

  @include full {
    &__item {
      min-width: 96px;
    }
  }
}

