$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.payments-pays{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  width: 100%;
  gap: 16px;
  &__item{
    $item: &;
    position: relative;
    background: $white-10;
    box-shadow: inset 0 0 6px 1px rgba(167, 167, 167, 0.1);
    border-radius: 16px;
    padding: 24px;
    width: auto;
    &.has-guide{
      padding-bottom: 54px;
    }
    &-logo{
      width: auto;
      height: 48px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      filter: grayscale(1) invert(1) brightness(1);
    }
    &-backbox{
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
    }
    &-content{
      display: grid;
      grid-template-columns: 60% 40%;
      & div {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $white-10;
        height: 44px;
        line-height: 17px;
        &:nth-last-child(-n+2){
          border-bottom: none;
        }
      }
    }
    &-title{
      justify-content: flex-start;
      color: #b3b4c1;
    }
    &-sub-title{
      justify-content: flex-end;
      text-align: right;
    }
    &-btn{
      margin-top: 24px;
      font-family: Montserrat,sans-serif;
    }
    &:hover{
      background: $white-20;
      & #{$item}-content{
        opacity: 0;
      }
      & #{$item}-backbox{
        display: grid;
        place-content: center;
      }
    }
    &-guide{
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 24px;
      &__btn{
        color: $white;
        appearance: none;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        font-family: 'Montserrat';
        text-decoration: underline;
        border: none;
        background: none;
        display: block;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }

  &__empty {
    grid-column: span 2;
    padding: 40px 20px;
    border-radius: 20px;
    border: 1px solid $white-20;
    background: rgba(14, 51, 88, 0.90);
    font-weight: 700;
    font-size: 16px;
    line-height: 1.4;
    color: $white;
    text-align: center;
  }
}
