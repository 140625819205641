$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dash-shop {
  background-image: url($cdn + "/wazamba/dashboard/Stone_gray.png");
  border-radius: 9px;
  color: $white;
  padding: 21px 16px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  text-align: center;
  align-items: center;
  width: 180px;
  height: 220px;
  z-index: 1;
  &::before {
    content: "";
    width: 71px;
    height: 38px;
    background-image: url($cdn + "/wazamba/shop-leaf.png");
    background-position: center top;
    top: 0;
    left: 50%;
    transform: translateY(-33px) translateX(-50%);
    background-size: 100% auto;
    position: absolute;
  }
  &__coins {
    background: linear-gradient(180deg, $violet-100 0%, $blue-600 95.03%);
    border-radius: 200px;
    padding: 0 12px;
    height: 32px;

    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    display: flex;
    align-items: center;
    &::before {
      content: '';
      width: 18px;
      height: 18px;
      background-image: url($cdn + "/wazamba/sprite-tournament-page.png");
      background-size: 125px;
      background-position: 0 -36px;
      margin-right: 5px;

    }
  }
  &__title {
    margin-bottom: auto;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
  }
  &__text {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 2px;
  }
  &__amount {
    font-weight: 800;
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 8px;
    word-break: break-word;
  }
  &__img {
    width: 95px;
    height: 80px;
    margin: 0 auto 11px;
  }
  &__mask {
    width: 100%;
    height: 123px;
    margin: 0 auto auto;
    background-size: auto 100%;
    background-position: center top -10px;
    background-repeat: no-repeat;
  }
  &__fs-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    mask: url( $cdn + "/wazamba/desktop/img/stone-svgmask.svg");
    mask-position: center;
    background-size: 123px auto;
    background-repeat: no-repeat;
    background-position: bottom right;
    opacity: 0.8;
  }
}
