$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
html{
  min-width: 1024px;
  max-width: 1024px;

  @include m {
    max-width: 100vw;
  }
}

body {
  @include text(14px, null, 400, $white);
  font-family: 'Montserrat', sans-serif;
  background-color: $blue-900;
  min-width: 1024px;
  max-width: 1024px;

  @include m{
    max-width: inherit;
  }
}

body.linda-has-user {
  & .show-guest{ display:none !important;}
}
body:not(.linda-has-user) {
  & .show-user{ display:none !important;}

}

.page-f {
  min-height: 100vh;
  width: 100%;
  display:flex;
  flex-direction: column;

  &__footer { margin-top:auto; }
}

.container {
  width: calc( 100% - 268px );
  margin:0 auto;
  transform: translateX(-114px);
  transition: .2s all ease-in-out;

  body.linda-hide-sidebar & {
    transform: translateX(0);
  }
}

.page{
  background-repeat: no-repeat;
  background-position: 50% top;
  background-size: 100% auto;
  min-height: calc( 100vh - 355px );

  &:not(.no-padding) {
    padding:0 230px 0 0;
    transition: .2s padding ease-in-out;

    body.linda-hide-sidebar & {
      padding:0 115px 0;
    }
  }

  &:last-child,
  body.linda-hide-sidebar &:last-child {
    padding-bottom: 60px;
  }

  &.is-faq-page {
    background: center / 298px url($cdn + '/wazamba/renovation/league-promo/bg-pattern.png'), linear-gradient(90deg, #181A3D 0%, #1E214C 54.17%, #181A3D 100%);

    .infopage__wrap {
      position: relative;
      z-index: 1;
    }

    .page__container {
      padding: 0 20px 200px;
    }
  }

  &_bg1 { background-image: url($cdn + '/wazamba/page-bg-1.jpg') ;}
  &_bg2 { background-image: url($cdn + '/wazamba/page-bg-2.jpg') ;}
  &_bg3 { background-image: url($cdn + '/wazamba/page-bg-3.jpg') ; background-repeat: repeat;}

  &_bg4 {
    background-image: linear-gradient( to bottom , rgba(7,31,62,0.3) , rgba(7,31,62,0.5) ) ,  url($cdn + '/wazamba/baner/lb_bg2.jpg') ;
    background-repeat: repeat;
    background-size: cover;
  }

  &_bg-hlw {
    background-image: url($cdn + '/wazamba/wof/wof_desk-bg.jpg ') ;
    background-size: 1715px 1060px;
    background-position: calc( 50% - 114px ) top;
    background-repeat: no-repeat;
    background-color: #07060C;
    position:relative;
    padding-bottom:40px !important;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 40px;
      height: 40px;
      width: 100%;
      background: linear-gradient(180deg, rgba(8, 7, 13, 0) 0%, #08070D 70%);
      z-index: 20 ;
    }
  }

  &_bg-ach {
    background-image: linear-gradient(to bottom , rgba(7,31,62,0) 650px , rgba(7,31,62,1) 840px ) ,  url($cdn + "/wazamba/page-bg-3.jpg") ;
    background-size: 100% 100% , auto 840px;
    background-repeat: repeat-x , repeat;
  }

  &_full {
    min-height:100vh;
    padding:0 230px 0 0 !important;
  }

  &__container {
    padding:0 20px;

    &_24{ padding:0 24px; }
  }

  &__title {
    @include text(36px, normal, 800, $yellow-200);
    padding-top:52px;
    text-align: center;
    text-shadow: 0 3px 0 $orange-1000;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.8px;
    margin: 0;
    background: url( $cdn + '/wazamba/page-title.png') 50% top no-repeat;
  }
}

.table-filter {
  padding: 10px 0;

  &__wrap {
    display: flex;
    flex-direction: row;
    margin: 0 -10px;
    align-items: center;

    .rtl-lang & {
      flex-direction: row-reverse;
    }
  }

  &__item {
    width: 180px;
    margin: 0 10px;

    &_big{ width: calc(100% - 420px);}
  }
}

.table {
  width: 715px;
  position: relative;
  min-height: 410px;

  &_pay{ font-weight: 500; }

  &__thead {
    border-radius: 2px;
    background-color: $orange-100;
    height: 40px;
    display:flex;

    .table_pay & {  padding: 0 5px; }
  }

  &__th {
    @include text(12px, null, bold, $brown-300);
    height: 40px;
    padding: 3px 5px;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__tbody-wrap {
    overflow: hidden;
  }

  &__tbody {
    overflow-y: auto;
    max-height: 369px;
  }

  &__tbody-inner {

    &_popup {
      width: 725px;
      max-width: 100%;
    }
  }

  &__tr {
    display: flex;
    max-width: 100%;
    margin-top: 1px;
    background-color: #f4dbb5;
    border-radius: 2px;

    .table_pay & {  padding: 0 5px; }
    .has-preloader & { opacity:0.6; }

    .table__td {
      direction: ltr;
    }
  }

  &__td {
    @include text(13px, 1.3em, null, #111114);
    min-height: 40px;
    max-height: 40px;
    padding:2px 5px;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    text-align: center;
  }

  &__arrows {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(to top, #f4dbb5, rgba(244 , 218, 181 , 0) 95%);
  }

  &__arr {
    position:absolute;
    bottom: -20px;

    &_next{ left: calc( 50% - 56px ); }
    &_prev{ left: calc( 50% + 10px ); }
  }

  &__noresult {
    @include text(16px, 1.8em, 600, #f4dbb5);
    padding: 100px 0;
    text-align: center;
    width: 400px;
    max-width: calc(100% - 40px);
    margin: 0 auto;

    .table.has-preloader &,
    .table.has-result & { display:none; }
  }

  &__status {
    @include ellipsis;
    font-size: 14px;
    font-weight: bold;

    &-pending { color: $orange-1000; }
    &-complete { color: $green-500; }
    &-cancel { color: $blue-500; }
  }

  &__cancel {
    font-size: 14px;
    font-weight: bold;
    color: $brown-300;
    text-decoration: underline;
  }

  &__game1{ width: 140px; }
  &__game2{ width: 100px; }
  &__game3{ width: 135px; }
  &__game4,
  &__game5,
  &__game6 { width: 83px; }
  &__game7{ width: 90px; }

  &__pay1{ width: 30%; }
  &__pay2{ width: 20%; }
  &__pay3{ width: calc(50% - 190px); }
  &__pay4{ width: 120px; }
  &__pay5{ width: 150px; padding-right: 10px; }
}

.label-status{
  @include text(12px, 32px, 800, $white);
  @include ellipsis;
  width: 114px;
  height: 45px;
  background-image: url( $cdn + '/wazamba/desktop/img/labels.svg');
  background-size: 300%;
  text-align: center;
  letter-spacing: 0.6px;
  background-position-y: top;
  background-repeat: no-repeat;
  padding: 0 17px;

  &_0 { background-position-x: 50% ; }
  &_1 { background-position-x: 0 ; }
  &_2 { background-position-x: 100% ; }
  &_active { background-position-x: 0 ; }
  &_new { background-position-x: 50% ; }
}

.pays {
  min-height: 400px;

  &__title {
    padding:30px 0;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  &__list {
    width: 600px;
    margin:0 auto;
    display:flex;
    flex-wrap: wrap;

    &.is-disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  &__item {
    width: calc( 25% - 12px );
    margin: 6px;
    height: 86px;
    background: $white;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 2px 6px 0 rgba(136, 136, 136, 0.3);
    transition: 0.2s ease-in-out;
    padding: 26px 8px;

    &:hover{
      background: linear-gradient(to bottom, $yellow-400, $orange-200);
      box-shadow: 0 4px 8px rgba(110, 57, 35, 0.456);
    }

    & img {
      width: 100%;
      height: 100%;
      font-size: 10px;
      word-break: break-all;
      color: $black;
    }
  }

  &__stub-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__stub {
    width: 100%;
    max-width: 480px;
    min-height: 230px;
    padding: 10px 32px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: $brown-300;
    text-align: center;
    letter-spacing: 0.05em;
    border-radius: 10px;
    background-color: $orange-100;
  }

  &__stub-title {
    @include text(16px, 20px, 800);
    margin-bottom: 16px;
  }

  &__stub-text {
    @include text(14px, 1.4, 500);
    margin-bottom: 20px;
  }
}

.pays-back {
  position: absolute;
  top: 85px;
  inset-inline-start: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & span {
    padding-inline-start: 10px;
  }

  .rtl-lang & {
    .g-btn {
      transform: scale(-1, 1);

      &:active {
        transform: translateY(2px) scale(-1, 1) !important;
      }
    }
  }
}

.payment-form {
  min-height: 473px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 0;
  font-size: 18px;
  font-weight: bold;

  &.is-crypto {
    padding-top: 10px;
    min-height: auto;

    .payment-bottom {
      padding-top: 0;
    }
  }

  &.has-qr-code > *:not(&__ico):not(&__qr) {
    display: none;
  }

  &__qr {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span {
      display: block;
      margin-top: 40px;
    }

    & > img {
      width: 164px;
      height: 164px;
      object-fit: contain;
      margin-top: 40px;
    }
  }

  &_withdraw {
    padding-top: 50px;
    min-height: 450px;
  }

  &__header {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &__ico {
    width: 138px;
    height: 86px;
    border-radius: 5px;
    background: linear-gradient(to bottom, $yellow-400, $orange-200);
    box-shadow: 0 4px 8px rgba(110, 57, 35, 0.456);
    padding: 26px 8px;

    & img {
      width: 100%;
      height: 100%;
      font-size: 10px;
      word-break: break-all;
    }
  }

  &__table {
    position: absolute;
    inset-inline-start: calc( 50% + 110px );
    top: 50%;
    transform: translateY(-50%);
  }

  &__table-item {
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__min{ padding-top:20px; }

  &__descriptoin {
    border-radius: 8px;
    border: 1px solid rgba(186, 145, 93, 0.45);
    background: #FEDC9F;
    box-shadow: 0 1px 0 0 #AA8C5B;
    padding: 16px 16px 16px 0;
    width: 588px;
    margin: 20px auto;

    ol {
      margin: 0;
      display: grid;

      li {
        @include text(14px, normal, 600, $brown-300);
        font-style: normal;
        letter-spacing: 0.7px;
        position: relative;
        z-index: 1;
      }
    }
  }
}

.hide-amount .if-hide-amount { display:none !important; }

.quick-amount {
  padding-top: 25px;

  &__title {
    text-align: center;
  }

  &__row {
    padding-top:10px;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;

  }
  &__item{
    @include text(28px, 86px, 800, $brown-300);
    margin: 10px;
    height: 86px;
    border-radius: 5px;
    background-color: $orange-100;
    padding: 0 20px;
    letter-spacing: 1.4px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    &:hover,
    &.active{
      box-shadow: inset 0 0 10px 0 $black-25;
      background-color: $brown-300;
      color: $white;

      &::before{
        left:calc( 50% - 10px );
        top: -10px;
        content: '';
        display: block;
        position: absolute;
        background: #dfab68;
        width: 18px;
        height: 18px;
        transform: rotate(45deg) ;
        box-shadow:  0 0 10px 0 $black-25;
      }
    }
  }
}

.payment-bottom {
  padding-top: 30px;
  display: flex;
  justify-content: center;

  &__item {
    width: 284px;
    margin: 0 10px;

    &.amount { padding-top:40px; }
  }

  &__text {
    @include ellipsis;
    text-align: center;
    padding-bottom: 20px;
  }

  &__input {
    position: relative;
  }
}

.payment-fields {
  padding-top: 30px;
  width: 588px;

  &__title { text-align: center; }

  &__list {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin:0 -10px;
  }

  &__item {
    width: calc( 50% - 20px );
    margin: 0 10px;
  }
}

.payment-reminder {
  @include text(18px, 29px, bold, $red-100);
  letter-spacing: 0.05em;
  padding-top: 20px;
  text-align: center;
}

.history-section {
  &__title {
    padding-top: 5px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  &__link {
    margin: 20px;
    text-align: center;

    & a {
      @include text(14px, 1.52, bold);
    }

    & svg {
      width: 6px;
      height: 9px;
      margin:0 7px;
      vertical-align: middle;
    }

    .rtl-lang & {
      & svg {
        transform: scale(-1, 1);
      }
    }
  }

  &__content {
    width: 715px;
    margin:10px auto 0;
  }
}

.description-deposit-registration,
.description-deposit-registration-form {
  @include text(12px, 158.47%, 500, $white);
  background: rgba(110, 57, 35, 0.7);
  border-radius: 10px;
  padding: 28px 24px;
  text-align: center;
  letter-spacing: 0.05em;
  margin: 8px auto 20px;
  width: 600px
}

h1.page-title{
  font-size:36px;
  font-weight: 800;
  text-align: center;
  margin:40px 0 20px;
}
