$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.funid-banner {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 0 20px;
    align-items: center;
    width: 587px;
    height: auto;
    padding: 8px 82px 12px;
    margin: 0 auto;
    background-color: #43BAFF;
    border-radius: 10px;
    background-size: 70px;
    background-repeat: no-repeat;
    background-position: calc(100% - 65px) bottom;
    &__logo {
        border-radius: 20px;
        width: 60px;
        height: 60px;
        grid-row: 1/4;
    }
    &__title {
        font-size: 16px;
        font-weight: 800;
        font-style: italic;
        line-height: 20px;
        color: #4041E2;
        margin: 0;
    }
    &__text {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        margin: 0;
    }
    &.in-cashbox {
        margin-top: -10px;
        margin-bottom: 20px;
    }
}