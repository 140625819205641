$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.crypto {
  position: absolute;
  bottom: 30px;
  left: 0;
  padding: 20px 250px 20px 20px;
  width: 100%;
  
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(98px, 98px));
  justify-content: center;

  &__icon {
    height: 35px;
    object-fit: contain;
    object-position: center;

    box-shadow: 0 2px 6px rgba(136, 136, 136, 0.3);
    background-color: rgba($orange-100, .92);
    border-radius: 5px;

    cursor: pointer;
  }
}
