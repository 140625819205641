$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.bomani-league-popup {
  position: relative;
  width: 516px;
  margin: 0 auto;

  background-repeat: repeat-y;
  background-size: contain;
  background-position: center;
  z-index: 1;

  background-image: url(#{$cdn}/wazamba/leagues/weekly-league-popup/bg_mid.png);

  @media (-webkit-max-device-pixel-ratio: 2) {
    background-image: url(#{$cdn}/wazamba/leagues/weekly-league-popup/bg_mid@x2.png);
  }
  @media (-webkit-max-device-pixel-ratio: 3) {
    background-image: url(#{$cdn}/wazamba/leagues/weekly-league-popup/bg_mid@x3.png);
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: -1;
  }

  &:before {
    top: -144px;
    width: 516px;
    height: 212px;

    background-image: url(#{$cdn}/wazamba/leagues/weekly-league-popup/bg_top.png);

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url(#{$cdn}/wazamba/leagues/weekly-league-popup/bg_top@x2.png);
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url(#{$cdn}/wazamba/leagues/weekly-league-popup/bg_top@x3.png);
    }
  }

  &:after {
    bottom: -120px;
    width: 516px;
    height: 179px;
    background-image: url(#{$cdn}/wazamba/leagues/weekly-league-popup/bg_down.png);

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url(#{$cdn}/wazamba/leagues/weekly-league-popup/bg_down@x2.png);
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url(#{$cdn}/wazamba/leagues/weekly-league-popup/bg_down@x3.png);
    }
  }

  .bomani-helper {
    position: relative;
    width: 323px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    & > span {
      @include text(18px, 140%, 800);
      display: block;
      color: $white;

      &.is-b {
        font-weight: 800;
        margin-bottom: 5px;
        margin-top: 24px;
      }
      &.is-t {
        @include text(16px, 18px, 600);

        margin-top: 24px;
        text-align: center;
        color: $white-70;
      }
      &.is-l {
        @include text(20px, 140%, 800);

        text-align: center;
        margin-bottom: 12px;
      }
      &.is-center {
        text-align: center;
      }
    }

    ul {
      list-style: disc;
      margin-left: 28px;
      margin-right: 14px;

      li {
        @include text(16px, 140%, 700);
        color: $white;
      }
    }

    .low-balance {
      @include text(18px, 140%, 800);

      margin-top: 32px;
      text-align: center;
      color: #FCD948;
    }

    &__close {
      position: absolute;
      top: -70px;
      inset-inline-end: -60px;
    }

    &__item {
      background: rgba(30, 10, 4, 0.3);
      border-radius: 8px;

      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px 20px 12px 36px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        @include text(16px, 20px, 700);

        color: $white;
        text-align: left;
        position: relative;
        padding-bottom: 8px;

        &:last-child {
          padding-bottom: 0;
        }

        &.is-done {
          @include text(18px, 130%, 700);
          color: #38b751;

          &:before {
            content: '';
            width: 12px;
            height: 13px;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIeSURBVHgBfVNNTxNRFD1vZoJgKJkG/EqQlMTPGpPKAlnWBW6M2EQ2rqD/ABdGg9FUFxoSE+vWhdYVK5OqsMGFLNUN3VhDWHQCBAI06aQlhZLOG+59TCdtabnJy/u659z7zrtXoIVNuTGzE05MQISOTmRGwsjMiLTV7CvqN0/dWEiD85mAUbQ0N+VAf1VP5BM8d8cmXCBJS7Pwu4SdnwXsZvfUXXe4C2dHgzBHAry1yW/qrfj+xSegyFEd8le1WEX2iYX8gt0y/vnxXlx5MQCjR4cDeWdGzC0qgml3LEdT6O+9f37UdtYdPo3h+TAvrTKKt/Rp9/4kJTKZS25ge67QFtjZ34Hg7QDsPyWVd3AkYBro2NLoPRPssPk1fyJ4aPYabn68pDJY+7RVu3qgkeKRUraM/fUDH3DhYa8C1YN5Zr9dGtWio2bGGqw6H9TszKiJ6+8GFeHK61Vcfjngg5ceLft+HsZkAptUNWsXpf9l7K1X0NV/SqWszjxwfSD+CcaSBm4mQO/yDlRkdmaSdmDOiLVgLGfwjUb0Yvwcch82GkiMHoPWlQaw0mi8z1uJlEhQ3Vcgl4Sqg6wS5yTjqhyev0HRYVE1DmoJkbYlZJwvh2avqh9oZyww/whbDeP3wjMqKA3iPbxeyC809kLf3SAXjxKOmurxG/Ej1UDAdtSNMiG84mo2Em1RQo+37MbjRNUIoEU8oLUPPZ2k5zb7HgLunetkYYViOAAAAABJRU5ErkJggg==');
            background-size: contain;
            position: absolute;
            top: 4px;
            left: -16px;
          }
        }

        &.is-not-done {
          @include text(18px, 130%, 700);
          color: #FCD948;

          &:before {
            content: '';
            width: 12px;
            height: 13px;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIGSURBVHgBjVNBaFNBEH27//+aYNSvCClejKZVctHkaKESD4WvF8WDJ6VpbwqiXgRP1ouCPSgUPNoInjzViwRBDAp6bPQSJLV+8aABsUGjjW3+bmf2t6FNk6bvsrOzO29ndt4IdICezbrLkcg5ASR4L6QqWUqWRKrgt98V6zeLZS/hWJiGRrYTsRDIywB31hO1CJpz3igC/ZBc7uyHOt68/425z4vmbCAZxckTu5E5HuP8anTnun208KRFsFz2skLi9Z+/Ae5OfsNbCu6E0yN7ce3yAcR2WtAKp5xUoWgImp+8L7QkclcqrVe7YTAZwfSjI2z61r9GxloqezmqLff4aRWvijX0wq+FplmpHDewraqUFkbZ8eLlAraLZzM/zSqlOGtD63RlvoEf1SXjvHf7IGIxq2vwrYmvqNcDVKhUKidt86+zg9Ef78Pw0J4tX99F5HX67DBGuDa3hV50+ZAPuIXdwOffVzMNs9Q1m9RRGjwczXJrmPXqzXn0Qn/cofSjFI+SVEo/Z+eF8/uxXZwZ2RcarExnx/88mf74xbjpcS8M0J3xS3E2fVajFIeKNVLVGHum7ieN2rphmOQ8NZk09lpMaxZYUFLqB2YWPtIsvGubhSGahWPhLCglbvSlCvkNBAyeRvrbCSlDcW2CRtHSGOs4je1EjlRprWQ6jIPvNBozIrNZ6ysMLcGoC+f5wwAAAABJRU5ErkJggg==');
            background-size: contain;
            position: absolute;
            left: -16px;
            top: 4px;
          }
        }
      }
    }

    .is-btn {
      width: fit-content;
      min-width: 208px;
    }

    .g-btn {
      align-self: center;
      &:not(:first-child) {
        margin-top: 16px;
        margin-bottom: 14px;
      }
    }
  }
}
