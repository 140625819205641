$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.league-faq {
	h1 {
		@include text(20px, 24px, 800);
		text-align: center;
		letter-spacing: 0.05em;
		color: #ffd467;
	}
	h2 {
		@include text(16px, 20px, 700);
		color: #ffd467;
		text-align: left;
		position: relative;
		padding-left: 30px;
		margin-bottom: 4px;
		& > span {
			width: 30px;
			position: absolute;
			inset-inline-start: 0;
		}
	}
	p {
		@include text(16px, 20px, 500);
		color: #ffffff;
		text-align: start;
		padding-inline-start: 30px;
		margin-bottom: 32px;
		&.league-faq-qualification {
			position: relative;
			width: 226px;
			height: 46px;
			background: #5d6b77;
			border-radius: 4px;
			display: flex;
			align-items: center;
			margin-left: 74px;
			@include text(12px, 16px, 700);
			color: #ffffff;
			padding-inline-start: 36px;
			margin-bottom: 20px;
			margin-top: 20px;

			&::before {
				content: '';
				width: 100px;
				height: 100px;
				background-image: url(#{$cdn}/wazamba/leagues/icon-league/closed.png);
				@media (-webkit-max-device-pixel-ratio: 2) {
					background-image: url(#{$cdn}/wazamba/leagues/icon-league/closed@2x.png);
				}
				@media (-webkit-max-device-pixel-ratio: 3) {
					background-image: url(#{$cdn}/wazamba/leagues/icon-league/closed@3x.png);
				}
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				inset-inline-start: -60px;
				top: 50%;
				margin-top: -50px;
			}
			&::after {
				content: '?';
				@include text(30px, 30px, 700);
				text-align: center;
				color: #d0d4d8;
				width: 100px;
				height: 100px;
				position: absolute;
				inset-inline-start: -60px;
				top: 50%;
				margin-top: -50px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-inline-start: 5px;
			}
		}
	}
	a {
		color: #ffd467;
		text-decoration: underline;
	}

	.screen.has-game-bg {

		background: center / 298px url($cdn + '/wazamba/renovation/league-promo/bg-pattern.png'), linear-gradient(90deg, #181A3D 0%, #1E214C 54.17%, #181A3D 100%);
		@media (-webkit-max-device-pixel-ratio: 2) {
			background: center / 298px url($cdn + '/wazamba/renovation/league-promo/bg-pattern@2x.png'), linear-gradient(90deg, #181A3D 0%, #1E214C 54.17%, #181A3D 100%);
		}
		@media (-webkit-max-device-pixel-ratio: 3) {
			background: center / 298px url($cdn + '/wazamba/renovation/league-promo/bg-pattern@3x.png'), linear-gradient(90deg, #181A3D 0%, #1E214C 54.17%, #181A3D 100%);
		}
		background-size: 298px, cover;
	}
}

.league-faq-coins, .league-faq-cash {
	background: rgba(255, 255, 255, 0.12);
	border-radius: 8px;
	display: inline-block;
	margin-top: -20px !important;
	margin-bottom: 0 !important;
	margin-inline-start: 30px !important;
	padding-block: 11px !important;
	padding-inline: 94px 18px !important;
	min-width: 148px;
	&::before {
		content: '';
		width: 47px;
		height: 42px;
		background-image: url(#{$cdn}/wazamba/leagues/terms/icon.png);
		@media (-webkit-max-device-pixel-ratio: 2) {
			background-image: url(#{$cdn}/wazamba/leagues/terms/icon@2.png);
		}
		@media (-webkit-max-device-pixel-ratio: 3) {
			background-image: url(#{$cdn}/wazamba/leagues/terms/icon@3.png);
		}
		display: inline-block;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		position: absolute;
		inset-inline-start: 10px;
		top: 50%;
		margin-top: -23px;
	}
}

.league-faq-cash {
	margin-bottom: 30px !important;

	&::before {
		background-image: url(#{$cdn}/wazamba/leagues/terms/icon_2.png);
		background-size: cover;
		margin-top: -20px;

		@media (-webkit-max-device-pixel-ratio: 2) {
			background-image: url(#{$cdn}/wazamba/leagues/terms/icon_2@2.png);
		}
		@media (-webkit-max-device-pixel-ratio: 3) {
			background-image: url(#{$cdn}/wazamba/leagues/terms/icon_2@3.png);
		}
	}
}

.league-faq-table {
	background: rgba(255, 255, 255, 0.12);
	border-radius: 8px;
	width: 260px;
	height: 48px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 14px 24px;
	margin-block: -22px 8px;
	margin-inline: 29px 0;

	span {
		padding: 0;
		margin: 0;
	}

	span:nth-child(1) {
		@include text(12px, 15px, 700);
		color: #ffedd2;
	}

	span:nth-child(2) {
		@include text(16px, 20px, 700);
		color: #e7c466;
	}
}
