$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-item {
  display: block;
  width: 130px;

  &_small { width: 100px; }
  &_big { width: 220px; }
  &_red { width: 100% !important; }
  &__wrap {
    position: relative;
    padding-top: 102.3%;
    border-radius: 18px;
    background: url( $cdn + "/wazamba/sprite-icons-3.png");
    background-size:  564%;
    background-position: 0 50%;

    &.is-status2,
    &.is-status3 { background-position: 22% 50%; }
  }

  &__img {
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    .is-status0 &,
    .is-status1 & { filter: brightness(0) invert(10%) sepia(67%) saturate(1148%) hue-rotate(182deg) brightness(98%) contrast(100%); }
  }

  &__progress {
    position: absolute;
    left: 17%;
    bottom: 26%;
    height: 8px;
    width: 65%;
    background: $white;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 $black-25;
    z-index: 7;
    display: none;

    .is-status1 & { display: flex; }
  }

  &__progress-str {
    height: 8px;
    border-radius: 4px;
    background-image: linear-gradient(to bottom, $green-400, $green-200 95%);
  }

  &__time {
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    height: 21px;
    background: $blue-500;
    padding: 0 12px;
    line-height: 21px;
    border-radius: 11px;
    font-size: 12px;
    font-weight: bold;
  }

  &__tooltip {
    .ach-item__wrap:not(:hover) &{ display:none;}
  }

  &__btns {
    margin-top: 15px;
    max-width: 100%;
  }

  @include l {
    width: 140px;

    &_small { width: 130px; }
    &_big { width: 220px; }
  }
}
