$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.landing-masks {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px calc(100% - 380px) 30px 20px;
  border-radius: 10px;
  background-image: url($cdn + '/wazamba/wazamba-v01/backgrounds/backgrpund_new-mask-widget.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 3px 6px 0 0 #9E8366;

  @include l {
    margin-top: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding: 30px calc(50% + 30px) 30px 20px;
  }

  @include xl {
    margin-top: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  @include xxl {
    padding-top: 65px;
    padding-bottom: 65px;
    min-height: calc(100% - 110px);
  }

  @include full {
    padding-top: 55px;
    padding-bottom: 55px;
    padding-left: 40px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -40px;
    right: 0;
    width: 49.2%;
    bottom: 0;
    background-image: url($cdn + "/wazamba/wazamba-v01/new-mask-widget/new_mask_widget_desk.png" );
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    pointer-events: none;
    z-index: 10;

    @include l {
      top: -60px;
      background-size: 450px auto;
    }

    @include xxl {
      background-size: 180%;
    }

    @include full {
      background-size: 150%;
    }
  }

  &__hr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: inherit;
    overflow: hidden;

    &::before,
    &::after {
      position: absolute;
      content: '';
      left: 0;
      width: 100%;
      height: 60px;
      background-image: url($cdn + '/wazamba/hr.png');
      background-position: left bottom;
      background-repeat: repeat-x;
      background-size: auto 100%;
    }

    &::before {
      top: -12px;
      transform: scale(1, -1);
    }

    &::after {
      bottom: -12px;
    }
  }

  &__title {
    max-width: 330px;
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.05em;
    text-align: center;
    color: $white;

    @include l {
      max-width: 100%;
    }

    @include xxl {
      font-size: 36px;
      line-height: 44px;
      max-width: 280px;
    }

    @include full {
      font-size: 40px;
      line-height: 49px;
      max-width: 350px;
    }
  }

  &__button {
    @include xxl {
      &.g-btn {
        height: 56px;
        font-size: 24px;
        line-height: 46px;
        letter-spacing: .05em;
      }
    }
  }
}
