$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
//TODO var colors

.funid-button {
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  min-width: 215px;
  height: 52px;
  padding-block: 0;
  padding-inline: 52px 41px;

  background: transparent;
  border: 2px solid #B88128;
  border-radius: 100px;

  font-family: inherit;
  font-weight: 800;
  font-size: 14px;
  line-height: 1.2;
  color: #6E3923;

  position: relative;
  z-index: 3;

  cursor: pointer;
  overflow: hidden;

  &:disabled {
    background: rgba(137, 105, 82, 0.2);
    border: 2px solid rgba(168, 140, 125, 0.8);
    color: rgba(138, 115, 104, 0.8);
    pointer-events: none;
    cursor: default;

    &::before {
      background-image: var(--icon-btn-funid-white);
      background-color: #A88C7DFF;
      opacity: 0.85;
    }
  }

  &:hover {
    color: #ffffff;
    transition: color 0.5s linear;

    &::before {
      animation: 1s forwards animate-logo;
    }

    &::after {
      animation: 1s forwards animate-background;
    }
  }

  &::before {
    content: "";
    width: 48px;
    height: 100%;

    background: var(--icon-btn-funid-color) 50% / 17px 25px no-repeat #3F38DD;
    border-radius: 30px;

    position: absolute;
    top: 50%;
    inset-inline-start: calc(0% + 24px);
    z-index: 2;
    transform: translateX(-50%) translateY(-50%) scale(0.834);
  }

  &::after {
    content: "";
    width: 0;
    height: 100%;

    background: #3F38DD;

    position: absolute;
    inset-inline-start: 0;
    z-index: 1;
  }

  &__text {
    position: relative;
    z-index: 4;
  }

  .rtl-lang & {
    &::before {
      inset-inline-start: calc(0% - 24px);
    }

    &:hover {
      &::before {
        animation: 1s forwards animate-logo-rtl;
      }
    }
  }
}

@keyframes animate-logo {
  0% {
    inset-inline-start: calc(0% + 24px);
  }

  25% {
    transform: translateX(-50%) translateY(-50%) scale(1);
    inset-inline-start: calc(0% + 24px);
  }

  100% {
    transform: translateX(-50%) translateY(-50%) scale(1);
    inset-inline-start: calc(100% - 24px);
  }
}

@keyframes animate-logo-rtl {
  0% {
    inset-inline-start: calc(0% - 24px);
  }

  25% {
    transform: translateX(-50%) translateY(-50%) scale(1);
    inset-inline-start: calc(0% - 24px);
  }

  100% {
    transform: translateX(50%) translateY(-50%) scale(1);
    inset-inline-start: calc(100% - 24px);
  }
}

@keyframes animate-background {
  0% {
    width: 0;
  }

  15% {
    width: 0;
  }

  25% {
    width: 13%;
  }

  100% {
    width: 90%;
  }
}
