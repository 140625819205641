$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.lang-version-notification {
    width: 400px;
    top: 50%;
    padding: 32px 30px;
    position: absolute;
    transform: translateY(calc(-50% - 20px));
    background-image: radial-gradient(50% 50% at 50% 50%, $violet-500 0%, $violet-700 100%);
    box-shadow: 0 0 15px $black-20;
    border-radius: 20px;
    &__close {
        position: absolute;
        inset-inline-end: 18px;
        top: 18px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        border-radius: 50%;
        fill: $white;

        &:hover {
            background: $white-26;
        }
    }
    &__header {
        margin-bottom: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__flag {
        height: 26px;
        margin-right: 12px;
    }
    &__zimpler-logo {
        height: 38px;
    }

    &__text {
        text-align: center;
        font-size: 16px;
        line-height: 1.4;
        font-weight: 500;
        margin-bottom: 42px;
    }
    &__btn-group {
        display: flex;
        justify-content: center;
    }
    &__btn {
       margin: 0 10px;
    }
}
