$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dashboard-weekly {
  background-image: url($cdn + '/wazamba/baner/mobile-league/banner-dekor-bg.png'), linear-gradient(90deg, #181A3D 0%, #2D3284 44.27%, #2D3284 61.46%, #181A3D 100%);
  background-size: contain;
  box-shadow: 0 4px 6px $black-36;
  border-top: 1px solid rgba(255, 255, 255, 0.11);
  border-radius: 12px;
  max-width: 580px;
  width: 100%;
  min-height: 112px;
  margin-inline: auto;
  padding: 15px 22px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media screen and (min-width: 1280px) {
    max-width: 660px;
  }
  &__info {
    max-width: 400px;
    width: 100%;
  }
  &__label {
    display: inline-block;
    font-size: 10px;
    line-height: 14px;
    font-weight: 700;
    background: #C70606;
    border-radius: 4px;
    padding: 2px 4px;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__link {
    display: block;
    margin-bottom: 5px;
    margin-top: 16px;
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0.05em;
    color: #F9EB62;
    text-shadow: 0 0 4px $black-25, 0 2px 0 $orange-900;
    position: relative;
    width: max-content;

    &:after {
      content: '';
      position: absolute;
      top: 7px;
      width: 10px;
      height: 22px;
      inset-inline-end: -20px;
      background-image: url($cdn + '/wazamba/icon/left-arrow.svg');
      transform: scale(-1,1);
      background-position: center;
    }
  }
  &__text {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: $white;
    letter-spacing: 0.05em;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__leagues {
    width: 222px;
    height: 26px;
  }
  &__img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    inset-inline-end: 27px;
    width: 198px;
    height: 131px;
  }

  .rtl-lang & {
    &__link {
      &:after {
        transform: none;
      }
    }
  }
}
