$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.link {
  @include text(18px, 22px, bold);
  text-decoration: underline;
  letter-spacing: 0.05em;

  &.is-size-14 { font-size: 14px; }
  &.is-size-12 { @include text(12px, 15px); }
  &.is-white { color: $white; }
  &.is-yellow { color: $yellow-700; }
  &.is-brown { color: $brown-300; }
  &.has-shadow { text-shadow: 0 0 10px $black-25; }
}

.g-link {
  @include text(14px, null, bold, $brown-300);
  letter-spacing: 0.7px;
  text-decoration: underline;

  &-hover {
    &-yellow {
      filter: drop-shadow(0 0px 10px $black-25);
      cursor: pointer;
      text-decoration: underline;
      color: $yellow-700;

      & svg { fill: $yellow-700; }

      &:hover {
        color: $yellow-100;

        & svg { fill: $yellow-100; }
      }
    }

    &-brown {
      cursor: pointer;
      text-decoration: underline;
      color: $brown-200;

      & svg { fill: $brown-200; }

      &:hover {
        color: $brown-100;

        & svg { fill: $brown-100; }
      }
    }

    &-white {
      cursor: pointer;
      text-decoration: underline;
      color: $white;

      & svg {
        fill: $white;
      }

      &:hover {
        text-decoration: none;
      }

      &--no-underline {
        color: $white;
        cursor: pointer;
        text-decoration: none;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          inset-inline: 0;
          height: 1px;
          background-color: $white;
          transform: scale(0, 1);
          transform-origin: bottom left;
          transition: transform .15s ease-out;

          .rtl-lang & {
            transform-origin: bottom right;
          }
        }

        &:hover::after {
          transform: scale(1, 1);
        }
      }
    }

    &-grey {
      cursor: pointer;
      color: $white-70;

      & svg { fill: $white-70; }

      &:hover {
        color: $white;

        & svg { fill: $white; }
      }
    }
  }

  &-chat {
    text-align: center;
    margin-top: 20px;

    & svg {
      width: 20px;
      height: 20px;
    }

    & span {
      @include text(14px, 20px, bold);
      margin-inline-start: 10px;
    }
  }
}
