$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dash-dep {
  padding: 24px 16px 20px;
  color: $white;
  display: flex;
  flex-direction: column;
  background-image: url($cdn + "/wazamba/dashboard/Stone_gray.png");
  background-position: center;
  background-size: 100% 100%;
  width: 180px;
  align-items: center;
  height: 220px;
  &__title {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    word-break: break-word;
    text-align: center;
    margin: auto 0;
  }
  &__img {
    width: 92px;
    height: 87px;
    margin: 0 auto;
    object-fit: cover;
  }
}
