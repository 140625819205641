$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.league-art__l-t {
	position: fixed;
	z-index: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url($cdn + '/wazamba/renovation/leagues/tree_l.png');
	width: 80vh;
	height: 100%;
	top: 0;
	left: 0;
	transform: translateX(-47%);

	@media (-webkit-max-device-pixel-ratio: 2) {
		background-image: url($cdn + '/wazamba/renovation/leagues/tree_l@2x.png');
	}
	@media (-webkit-max-device-pixel-ratio: 3) {
		background-image: url($cdn + '/wazamba/renovation/leagues/tree_l@3x.png');
	}

	@include l {
		transform: translateX(-26%);
	}

	@include xl {
		transform: translateX(-17%);
	}

	@include xxl {
		transform: translateX(0);
	}
}

.league-art__l-b {
	position: fixed;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url($cdn + '/wazamba/renovation/leagues/box_l.png');
	width: 400px;
	height: 448px;
	bottom: 0;
	left: 0;
	z-index: 3;
	pointer-events: none;

	@media (-webkit-max-device-pixel-ratio: 2) {
		background-image: url($cdn + '/wazamba/renovation/leagues/box_l@2x.png');
	}

	@media (-webkit-max-device-pixel-ratio: 3) {
		background-image: url($cdn + '/wazamba/renovation/leagues/box_l@3x.png');
	}

	@include full {
		width: 560px;
		height: 610px;
	}
}

.league-art__r-t {
	position: fixed;
	z-index: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url($cdn + '/wazamba/renovation/leagues/tree_r.png');
	width: 91vh;
	height: 100%;
	top: 0;
	right: 0;
	transform: translateX(34%);

	@media (-webkit-max-device-pixel-ratio: 2) {
		background-image: url($cdn + '/wazamba/renovation/leagues/tree_r@2x.png');
	}

	@media (-webkit-max-device-pixel-ratio: 3) {
		background-image: url($cdn + '/wazamba/renovation/leagues/tree_r@3x.png');
	}

	@include l {
		transform: translateX(25%);
	}

	@include xl {
		transform: translateX(20%);
	}

	@include xxl {
		transform: translateX(0);
	}
}

.league-art__r-b {
	position: fixed;
	z-index: 3;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url($cdn + '/wazamba/renovation/leagues/box_r.png');
	width: 400px;
	height: 448px;
	bottom: 0;
	right: 0;

	@media (-webkit-max-device-pixel-ratio: 2) {
		background-image: url($cdn + '/wazamba/renovation/leagues/box_r@2x.png');
	}

	@media (-webkit-max-device-pixel-ratio: 3) {
		background-image: url($cdn + '/wazamba/renovation/leagues/box_r@3x.png');
	}

	@include full {
		width: 660px;
		height: 558px;
	}
}

.league-art__c {
	position: fixed;
	z-index: 2;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url($cdn + '/wazamba/renovation/leagues/grass_c.png');
	width: 1400px;
	height: 218px;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	pointer-events: none;

	@media (-webkit-max-device-pixel-ratio: 2) {
		background-image: url($cdn + '/wazamba/renovation/leagues/grass_c@2x.png');
	}

	@media (-webkit-max-device-pixel-ratio: 3) {
		background-image: url($cdn + '/wazamba/renovation/leagues/grass_c@3x.png');
	}

	@include full {
		width: 100%;
		height: 296px;
		transform: translateX(-43%);
	}
}
