$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.auth-deposit-popup__container {
  &.main-login-popup {
    border-radius: 0 0 20px 20px;

    .login-wrap {
      padding: 32px 0 34px;

      &:before {
        top: -108px;
      }

      &__header {
        padding: 0 48px 12px;
      }

      &__close {
        top: -68px;
        position: absolute;
        inset-inline-end: 6px;
      }
    }
  }
}
