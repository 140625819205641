$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.provider-list {
  .rtl-lang & {
    direction: rtl;
  }

  &__item {
    margin:4px 0;
  }

  &__header {
    position:relative;
    display:flex;
    justify-content: center;
  }

  &__ico {
    width: 122px;
    height: 57px;

    & img {
      width: 100%;
      height: 100%;
      font-size: 10px;
      word-break: break-all;
    }
  }

  &__link {
    position: absolute;
    inset-inline-end: 0;
    top: 20px;
    font-weight: bold;
    line-height: 16px;

    & svg {
      width: 6px;
      height: 9px;
      vertical-align: middle;
      margin-inline-start: 7px;

      .rtl-lang & {
        transform: scaleX(-1);
      }
    }
  }
}
