$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-settings {
  position: relative;

  &__section {
    width: 756px;
    margin:0 auto 15px;
  }

  &__title {
    text-shadow: 0 0 10px $black-25;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1.2px;
    text-align: center;
    padding: 20px 0;
  }
}

.profile-flex {
  display: flex;
  padding: 10px 70px;
  flex-wrap: wrap;
  margin: 0 -10px;

  &__item {
    margin: 0 10px;
    width: calc( 50% - 20px );

    &_small {
      width: calc( 50% - 90px );
      padding-top:7px;
      display:flex;
    }

    &_big {
      width: calc( 50% + 50px );
      display:flex;
    }
  }
}

.profile-card {
  margin: -9px -10px -8px;

  &__item {
    background: rgba(34,8,2,0.3);
    height: 48px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
    margin: 8px 10px;
  }

  &__key {
    @include text(14px, null, 500, $orange-100);
    @include ellipsis;
    letter-spacing: 0.7px;
    max-width: 110px;
  }

  &__val {
    @include text(18px, null, bold, $white);
    @include ellipsis;
    letter-spacing: 0.9px;
    max-width: calc( 100% - 70px );
    text-align: right;
    direction: ltr;
  }
}

.profile-bottom {
  padding-bottom: 30px;

  &__link {
    padding-top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    & a {
      display: flex;
      gap: 4px;
      align-items: baseline;
      font-weight: bold;

      & svg {
        width: 6px;
        height: 9px;
        line-height: 20px;

        .rtl-lang & {
          transform: scaleX(-1);
        }
      }
    }
  }
}

.profile-form {
  &__item {
    overflow: hidden;
  }

  &__btns {
    margin: 20px 10px;
    width: calc( 50% - 20px );
  }
}
