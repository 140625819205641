$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.registration-with-deposit,
.login-form {
  &__form-item {
    position: relative;
    margin-bottom: 8px;
    display: block;
  }
}
