$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.main-sport{
  margin-top: 115px;
  background-image: url($cdn + "/wazamba/sport/bg-arena.jpg");
  background-size: contain;
  background-position: center center;

  & .landing-games{
    transform: translateY(-130px);
  }
  &__title-wrapp{
    position: relative;
    text-align: center;
    background-image: url( $cdn +  "/wazamba/sport/listswithbool.png");
    background-size: 554px 136px;
    background-position: center top;
    padding-top: 48px;
    background-repeat: no-repeat;
  }

  &__title{
    @include text(44px, 54px, 800, #F6D02B);

    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-shadow: 0 3.02092px 3.02092px $black-25;

    min-width: 636px;
    background-image: url( $cdn +  "/wazamba/sport/woodtitle.png");
    background-repeat: no-repeat;
    display: inline-flex;
    padding: 36px 73px 41px 73px;
    z-index: 1;
    background-size: 100% 100%;
    justify-content: center;

  }

  &__link {
    @include text(16px, 20px, 600, $blue-900);
    letter-spacing: 0.05em;
    position: absolute;
    top: 16px;
    inset-inline-end: 12px;
    cursor: pointer;

    &:hover {
      color: $brown-300;
    }
  }

  &__new{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -5px);
    padding: 12px 16px;
    @include text(18px, 12px, 900, $white);

    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    background: $red-200;
    box-shadow: 0 2px 0 $red-300;
    border-radius: 4px;
  }

  // widgets
  &__widgets-wrapp{
    position: relative;
  }

  &__widget{
    // name widget
    & .asb-livenow__header-label{
      position: relative;
      padding-inline-end: 30px;
      &::after{
        content: '';
        width: 27px;
        height: 14px;
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        background-image: url( $cdn + "/wazamba/desktop/img/live-red.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    & .asb-upcoming__header-label,
    & .asb-highlights__header-label,
    & .asb-livenow__header-label{

        @include text(26px, 26px, 800, $blue-900);

    }

    // tabs
    & .asb-upcoming__tabs,
    & .asb-highlights__tabs,
    & .asb-livenow__tabs{
      padding-top: 12px;
    }

    & .asb-upcoming__tabs >,
    & .asb-highlights__tabs >,
    & .asb-livenow__tabs >{
        & div:first-child{
          background: $white;
          border-radius: 100px;
          overflow: visible;
          box-shadow: 0 2px 0 $black-25;
          margin-bottom: 12px;
          max-width: max-content;
          height: 34px;
          & > div:last-child{
            display: none;
          }
        }
    }

    // empty items
    & .asb-highlights__tabs > div > div > .asb-nodata {
        padding: 17px 0 17px 0;
        background: #ffffff24;
        border-radius: 12px;
        margin-bottom: 4px;
        display: inline-block;
        width: 100%;
        text-align: center;
    }
    .asb-nodata > div:nth-child(2){
      @include text(14px, 20px, 600, $blue-900);
      padding-top: 8px;
    }
    .asb-icon-bell{
      width: 28px;
      height: 28px;
      background-image: var(--icon-icon_bells);
      background-size: contain;
      background-repeat: no-repeat;
    }

    // active tab
    & .asb-tab_selected{
      @include text(16px, 20px, 800);

      background-color: $orange-1000;
      background-image: linear-gradient($orange-1000, $orange-1100),linear-gradient(#f90,$orange-500);
      background-clip: padding-box,border-box;
      background-size: cover,calc(100% + 20px) calc(100% + 20px);
      background-repeat: no-repeat,no-repeat;
      background-position: center top,left -10px top -10px;
      box-shadow: 0 0 5px $orange-1000, 0 2px 0 $orange-1200;
      border: 5px solid transparent;
      border-radius: 10px;
      padding: 4px 10px;
      height: 38px;
      margin-top: -2px;
      & div{
        & div{
          color: $white;
        }
      }
    }
    & .asb-tab{
      @include text(16px, 20px, 800, $blue-900);
      border: 5px solid transparent;
      border-radius: 10px;
      padding: 4px 10px;
    }

    // content
    & .asb-event-row{
      & > div:nth-child(2){
        justify-content: space-between;
        & > div:nth-child(1){
          flex: 1 0 32%;
        }
      }
      & div{
        justify-content: flex-start;
        & div{
          @include text(14px, 16px, 700, $blue-900);
        }
      }
    }
    & .asb-livenow__tabs-content{
      & .asb-live{
        @include text(8px, 10px, 800, $white);
        font-style: italic;
        text-transform: uppercase;
        padding: 0 4px;
        margin-left: 12px;
        margin-bottom: 3px;

        background: #F01919;
        box-shadow: 1px 1px 0 $red-300;
        border-radius: 2px;
      }


    }

    & .asb-upcoming__tabs-content,
    & .asb-highlights__tabs-content{
      & .asb-prefix__wrapper{
        & div:nth-child(2){
          display: none;
        }
        & div:nth-child(4),
        & div:nth-child(5),
        & div:nth-child(6){
          @include text(12px, 17px, 600, $blue-900);
        }
        & .asb-flag-icon{
          margin-left: calc(100%  - 306px);
        }
        & > div:nth-child(1){
          width: 100px;
        }
        & > div:nth-child(4){
          max-width: 80px;
        }
        & > div:nth-child(5){
          width: 13px;
        }
        & > div:nth-child(6){
          width: 100px;
        }
      }
    }

    & .asb-livenow__tabs-content{
      & .asb-prefix__wrapper{
        & div:nth-child(3){
          display: none;
        }
        & div:nth-child(5),
        & div:nth-child(6),
        & div:nth-child(7){
          @include text(12px, 17px, 600, $blue-900);
        }
        & .asb-flag-icon{
          margin-left: calc(100%  - 306px);
        }
        & .asb-prefix__live-time{
          width: 66px;
        }
        & > div:nth-child(5){
          min-width: 54px;
        }
        & > div:nth-child(6){
          min-width: 13px;
        }
        & > div:nth-child(7){
          width: 160px;
        }

      }
    }

    & .asb-upcoming__tabs-content,
    & .asb-highlights__tabs-content,
    & .asb-livenow__tabs-content{
      &::-webkit-scrollbar {
        width: 4px;
        height: 0;
        background-color: transparent;

      }
      &::-webkit-scrollbar-thumb {
        background-color: $orange-900;
        border-radius: 4px;
      }
      overflow-y: scroll;
      display: block;
      height: 258px;
      padding-inline-end: 7px;

      & .asb-event-row{
        & .asb-score{
          padding-inline-end: 3px;
          font-weight: 600;
        }
        padding: 12px 16px 14px 8px;
        background: #ffffff24;
        border-radius: 12px;
        margin-bottom: 4px;
        display: inline-block;
        width: 100%;
        & > div:last-child{
          & > div:last-child{
            & > div{

                display: grid;
                grid-template-columns: repeat(3,1fr);
                grid-gap: 4px;
                & .asb-odd{
                  display: flex;
                  flex-direction: row;
                  padding: 6px 11px;
                  background: $white;
                  border-radius: 20px;
                  justify-content: space-around;
                  width: 68px;
                  @include text(14px, 20px, 600, $blue-900);
                  & .asb-odd__label{
                    color: $grey-200;
                  }
                }

            }
          }
        }
        & .asb-prefix__live-time{
          @include text(12px, 11px, 900, #F01919);
          margin-left: 4px;
        }
      }

      & .asb-prefix__wrapper{
        flex-wrap: nowrap;
        margin-bottom: 8px;
        & .asb-prefix__prelive-time{
          @include text(12px, 14px, 800, $blue-900);
        }
      }

      & .asb-flag-icon{
        width: 18px;
        height: 12px;
        margin-inline-end: 4px;
        i{
          width: 100%;
          height: 12px;
          background-size: contain;
          background-position: center center;
        }
      }

      // markers up down
      & .asb-odd{
        position: relative;
        overflow: visible;
        & .asb-odd__block > {
          & div:first-child{
            position: absolute;
            top: 0;
            inset-inline-end: 0;
            transform: translate(5px,0px);
            i{
              background-image: url( $cdn + "/wazamba/desktop/img/sport-arrow-green.svg");
            }
          }
          & div:last-child{
            position: absolute;
            bottom: 0;
            inset-inline-end: 0;
            transform: translate(5px, 0);
            i{
              background-image: url( $cdn + "/wazamba/desktop/img/sport-arrow-red.svg");
            }
          }

        }
      }

    }


    & .asb-livenow__tabs-content{
      columns: 2;
      column-gap: 20px;
      height: auto;
    }
    & .asb-livenow__tabs > {
      div:nth-child(2){
        > div{
          height: 258px;
          overflow-y: scroll;
          align-items: flex-start;

          &::-webkit-scrollbar {
            width: 4px;
            height: 0;
            background-color: transparent;

          }
          &::-webkit-scrollbar-thumb {
            background-color: $orange-900;
            border-radius: 4px;
          }
        }
      }

    }

  }
}
