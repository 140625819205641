$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.footer {
	position: relative;
	background-color: $blue-1000;
	padding: 40px 0;
	color: #21728a;
	z-index: 100;

	&::before {
		content: '';
		position: absolute;
		top: -30px;
		height: 30px;
		width: 100%;
		left: 0;
		z-index: 5;
		background: url( $cdn + '/wazamba/desktop/img/footer.svg');
	}

	.rtl-lang & {
		direction: rtl;
	}

	&__container {
		max-width: 754px;
		margin: 0 auto;
	}

	&__row {
		display: flex;
	}

	&__text {
		@include text(8px, 2.7, $blue-200);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		letter-spacing: normal;
		padding-top: 15px;
	}

	&__text-logos {
		display: grid;
		align-items: center;
		grid-template-columns: 116px 127px 86px;
		grid-column-gap: 16px;
	}

	&__text-logos-awards {
		grid-row: 1/3;
		width: 100%;
		height: 48px;
		opacity: 0.8;
	}

	&__pays {
		padding: 10px 0;
		border-top: solid 1px #3677c433;
		border-bottom: solid 1px #3677c433;
		margin-top: 20px;
	}

	&__copyright {
		align-self: center;
		margin-left: auto;
		font-size: 10px;
		direction: ltr;

		span {
			text-transform: capitalize;
		}
	}

	@include l {
		&__text-logos {
			grid-template-columns: 116px 127px 86px 77px;
		}

		&__text-logos-awards {
			grid-row: initial;
		}

		&__container {
			max-width: 1010px;
		}
	}

	@include xl {
		&__container {
			max-width: 1150px;
		}
	}

	@include xxl {
		&__container {
			max-width: 1270px;
		}
	}

	@include full {
		&__container {
			max-width: 1360px;
		}
	}

	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__btns {
		display: flex;
		flex-direction: row;
		gap: 16px;
	}

	&__btn {
		display: flex;
		flex-direction: row;
		gap: 16px;
		align-items: center;
		padding: 14px 16px;
		cursor: pointer;
		background-color: rgba(33, 114, 138, 0.1);
		box-shadow: inset 0 0 6px #0C2F39;
		border-radius: 8px;
		min-width: 156px;

		&:hover {
			background-color: rgba(126, 190, 209, 0.1);
		}

		&__icon {
			width: 24px;
			height: 24px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}

		&__text {
			@include text(14px, 140%, 700, $grey-200);
		}

		&.livechat {
			.footer__btn__icon {
				background-image: url($cdn + '/wazamba/wazamba-v01/sidebar-icons/icon-input-chat.svg');
			}
		}
	}

	&__copyright {
		@include text(12px, 140%, 400, $grey-200);
		text-align: center;
		margin-top: 12px;
	}

	&__license {
		text-align: center;
		margin-top: 16px;

		&__title {
			@include text(12px, 140%, 700);
			display: inline-flex;
			gap: 3px;
			align-items: baseline;
			justify-content: center;

			& svg {
				width: 9px;
				height: 8px;
				transition: transform .3s linear;
				transform: rotate(90deg);
			}

			&.open svg {
				transform: rotate(-90deg);
			}
		}

		&__inner {
			display: none;

			&.open {
				display: block;
			}
		}

		&__content {
			@include text(12px, 140%, 400, $grey-200);
			margin-top: 12px;
			text-align: center;

			&.open {
				display: block;
			}

			span{
				text-transform: capitalize;
			}
		}
	}

	&__links {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 0;
		grid-row-gap: 0;
		margin-top: 30px;

		@include l {
			grid-column-gap: 40px;
		}

		@include xl {
			grid-template-columns: repeat(5, 1fr);
			grid-template-rows: 1fr;
		}
	}

	&__section {
		&:nth-child(1) { grid-area: 1 / 1 / 3 / 2; }
		&:nth-child(2) { grid-area: 1 / 2 / 3 / 3; }
		&:nth-child(3) { grid-area: 1 / 3 / 3 / 4; }
		&:nth-child(4) { grid-area: 1 / 4 / 2 / 5; }
		&:nth-child(5) { grid-area: 2 / 4 / 3 / 5; }

		@include xl {
			&:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
			&:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
			&:nth-child(3) { grid-area: 1 / 3 / 2 / 4; }
			&:nth-child(4) { grid-area: 1 / 4 / 2 / 5; }
			&:nth-child(5) { grid-area: 1 / 5 / 2 / 6; }
		}

		&__title {
			@include text(14px, 140%, 700, $grey-200);
			padding-bottom: 10px;
			text-align: start;
		}

		&__content {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		&__item {
			@include text(14px, 140%, 400, $blue-200);
			width: fit-content;
			text-align: start;

			&:hover {
				color: $blue-400;
			}
		}
	}

	&__social {
		flex-shrink: 0;
		display: flex;
		justify-content: end;
		align-items: center;
		margin-left: auto;
		z-index: 10;
		margin-bottom: -35px;
		position: relative;

		&-item {
			background-size: cover;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			margin: 10px 15px 0;

			& svg {
				fill: #21728a;
			}
		}
	}
}

.footer-icons {
	display: flex;
	justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 16px 0;

	&__item {
		height: 30px;

		&_18 { width: 30px; }
		&_iso { width: 28px; }
		&_7 { width: 56px; }
		&_s2b { width: 70px; }
		&_license {
			width: 40px;
          	height: 30px;
			background-image: var(--icon-gcb);
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
		}
		& svg { fill: #21728a; }
		&--askgamblers { display: inline-flex; }
        &_igate {
          width: 86px;
          height: 20px;
        }
		.item_pagcor {
			height: 30px;
		}

	}

	&__logo {
		margin-right: 4px;
		height: 100%;
	}

	&__logo-text {
		@include text(12px, 15px, 600, #21728a);
		max-width: 120px;
	}
}

.footerLicense-icons {
	padding: 16px 0;
	margin: 0;

	&__license {
		width: 34px;
		height: 34px;
	}
}

.footerLicense-text {
	@include text(12px, 140%, 400, $grey-200);
	text-align: center;
	direction: ltr;

	p {
		margin-top: 12px;
		margin-bottom: 0;
	}
}

.footerLicense-hiddenText {
	button {
		@include text(12px, 140%, 700, #ffffffb3);
	}

	&__inner {
		@include text(12px, 140%, 400, #a9a9a9);
		text-align: center;
		margin-top: 12px;
	}
}
