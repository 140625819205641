$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-shop-popup {
  padding: 20px 0 0;
  position: relative;
  width: 312px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: auto;

  &__header {
    @include text( 28px, 34px, 800 );
  }

  &__text {
    @include text( 14px, 17px, 800, $white );
    text-shadow: 0 0 10px 0 $black-25;
  }

  &__confirm-btn {
    min-width: 160px;
  }

  &__long-btn {
    min-width: 278px;
  }

  &__devider-arrow {
    height: 42px;
    width: 100%;
    z-index: 3;
    background-image: url($cdn + '/wazamba/shop/swap-bonus-arrow.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-block: -5px -11px;
  }

  &__back {
    position: absolute;
    inset-inline-start: 0;
    top: 150px;
    z-index: 5;
    min-width: unset;

    .rtl-lang & {
      transform: rotate(180deg);

      .g-btn-orange {
        box-shadow: inset 0 0 3px #00000040, 0 -2px #b75637;
      }
    }

    &.has-active {
      inset-inline-start: -10px;
      top: unset;
      bottom: 340px;
    }
  }

  &__and-divider {
    @include text( 14px, 42px, 800, rgba(110, 57, 35, 1) );

    text-align: center;
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin: 0;

    &::before,
    &::after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid rgb(184, 138, 64);
      margin-block: auto;
      box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.55);
    }
  }
}
