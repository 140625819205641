$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.filter-btns {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  min-height: 36px;
  padding-inline: var(--screen-side-padding) ;
  overflow-x: scroll;
  scrollbar-width: none;

  @include without-scrollbar();

  .rtl-lang & {
    direction: rtl;
  }

  .filter-btns__item {
    @include text(16px, 24px, 800, $grey-400);
    font-family: Montserrat, sans-serif;
    text-align: center;
    text-transform: capitalize;
    background-color: $white;
    box-shadow: 0 4px 0 #9A9A9A;
    border-radius: 20px;
    padding: 4px 20px;
    white-space: nowrap;
    flex-basis: 68px;
    flex-shrink: 0;
    cursor: pointer;
    outline: none;
    border: none;

    &:hover {
      background-color: $grey-100;
    }

    &.is-active {
      color: $white;
      padding-block: 6px;
    }

    &:first-child {
      margin-inline-start: auto;
    }

    &:last-child {
      margin-inline-end: auto;
    }
  }
}

.filter-btns__accent-orange {
  .is-active {
    background-color: $orange-1000;
    box-shadow: 0 4px 3px 0 $orange-1200 inset;

    &:hover {
      background-color: $orange-800;
    }
  }
}

.filter-btns__accent-purple {
  .is-active {
    background-color: $violet-400;
    box-shadow: inset 0 4px 3px #8311E8;
    text-shadow: 0 2px 0 #7E341C;

    &:hover {
      background-color: $violet-200;
    }
  }
}
