$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-info-mask{
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:100%;
  height:100%;
  max-width: 100%;
  background: $brown-400;
  z-index: 999;
  display: none;
  padding: 100px 0 50px;
  max-height: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  @include xxl {
    padding: 150px 0 50px;
  }

  &__popup{
    position: absolute;
    bottom: -120px;
    left: 50%;

    @include xxl {
      bottom: -200px;
    }
  }
  &__wrapper{
    position: relative;
    width: 768px;
    height: 480px;
    margin: 10px;
    top: -50px;

    @include xxl {
      width: 1024px;
      height: 576px;
    }
  }
  &__block{
    position: absolute;
    width: 336px;
    height: 229px;
    opacity: 0;
    text-align: center;
    img{
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    span{
      position: relative;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      font-size: 20px;
      bottom: 50px;
    }

  }
  &__step1{
    top: 0;
    inset-inline-start: 0;
    animation: 0.2s showsteps forwards;
    animation-delay: 0.5s;
  }
  &__step2{
    top: -45px;
    inset-inline-end: 0;
    animation: 0.2s showsteps forwards;
    animation-delay: 1.5s;
  }
  &__step3{
    bottom: -10px;
    inset-inline-start: 31%;
    animation: 0.2s showsteps forwards;
    animation-delay: 2.5s;

  }
  &__arrow1{
    top: -15px;
    inset-inline-start: 43%;
    animation: 0.2s showsteps forwards;
    animation-delay: 1s;
    img{
      transform: rotateX(180deg) rotate(-90deg);

      object-fit: contain;
    }
  }
  &__arrow2{
    bottom: 175px;
    inset-inline-end: 11%;
    animation: 0.2s showsteps forwards;
    animation-delay: 2s;
    img{
      transform: rotateX(180deg) rotate(147deg);
      object-fit: contain;
    }
  }
  &__arrow3{
    bottom: 96px;
    inset-inline-start: 16%;
    animation: 0.2s showsteps forwards;
    animation-delay: 3s;
    img{
      transform: rotateX(180deg) rotate(50deg);

      object-fit: contain;
    }
  }

  @keyframes showsteps {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  &__arrow1,
  &__arrow2,
  &__arrow3{
    position: absolute;
    width: 128px;
    height: 128px;
    opacity: 0;
    text-align: center;
  }

  //======media======
  @include m {

  }
  @include l {

  }
  @include xxl {
    &__block{
      width: 493px;
      height: 335px;
      span{
        font-size: 24px;
        bottom: 65px;
      }
    }
    &__step1{
      inset-inline-start: -125px;
    }
    &__step2{
      inset-inline-end: -125px;
    }
    &__step3{
      bottom: -70px;
      inset-inline-start: 28%;
    }
    &__arrow1{
      top: 33px;
      inset-inline-start: 43%;
    }
    &__arrow2{
      bottom: 124px;
      inset-inline-end: 11%;
    }
    &__arrow3{
      bottom: 54px;
      inset-inline-start: 12%;
    }
  }

  .rtl-lang & {
    direction: rtl;

    &__arrow1, &__arrow2, &__arrow3 {
      transform:scale(-1,1);
    }
  }


}
.linda-shown .popup-info-mask {
  display: block;
}
