$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-board {
  &__title {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.05em;
    color: $white;
    margin-bottom: 20px;
  }

  &__bg {
    background-image: url($cdn + "/wazamba/board/board2_top.png"),
                      url($cdn + "/wazamba/board/board2_bottom.png"),
                      url($cdn + "/wazamba/board/board2_body.png");
    background-position: center top,center bottom,center calc(100% - 30px);
    background-size: 100% auto;
    background-clip: padding-box,padding-box,content-box;
    background-repeat: no-repeat,no-repeat,repeat-y;
    padding: 0 0 10px;
  }

  &__item {
    background: $black-25;
    box-shadow: inset 0 4px 4px $black-25;
    border-radius: 5px;
    height: 60px;
    margin-bottom: 16px;
    padding: 0 16px 0 12px;
    display: grid;
    grid-template-columns: 30px 88px 1fr 1fr;
    align-items: center;
    position: relative;
    z-index: 1;

    &.is-active-item {
      background: #F4CA33;
      box-shadow: 0 0 6.84883px rgba(222, 224, 61, 0.5);
      background-image: url($cdn + "/wazamba/wazamba-v01/tournaments/tournaments-zigzag-myplace.svg");
      background-position: center top;

      .tournament-board__price {
        color: $white;
        text-shadow: 1px 1px 0 rgba(255, 121, 1, 1);
      }

      .tournament-board__num { text-shadow: 1px 1px 0 rgba(255, 121, 1, 1); }
      .tournament-board__info { color: rgba(114, 85, 0, 1); }
    }

    &.is-big {
      height: 76px;

      .tournament-board__num {
        font-weight: 800;
        font-size: 40px;
        line-height: 49px;
        color: $white;
        text-shadow: 0 2px 0 $orange-900;

        &::before {
          content: "";
          position: absolute;
          width: 92px;
          height: 50px;
          transform: rotate(-7deg);
          background-image: url($cdn + "/wazamba/wazamba-v01/tournaments/tournament_label_top3.svg");
          inset-inline-start: -30px;
          top: 0;
          z-index: -1;
          background-size: cover;
        }

        .rtl-lang & {
          &::before {
            transform: rotate(-7deg) scale(-1, 1);
          }
        }
      }

      .tournament-board__price {
        font-size: 20px;
      }

      .tournament-board__img {
        margin-top: -33px;
        margin-left: -14px;
        width: 108px;
        height: 108px;
        object-fit: cover;
      }
    }

    &.has-ribbon {
      height: 68px;

      .tournament-board__num::before {
        content: "";
        position: absolute;
        width: 92px;
        height: 54px;
        background-image: url($cdn + "/wazamba/wazamba-v01/tournaments/tournament_label_top3.svg");
        inset-inline-start: -35px;
        top: -6px;
        z-index: -1;
        background-size: cover;

        .rtl-lang & {
          transform: scaleX(-1);
        }
      }
    }

    &.position {
      background: #F4CA33;
      box-shadow: 0 0 6.84883px rgba(222, 224, 61, 0.5);
      background-image: url($cdn + "/wazamba/wazamba-v01/tournaments/tournaments-zigzag-myplace.svg");
      background-position: center top;

      .tournament-board__info { color: #725500; }
      .tournament-board__price { color: $white; }
    }

    &.is-last {
      grid-template-columns: minmax(min-content, min-content) 88px 1fr 1fr;

      .tournament-board__num {
        font-size: 22px;
        line-height: 27px;
        color: #725500;
      }

      .tournament-board__price {
        font-weight: 900;
        font-size: 20px;
        line-height: 24px;
        color: #725500;
        text-shadow: none;
        letter-spacing: -2px;
      }
      &.is-stacked {
        height: 72px;
        box-shadow:
          0px -3px 0px 0px #cfac2d,
          0px -6px 0px 0px #bf9e2a,
          0px -9px 0px 0px #a08628,
          0px -12px 0px 0px #8a7422;
        margin-top: 28px;

        .tournament-board__img {
          margin-top: -15px;
          margin-left: -2px;
        }
      }
    }
  }

  &__wrap { padding: 24px 24px 8px; }

  &__num {
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    color: $white;
    position: relative;
    z-index: 1;
  }

  &__price {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-align: end;
    letter-spacing: 0.05em;
    text-shadow: 0 1px 0 $orange-900;
    color: #FAF068;
    position: relative;

    &.has-coins {
      width: 60px;
      margin-inline-start: auto;
      padding-left: 18px;
      text-align: left;
    }

    &.has-coins::after {
      content: '';
      width: 16px;
      height: 16px;
      background-image: url($cdn + "/wazamba/sprite-tournament-page.png");
      background-size: 115px;
      background-position: 0 -33px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__info {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: $white;
  }

  &__name {
    margin-bottom: 5px;
    direction: ltr;

    .rtl-lang & {
      text-align: end;
    }
  }

  &__img {
    margin-top: -28px;
    object-fit: contain;
    position: relative;
    z-index: 2;
    margin-inline-start: -6px;
  }

  @include l {
    &__wrap {
      padding: 32px 32px 16px;
    }
  }
}
