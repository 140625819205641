$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dashboard-prize {
  background-color: #5A2B70;
  background-image: url($cdn + '/wazamba/baner/mobile-league/banner-dekor-bg.png');
  background-size: 170px;
  box-shadow: 0 4px 6px $black-36;
  border-top: 1px solid rgba(255, 255, 255, 0.11);
  border-radius: 12px;
  max-width: 730px;
  width: 100%;
  min-height: 126px;
  margin: 20px auto 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 14px 25px 14px 50px;
  position: relative;
  @media screen and (min-width: 1280px) {
    max-width: 763px;
  }
  &__info {
    max-width: 470px;
    width: 100%;
    flex-shrink: 0;
  }
  &__title {
    font-size: 22px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #F9EB62;
    margin-top: 0;
    margin-bottom: 0;
    text-shadow: 0 0 4px $black-25, 0 2px 0 $orange-900;
  }
  &__text {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: $white;
    margin-top: 5px;
    margin-bottom: 0;
  }
  &__btn {
    margin-top: 12px;
  }
  &__img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 205px;
    height: 139px;
    margin-top: -8px;
  }
}
