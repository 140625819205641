$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dashboard-league {
  background-size: cover;
  padding-top: 40px;
  padding-bottom: 10px;
  padding-right: 250px;
  position: relative;
  background-image: url($cdn + "/wazamba/renovation/new-main-banner/dashboard_bg.jpg");

  @media (-webkit-max-device-pixel-ratio: 2) {
    background-image: url($cdn + "/wazamba/renovation/new-main-banner/dashboard_bg@2x.jpg");
  }

  &__l-elem {
    position: absolute;
    left: 0;
    transform: translateX(-120px);
    bottom: 0;
    content: '';
    background-image: url($cdn + "/wazamba/renovation/new-main-banner/leaves_left.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 374px;
    height: 386px;

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + "/wazamba/renovation/new-main-banner/leaves_left@2x.png");
    }

    @include full {
      transform: translateX(-20px);
    }
  }

  &__r-elem {
    position: absolute;
    right: 0;
    transform: translateX(160px);
    bottom: 0;
    content: '';
    background-image: url($cdn + "/wazamba/renovation/new-main-banner/leaves_right.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 615px;
    height: 410px;
    pointer-events: none;

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + "/wazamba/renovation/new-main-banner/leaves_right@2x.png");
    }

    @include full {
      transform: translateX(20px);
    }
  }

  &__block {
    padding-left: 20px;
    position: relative;
    transition: .2s all;

    body.linda-hide-sidebar & {
      transform: translateX(125px);
    }

    &.is-only {
      padding-bottom: 30px;
    }
  }

  &__dep {
    padding-bottom: 40px;
    transition: .2s all;
    position: relative;
    z-index: 2;

    body.linda-hide-sidebar & {
      transform: translateX(125px);
    }
  }

  .rtl-lang & {
    direction: rtl;
  }
}
