$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dash-tournament {
  color: $white;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: 100% 100%;
  position: relative;
  width: 180px;
  height: 220px;
  z-index: 1;
  padding: 0 16px 20px;
  text-align: center;
  background-image: url($cdn + "/wazamba/dashboard/cards/tournament/tournament_bg.png");
  align-items: center;
  &.has-place {
    .dash-tournament__place { display: flex; }
    .dash-tournament__price { display: none; }
    .dash-tournament__name { display: none }
    .dash-tournament__name.has-place { display: block; }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -4px;
    right: 0;
    height: 88px;
    background-image: url($cdn + "/wazamba/dashboard/cards/tournament/tournament_contentpic.png");
    background-size: cover;
    background-position: center;
    z-index: -1;
    transform: translateY(-22px);
  }
  &__name {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    margin: auto 0;
    word-break: break-word;
    &.has-place {
      display: none;
    }
  }
  &__time {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 3px;
    display: block;
  }
  &__price {
    font-weight: 800;
    font-size: 26px;
    line-height: 26px;
    max-height: 44px;
  }
  &__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
  }
  &__info {
    text-align: center;
    height: 32px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  &__place {
    height: 34px;
    position: relative;
    display: none;
    align-items: center;
    justify-content: space-between;

    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    padding: 0 5px;

    &::before {
      content: "";
      left: 0;
      right: -2px;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255,0.2);
      position: absolute;
    }
  }
  &__btn {
    flex-shrink: 0;
  }
}
