$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.main-slider{

  position:relative;
  min-height:360px;
  &__item{
    height: 360px;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    padding:0 250px 0 20px;

    display:flex !important;
    align-items: center;
    justify-content: center;
  }
  &__wrap{
    width: 430px;
    height: 100%;
    background-image: url( $cdn + '/wazamba/desktop/img/logo.svg') ,  url( $cdn + "/wazamba/baner/lb_front.png") ;
    background-size: 390px auto , 100% auto;
    background-position: center 20px ,  center calc(100% - 10px);
    background-repeat: no-repeat;
    padding:70px 80px 80px;

    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    text-align: center;
    text-shadow: 0 1px 0 #f4901b;
    color:$yellow-200;
    font-weight: 800;
    position:relative;

  }
  &__title{ font-size:18px;}
  &__text1{ font-size:26px; padding:15px 0;}
  &__text2{ font-size:16px;}
  &__btn{
    position:absolute;
    bottom:30px;
    left: 0;
    text-align: center;
    width: 100%;
  }
  & .slick-dots{
    position:absolute;
    left: calc( 50% - 113px);
    bottom: 20px;
    width: 0;
    height: 0;

    display:flex;
    align-items: center;
    justify-content: center;
    z-index: 80 ;
    & button{
      width: 12px;
      height: 12px;
      border-radius: 50%  ;
      border:none;
      font-size: 0;
      background: $white;
      padding:0;
      cursor:pointer;
    }
    & li{
      margin:0 5px;
      opacity:0.5;
      transition:0.3;
      &.slick-active{ opacity:1;}
    }
  }

  @include l{
    min-height:480px;
    &__item{
      height: 480px;
    }
    &__wrap{
      padding:90px 110px 100px;
      width: 600px;
      background-size: 400px auto , 100% auto;
      background-position: center 20px ,   center calc(100% - 15px);
    }
    &__title{ font-size:32px;}
    &__text1{ font-size:40px; }
    &__text2{ font-size:24px;}
    &__btn{bottom:45px;}
    & .slick-dots{ bottom:30px;}
  }
}


