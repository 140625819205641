$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.reward-block {
  width: 188px;
  height: 37px;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 5px;
  background-size: 188px;
  background-repeat: no-repeat;
  background-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-sm.png');
  mask-size: 100% 100%;
  mask-clip: border-box;
  mask-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-sm.png');

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 1px;
    width: 100%;
    height: 74%;
    box-shadow: 0px 0px 20px 0px rgba(244, 214, 66, 0.60);
    border-radius: 50px;
    display: none;
    z-index: -1;
  }

  &:hover {
    &:before {
      display: block;
      animation: hover-show .2s ease-in-out;
    }
  }

  @media (-webkit-max-device-pixel-ratio: 2) {
    background-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-sm@2x.png');
    mask-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-sm@2x.png');
  }
  @media (-webkit-max-device-pixel-ratio: 3) {
    background-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-sm@3x.png');
    mask-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-sm@3x.png');
  }
  &__text {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: $white;

    span {
      display: inline-block;
    }
  }
  @include xxl {
    width: 271px;
    height: 53px;
    background-size: 271px;
    background-repeat: no-repeat;
    background-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-lg.png');
    mask-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-lg.png');
    padding-top: 10px;

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-lg@2x.png');
      mask-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-lg@2x.png');
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-lg@3x.png');
      mask-image: url($cdn + '/wazamba/renovation/mask-collection/reward-wood-lg@3x.png');
    }

    &__text {
      font-size: 20px;
    }
  }

  @keyframes hover-show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
