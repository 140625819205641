$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.landing-jackpot {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 297px;
  z-index: 0;
  text-align: center;
  margin-bottom: 40px;
  background-image: url($cdn + '/wazamba/jackpot/desktop/main-jeckpot-bgs.png');
  @media (-webkit-max-device-pixel-ratio: 2) {
    background-image: url($cdn + '/wazamba/jackpot/desktop/main-jeckpot-bgs@2x.png');
  }
  @include l {
    background-image: url($cdn + '/wazamba/jackpot/desktop/main-jeckpot-bgm.png');
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/wazamba/jackpot/desktop/main-jeckpot-bgm@2x.png');
    }
  }
  @include xl {
    background-image: url($cdn + '/wazamba/jackpot/desktop/main-jeckpot-bgl.png');
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/wazamba/jackpot/desktop/main-jeckpot-bgl@2x.png');
    }
  }
  @include xxl {
    height: 390px;
    background-image: url($cdn + '/wazamba/jackpot/desktop/main-jeckpot-bgxl.png');
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/wazamba/jackpot/desktop/main-jeckpot-bgxl@2x.png');
    }
  }
  @include full {
    height: 419px;
    background-image: url($cdn + '/wazamba/jackpot/desktop/main-jeckpot-bgxxl.png');
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/wazamba/jackpot/desktop/main-jeckpot-bgxxl@2x.png');
    }
  }

  &__title{
    position: absolute;
    top: 13px;
    width: 100%;
    font-weight: 800;
    font-size: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FCE533;
    text-shadow: 0 3.02092px 3.02092px $black-25;
    @include l {
      font-size: 28px;
    }
    @include xxl {
      top: 27px;
    }
  }
  &__sum {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    font-weight: 800;
    font-size: 38px;
    color: $yellow-700;
    text-shadow: 0 2px 1px $orange-900, 0 0 10px $black-25;
    text-align: center;
    letter-spacing: 0.05em;
    @include l {
      font-size: 46px;
      transform: translate(-50%, -50%);
    }
    @include xxl {
      transform: translate(-50%, -25%);
    }

  }
  &__btn{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    @include xl {
      bottom: 8px;
      & .g-btn-46{
        height: 52px;
        line-height: 42px;
        font-size: 24px;
        padding: 0 15px;
        letter-spacing: .9px;
      }
    }
  }

  .rtl-lang & {
    &__sum {
      direction: ltr;
    }
  }

}
