$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.tabs {
    background: $white;
    box-shadow: 0 2px 0 $black-25;
    border-radius: 100px;
    height: 34px;
    display: flex;
    align-items: center;
    margin: 33px auto 10px;
    padding: 0 30px;
    &__wrap {
        display: flex;
    }
    &__item {
      padding: 0;
      background: none;
      border: none;
      font-family: inherit;
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: $brown-300;
      cursor: pointer;
  
      margin: 0 18px;
      position: relative;
      span {
        z-index: 1;
        position: relative;
        text-transform: capitalize;
      }
      &.active {
        color: $white;
        &::before {
          content: "";
          position: absolute;
          left: -15px;
          right: -15px;
          top: -9px;
          bottom: -9px;
          border-radius: 10px;
          border: 5px solid transparent;
          background-color: $orange-1000;
          background-image: linear-gradient($orange-1000,$orange-1100),linear-gradient(#f90,$orange-500);
          background-clip: padding-box,border-box;
          background-size: cover,calc(100% + 20px) calc(100% + 20px);
          background-repeat: no-repeat,no-repeat;
          background-position: center top,left -10px top -10px;
          box-shadow: 0 0 5px $orange-1000, 0 2px 0 $orange-1200;
        }
      }
    }
  }
