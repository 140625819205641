$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.mask-banner {
  border-radius: 10px;
  padding: 6px;
  background-image: linear-gradient(180deg, #CC7A00 0%, #CE8048 95.03%);
  box-shadow: inset 0 0 9px $black-40;

  &__wrap {
    display: flex;
    align-items: center;
    border-radius: 4px;
    position: relative;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: darken;

    min-height: 242px;
    flex-direction: row;
    padding: 16px 28px 36px;
    justify-content: space-between;
    gap: 8px;

    &::after {
      content: "";
      position: absolute;
      height: 144px;
      inset: auto 0 0;
      background: linear-gradient(0deg, $blue-900 0%, rgba(7, 31, 62, 0) 100%);
      z-index: 0;
    }

    @include xl {
      min-height: 340px;
      padding: 0 28px 0 0;
    }

  }

  &__logo {
    height: 204px;
    position: relative;
    z-index: 1;

    @include l {
      padding-left: 32px;
      height: 232px;
    }

    @include xl {
      height: 300px;
    }

  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    z-index: 1;
    max-width: 262px;

    @include l {
      max-width: 430px;
    }

    @include xl {
      max-width: 450px;
    }
  }

  &__title {
    @include title-gold-shadow(4px, 1px);

    font-weight: 800;
    font-size: 38px;
    line-height: 1;
    letter-spacing: 3.1px;
    padding-bottom: 24px;

    @include l {
      font-size: 50px;
    }

    @include xl {
      font-size: 62px;
    }

  }

  &__text {
    @include title-gold-shadow(2px, 1px);

    font-weight: 800;
    font-size: 18px;
    line-height: 1;
    letter-spacing: .9px;
    padding-bottom: 5px;
    text-wrap: balance;

    @include xl {
      font-size: 22px;
    }
  }

  .shop-mask-banner {
    min-width: 196px;
    box-shadow: 0 0 20px 0 #F89E3B;

    @include xl {
      font-size: 24px;
      min-width: 238px;
      height: 52px;
    }
  }
}
