$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.g-hr{
  height: 1px;
  width: 100%;
  position:relative;
  &::before{
    content: '';
    position:absolute;
    bottom: -15px;
    height: 54px;
    width: 100%;
    background: url($cdn + "/wazamba/hr.png") center bottom repeat-x;
    background-size: auto 100%;
    z-index: 50;
  }
  &-180{ transform: rotate(180deg); }


  &_over{
    width: 100vw;
    margin-left: -24px;
  }

}
