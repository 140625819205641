$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dash-welcome {
  padding: 24px 16px 20px;
  background-image: url($cdn + "/wazamba/dashboard/Stone_gray.png");
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 1;
  background-size: 100% 100%;

  width: 180px;
  height: 220px;
  &__title {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    max-height: 32px;
    overflow: hidden;
    word-break: break-word;
  }
  &__img {
    width: 96px;
    height: 76px;
    object-fit: contain;
    filter: drop-shadow(0 2px 5px $black-30);
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  &__prize {
    font-weight: 800;
    font-size: 26px;
    line-height: 26px;
    color: $white;

    margin: 4px auto auto;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      width: 26px;
      height: 26px;
      background-image: url($cdn + "/wazamba/sprite-tournament-page.png");
      background-size: 177px;
      background-position: 0 -50px;
      margin-left: 2px;
    }
  }
}
