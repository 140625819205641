$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.provider-hall {
  .rtl-lang & {
    direction: rtl;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__ico {
    width: 122px;
    height: 57px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__back {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    gap: 10px;
    align-items: center;

    .rtl-lang & {
      flex-direction: row-reverse;
    }
  }
}
