$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-card {
  display: block;
  padding: 20px 0 25px;

  &__title {
    text-align: center;
    text-shadow: 0 0 10px $black-25;
    background-image: linear-gradient(to top, $white, $yellow-200 7%, $yellow-500);
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 1.2px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__list {
    padding-top: 5px;
    display:flex;
    justify-content: center;
  }

  &__item {
    text-align: center;
    max-width: 116px;
    margin: 8px 8px 0;
  }

  @include l {
    &__item{ max-width: 156px;}
  }
}
