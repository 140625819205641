$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.lang-popup {
	padding-top: 40px !important;

	&::before {
		height: 27px !important;
		background: url($cdn + '/wazamba/board/board1_top.png') center bottom no-repeat !important;
		background-size: contain !important;
		width: 448px !important;
		inset-inline-start: inherit !important;
		top: 40px !important;
	}

	.popup__wrap {
        width: 500px;

		&:before {
			content: none;
		}

		.popup__content {
			margin: 0 auto 20px;
			padding: 0;
			max-width: inherit;
			width: 426px;
		}
	}

	.popup__close {
		top: -20px;
		inset-inline-end: -20px;
	}

	.choose-lang {
		&__title {
			font-weight: 800;
			font-size: 36px;
			line-height: 44px;
			text-align: center;
			letter-spacing: 0.05em;
			color: $white;
			margin-bottom: 24px;
			margin-top: 20px;
		}

		&__items {
			display: flex;
			flex-wrap: wrap;
		}

		&__item {
			width: 33.33%;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 32px;

			& > span {
				@include text(16px, 20px, 700, #894225);
				display: flex;
				align-items: center;
				text-align: center;
				letter-spacing: 0.05em;
				margin-top: 15px;
			}
		}
	}
}
