$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
//- Style for large bonus tile to be used on shop confirmation popup

.bonus-item-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  box-shadow: 0 4px 8px 0 $black-20;
  border-radius: 12px;
  text-align: start;
  margin: 0 auto 16px auto;
  padding: 26px 24px;
  width: 280px;
  min-height: 276px;
  z-index: 2;

  &__title {
    z-index: 1;
  }

  &__logo-img {
    position: absolute;
    width: 188px;
    height: 188px;
    inset-inline: 0;
    margin-inline: auto;
    bottom: -16px;
  }

  &__offer {
    position: relative;
    width: fit-content;
    display: flex;
    min-width: 104px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    padding-inline: 16px;
    z-index: 2;

    &.currency-font {
      &-default { font-size: 44px; }
      &-medium { font-size: 36px; }
      &-small { font-size: 28px; }
    }

    &-bg {
      position: absolute;
      inset: 0;
      border-radius: 12px;
      z-index: 1;
      background-color: rgb(47, 53, 138);
    }

    &-currency, &-amount {
      @include title-gold-shadow(3px, 0);
      line-height: 40px;
      z-index: 2;
    }

    &-amount {
      position: relative;

      &.is-crab {
        font-size: 44px;
      }
    }

    .price-tag {
      position: absolute;
      bottom: -10px;
      inset-inline-end: -88px;
      min-width: 93px;
      z-index: 0;
      transform: rotate(15deg);

      .rtl-lang & {
        transform: rotate(-15deg);
      }
    }
  }

  .amount-wrap {
    display: flex;

    .rtl-lang & {
      direction: ltr;
    }
  }

  //- Common style rules for all bonus types
  &.is-money-bonus-tile,
  &.is-freespin-tile {
    .bonus-item-tile__offer-bg {
      background-color: #482B2A;
    }
  }

  //- Specific styles for each bonus type
  &.is-money-bonus-tile {
    background-image: url($cdn + "/wazamba/shop/bonus-backgrounds/casino_confirm-popup.png");
  }

  &.is-freespin-tile {
    padding-inline: 20px;
    background-image: url($cdn + "/wazamba/shop/bonus-backgrounds/freespin_confirm-popup.png");
  }

  &.is-freebet-bonus-tile {
    background-image: url($cdn + "/wazamba/shop/bonus-backgrounds/sport_confirm-popup.png");

    .bonus-item-tile__offer-bg {
      background-color: #173268;
    }
  }

  h3,
  p {
    margin: 0;
  }
}

.is-cash-crab-tile {
  background-image: url("#{$cdn}/wazamba/shop/bonus-backgrounds/freespin_art-pattern.png"), url("#{$cdn}/wazamba/shop/bonus-backgrounds/freespin_wood-pattern.png");
  background-repeat: no-repeat, no-repeat;
  background-size: 1200px 49px, 100% 100%;
  background-position: left 50% bottom 4px, center;

  &--popup-item::after {
    content: '';
    position: absolute;
    inset: 8px 8px 40px;
    border-radius: 8px 8px 16px 16px;
    border: 1px solid rgba(40, 93, 168, 0.4);
    box-shadow: 0 2px 6px 0 rgba(255, 195, 78, 0.4), 0 4px 24px 0 rgba(157, 29, 29, 0.4) inset;
    background-image: url("#{$cdn}/wazamba/shop/bc_small_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    z-index: -1;
  }

  .bonus-item-tile {
    &__offer {
      &-bg {
        background-color: #2F358A;
      }

      &-amount {
        padding-inline-end: 50px;

        &::after {
          content: '';
          position: absolute;
          inset-inline-end: 0;
          top: 0;
          width: 43px;
          height: 43px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url($cdn + '/wazamba/crab/sidebar-icon.png');
        }
      }
    }
  }
}

.freespin-tile {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-block: 30px 0;
  padding-inline-end: 90px;
  text-align: start;
  z-index: 1;
  align-self: flex-start;
  margin-block: auto;

  &__game-name {
    @include text(20px, 20px, 700);
  }

  &__offer {
    @include text(12px, 16px, 500, #FFC700);

    span {
      display: inline-block;
      padding-inline-start: 4px;
    }
  }

  &__game-img.shop-fs-item__img {
    inset-inline-start: unset;
    inset-inline-end: 20px;
    top: unset;
    bottom: 20px;
    z-index: 1;
    height: 84px;

    img {
      border-radius: 5px;
      object-fit: cover;
    }
  }
}

.is-small-bonus-tile,
.is-small-bonus-tile--no-fs-amount {
  width: 100%;

  //- Common style rules for all bonus types
  .bonus-item-tile {
    min-height: unset;
    height: 96px;
    width: 100%;
    padding-block: 0;
    padding-inline-end: 100px;
    padding-inline-start: 24px;
    margin: unset;
    justify-content: center;
    outline: 1px solid $white-10;
    outline-offset: -1px;
    box-shadow: 0 4px 8px 0 $black-20, inset 0 -4px 12px rgba(39, 11, 5, 0.2), inset 0 2px 3px rgba(255, 162, 109, 0.4);

    &::after {
      content: none;
    }

    &__offer {
      height: unset;
      padding: unset;
      justify-content: start;

      &.currency-font {
        &-default { font-size: 28px; }
        &-medium { font-size: 24px; }
        &-small { font-size: 20px; }
      }

      span {
        line-height: 32px;
      }
    }

    &__offer-bg {
      display: none;
    }

    &__title,
    .freespin-tile__game-name {
      @include text(14px, 14px, 800);
      text-align: start;
    }

    .price-tag {
      display: none;
    }
  }

  .is-money-bonus-tile,
  .is-freebet-bonus-tile,
  .is-freespin-tile {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    gap: 4px;
    align-items: flex-start;

    &::before {
      content: '';
      position: absolute;
      inset-block: 6px;
      inset-inline: 6px 98px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      filter: drop-shadow(0 1px 2px rgba(255, 162, 109, 0.4));
    }
  }

  .is-money-bonus-tile,
  .is-freebet-bonus-tile {
    .bonus-item-tile__logo-img {
      inset-inline: unset;
      bottom: unset;
      inset-block: -8px;
      inset-inline-end: -4px;
      width: 112px;
      height: 112px;
    }
  }

  .is-money-bonus-tile,
  .is-freespin-tile {
    background-image: url($cdn + "/wazamba/shop/bonus-backgrounds/freespin_wood-pattern.png");
  }

  // Specific styles for each bonus type
  .is-money-bonus-tile {
    &::before {
      background-image: url($cdn + "/wazamba/shop/bonus-backgrounds/casino_line-block.png");
    }

    .rtl-lang & {
      &:before {
        transform: scale(-1, 1);
      }
    }
  }

  .is-freespin-tile {
    flex-direction: column-reverse;

    &::before {
      background-image: url($cdn + "/wazamba/shop/bonus-backgrounds/freespin_line-block.png");
    }

    .bonus-item-tile__title {
      display: none;
    }

    .freespin-tile {
      padding: unset;
      margin: unset;
      max-width: unset;

      &__game-img {
        inset-block: 6px;
        inset-inline-end: 6px;
      }

      &__offer {
        display: none;
      }
    }

    .rtl-lang & {
      &:before {
        transform: scale(-1, 1);
      }
    }
  }

  .is-freebet-bonus-tile {
    background-image: url($cdn + "/wazamba/shop/bonus-backgrounds/sport_wood-pattern.png");

    &::before {
      background-image: url($cdn + "/wazamba/shop/bonus-backgrounds/sport_line-block.png");
    }

    .rtl-lang & {
      &:before {
        transform: scale(-1, 1);
      }
    }
  }

  &--no-fs-amount .is-freespin-tile {
    .freespin-tile {
      &__offer {
        display: block;


      }

      &__game-name {
        @include text(16px, 18px, 700);
      }
    }

    .bonus-item-tile__offer {
      display: none;
    }
  }
}

.icon-label {
  &-active,
  &-new {
    position: relative;
  }

  &-active::before,
  &-new::before {
    @include text(10px, 22px, 700, $white);
    text-align: center;
    content: attr(status);
    text-transform: capitalize;
    letter-spacing: .5px;
    position: absolute;
    top: -6px;
    inset-inline-end: -23px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 72px;
    height: 26px;
    text-shadow: 0 1px 5px rgba(36, 36, 36, 0.5);
    transform: rotate(30deg);
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 4;

    .rtl-lang & {
      transform: rotate(-30deg);
    }
  }
}

//- Style for large bonus tile to be used on autobonus popup

.is-large-autobonus-tile {
  $lightShadowColor: rgba(255, 162, 109, 0.2);
  $darkShadowColor: rgba(112, 20, 20, 0.4);

  .bonus-item-tile {
    height: 228px;
    margin-bottom: -138px;
    padding-top: 20px;
    justify-content: flex-start;
  }

  .is-money-bonus-tile,
  .is-freebet-bonus-tile,
  .is-freespin-tile {
    background-image: url("#{$cdn}/wazamba/shop/bonus-backgrounds/freespin_art-pattern.png"), url("#{$cdn}/wazamba/shop/bonus-backgrounds/freespin_wood-pattern.png");
    background-repeat: no-repeat, no-repeat;
    background-size: 3000px 128px, 100% 100%;
    background-position: left -100px bottom 4px, center;
    background-blend-mode: soft-light;

    &:before {
      position: absolute;
      content: '';
      border-radius: 6px;
      inset-inline: 6px;
      top: 6px;
      height: 84px;
      box-shadow: 0 1px 2px 0 $lightShadowColor, 0 1px 2px 0 $lightShadowColor, 0 4px 24px 0 $darkShadowColor inset;
    }
  }

  .is-money-bonus-tile,
  .is-freespin-tile {
    &::before {
      background-color: rgba(43, 7, 7, 0.4);
    }
  }

  .is-money-bonus-tile,
  .is-freebet-bonus-tile {
    &::before {
      background-image: url($cdn + '/wazamba/shop/bonus-backgrounds/bonus_star_bg.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-blend-mode: overlay;
    }
  }

  .is-freebet-bonus-tile {
    background-image: url("#{$cdn}/wazamba/shop/bonus-backgrounds/freespin_art-pattern.png"), url("#{$cdn}/wazamba/shop/bonus-backgrounds/sport_wood-pattern.png");

    &::before {
      background-color: rgba(27, 67, 146, 1);
      $lightShadowColor: rgba(109, 168, 255, 0.2);
      $darkShadowColor: rgba(13, 89, 122, 0.2);
    }
  }

  .is-freespin-tile {
    &::before {
      background-image: unset;
    }

    &.bonus-item-tile {
      justify-content: flex-end;
    }

    .freespin-tile__game-img {
      height: 72px;
      top: 12px;
      inset-inline-end: 12px;
    }
  }
}

.hero-room {
  padding: 24px 14px;

  &__wrap {
    height: 100%;
  }

  .bonus__data {
    padding-top: 20px;

    &-value {
      width: 60%;
    }
  }

  .bonus__item {
    height: 100%;
    gap: unset;

    &-btn {
      margin-top: auto;
      margin-bottom: 20px;
    }
  }
}

