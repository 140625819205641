$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dashboard-slide {
  max-width: 732px;
  width: 100%;
  margin: 15px auto;
  position: relative;
  @media screen and (min-width: 1280px) {
    max-width: 880px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 1040px;
  }
  &__block {
    position: relative;
  }
  &__title {
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: $white;
    text-shadow: 0 0 10px $black-25;
    margin-top: 0;
    margin-bottom: 4px;
    margin-inline-start: 4px;
    &.is-recommended {
      position: absolute;
      top: 0;
      inset-inline-start: calc(100% - 286px);
      margin-inline-start: 0;
      @media screen and (min-width: 1280px) {
        inset-inline-start: calc(100% - 433px);
      }
      @media screen and (min-width: 1440px) {
        inset-inline-start: calc(100% - 588px);
      }
    }
  }
  &__item {
    width: 140px;
    height: 212px;
    position: relative;
  }
  .slick-next, .slick-prev {
    position: absolute;
    top: calc(50% - 24px);
    z-index: 8;

    .rtl-lang & {
      transform:scaleX(-1);

      &.g-btn:active:not(.g-btn-noclick):not(.shop-btn):active {
        transform: scaleX(-1) translateY(2px);
      }
    }
  }
  .slick-track {
    margin-inline-start: unset;
    margin-inline-end: unset;
  }
  .slick-slide {
    margin: 4px;
  }
  .slick-prev {
    inset-inline-start: -20px;
    @media screen and (min-width: 1280px) {
      inset-inline-start: -55px;
    }
  }

  .slick-next {
    inset-inline-end: -20px;
    @media screen and (min-width: 1280px) {
      inset-inline-end: -60px;
    }
  }
}

.height-dashboard {
  height: 244px;
  display: flex;
  align-items: center;
  justify-content: center;
}
