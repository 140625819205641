$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-notification{
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 5000 ;


  &__item{
    width: 422px;



  }
  &__row{
    position:relative;
    display:flex;
    padding:15px 30px 15px;
  }
  &__img{
    width: 90px;
    height: 92px;
    background: red;
    flex-shrink: 0;
    position: relative;

    background: url( $cdn + "/wazamba/sprite-icons-3.png");
    background-size:  564%;
    background-position: 22% 50%;


  }
  &__content{
    width: 260px;
    padding-left:20px;
    flex-grow: 1;
    color: $brown-300;

  }
  &__title{
    font-weight: 800;
    padding:10px 0 5px;
  }
  &__btns{
    padding-top:5px;
  }


}
