$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;

$m: 1024px;
$l: 1280px;
$xl: 1440px;
$xxl: 1680px;
$full: 1920px;
$full2k: 2200px;


$minWidthScreen:$m;

@mixin m {
  @media screen and ( min-width: #{$m} ) {
    @content;
  }
}
@mixin l {
  @media screen and ( min-width: #{$l} ) {
    @content;
  }
}
@mixin xl {
  @media screen and ( min-width: #{$xl} ) {
    @content;
  }
}

@mixin xxl {
  @media screen and ( min-width: #{$xxl} ) {
    @content;
  }
}

@mixin full {
  @media screen and ( min-width:  #{$full} ) {
    @content;
  }
}

@mixin full2k {
  @media screen and ( min-width:  #{$full2k} ) {
    @content;
  }
}

@mixin max-m {
  @media screen and ( max-width: #{$m} ) {
    @content;
  }
}

@mixin max-l {
  @media screen and ( max-width: #{$l} ) {
    @content;
  }
}

@mixin max-xl {
  @media screen and ( max-width: #{$xl} ) {
    @content;
  }
}

@mixin max-xxl {
  @media screen and ( max-width: #{$xxl} ) {
    @content;
  }
}



