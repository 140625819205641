$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-registration-promotions {
  background: linear-gradient(180deg, #3B7096 0%, #254A64 100%);
  border-radius: 14px;
  position: relative;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  cursor: pointer;

  &:after {
    position: absolute;
    content: '';
    border-radius: 14px;
    background: linear-gradient(180deg, rgba(248,225,83,1) 0%, rgba(219,124,35,1) 100%);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: -2px;
    left: -2px;
    right: 0;
    bottom: 0;
    z-index: -1;
    box-shadow: 0 8px 4px rgba(14, 17, 34, 0.6), 0 20px 40px 12px rgba(11, 32, 49, 0.9);
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Montserrat', sans-serif;
  }
}

.quick-registration-promo {
  $root: &;
  display: flex;
  align-items: center;
  padding: 12px;
  min-height: 80px;

  &.is-pnp{
    width: 100%;
  }

  &.is-default {
    margin: 20px auto 22px;

    #{$root}__img {
      width: 90px;
      height: 98px;
    }
  }
  &__img-wrap {
    display: flex;
    align-items: center;
    height: 61px;
  }
  &__img {
    width: 56px;
    height: 61px;
    display: block;
    object-fit: contain;
  }

  .wrap {
    overflow: hidden;
  }

  &__label {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    .is-active &{

      &:after {
        width: 10px;
        height: 7px;
        content: '';
        bottom: 0;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC40OTc5MjUgMS42MzQzNEMwLjQ5NzkyNSAxLjkyNTg0IDAuNjExNjI2IDIuMjE3NTQgMC44MzkxMjYgMi40MzkwNEw1LjAwMDM0IDYuNTAwMDZMOS4xNjEyNSAyLjQzOTA0QzkuNjEzNTUgMS45OTc3NCA5LjYxNTc1IDEuMjc5NTQgOS4xNjY0NSAwLjgzNTAzM0M4LjcxNzE1IDAuMzkwNDMxIDcuOTg2NTQgMC4zODgwMzEgNy41MzM3NCAwLjgyOTYzM0w1LjAwMDM0IDMuMzAyNTVMMi40NjU3MyAwLjgyOTYzM0MyLjAxMzEzIDAuMzg4MDMxIDEuMjgyMzMgMC4zOTA0MzEgMC44MzI5MjYgMC44MzUwMzNDMC42MDk3MjYgMS4wNTY1MyAwLjQ5NzkyNSAxLjM0NTI0IDAuNDk3OTI1IDEuNjM0MzRaIiBmaWxsPSIjZmZmZmZmIi8+Cjwvc3ZnPgo=');
        background-repeat: no-repeat;
        opacity: .9;
        position: absolute;
        top: 50%;
        bottom: 0;
        right: 0;
        transform: translateY(-50%);

        .quick-registration-dropdown.open & {
          content: none;
          opacity: 0;
        }
      }
    }

    .radio-block {
      flex-shrink: 0;
    }

    .chose-code__img {
      width: 56px;
      height: 56px;
    }

    .chose-code__title {
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      color: $white;
      padding-bottom: 4px;
    }

    .chose-code__promocode {
      @include text(18px, 20px, 800, #FFCF01);
      @include ellipsis;
      text-shadow: 0 1px 0 #CC2500, 0 4px 4px $black-35;
      text-transform: uppercase;
    }
  }

  &__wrap {
    color: $white;
    overflow: hidden;
    max-width: 220px;
  }

  &__title {
    @include text(12px, 12px, 700, $white);
  }

  &__text {
    @include text(20px, 20px, 800, #FFCF01);
    text-shadow: 0 1px 0 #CC2500, 0 4px 4px $black-35;
    padding: 4px 0;
  }

  &__subtext {
    @include text(16px, 16px, 800, $white);
    text-shadow: 0 4px 4px $black-35;
  }

  .promocode-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    &__text {
      @include text(12px, 12px, 700, $white);
      width: 100%;
      text-align: left;
      padding-bottom: 4px;
    }

    .wrap {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}
