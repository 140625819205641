$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.league-popup {
  .g-btn {
    min-width: 177px;
    margin: auto auto 20px;
    position: relative;
  }

  .icon-reward {
    width: 88px;
    position: relative;

    &.is-finish {
      width: 94px;
      height: 94px;
      margin-top: -22px;
      margin-bottom: -22px;
      margin-left: 10px;
    }
  }

  &__icon {
    .icon-league {
      margin: 0 auto;
      z-index: 1;
      width: 112px;
      height: 112px;
      background-size: contain;
    }
  }

  &__status {
    height: 156px;
    margin-bottom: -90px;
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: center;

    &.has-shining:before {
      width: 406px;
      height: 294px;
      background-size: contain;
      margin-left: unset;
      margin-top: unset;
      background-position: center;
      top: 50%;
      inset-inline-start: 50%;
      transform: translate(-50%, -50%);
      background-repeat: no-repeat;
      margin-inline-start: 0;

      .rtl-lang & {
        transform: translate(50%, -50%);
      }
    }
    .icon-league {
      width: 149px;
      height: 159px;
      background-size: contain;
      &.has-icon-league-gift{
        background-size: 132px;
      }
    }
  }

  &__head {
    width: 464px;
    height: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 14px 15px 18px;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    background-image: url(#{$cdn}/wazamba/leagues/popup/reward-popup/popup-header.png);

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url(#{$cdn}/wazamba/leagues/popup/reward-popup/popup-header@x2.png);
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url(#{$cdn}/wazamba/leagues/popup/reward-popup/popup-header@x3.png);
    }
  }
  &__wrapper {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  &__leaf {
    width: 460px;
    height: 112px;
    background-image: url(#{$cdn}/wazamba/leagues/popup/popup-leaf.png);

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url(#{$cdn}/wazamba/leagues/popup/popup-leaf@2x.png);
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url(#{$cdn}/wazamba/leagues/popup/popup-leaf@2x.png);
    }
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-bottom: -30px;
  }

  &__body {
    width: 411px;
    margin: -60px auto 0;
    position: relative;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 70px;
    padding-bottom: 45px;
    flex-flow: wrap;
    background-position: bottom;

    background-image: url(#{$cdn}/wazamba/leagues/popup/reward-popup/bg-popup.png);
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url(#{$cdn}/wazamba/leagues/popup/reward-popup/bg-popup@x2.png);
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url(#{$cdn}/wazamba/leagues/popup/reward-popup/bg-popup@x3.png);
    }

    &:before {
      position: absolute;
      content: '';
      background-image: url(#{$cdn}/wazamba/leagues/popup/reward-popup/lines-popup.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 149px;
      bottom: 80px;
    }

    &.is-s {
      height: 290px;
    }
    &.is-m {
      height: 353px;
      flex-flow: column;
      grid-gap: 20px;
    }
  }
  &__text {
    @include text(20px, 22px, 700);

    color: #7d4826;
    text-align: center;

    &.sm {
      @include text(12px, 15px, 700);
      color: #b89624;
    }
    &.des {
      @include text(16px, 20px, 800);
      max-width: 190px;
      color: #725500;
      margin-top: 5px;
    }
    &.is-l {
      @include text(22px, 27px, 700);
    }
    &.is-xl {
      @include text(24px, 24px, 700);
      background: linear-gradient(180deg, #ff7a00 0%, $orange-1200 95.03%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-top: 12px;
      margin-bottom: 56px;

      .rtl-lang & {
        direction: ltr;
      }
    }
    &.is-xxl{
      @include text(40px, 44px, 800);
      background: linear-gradient(180deg, #B54100 0%, #78350B 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0 3px 0 rgba(77, 23, 0, 0.08);
    }
    &.text-color {
      background: linear-gradient(180deg, #B54100 0%, #78350B 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0 3px 0 rgba(77, 23, 0, 0.08);
    }
  }
  &__title {
    @include text(28px, 30px, 800);
    color: #7d4826;
  }
  &__subtitle {
    @include text(18px, 20px, 700);
    color: rgba(110, 57, 35, 0.9);
    opacity: 0.7;
    margin-top: 7px;
    text-align: center;
    span {
      &.date {
        margin-left: 4px;
      }
    }
  }
  &__stick {
    width: 100%;
    max-width: 398px;
    height: 22px;
    background-image: url(#{$cdn}/wazamba/leagues/popup/popup-stick.png);
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url(#{$cdn}/wazamba/leagues/popup/popup-stick@2x.png);
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url(#{$cdn}/wazamba/leagues/popup/popup-stick@2x.png);
    }
    background-size: contain;
    background-repeat: repeat;
    margin: -5px auto -15px;
    position: relative;
    z-index: 1;
  }

  &__gifts {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    margin-top: 16px;
  }
  &__gift {
    &__icon {
      width: 92px;
      height: 92px;
      border-radius: 50%;
      background: rgba(148, 110, 48, 0.21);
      cursor: pointer;
      border: 4px solid transparent;
      box-sizing: border-box;
      position: relative;

      background-position: center;
      background-repeat: no-repeat;
      &.cash {
        background-image: url(#{$cdn}/wazamba/leagues/money.png);
        background-size: 68px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &.amazon {
        background-image: url(#{$cdn}/wazamba/leagues/popup/amazon.svg);
        background-size: 68px;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.apple {
        background-image: url(#{$cdn}/wazamba/leagues/popup/amazon.svg);
        background-size: 68px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &__des {
      @include text(12px, 16px, 700);
      color: #725500;
      text-align: center;
      margin-top: 4px;
    }
    width: 92px;
    margin-left: 5px;
    margin-right: 5px;
    &.active {
      .league-popup__gift__icon {
        border: 4px solid $green-500;
        &:after {
          position: absolute;
          width: 34px;
          height: 34px;
          background-image: url(#{$cdn}/wazamba/leagues/popup/Icon-input-success_circle.svg);
          left: 63px;
          top: 9px;
          content: '';
        }
      }
    }
  }

  &__selected-gift {
    &__icon {
      width: 145px;
      height: 145px;
      position: relative;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 0 auto;
      &.cash {
        background-image: url(#{$cdn}/wazamba/leagues/money.png);
      }

      &.amazon {
        background-image: url(#{$cdn}/wazamba/leagues/popup/amazon.svg);
      }
      &.apple {
        background-image: url(#{$cdn}/wazamba/leagues/popup/amazon.svg);
      }
      &:after {
        position: absolute;
        width: 34px;
        height: 34px;
        background-image: url(#{$cdn}/wazamba/leagues/popup/Icon-input-success_circle.svg);
        right: 4px;
        bottom: 4px;
        content: '';
      }
    }
  }
  &__extra-gift {
    height: 107px;
    position: relative;
    text-align: center;
    &:after {
      position: absolute;
      width: 34px;
      height: 34px;
      background-image: url(#{$cdn}/wazamba/leagues/popup/Icon-input-success_circle.svg);
      left: 50%;
      margin-left: -17px;
      bottom: 4px;
      content: '';
      z-index: 10;
    }
    &__icon {
      width: 107px;
      height: 107px;
      position: relative;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(#{$cdn}/wazamba/leagues/cash.png);
      display: inline-block;
      &:not(:last-child) {
        margin-right: -80px;
      }
      &:nth-child(1) {
        z-index: 10;
      }
      &:nth-child(2) {
        z-index: 9;
      }
      &:nth-child(3) {
        z-index: 8;
      }
      &:nth-child(4) {
        z-index: 7;
      }
      &:nth-child(5) {
        z-index: 6;
      }
      &:nth-child(6) {
        z-index: 5;
      }
      &:nth-child(7) {
        z-index: 4;
      }
      &:nth-child(8) {
        z-index: 3;
      }
      &:nth-child(9) {
        z-index: 2;
      }
      &:nth-child(10) {
        z-index: 1;
      }
    }
  }

  &__best-gift {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    &__cash {
      width: 143.84px;
      height: 97px;
      background-image: url(#{$cdn}/wazamba/leagues/cash-pyramid.png);
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url(#{$cdn}/wazamba/leagues/cash-pyramid@2x.png);
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url(#{$cdn}/wazamba/leagues/cash-pyramid@2x.png);
      }
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: -40px;
    }
    &__mask {
      width: 159px;
      height: 159px;
      background-image: url(#{$cdn}/wazamba/leagues/popup/hero.png);
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url(#{$cdn}/wazamba/leagues/popup/hero@2x.png);
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url(#{$cdn}/wazamba/leagues/popup/hero@2x.png);
      }
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__prize {
    display: flex;
    justify-content: center;
    &__item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .icon-league{
        width: 88px;
        height: 88px;
        margin-bottom: 7px;
        &.has-icon-league-gift{
          background-size: 120px;
        }
      }
    }
    &__items {
      display: flex;
      justify-content: space-evenly;
      margin-top: 10px;
    }
    &#weekly-prize {
      transform: scale(0);
      flex-direction: column;
    }
    &__icon {
      width: 110px;
      height: 110px;
      background-image: url(#{$cdn}/wazamba/leagues/gift-league-5.png);
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url(#{$cdn}/wazamba/leagues/gift-league-5@2x.png);
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url(#{$cdn}/wazamba/leagues/gift-league-5@2x.png);
      }
      background-repeat: no-repeat;
      background-size: contain;
      margin-top: -23px;
      margin-bottom: -23px;
    }
  }

  &__plane {
    border-radius: 12px;
    padding: 16px;
    margin: 0 auto 16px;
    width: 280px;
    background: rgba(219, 177, 27, 0.4);
    position: relative;

    &.league-reward {
      box-shadow: 0 6px 5px rgba(126, 89, 13, 0.24), inset 0 3px 8px rgba(194, 115, 22, 0.78);
      border-radius: 16px;
      margin-top: 12px;

      &:before {
        position: absolute;
        content: '';
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(180deg, #FFB929 0%, #FFB929 100%);
        border-radius: 16px;
        z-index: 1;
      }

      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(249, 229, 128, 0.85) 0%, rgba(254, 224, 28, 0.85) 100%);
        border-radius: 16px;
      }
    }

    &.is-rewards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 0;
      padding-bottom: 0;
      min-height: 80px;
      margin-top: 20px;

      .icon-league {
        height: 65px;
      }
      span {
        @include text(18px, 22px, 700);
        color: rgba(255, 212, 103, 0.9);
      }
    }

    .level-block {
      position: relative;
      z-index: 3;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    max-width: 212px;
    margin: 0 auto;
    &.border-top {
      border-top: 1px solid $white-30;
      margin-top: 12px;
      padding-top: 12px;
    }
    &.flex-row {
      flex-direction: row;
      padding-top: 0;
    }
    &__left {
      width: 50%;
      border-right: 1px solid $white-30;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__right {
      width: 50%;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &.is-instant-rewards-popup {

    .league-popup__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
    }

    .league-popup__prize {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .league-popup__icon {
      z-index: 1;
      position: relative;
      bottom: 50px;

      .icon-league {
        height: 159px;
        width: 149px;
      }

      .has-shining {
        height: 159px;
        width: 149px;
        margin: 0 auto;

        &:before {
          width: 406px;
          height: 294px;
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          background-position: center;
          z-index: -4;
          transform: translate(-50%, -50%);
          margin-left: unset;
          margin-top: unset;
        }
      }
    }

    #weekly-points {
      margin-top: -20px;
    }
  }

  .level-block__text {
    background: linear-gradient(180deg, #B54100 0%, #78350B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    text-shadow: 0 3px 0 rgba(77, 23, 0, 0.08);
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;

    &.is-s {
      color: #7D4826;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;

    }
  }
  .level-block__box.border-top {
    border-top-color: #e2bd3a;
  }
  .level-block__left {
    border-right-color: #e2bd3a;
  }
}

.weekly-league-popup {
  min-height: 627px;
  position: relative;
  padding-top: 130px;
  &__bg {
    width: 612px;
    height: 627px;

    width: 471px;
    height: 592px;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -235px;

    background-image: url(#{$cdn}/wazamba/leagues/popup/popup-after.png);
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url(#{$cdn}/wazamba/leagues/popup/popup-after@2x.png);
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url(#{$cdn}/wazamba/leagues/popup/popup-after@2x.png);
    }

    background-repeat: no-repeat;
    background-size: contain;
    &:before {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(#{$cdn}/wazamba/leagues/popup/popup-after-dep-half.png);
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url(#{$cdn}/wazamba/leagues/popup/popup-after-dep-half@2x.png);
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url(#{$cdn}/wazamba/leagues/popup/popup-after-dep-half@2x.png);
      }

      background-repeat: no-repeat;
      background-size: contain;
    }
    &:after {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url(#{$cdn}/wazamba/leagues/popup/popup-after-dep-half.png);
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url(#{$cdn}/wazamba/leagues/popup/popup-after-dep-half@2x.png);
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url(#{$cdn}/wazamba/leagues/popup/popup-after-dep-half@2x.png);
      }
      background-repeat: no-repeat;
      background-size: contain;
      transform: scale(-1, 1);
    }
  }
  &__title {
    width: 153px;
    height: 62px;
    margin: 0 auto;
    background-image: url(#{$cdn}/wazamba/leagues/weekly-leagues2.png);
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url(#{$cdn}/wazamba/leagues/weekly-leagues2@2x.png);
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url(#{$cdn}/wazamba/leagues/weekly-leagues2@2x.png);
    }
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    z-index: 2;
  }
  &__body {
    position: relative;
  }
  &__status {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    height: 185px;
    align-items: center;
    .icon-league {
      width: 102px;
      height: 102px;
      background-size: contain;
      &.has-shining:before {
        width: 256px;
        height: 185px;
        margin-left: -128px;
        margin-top: -92px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &.sm {
      margin-top: -25px;
      margin-bottom: -35px;

      .icon-league {
        width: 87px;
        height: 87px;
        background-size: contain;
        &.has-shining:before {
          width: 220px;
          height: 150px;
          margin-left: -110px;
          margin-top: -75px;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
  &__points {
    position: absolute;
    z-index: 2;
    width: 400px;
    left: 50%;
    margin-left: -200px;
    top: 190px;
  }
  &__text {
    @include text(16px, 20px, 700);
    color: #ffc414;
    text-align: center;
    &.congrat {
      @include text(22px, 27px, 800);
    }
  }
  &__value {
    @include text(40px, 40px, 700);
    color: #ffc414;
    text-align: center;
  }
  &__prize {
    position: absolute;
    top: 125px;
    transform: scale(0);
    width: 400px;
    left: 50%;
    margin-left: -200px;
    .weekly-league-popup__text {
      @include text(16px, 20px, 800);
      color: $white;
      &.congrat {
        @include text(22px, 27px, 800);
        text-align: center;
        letter-spacing: 0.05em;
        color: #ffc414;
        margin-bottom: 10px;
      }
    }
  }
  &__icon {
    width: 92px;
    height: 88px;
    background-image: url(#{$cdn}/wazamba/leagues/gift-league-3.png);
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url(#{$cdn}/wazamba/leagues/gift-league-3@2x.png);
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url(#{$cdn}/wazamba/leagues/gift-league-3@2x.png);
    }
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
  }
  .g-btn {
    margin-top: 30px;
    z-index: 1;
    position: relative;
  }
  #weekly-button {
    transform: scale(0);

    position: absolute;
    width: 400px;

    left: 50%;
    bottom: 80px;
    margin-left: -200px;
    text-align: center;
    .g-btn {
      display: inline-block;
      margin-bottom: 0;
    }
  }
}

#shining {
  position: absolute;
  transform: scale(0);
  & > div {
    animation: rotate 6s linear infinite;
  }
}

.popup-vip {
  padding-top: 50px;
}

#weekly-button {
  transform: scale(0);
  height: 0;
  text-align: center;
}

#weekly-status {
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  bottom: 0;
}

.linda-has-popup .popup {
  &.hiding {
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
  }
}

.how-popup {
  background-color: #110401;
}
