$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.is-hidden { display: none !important; }
.is-underline { text-decoration: underline; }
.is-center { text-align: center; }
.is-relative { position: relative; }
.is-mirrorY { transform: scaleY(-1); }
.is-weight-800 { font-weight: 800; }

.is-reset-type-button-styles {
  background: none;
  border: none;
  padding: 0;
  font-size: 1rem;
  color: inherit;
}

.is-hlw-text-gr {
  background: linear-gradient(180deg, $white 13.03%, $yellow-200 18.73%, $yellow-500 96.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@for $i from 0 through 60 {
  .has-margin-bottom-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .has-margin-top-#{$i} {
    margin-top: #{$i}px !important;
  }
}

body:not(.linda-is-halloween_shop) .if-halloween-shop{ display: none!important;}

@media ( min-width: #{$l} ) {
  .hide-l{display: none!important;}
}

@media ( max-width: #{$l - 1px} ) {
  .show-l{display: none!important;}
}
