$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
button.mask-popup__buy,
button.mask-popup__wear {
  display: none;
  font-family: Montserrat,sans-serif;
}

.mask-popup {
  margin: 0 auto;
  padding-top: 82px;
  padding-bottom: 45px;
  text-align: center;
  h1{
    margin-top: 15px;
  }
  &__img{
    width: 260px;
    position: relative;
    height: 325px;
    background-image: url($cdn + "/wazamba/masks-collection/mask-bg.png");
    margin: 0 auto 28px;
    img{
      width: 283px;
      height: 283px;
      object-fit: contain;
      transform: translate(4px, -60px);
    }
    .g-btn-completed{
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-inline-start: -16px;
    }
  }
  &__price{
    width: 148px;
    height: 81px;
    margin: 0 auto;
    transform: translateY(-40px);
  }
  &__coin{
    height: 40px;
    border-radius: 36px;
    background-image: linear-gradient(to top,$violet-100,$blue-600 95%);
    padding: 10px 20px;
    align-items: center;
    font-size: 26px;
    font-weight: 800;
    border: solid 1px transparent;
    background-clip: padding-box;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    &:before{
      content: '';
      width: 30px;
      height: 30px;
      background-image: url($cdn + '/wazamba/sprite-tournament-page.png');
      background-position: -137px -62px;
      background-size: auto;
      margin-inline-end: 5px;
    }
  }
  &__title {
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.05em;
    margin: 16px 0;
  }
  &__button{
    width: 200px;
    margin: 0 auto;
    text-align: center;
    span{
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: $yellow-700;
    }
    .g-btn-completed{
      display: none;
    }
  }
  &__text {
    letter-spacing: 0.05em;
    color: $yellow-700;
    text-shadow: 0 0 10px $black-25;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    margin-top: 28px;
  }
  &.status-0,
  &.status-1 {
    .g-btn-completed{
      display: none;
    }
    .mask-popup__img  img{
      filter: url(#svgTint);
    }
    .mask-popup__buy {
      display: block;
    }
    .mask-popup__coin {
      display: flex;
    }
    .mask-popup__price {
      background-image: url($cdn + "/wazamba/masks-collection/Leaf-card-popup.png");
    }
    .mask-popup__buy {
      display: block;
    }
  }
  &.status-3,
  &.status-2 {
    .mask-popup__wear {
      display: block;
    }
  }

  &.is-shop-confirm {
    padding: 35px 0 25px;
    img {
      filter: url(#svgTint);
    }
    .mask-popup__coin {
      display: flex;
    }
    .mask-popup__price {
      background-image: url($cdn + "/wazamba/masks-collection/Leaf-card-popup.png");
    }
    .mask-popup__button {
      width: 100%; 
      button {
        min-width: 260px;
      }
    }
  }

  &.is-shop-confirm {
    padding: 35px 0 25px;
    img {
      filter: url(#svgTint);
    }
    .mask-popup__coin {
      display: flex;
    }
    .mask-popup__price {
      background-image: url($cdn + "/wazamba/masks-collection/Leaf-card-popup.png");
    }
    .mask-popup__button {
      width: 100%; 
      button {
        min-width: 260px;
      }
    }
  }

  .rtl-lang & {
    direction: rtl;
  }
}
