$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.lang-list{
  display:block;
  padding:5px 0 15px;
  border-bottom:solid 1px #21728a;
  &__row{
    display:flex;
    justify-content: space-around;
    margin:0 -15px;
  }
  &__item{
    display:block;
    width: 16px;
    height: 28px;
    opacity:0.5;
    transition:0.2s;
    &.active{ opacity:1; }
    &:hover{ opacity:0.8; }
    img {
      overflow: hidden;
      font-size: 10px;
      word-break: break-all;
      width: 100%;
      height: 100%;
    }
  }
}