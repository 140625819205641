$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
linda-widget-event-list {
  display: block;
  max-width: 100vw;
  overflow: auto;
}

.events {
  height: 230px;
  background: url($cdn + '/wazamba/event_bg.png');
  background-size: contain;
  background-position: center;
  overflow: hidden;
  position: relative;

  &__scroll {
    height: 240px;
    max-width: 100%;
    overflow-x: auto;
    padding-right: 200px;
  }

  &__list {
    display: flex;
    height: 190px;
    padding: 63px 30px 35px;
  }

  &__item-wrap {
    display: flex;
    align-items: flex-start;
    height: 100%;
    padding-top: 17px;
  }

  &__item {
    position: relative;
    flex-shrink: 0;
    height: 116px;
    width: 294px;
    background: linear-gradient(180deg, #F5CC8A 0%, #EA8942 100%);
    border: 2px solid $white-30;
    box-shadow: inset 0 0 16.7194px rgba(147, 55, 4, 0.91);
    border-radius: 16px;
    padding-block: 5px;
    padding-inline: 20px 100px;
    cursor: pointer;

    &:not(:last-child) {
      margin-inline-end: 32px;
    }
  }

  &__ico {
    position: absolute;
    width: 220px;
    height: 186px;
    inset-inline-end: -71px;
    bottom: -26px;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: contain;
    z-index: 10;
  }

  &__text {
    font-weight: 800;
    font-size: 16px;
    line-height: 18px;
    color: $brown-300;

    width: 92%;
  }

  &__empty {
    width: 240px;
    flex-shrink: 0;
  }

  &__win {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.05em;
    color: $white;
    text-shadow: 0 2px 0 #69380C, 0 0 10px $black-25;
  }

  &__game {
    position: absolute;
    width: 88px;
    inset-inline-end: 0;
    top: -10px;
  }

  .rtl-lang & {
    direction: rtl;

    &__login {
      direction: ltr;
      display: inline-block;
    }
  }
}

.events__item.ng-move,
.events__item.ng-enter {
  transition: 0.8s;
}
.events__item.ng-leave {
  transition: 0.001s !important;
}
.events__item.ng-leave.ng-leave-active,
.events__item.ng-move,
.events__item.ng-enter {
  margin-inline-start: -60px;
}

.events__item.ng-leave,
.events__item.ng-move.ng-move-active,
.events__item.ng-enter.ng-enter-active {
  margin-inline-start: 0;
}
