$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page-footer {
  margin-inline: 60px;

  &__wrapper {
    background-image: url($cdn + '/wazamba/game-page/header-pattern-bg.png');
    background-color: #56370e;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150% 200%;
    background-blend-mode: overlay;
  }

  &__to-real {
    &-mode-switch-bar {
      position: relative;
      max-width: 596px;
      margin: 8px auto;
      border-radius: 50px;
      border: 2px solid $white-20;
      display: flex;
      flex-direction: row;
      gap: 40px;
      align-items: center;
      padding: 8px 12px 8px 24px;
      background-color: #119E1F;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-image: url($cdn + '/wazamba/game-page/play-for-real-mask.png');
        background-position: center;
        background-repeat: repeat;
        opacity: 0.05;
      }
    }

    &-text {
      @include text(14px, 20px, 600, $white);
      text-align: start;
      flex-grow: 1;
      margin-inline-start: 12px;
    }

    &-btn {
      height: 32px;
      width: 51px;
      background-image: var(--icon-toggle-off);
      flex-shrink: 0;

      .rtl-lang & {
        transform: scaleX(-1);
      }
    }
  }

  &__seo {
    transform: scale(0);
    height: 0;
  }
}
