$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.heroroom{
  display:grid;
  grid-gap:21px;
  grid-template-columns: repeat( 12 , 1fr);
  grid-template-areas:
    "f f f f f f f f f f f f"
    "a a a a a a b b b b b b"
    "c c c c e e e e e e e e"
    "c c c c d d d d d d d d"
  ;

  &__section{
    position:relative;
    &_1{ grid-area:a; height:275px;}
    &_2{ grid-area:b;}
    &_3{
      grid-area:c;
      border-radius: 12px;
    }
    &_4{ grid-area:d; height:211px;}
    &_5{ grid-area:e; height:260px;}
    &_0{ grid-area: f;}
    &_1 ,
    &_2{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @include l{
    &__section_4{ height:240px;}
  }
  @include xl{
    &__section_4{ height:275px;}
    &__section_5{ height:275px;}
  }

  @include xxl{
    grid-template-columns: repeat( 11 , 1fr);
    grid-template-areas:
      "f f f f f f f f c c c "
      "a a a a b b b b c c c "
      "d d d d e e e e j j j ";
    &__section_4{ height:210px;}
    &__section_5{ height:210px;}
  }
}

.hero-baner{
  background-image: url( $cdn + '/wazamba/profile-baner.jpg' );
  background-position: right bottom;
  background-repeat: no-repeat;
  position:relative;

  &__img{
    width: 50%;
    height: calc( 100% + 20px);
    background: url( $cdn + "/wazamba/hero/3_0.png") right 50% no-repeat;
    background-size: contain;
    position:absolute;
    top: -20px;
    left: -12px;
    transform: scale(-1, 1);
  }

  &__content {
    position:absolute;
    right: 30px;
    top: 0;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: hidden;
  }

  &__title{
    text-shadow: 0 2px 10px $black-25;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: 0.4px;
    color: $yellow-700;
  }

  &__text {
    text-shadow: 0 0 10px $black-25;
    font-size: 36px;
    font-weight: 800;
    padding-top:5px;
  }

  @include xl {
    &__content { width: 350px; }
  }

  @include xxl {
    &__content { width: 300px; }
  }
}

.profile-mask-banner {
  height: 100%;
  border-radius: 15px;
  width: 100%;
  padding: 6px;
  background-image: linear-gradient(180deg, #f2b600 -5.33%, $orange-1000 93.92%);
  box-shadow: inset 0 0 9px $black-40;
  background-position: -485px center, center;
  background-size: 702px auto, cover;

  &__text-wrap {
    width: 353px;
    text-align: center;
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__wrap {
    --mask-background-position: -452px;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: var(--mask-background-position) center, center;
    background-size: 702px auto, cover;
    height: 100%;
    border-radius: 15px;
    position: relative;
    z-index: 1;
    justify-content: center;
    align-items: flex-end;

    .rtl-lang & {
      --mask-background-position: 224px;
    }

    @include l {
      --mask-background-position: -418px;

      .rtl-lang & { --mask-background-position: 282px; }
    }

    @include xl {
      --mask-background-position: -380px;

      .rtl-lang & { --mask-background-position: 303px; }
    }

    @include xxl {
      --mask-background-position: -470px;

      .rtl-lang & { --mask-background-position: 254px; }
    }
  }

  &__title {
    @include text(24px, 24px, 600, $white);
    text-shadow: 0 2px 5px $black-70;
    margin-bottom: 8px;

    @include xl { font-size: 16px; }
  }

  &__text {
    @include text(40px, 48px, 800, $white);
    text-align: center;
    letter-spacing: 0.05em;
    text-shadow: 0 2px 5px $black-70;
    margin-bottom: 16px;
  }

  &__btn {
    flex-shrink: 0;
    min-width: 180px;
  }

  &__label {
    @include text(10px, 12px, 900, $white);
    background: $red-200;
    position: absolute;
    top: -34px;
    transform: translateX(-50%);
    left: 50%;
    box-shadow: 0 2px 0 $red-300;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    padding: 0 12px;
    display: flex;
    align-items: center;
    height: 28px;
  }
}
