$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.grid-ach {
  width: 748px;
  margin: 32px auto 0;

  &__top { position:relative; }

  &__center {
    display: flex;
    flex-wrap: wrap;
    margin:0 -10px;
    padding-top:10px;
  }

  &__small {
    width: calc(50% - 20px);
    margin:10px;
  }

  &__big {
    width: calc(100% - 20px);
    margin:10px;
  }

  &__btn { text-align: center; }

  @include l{ width: 970px; }
  @include xxl{ width: 1247px; }
}
