$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
@mixin text( $size: null, $lineHeight: null, $weight: null, $color: null ) {
  @if $size != null {
    font-size: $size;
  }

  @if $lineHeight != null {
    line-height: $lineHeight;
  }

  @if $weight != null {
    font-weight: $weight;
  }

  @if $color != null {
    color: $color;
  }
}


@mixin text-lines-clip($lines: 2){
  display: -webkit-box;
  overflow-wrap: break-word;
  line-clamp: $lines;
  -webkit-line-clamp: $lines;
  overflow: hidden;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  /*! autoprefixer: on */
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin title-gold-shadow($offset-y: null, $blur-radius: null) {
  font-weight: 800;
  letter-spacing: 0.05em;
  background: linear-gradient(180deg, $yellow-200 18.73%, $yellow-500 96.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @if $offset-y != null and $blur-radius != null {
    filter: drop-shadow(0 $offset-y $blur-radius $orange-900) drop-shadow(0 0 10px rgba(0, 0, 0, .75));
  }
}

@mixin shop-title-white($font-size, $line-height) {
  font-weight: 800;
  letter-spacing: 0.05em;
  color: $white;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin without-scrollbar(){
  &::-webkit-scrollbar  {
    display: none;
    height: 0;
    width: 0;
  }
}

@mixin reversed-tooltip {
  .ach-tooltip {
    padding-inline-start: unset;
    inset-inline-start: unset;
    padding-inline-end: 13px;
    inset-inline-end: 100%;

    &::before {
      inset-inline-start: unset;
      inset-inline-end: 3px;
      transform: scaleX(-1);
    }
  }
}