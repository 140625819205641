$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
//------ no bonus
.profile-bonuses-promo {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding: 24px 16px;

  &__title {
    border-radius: 12px;
    background: rgba(50, 33, 29, 0.50);
    box-shadow: 0 0 8px 0 $black-20 inset;
    padding: 12px;

    @include text(16px, 1, 700);

    color: $white;
    text-align: center;
    text-shadow: 0 1px 1px $black-25;
    letter-spacing: 0.8px;
    margin-bottom: 36px;
  }

  &__img {
    background: url( $cdn + "/wazamba/promo.png") 50% 50% no-repeat;
    background-size: contain;
    height: 158px;
    width: 136px;
    margin-bottom: 46px;
  }
}


//---- new design bonus
.profile-bonuses {
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 20px;
  display: grid;
}

.bonus {
  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    justify-items: center;
    position: relative;

    @include xl {
      grid-template-columns: repeat(3, 1fr);
    }

    @include xxl {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__box {
    min-height: 352px;
    width: 100%;
    border-radius: 12px;
    padding: 24px 12px;
    position: relative;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;

    &-btn {
      min-width: 124px;
    }
  }

  &__data {
    width: 100%;
    border-spacing: 0 17px;
    border-collapse: unset;
    margin-block: -10px;
    padding-inline: 10px;

    &.has-small-lines {
      border-spacing: 0 9px;
    }

    &-row{
      font-size: 12px;
      line-height: 15px;
      color: $white;
    }

    &-title {
      font-weight: 500;
      text-align: start;
      width: 40%;
    }

    &-value {
      font-weight: 700;
      text-align: end;
      width: 60%;

      &.is-ltr {
        direction: ltr;

        .rtl-lang & {
          text-align: start;
        }
      }
    }
  }

  &__no-bonus {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.has-preloader {
      display: none;
    }

    .no-bonus-title {
      @include text(20px, 1, 700);

      color: $white;
      text-align: center;
      padding-bottom: 20px;
    }

    .no-bonus-subtitle {
      @include text(12px, 1, 700);

      color: $white-80;
      text-align: center;
      padding-bottom: 16px;
    }

    button.g-btn {
      min-width: 180px;
      width: fit-content;
      margin: 0 auto;
    }
  }

  &__cancel {
    @include text(14px, 1.4, 700);
    text-align: center;
    letter-spacing: 0.7px;
  }
}
