$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.masks {
  &__item {
    position: relative;

    @include m {
      width: 174px;
      height: 217px;
    }
    @include l {
      width: 209px;
      height: 260px;
    }
    @include xl {
      width: 244px;
      height: 304px;
    }

    batman-shop-item7 {
      width: 100%;
      position: absolute;
      display: block;
      text-align: center;
      bottom: -5px;
    }
  }
}
