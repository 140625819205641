$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
@keyframes slide {
  from {
    background-position-y: 0;
  }
  to {
    background-position-y: -128px;
  }
}

.temporary-box {
  margin-top: 50px;
}

.scroll-info {
  width: 168px;
  height: 168px;
  position: fixed;
  bottom: -84px;
  left: 50%;
  transform: translateX(-50%);
  background: $black-40;
  z-index: 20;
  border-radius: 50%;
  transition: .25s ease-in-out;

  &.is-hide {
    transform: translate(-50%, 200px);
  }

  &__img {
    width: 28px;
    height: 40px;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid $white;
    border-radius: 18px;

    &:before {
      position: absolute;
      content: '';
      background: $white;
      border-radius: 13px;
      width: 2px;
      height: 8px;
      top: 9px;
      left: 44%;
      animation: anim-translate 2s linear infinite both;
    }

    &:after {
      position: absolute;
      content: '';
      width: 11px;
      height: 11px;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADcSURBVHgB7ZXREYIwDIaD5wCOwAYyApvIBrJBu4ErsAE6iSMUJ8ANYnKWOy+mEJAX7/jvfh7S5GvaKy2AQYh4JvfRDtYQgUr8VjlVt4Np5cbYbPAibeA/Bu/5E8/lkXzLsqyDBfpgPIhx5YATh78WBZXyg1Qi5yTGHaAuZwUrjbF63uNOWZlHw50Qc7wy9OTBAt+Xi9p5qmPyJVHDrGKYOSeHRGIwxoZ4IZc1BrcoMCO1Z0vhIQkV8PsMKOcewCJOJLcGaGuGigmaEWgDv4gAXoF6WEMRPjymtaXmBcr8h5aN+QqeAAAAAElFTkSuQmCC');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

@keyframes anim-translate {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0);
  }
}

.vip-page {
  padding: 0 0 150px;
  background-size: cover;
  position: relative;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(90deg, #07192D 0%, #0B2F53 45.83%, #06182E 100%);

  .rtl-lang & {
    direction: rtl;
  }

  .header-logo-wrap {
    position: fixed;
    top: 20px;
    left: 12px;
    z-index: 12;
    transition: all .35s ease-in-out;
    opacity: 0;
    transform: scale(0);

    &.active {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__logo {
    width: 220px;
    height: 95px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    background-image: url($cdn + '/wazamba/league/header/new-header.png');
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/wazamba/league/header/new-header@x2.png');
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url($cdn + '/wazamba/league/header/new-header@x3.png');
    }

    @include xl {
      width: 272px;
      height: 117px;
    }
  }

  &__subtext {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $white-70;

  }
}
.league-hide {
  position: absolute;
  inset: 0;
  background-color: rgba(34, 8, 2, 0.95);
  z-index: 12;
}
.league-faq {
  .screen {
    padding-top: 54px;
  }
}
.vip-page,
.league-faq {
  .shroud {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .league-header {
    height: 173px;

    background: url('#{$cdn}/wazamba/leagues/header/short.png') center 34px no-repeat,
      url('#{$cdn}/wazamba/renovation/leagues/header/bg.png') center top no-repeat;
    background-size: 414px, 706px;
    @media (-webkit-max-device-pixel-ratio: 2) {
      background: url('#{$cdn}/wazamba/leagues/header/short@2x.png') center 34px no-repeat,
        url('#{$cdn}/wazamba/renovation/leagues/header/bg@2.png') center top no-repeat;
      background-size: 414px, 706px;
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background: url('#{$cdn}/wazamba/leagues/header/short@3x.png') center 34px no-repeat,
        url('#{$cdn}/wazamba/renovation/leagues/header/bg@3.png') center top no-repeat;
      background-size: 414px, 706px;
    }

    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;

    z-index: 1;

    margin: 0 auto;
    transform: translateX(-88px);

    &__counter {
      font-weight: 700;
      font-size: 12px;
      line-height: 1.1;
      width: 115px;
      margin-top: 70px;
      height: 30px;
      text-align: balance;
      flex-wrap: wrap;

      display: flex;
      align-items: center;
      justify-content: center;

      &.live-counter {
        margin-inline-end: 36px;
      }
      &.players-counter {
        margin-inline-start: 30px;
      }
    }

    &__icon {
      position: absolute;
      width: 86px;
      height: 90px;
      background-size: contain;
      inset-inline-start: 50%;
      margin-inline-start: -43px;
      top: 12px;
      background-position: top center;
    }

    &__button {
      position: absolute;
      top: 92px;
      &.icon-back__after {
        inset-inline-end: calc(50% + 125px);
      }
      &.icon-question__after {
        inset-inline-start: calc(50% + 125px);
        &:before {
          position: absolute;
          content: '';
          background-image: url('#{$cdn}/wazamba/league/question-white.svg');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          inset-inline-start: 0;
          top: 0;
        }
      }
    }

    height: 208px;

    background: url('#{$cdn}/wazamba/leagues/header/wide.png') center 42px no-repeat,
      url('#{$cdn}/wazamba/renovation/leagues/header/back-hide.png') center top no-repeat;
    background-size: 564px, 796px;
    @media (-webkit-max-device-pixel-ratio: 2) {
      background: url('#{$cdn}/wazamba/leagues/header/wide@2x.png') center 42px no-repeat,
        url('#{$cdn}/wazamba/renovation/leagues/header/back-hide@2x.png') center top no-repeat;
      background-size: 564px, 796px;
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background: url('#{$cdn}/wazamba/leagues/header/wide@3x.png') center 42px no-repeat,
        url('#{$cdn}/wazamba/renovation/leagues/header/back-hide@3x.png') center top no-repeat;
      background-size: 564px, 796px;
    }

    &__counter {
      margin-top: 70px;
      width: 150px;
    }
    &__button {
      top: 105px;
      &.icon-back__after {
        right: calc(50% + 215px);
      }
      &.icon-question__after {
        left: calc(50% + 215px);
      }
    }

    &__icon {
      width: 96px;
      height: 100px;
      margin-left: -48px;
      top: 16px;
    }
  }

  .league-footer {
    display: flex;

    margin: 0 auto;
    flex-direction: row;
    width: auto;
    justify-content: center;
    margin-top: 50px;
    position: relative;
    z-index: 1;

    &__link {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: rgba(255, 255, 255, 0.71);
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 24px;

      &.has-icon-calendar {
        &:before {
          background-image: url('#{$cdn}/wazamba/leagues/calendar.svg');
        }
      }

      &.has-icon-question {
        &:before {
          background-image: url('#{$cdn}/wazamba/leagues/question.svg');
        }
      }

      &.has-icon-chat {
        &:before {
          background-image: url('#{$cdn}/wazamba/leagues/chat.svg');
        }
      }
      &:before {
        width: 22px;
        height: 22px;
        margin-right: 20px;
        content: '';
        background-position: center;
        background-repeat: no-repeat;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $white-20;

        margin-right: 32px;
        padding-right: 32px;
        border-bottom: none;
        border-right: 1px solid $white-20;
      }
    }
  }

  .league-sections-wrap {
    position: relative;
    z-index: 1;
    max-width: 580px;
    margin: 0 auto;
    padding-inline-start: 230px;
    transform: translateX(-88px);

    @include l {
      padding-inline-start: 160px;
    }
  }

  .league-point {
    position: absolute;
    min-width: 110px;
    bottom: 0;
    z-index: 11;
    transition: bottom 1.5s;
    transform: translate(-2px, -110px);
    inset-inline-end: calc(50% + 164px);
    background: rgba(35, 80, 130, 0.7);
    padding: 5px 0 3px;
    opacity: 0;

    &.is-unleag {
      transform: translate(-2px, -96px);

      @include l {
        transform: translate(-10px, -110px);
      }
    }

    @include l {
      margin-bottom: 12px;
      inset-inline-end: calc(50% + 228px);
      transform: translate(-10px, -132px);

      &:after {
        inset-inline-end: -20px;
      }
    }

    &.is-show {
      opacity: 1;
    }

    &.is-first {
      transform: translate(-2px,-113px);

      @include l {
        transform: translate(-10px,-138px);
      }
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 100%;
      top: 0;
    }

    &:before {
      background-image: url('#{$cdn}/wazamba/leagues/arrow-part-1.svg');
      width: 10px;
      inset-inline-start: -9px;
    }

    &:after {
      background-image: url('#{$cdn}/wazamba/leagues/arrow-part-2.svg');
      width: 22px;
      inset-inline-end: -19px;
    }

    .rtl-lang & {
      transform: translate(2px, -96px);

      @include l {
        transform: translate(10px, -110px);
      }

      &:before,
      &:after {
        transform: rotate(180deg);
      }
    }

    &__point {
      font-style: normal;
      font-weight: 700;
      font-size: 17.1477px;
      line-height: 17px;
      text-align: center;
      color: #FCD948;
    }

    &__desc {
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      color: $white-80;
    }

    &__slider {
      max-width: 133px;
    }

    &__subtext {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: $white-80;

    }
  }

  .bomani-holder {
    height: 150px;
    max-width: inherit;
    bottom: 36px;
    inset-inline-end: -55px;
    background-size: contain;
  }

  .bomani-helper {
    position: fixed;
    inset-inline-start: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fcd948;
    box-shadow: 0 4px 0 #785208;
    border-radius: 6px;
    padding: 20px 16px;
    line-height: 16px;
    width: 246px;

    display: flex;
    flex-direction: column;
    z-index: 12;

    .rtl-lang & {
      transform: translate(-50%, 50%);
    }
    & > span {
      @include text(12px, 16px, 700);
      display: block;
      color: #64321f;
      &.is-b {
        font-weight: 800;
        margin-bottom: 4px;
      }
      &.is-t {
        font-weight: 600;
        color: rgba(100, 50, 31, 0.7);
      }
      &.is-l {
        @include text(14px, 18px, 800);
        margin-bottom: 12px;
      }
      &.is-center {
        text-align: center;
      }
    }

    ul {
      list-style: disc;
      margin-left: 28px;
      margin-right: 14px;

      li {
        @include text(12px, 16px, 700);
        color: #64321f;
      }
    }

    &__close {
      position: absolute;
      top: -23px;
      inset-inline-end: -23px;
    }
    &__item {
      background: #f8f8f8;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      margin-bottom: 6px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 24px;
      padding-right: 12px;
      span {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-align: start;
        color: #999999;
        position: relative;
        &.is-done {
          color: #38b751;
          &:before {
            content: '';
            width: 12px;
            height: 13px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyLjUyMTciIHJ4PSI2IiBmaWxsPSIjMkE5NzA2Ii8+CjxyZWN0IHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgcng9IjYiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8xNDQ1MV8yNTIxMTYpIi8+CjxyZWN0IHg9IjEuMzA0MiIgeT0iMS4zMDQyIiB3aWR0aD0iOS4zOTEzIiBoZWlnaHQ9IjkuMzkxMyIgcng9IjQuNjk1NjUiIGZpbGw9InVybCgjcGFpbnQxX2xpbmVhcl8xNDQ1MV8yNTIxMTYpIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS40MDE1MSAzLjc5MzA0QzkuNzQ4NjQgNC4xNDAxOCA5Ljc0ODY0IDQuNzAyOTkgOS40MDE1MSA1LjA1MDEyTDYuNDE0MTkgOC4wMzc0NEM1Ljg5MzQ5IDguNTU4MTQgNS4wNDkyNyA4LjU1ODE0IDQuNTI4NTcgOC4wMzc0NEwzLjIzNjkxIDYuNzQ1NzhDMi44ODk3OCA2LjM5ODY0IDIuODg5NzggNS44MzU4MyAzLjIzNjkxIDUuNDg4N0wzLjI3MTA3IDUuNDU0NTRDMy42MTgyIDUuMTA3NCA0LjE4MTAyIDUuMTA3NCA0LjUyODE1IDUuNDU0NTRMNS4xNTcxMSA2LjA4MzVDNS4zMzA2OCA2LjI1NzA3IDUuNjEyMDkgNi4yNTcwNyA1Ljc4NTY1IDYuMDgzNUw4LjExMDI3IDMuNzU4ODhDOC40NTc0IDMuNDExNzUgOS4wMjAyMSAzLjQxMTc1IDkuMzY3MzUgMy43NTg4OEw5LjQwMTUxIDMuNzkzMDRaIiBmaWxsPSJ3aGl0ZSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzE0NDUxXzI1MjExNiIgeDE9IjYiIHkxPSIwIiB4Mj0iNiIgeTI9IjEyIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiNBMEZGMDAiLz4KPHN0b3Agb2Zmc2V0PSIwLjk1MDI3NiIgc3RvcC1jb2xvcj0iIzY4RjI4NCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MV9saW5lYXJfMTQ0NTFfMjUyMTE2IiB4MT0iNS45OTk4NSIgeTE9IjEuMzA0MiIgeDI9IjUuOTk5ODUiIHkyPSIxMC42OTU1IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiM0RUU3MDYiLz4KPHN0b3Agb2Zmc2V0PSIwLjk1MDI3NiIgc3RvcC1jb2xvcj0iIzM3Qjc1MSIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=');
            background-size: contain;
            position: absolute;
            inset-inline-start: -16px;
          }
        }
        &.is-not-done {
          color: #e87443;
          &:before {
            content: '';
            width: 12px;
            height: 13px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyLjUyMTciIHJ4PSI2IiBmaWxsPSIjQkY1OTJEIi8+CjxyZWN0IHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgcng9IjYiIGZpbGw9IiNFODc0NDMiLz4KPHJlY3QgeD0iMS4zMDQ2OSIgeT0iMS4zMDQyIiB3aWR0aD0iOS4zOTEzIiBoZWlnaHQ9IjkuMzkxMyIgcng9IjQuNjk1NjUiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02LjUgNi45MzI5M0g2Ljk0MDMxSDguNjA4OTRDOS4zMDM3IDYuOTMyOTMgOS4zMDM3IDUuMDY3MzggOC42MDg5NCA1LjA2NzM4TDYuOTQwMzEgNS4wNjczOEg2LjVINS41SDUuMDU4ODRMMy4zOTAyMSA1LjA2NzM4QzIuNjk1NDUgNS4wNjczOCAyLjY5NTQ1IDYuOTMyOTMgMy4zOTAyMSA2LjkzMjkzSDUuMDU4ODRINS41SDYuNVoiIGZpbGw9IiNFODc0NDMiLz4KPC9zdmc+Cg==');
            background-size: contain;
            position: absolute;
            inset-inline-start: -16px;
          }
        }
      }
    }
    .g-btn {
      align-self: center;
      &:not(:first-child) {
        margin-top: 16px;
        margin-bottom: 14px;
      }
    }
    &.focus {
      padding-top: 13px;
    }
  }

  #meter {
    height: 100%;
    width: 20px;
    background: #124174;
    box-shadow: inset 0 0.635008px 8.89011px 1.27002px #465C81;
    border-radius: 28px;
    position: absolute;
    top: 60px;
    margin-inline-start: 0;
    overflow: hidden;
    inset-inline-start: calc(50% - 229px);

    .rtl-lang & {
      inset-inline-start: calc(50% - 53px);

      @include l {
        inset-inline-start: calc(50% - 124px);
      }
    }

    @include l {
      inset-inline-start: calc(50% - 298px);
    }

    #meter-progress {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      height: 0;
      bottom: 0;
      inset-inline-start: 0;
      width: 100%;
      position: absolute;
      transition: height 1.5s;
      box-shadow: 0 10px 13px -6px rgba(44, 62, 80, 1);
      background: #ffe562;
      background: linear-gradient(90deg, #ffe562 0 3.12%, #fec314 50% 93.92%);

      &::after {
        background-image: repeating-linear-gradient(
          125deg,
          transparent,
          transparent 19px,
          $black-10 19px,
          $black-10 32px
        );

        animation: slide 4s linear infinite;
        will-change: background-position;
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 150%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-size: 20px;
        background-image: url($cdn + '/wazamba/renovation/flags/progress.png');
        @media (-webkit-max-device-pixel-ratio: 2) {
          background-image: url($cdn + '/wazamba/renovation/flags/progress@2x.png');
        }
        @media (-webkit-max-device-pixel-ratio: 3) {
          background-image: url($cdn + '/wazamba/renovation/flags/progress@3x.png');
        }
      }
      &::before {
        background-image: url('data:image/svg+xml;utf8,<svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse rx="2.95568" ry="2.28942" transform="matrix(-0.503793 0.863825 0.860074 0.510169 3.45805 4.2788)" fill="white"/><ellipse rx="1.08784" ry="0.851766" transform="matrix(-0.944967 0.327166 0.322249 0.946655 7.69719 1.16223)" fill="white"/></svg>');
        content: '';
        position: absolute;
        width: 9px;
        height: 8px;
        inset-inline-start: 3px;
        top: 5px;
      }
    }
  }

  .league-section {
    height: 184px;
    position: relative;
    display: flex;
    align-items: flex-start;
    transition: height 0.1s;

    &:before {
      content: '';
      position: absolute;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url($cdn + '/wazamba/renovation/leagues/league-level-point.svg');
      width: 32px;
      height: 33px;
      inset-inline-start: -87px;
      top: 40px;
    }

    @include l {
      height: 216px;

      &:before {
        top: 44px;
      }
    }

    &.is-period {
      .league-tile {
        cursor: inherit;
      }

      .title {
        cursor: inherit;
      }
    }
    &.current,
    .league-reached {
      .prize {
        display: none;
      }
    }

    &.unlog-curr {
      margin-top: 0;
    }

    &.current {
      .league-container {
        background: #FCD948;
        box-shadow: 0 1.9053px 0 #C98E00;

        padding-block: 12px 16px;
        padding-inline: 98px 38px;

        .title,
        .description {
          color: #745425;
        }

        .title {
          &:hover {
            text-decoration: underline;
          }
          &:after {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADOSURBVHgB3Y+xCoJQFIbPLaFGDR/ApSBpaEyneoMEH6RAaKxmiXwPA3uDaqnVhlpyuC8gtEbaTcFK5Fyqsf7l3nP4/g8OwM+njC0tszHW1dpaV2XYHsPN14K0nH277ySoQGtKEiGk85BoLZnuDuEeYwlwYpn1VVrOxjMh0LPdk1/kSjxBFF0NRoBmo3gD8EZ9RflYUIGqWNxdEI4riIXYIwyUHGg4S0qLnICVk/vnydN+bdjQdgMfY1FBksGzCmw6WwQOh8NPSEu58gT+O3dP0Dqvn4+DPAAAAABJRU5ErkJggg==');
          }
        }

        .level-point {
          color: rgba(55, 46, 5, 0.5);
          opacity: 1;
        }

        &.is-unlog {
          background: $black-25;
          box-shadow: none;

          .title {
            color: $white;
            margin-bottom: 8px;

            &:after {
              background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.4756 8.00003L7.63885 12.4762C7.09972 13.1052 6.15277 13.178 5.52378 12.6389C4.89479 12.0998 4.82195 11.1528 5.36108 10.5238L7.52435 8.00003L5.36108 5.47621C4.82195 4.84723 4.89479 3.90028 5.52378 3.36114C6.15277 2.82201 7.09972 2.89485 7.63885 3.52384L11.4756 8.00003Z" fill="white"/></svg>');
            }
          }
        }
      }

      &:before {
        background-image: url($cdn + '/wazamba/renovation/leagues/league-level-point-done.svg');
      }
      height: 172px;

      @include l{
        height: 204px;
      }

      &.league-section-8 {
        height: 190px;

        @include l {
          height: 216px;

          .league-container {
            padding-block: 12px 16px;
            padding-inline: 80px 38px;
          }
        }
        .level-point {
          display: none;
        }

        .avatar-progress {
          top: 0;
          inset-inline-end: -54px;
        }
      }
    }

    &.next {
      height: 266px;

      .league-container {
        margin-bottom: inherit;
      }

      @include l {
        height: 329px;
      }

    }
    &.prev {
      &:before {
        background-image: url($cdn + '/wazamba/renovation/leagues/league-level-point-done.svg');
      }
    }
    .league-tile {
      width: 154px;
      height: 167px;
      margin-bottom: -45px;
      background: url('#{$cdn}/wazamba/leagues/empty.png');
      background-image: url('#{$cdn}/wazamba/leagues/icon-league/closed.png');
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url('#{$cdn}/wazamba/leagues/icon-league/closed@2x.png');
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url('#{$cdn}/wazamba/leagues/icon-league/closed@3x.png');
      }
      background-size: contain;
      margin-inline-start: -54px;
      margin-inline-end: -46px;
      position: absolute;
      cursor: pointer;
      background-position: top center;
      background-repeat: no-repeat;
      &:after,
      &:before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s;
        background-size: contain;
      }

      .prize {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 100px;
        inset-inline-start: 88px;
        z-index: 1;

        @include l {
          top: 130px;
          inset-inline-start: 105px;
        }

        .is-icon-count {
          font-weight: 800;
          font-size: 12px;
          width: 23px;
          height: 22px;
          background: #9a4c04;
          border-radius: 50px;
          color: $white-90;
          box-shadow: 0 2px 0 #663100;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 8px;
          inset-inline-start: -4px;
        }
      }

      $leagues: '1', '2', '3', '4', '5', '6', '7', '8';

      @each $league in $leagues {
        &.league-tile-#{$league} {
          background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/closed-flag/#{$league}.png');
          @media (-webkit-max-device-pixel-ratio: 2) {
            background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/closed-flag/#{$league}@2x.png');
          }
          @media (-webkit-max-device-pixel-ratio: 3) {
            background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/closed-flag/#{$league}@2x.png');
          }

          background-size: 150px;
          &:after {
            background-size: 150px;
          }

          @include l {
            background-size: 178px;
            &:after {
              background-size: 178px;
            }
          }
        }

        &.league-tile-#{$league}:after {
          background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/open-flag/#{$league}.png');
          @media (-webkit-max-device-pixel-ratio: 2) {
            background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/open-flag/#{$league}@2x.png');
          }
          @media (-webkit-max-device-pixel-ratio: 3) {
            background-image: url('#{$cdn}/wazamba/renovation/flags/no-cropped/open-flag/#{$league}@2x.png');
          }

          background-size: 150px;

          @include l {
            background-size: 178px;
          }
        }
      }

      @include l {
        width: 178px;
        height: 204px;
        position: absolute;
      }

      &.league-tile-0 {
        background-size: 102px;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-weight: 700;
          font-size: 30px;
          line-height: 16px;
          text-align: center;
          color: #d0d4d8;
          transform: translate(2px, 0);

          .rtl-lang & {
            transform: translate(2px, 0) scaleX(-1);
          }
        }
      }

      &:before {
        opacity: 0;
        width: 280px;
        height: 200px;
        top: -30px;
        inset-inline-start: -65px;

        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('#{$cdn}/wazamba/leagues/shining.png');
        @media (-webkit-max-device-pixel-ratio: 2) {
          background-image: url('#{$cdn}/wazamba/leagues/shining@2x.png');
        }
        @media (-webkit-max-device-pixel-ratio: 3) {
          background-image: url('#{$cdn}/wazamba/leagues/shining@3x.png');
        }
      }
      &.league-active.league-reached:before {
        opacity: 1;
      }

      &.league-reached:after {
        opacity: 1;
        background-position: top center;
        background-repeat: no-repeat;
      }
    }

    .league-points {
      width: 120px;
      height: 24px;
      background: #2e3160;
      font-weight: 800;
      font-size: 12px;
      line-height: 12px;

      color: $white-40;
      padding-left: 8px;
      display: flex;
      align-items: center;
      margin-top: 46px;
      border-radius: 4px;

      &.is-transparent {
        opacity: 0;
      }
    }

    .avatar-progress {
      position: absolute;
      inset-inline-end: -30px;
      top: -40px;
      width: 155px;
      height: 155px;

      .rtl-lang & {
        transform: scaleX(-1);
      }
    }

    .league-container {
      width: 316px;
      height: fit-content;
      padding-block: 12px;
      padding-inline: 110px 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      background: $black-25;
      border-radius: 8px;
      min-height: 60px;
      column-width: 50%;
      margin-top: 30px;

      @include l {
        margin-top: 44px;
        margin-inline-start: 50px;
        width: 365px;
        padding-block: 20px 24px;
        padding-inline: 86px 10px;
      }

      & * {
        align-self: stretch;
        z-index: 1;
      }

      h2 {
        z-index: 11;
        margin: 0 0 2px 0;
        cursor: pointer;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: inherit;
        color: $white;
        position: relative;
        &::after {
          content: '';
          background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.4756 8.00003L7.63885 12.4762C7.09972 13.1052 6.15277 13.178 5.52378 12.6389C4.89479 12.0998 4.82195 11.1528 5.36108 10.5238L7.52435 8.00003L5.36108 5.47621C4.82195 4.84723 4.89479 3.90028 5.52378 3.36114C6.15277 2.82201 7.09972 2.89485 7.63885 3.52384L11.4756 8.00003Z" fill="white"/></svg>');
          width: 16px;
          height: 16px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        &:hover {
          text-decoration: underline;
        }

        .rtl-lang & {
          &:after {
            transform: rotate(180deg);
          }
        }

        @include l {
          font-size: 24px;
          line-height: 29px;
        }
      }

      .level-point {
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #DEDFFD;
        margin-top: 8px;
        opacity: .5;

        @include l {
          font-size: 18px;
          line-height: 18px;
        }
      }

      .description {
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        color: #dedffd76;
        span {
          &.is-start {
            color: #fcd948;
          }
        }

        .first-place {
          width: 86%;
        }

        .g-btn {
          align-self: flex-start;
          margin-top: 16px;
          width: fit-content;
          min-width: 202px;

          &.is-custom {
            box-shadow: none;
          }

          &.btn-con {
            width: fit-content;
            min-width: 138px;
          }
        }
        .points {
          margin-top: 4px;
        }
        .benefits {
          margin-top: 4px;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: #fcd948;

          @include l {
            font-size: 18px;
            line-height: 20px;
          }
        }
      }
    }

    &.is-pointer {
      height: inherit;
    }

    &.is-qualification {
      align-items: center;

      .league-tile{
        margin-bottom: 0;
      }

      .league-container {
        background: #5d6b77;
        margin-bottom: 0;
        padding-inline-start: 52px;
        margin-top: 0;
        width: 324px;

        @include l {
          padding-inline-start: 70px;
        }

        .description {
          color: $white;
          font-weight: 700;

          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    &.league-section-1 {
      .league-container {
        width: 306px;

        @include l {
          width: 365px;
        }

        .level-point {
          display: none;
        }
      }
    }
  }

  .league-sections-wrap {
    &.qualification-period {
      display: flex;
      flex-direction: column;
      max-width: 427px;
      padding-left: inherit;

      transform: translateX(-88px);

      .rtl-lang & {
        padding-inline-start: 0;
      }

      .league-pointer {
        flex-direction: column;
        width: 380px;
        padding-block: 20px;
        padding-inline: 24px 125px;
        position: relative;
        margin-bottom: 0;

        background: #FCD948;
        box-shadow: 0 2px 0 #C98E00;
        border-radius: 8px;

        &.is-qual {
          &:before {
            position: absolute;
            content: '';
            background-image: url($cdn + '/wazamba/leagues/qual-arrow.svg');
            background-position: center;
            background-repeat: no-repeat;
            width: 8px;
            height: 17px;
            inset-inline-start: -6px;
            bottom: 30px;
          }

          .rtl-lang & {
            &:before {
              transform: rotate(180deg);
            }
          }
        }

        &__text {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #64321F;
        }

        .is-small {
          font-weight: 800;
          font-size: 16px;
          line-height: 20px;
          color: #64321F;
          padding-top: 14px;
        }

        .g-btn {
          margin-top: 12px;
          min-width: 180px;
        }
        &:after {
          right: inherit;
          top: inherit;
          inset-inline-start: -7px;
          bottom: 20px;
          z-index: 4;
          box-shadow: -1px 2px 0 #c98e00;
          content: none;
        }
        .bomani-holder {
          height: 175px;
          bottom: inherit;
          top: -20px;
          transform: scale(1);
          position: absolute;

          .rtl-lang & {
            transform: scaleX(-1);
          }

          img{
            height: 100%;
          }
          &.has-shining{
            &::before{
              width: 314px;
              height: 224px;
              background-size: contain;
              background-position: center;
              margin-inline-start: -157px;
              margin-top: -112px;
            }
          }
        }
      }
      .league-tile {
        position: absolute;
        inset-inline-start: 70px;
        top: 0;
        background-size: 130px;

        &.league-tile-1{
          margin-bottom: 0;
          inset-inline-start: 90px;
          &::before{
            opacity: 1;
            width: 330px;
            height: 260px;
            top: -40px;
            inset-inline-start: -90px;
          }
        }

        &.is-period {
          cursor: inherit;
        }
      }

      .league-section-1{
        margin-top: 20px;

        .league-container{
          padding-block: 20px 24px;
          padding-inline: 90px 24px;

          @include l {
            padding-inline: 150px 20px;
          }
        }
      }

      .league-section {

        &.is-qualification {
          &:before {
            content: '';
            position: absolute;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url($cdn + '/wazamba/renovation/leagues/league-level-point.svg');
            width: 32px;
            height: 33px;
            inset-inline-start: 0;
            top: 90px;
            filter: grayscale(1);
          }
        }

        &.league-section-1 {
          &:before {
            content: '';
            position: absolute;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url($cdn + '/wazamba/renovation/leagues/league-level-point-done.svg');
            width: 32px;
            height: 33px;
            inset-inline-start: 0;
            top: 46px;
          }
        }

        &.is-pointer,
        &.league-section-1,
        &.is-qualification {
          justify-content: flex-end;
        }
        &:before {
          content: none;
        }
      }
    }
  }

  #qualification-meter {
    width: 20px;
    position: absolute;
    top: 90px;
    bottom: 110px;
    margin-inline-start: 0;
    overflow: hidden;

    background: #124174;
    box-shadow: inset 0 0.635008px 8.89011px 1.27002px #465C81;
    border-radius: 6px;

    inset-inline-start: calc(50% - 296px);

    .rtl-lang & {
      inset-inline-start: calc(50% - 119px);
    }

    @include l {
      bottom: 150px;
    }

    #qualification-meter-progress {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      height: 132px;
      bottom: 0;
      inset-inline-start: 0;
      width: 100%;
      position: absolute;
      transition: height 0.2s;
      box-shadow: 0 10px 13px -6px rgba(44, 62, 80, 1);
      background: #ffe562;
      background: linear-gradient(180deg, #ffe562 0 3.12%, #fec314 50% 93.92%);

      &::after {
        animation: slide 4s linear infinite;
        will-change: background-position;
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 150%;
        background-size: 20px;
        background-image: url($cdn + '/wazamba/renovation/flags/progress.png');
        @media (-webkit-max-device-pixel-ratio: 2) {
          background-image: url($cdn + '/wazamba/renovation/flags/progress@2x.png');
        }
        @media (-webkit-max-device-pixel-ratio: 3) {
          background-image: url($cdn + '/wazamba/renovation/flags/progress@3x.png');
        }
      }

      @include l {
        height: 126px;
      }
    }
  }
  .league-body {
    position: relative;
    margin-bottom: 120px;
  }
}
