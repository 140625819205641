$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.xmas-btn{
  position: absolute;
  top: 50px;
  left: -35px;

  width: 70px;
  height: 76px;
  background: url( $cdn + "/wazamba/christmas-game/bt-christmas-v1.svg") center top no-repeat;
  background-size: contain;
  border-radius: 35px;


  &__link{
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  body:not(.linda-is-christmas) &{ display: none;}

}
