$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  &__tournaments {
    margin-inline: var(--shop-margin-inline);

    &-empty {
      @include text(18px, 25px, 700, white);
      text-align: center;
      padding: 40px;
      background-color: $black-25;
      border: 1px solid rgba(130, 89, 72, 0.61);
      border-radius: 10px;
    }
  }

  &__dashboard {
    display: flex;
    flex-direction: column;

    &-title {
      @include title-gold-shadow(1px, 0px);
      @include text(16px, 19px, 700);
      letter-spacing: unset;
      padding-bottom: 12px;
      order: -4;
      margin-block: 0;
    }

    &-other {
      @include text(16px, 20px, 800, $white);
      padding-bottom: 24px;
      margin: 0;
      order: -2;
    }

    &-item {
      display: block;
      border: 1px solid #90583B;
      background: #6B412C;
      margin-bottom: 24px;
      border-radius: 11px;
      overflow: hidden;

      &.is-for-game {
        order: -3;
      }
    }

    &-content-wrap {
      padding: 16px;
      cursor: pointer;
      position: relative;
    }

    &-info {
      min-height: 124px;
      width: 75%;
      z-index: 1;
      position: relative;
    }

    &-hero {
      position: absolute;
      bottom: 0;
      inset-inline-end: 5px;
      height: 150px;

      @include l {
        inset-inline-end: 16px;
      }
    }

    &-info-title {
      @include text(18px, 22px, 800, $white);
      margin-bottom: 8px;
      text-wrap: balance;
    }

    &-details {
      @include text(12px, 17px, 700, $white);
      display: flex;
      align-items: center;
      margin-top: 6px;

      &-icon {
        width: 18px;
        margin-inline-end: 3px;
      }

      .is-prize {
        @include title-gold-shadow(1px, 0px);
        @include text(18px, 22px, 900);
      }

      strong {
        margin-inline-end: 12px;
      };

      span {
        @include text(12px, 17px, 600, $white-80);

        .timer {
          @include text(12px, 17px, 600, $white-80);
        }
      }

      .timer {
        letter-spacing: 0;
        direction: ltr;
      }
    }

    &-prize {
      display: inline-flex;
      direction: ltr;
    }
  }

  &__toggle {
    &-header {
      position: relative;
      min-height: 59px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      box-shadow: 0 4px 7px 0 $black-20;
      cursor: pointer;

      &:before {
        position: absolute;
        inset: 0;
        content: '';
        background-image: url($cdn + '/wazamba/game-page/header-pattern-bg.png');
        background-position: 50% 5%;
        background-repeat: no-repeat;
        background-size: 700px 400px;
        background-color: #826c65;
        background-blend-mode: soft-light;
      }

      &-icon {
        background-repeat: no-repeat;
        background-image: url($cdn + '/wazamba/shop/shop_arrow-right.svg');
        background-position: center;
        background-size: cover;
        width: 20px;
        height: 20px;
        transform: rotate(90deg);
        transition: transform .25s ease-in-out;
        position: absolute;
        inset-inline-end: 22px;
      }

      &.is-participant {
        &:before {
          background-color: #873A24;
          background-blend-mode: luminosity;
        }
      }

      &.is-open {
        .game-page__toggle-header-icon {
          transform: rotate(-90deg);
        }
      }

      &.is-open + .game-page__toggle-content {
        display: block;
      }

      &-text {
        @include text(12px, 17px, 800, $white);
        position: relative;
      }
    }

    &-content {
      display: none;
      padding-top: 24px;
    }

    &-user-info {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 250px;
    }

    &-user-title {
      @include text(12px, 17px, 700, $white);
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &-user-detail {
      @include text(16px, 20px, 900);
      @include title-gold-shadow();
      position: relative;
      display: flex;
      align-items: center;

      &.is-place {
        gap: 3px;

        &:after,
        &:before {
          display: block;
          content: '';
          background-image: var(--icon-leafLeft);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          width: 7px;
          height: 16px;
        }

        &:after {
          transform: scaleX(-1);
        }
      }
    }

    &-user-icon {
      width: 12px;
      margin-right: 3px;
    }

    &-see-all {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 0;
      min-width: 190px;
      width: fit-content;
      border-radius: 40px;
      border: none;
      background-color: $black-20;
      margin: 16px auto;

      span {
        @include title-gold-shadow();
        @include text(12px, 17px, 700);
      }
    }

    &-footer {
      @include text(12px, 17px, 700, $white);
      display: flex;
      align-items: center;
      background: rgba(53, 30, 23, 0.6);
      justify-content: center;
      min-height: 59px;
      cursor: pointer;

      &-text {
        position: relative;
        display: flex;
        align-items: center;

        &:after {
          content: '';
          display: block;
          background-repeat: no-repeat;
          background-image: url($cdn + '/wazamba/shop/shop_arrow-right.svg');
          background-position: center;
          background-size: cover;
          width: 20px;
          height: 20px;
          transform: rotate(-90deg);
          margin-inline-start: 8px;
        }
      }
    }

    &-empty-leaderboard {
      @include text(14px, 20px, 700, $white);
      padding: 24px 30px;
      border: 1px solid rgba(130, 89, 72, 0.61);
      background: $black-20;
      margin: 0 16px 32px;
      border-radius: 10px;
      text-align: center;
    }

    &-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px 24px;

      &-title {
        @include title-gold-shadow(1px, 0px);
        @include text(20px, 24px, 700);
        margin: 0;
        filter: none;
      }

      &-players {
        @include text(14px, 20px, 700, $white);
        margin: 0;
      }
    }
  }
}
