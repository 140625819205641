$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-total{
  position:absolute;
  bottom: 0;
  inset-inline-end: 0;
  display:flex;
  align-items: flex-end;

  &__key{
    background-image: linear-gradient(to top, $white, $yellow-200 7%, $yellow-500);
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.9px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

  }
  &__val{
    margin: 0 8px;
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
    letter-spacing: 1.8px;

  }
  &__img{
    width: 51px;
    height: 51px;

    margin-bottom: -7px;
    background-image: url( $cdn + '/wazamba/sprite-icons-2.png') , url( $cdn + '/wazamba/sprite-icons-3.png');
    background-size: 162px , 278px;
    background-position: -5px -131px , -49px 50%;
  }
}
