$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.crab-banner {
  &__layout {
    display: block;

    &.is-crab-tab-active {
      display: grid;
      gap: 12px;
      grid-template: 328px / 202px 328px 202px;
      align-items: center;
      justify-content: center;

      .crab-banner__banner {
        min-height: unset;
        padding: 12px 12px 40px;
        grid-column-start: 2;

        .crab-banner__wrapper {
          background-image: url("#{$cdn}/wazamba/shop/bc_small_bg.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          height: 228px;
          padding: 18px;

          .rtl-lang & {
            background-image: url("#{$cdn}/wazamba/shop/bc_small_bg-rtl.jpg");
          }
        }
      }

      .shop-crab-button {
        min-width: 140px;
        width: fit-content;
        position: absolute;
        bottom: -23px;
      }
    }
  }

  &__wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    height: 100%;
    border-radius: 8px 8px 16px 16px;
    border: 1px solid #00000066;
    box-shadow: 0 2px 6px 0 rgba(255, 195, 78, 0.40), 0 4px 24px 0 rgba(157, 29, 29, 0.40) inset;
    background-repeat: no-repeat, no-repeat;
    //padding: 18px 400px 20px 28px;
    padding-block: 18px 20px;
    padding-inline: 28px 400px;
    background-image: url("#{$cdn}/wazamba/shop/bc_art_bg2.png"), url("#{$cdn}/wazamba/shop/bc_big_bg.png");
    background-size: 466px 285px, cover;
    background-position: right -33px bottom -11px, center;

    .rtl-lang & {
      background-position: left -33px bottom -11px, center;
      background-image: url("#{$cdn}/wazamba/shop/bc_art_bg2-rtl.png"), url("#{$cdn}/wazamba/shop/bc_big_bg-rtl.jpg");
    }

    .shop-crab-button {
      font-family: Montserrat, sans-serif;
      min-width: 268px;
      text-transform: uppercase;
      margin-inline: auto;
      position: relative;
      bottom: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;

      > div {
        direction: ltr;
        display: flex;
      }

      img {
        width: 20px;
      }
    }

    @include l {
      padding: 18px 0 20px 0;
      background-image: url("#{$cdn}/wazamba/shop/bc_big_bg.png");
      background-size: cover;
      background-position: center;
      overflow: hidden;

      &:before {
        position: absolute;
        content: '';
        background-image: url("#{$cdn}/wazamba/shop/bc_art_bg.png");
        background-size: cover;
        background-position: center;
        width: 678px;
        height: 323px;
        inset-inline-end: 0;
        bottom: -56px;
        transform: translateX(50%) matrix(-1,0,0,1,0,0);
      }

      &:after {
        position: absolute;
        content: '';
        background-image: url("#{$cdn}/wazamba/shop/bc_art_bg.png");
        background-size: cover;
        background-position: center;
        width: 678px;
        height: 323px;
        inset-inline-start: 0;
        bottom: -56px;
        transform: translateX(-50%) matrix(-1,0,0,1,0,0);
      }
    }

    @include xl {
      &:before {
        transform: translateX(41%) matrix(-1,0,0,1,0,0);
      }

      &:after {
        transform: translateX(-41%) matrix(-1,0,0,1,0,0);
      }
    }

    &.is-cash-crab-tile {
      overflow: visible;

      &:before,
      &:after {
        content: none;
      }
    }

    .rtl-lang & {
      @include l {
        background-image: url("#{$cdn}/wazamba/shop/bc_big_bg-rtl.jpg");
        background-position: left 0 bottom -11px, center;

        &:before {
          transform: translateX(-50%) matrix(-1,0,0,1,0,0);
          background-image: url("#{$cdn}/wazamba/shop/bc_art_bg-rtl.png");
        }

        &:after {
          transform: translateX(50%) matrix(-1,0,0,1,0,0);
          background-image: url("#{$cdn}/wazamba/shop/bc_art_bg-rtl.png");
        }
      }

      @include xl {
        &:before {
          transform: translateX(-41%) matrix(-1,0,0,1,0,0);
        }

        &:after {
          transform: translateX(41%) matrix(-1,0,0,1,0,0);
        }
      }
    }
  }

  &__banner {
    background-image: url("#{$cdn}/wazamba/shop/bonus-backgrounds/freespin_wood-pattern.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    min-height: 356px;
    padding: 12px 12px 24px;
    border-radius: 20px;
  }

  &__logo-img {
    width: 148px;
    height: 84px;
  }

  &__title {
    line-height: normal;
    padding: 8px 16px;
    border-radius: 8px;
    background: rgba(39, 3, 86, 0.60);
    box-shadow: 0 1px 2px 0 rgba(17, 2, 35, 0.25) inset;
    margin: 0;
  }

  &__offers {
    display: grid;
    grid-template: auto / 128px 128px;
    column-gap: 16px;

    .crab-banner__offer {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &-logo {
        height: 50px;
        width: 50px;

        .rtl-lang & {
          transform: scaleX(-1);
        }
      }

      &-name {
        line-height: normal;
        margin: 0;
      }

      &::after {
        @include text(10px, 12px, 900, $white);
        content: attr(data-info);
        position: absolute;
        top: 4px;
        inset-inline-start: 76px;
        padding: 2px 4px;
        border-radius: 16px;
        background: #F00;
        box-shadow: 0 2px 0 0 $red-300;
      }
    }
  }

  &__info-btn {
    @include text(14px, 28px, 800, $white);
    content: 'i';
    position: absolute;
    top: 12px;
    inset-inline-end: 12px;
    width: 28px;
    background-color: $white-30;
    box-shadow: 0 0 8px 0 rgba(52, 13, 7, 0.20);
    border-radius: 14px;
    transition: .3s;

    &:hover {
      background-color: $white-60;
    }
  }

  &__prize-list {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 8px;
    height: min-content;
    border-radius: 12px;
    border: 1px solid $white-20;
    background: #134261;
    box-shadow: 0 2px 3px 0 rgba(109, 159, 255, 0.40) inset, 0 -4px 12px 0 rgba(5, 12, 39, 0.20) inset, 0 4px 8px 0 $black-20;

    h3 {
      font-size: 16px;
      margin: 0;
    }

    ul {
      @include text(14px, normal, 700, #6FADEC);
      text-shadow: 0 0 10px 0 $black-25;
      letter-spacing: 0.7px;
      list-style-position: inside;
      text-align: start;
      list-style-type: '- ';

      li {
        text-transform: capitalize;
      }
    }
  }
}
