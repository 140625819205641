$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-registration {
  padding-top: 80px;

  @include xxl {
    padding-top: 116px;
  }

  &__title {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ffcf01;
    text-shadow: 0 1px 0 #cc2500, 0 4px 4px $black-35;
    padding-bottom: 10px;
  }

  &__wrap {
    background: $black-40;
    padding: 16px;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.quick-registration-dropdown {
  border-radius: 20px;
  position: relative;
  z-index: 0;


  &__item {
    position: relative;
    width: 280px;
    background-color: rgba(193, 157, 102, 0.7);
    box-shadow: inset 0 0 6px 1px $black-20;
    border-radius: 15px;
    display: none;
    align-items: center;

    margin: 0 auto 12px;

    &.active {
      order: -1;
      display: flex;
      background-color: rgba(58, 20, 4, 0.7);
      box-shadow: inset 0 0 6px 1px $black;
    }
  }

  .quick-registration-promo {
    position: relative;
    display: none;

    &.is-active {
      order: -1;
      display: flex;
    }
  }

  &__bonus {
    width: 100%;
    padding: 5px 12px;
    display: flex;
  }
  
  &__bind {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &.open {
    z-index: 35;
    min-height: 82px;

    .quick-registration-promotions {
      position: absolute;
      height: unset;
    }

    .quick-registration-promo {
      display: flex;
      border-bottom: 1px rgba(0, 0, 0, 0.17) solid;
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.12);
      min-height: 52px;

      &:last-child {
        box-shadow: none;
      }

      &.is-active {
        &:after {
          opacity: 0;
        }
      }
    }
  }
}
