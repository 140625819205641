$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.crypto-stub {
  width: 480px;
  height: 506px;
  margin: auto;
  border-radius: 10px;
  background-color: $orange-100;

  color: $brown-300;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;

  text-align: center;
  letter-spacing: 0.05em;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &__icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-bottom: 20px;
    background-size: 16px;
    background-color: $brown-300;
    background-position: center;
    background-repeat: no-repeat;
  }
}
