$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dash-slider {
  position: relative;
  width: 780px;
  margin: 0 auto;
  &::after {
    content: "";
    width: 615px;
    height: 410px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: -615px;
    bottom: 0;
    transform: translateY(50px);
    background-position: right bottom;
    background-image: url($cdn + "/wazamba/wazamba-v01/dashboard/leaves-right.png");
    //display: none;
  }
  &::before {
    content: "";
    width: 396px;
    height: 386px;
    left: -366px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    background-position: left bottom;
    background-image: url($cdn + "/wazamba/wazamba-v01/dashboard/leaves-left.png");
    transform: translateY(43px);
    z-index: 0;
  }
  &__wrap {
    position: relative;
  }
  &__item {
    transition: all .2s linear;
    margin: 0 6px;
    display: flex !important;
    align-items: flex-end;
  }
  &__left {
    left: -6px;
  }
  &__right,
  &__left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  &__right {
    right: 0;
  }
  .slick-list {
    overflow: visible;
  }
  .slick-track {
    height: 236px;
  }
  .slick-slide {
    display: flex;
    align-items: flex-end;
    opacity: 0.5;
    transition: opacity .2s linear;
  }
  .slick-disabled {
    filter: grayscale(100%); cursor:default;
  }
  .slick-active {
    opacity: 1;
  }
  .slick-arrow:active:not(.g-btn-noclick) {
    transform: translateY(-50%);
  }
  @include l {
    width: 1000px;

    &::before {
      z-index: 1;
    }
    &__item {
      margin: 0 10px;
    }
    .slick-slide.slick-active + .slick-slide {
      opacity: 1;
    }
    &__rigth {
      right: -20px;
    }
  }
  @include xl {
    &__left {
      left: -37px;
    }
    &::after,
    &::before {
      transform: translateY(93px);
    }
    &__right {
      right: -39px;
    }
  }
  @include xxl {
    //max-width: 1098px;
    margin: 0 auto;
    &::before {
      left: -396px;
    }
    &__rigth {
      right: 213px;
    }
  }
}