$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-field {
  width: 100vw;
  height: 100%;
  position: relative;
  box-sizing: border-box;

  &__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #292929;
    display: flex;
    align-items: center;
    justify-content: center;

    & .preloader { background: none; }
    &__container { width: 100px; }
    &.ng-hide-add {
      transition: all 0.3s linear;
      opacity: 1;
    }

    &.ng-hide { opacity: 0; }
  }

  &__frame {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
  }

  & iframe,
  & object {
    border: none !important;
  }
}

iframe { border: none !important; }
