$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.g-btn,
.banner-btns .ab-btn {
  @include ellipsis;
  @include text(22px, 43px, 800, $white);
  box-sizing: border-box;
  display: inline-block;
  height: 52px;
  border-radius: 80px;
  cursor: pointer;
  max-width: 100%;
  border: 5px solid transparent;
  text-shadow: 0 0 10px $black-25;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1.2px;
  padding: 0 25px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;

  &:active:not(.g-btn-noclick):not(.shop-btn) {
    transform: translateY(2px);
  }

  &-f18 {
    font-size: 18px;
  }

  &-purple {
    background-color: $purple-200;
    background-image: linear-gradient($purple-200, $purple-200), linear-gradient($purple-100, $purple-100);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px $black-25, 0 4px 0 0 $purple-200;

    &:hover {
      box-shadow: inset 0 0 3px $black-25, 0 4px 0 0 $purple-200, 0 0 14px $purple-100;
      background-image: linear-gradient($blue-800, $blue-800), linear-gradient($purple-100, $purple-100);
    }

    &:active,
    &.active {
      background-image: linear-gradient($blue-800, $blue-800), linear-gradient($purple-100, $purple-100);
      box-shadow: inset 0 0 3px $black-25, 0 4px 0 0 $purple-200, 0 0 14px $purple-100;
    }

    &.is-funid {
      font-size: 14px;

      &:before {
        content: '';
        display: inline-block;
        vertical-align: bottom;
        width: 16px;
        height: 100%;
        margin-right: 12px;
        background-image: var(--icon-funid);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      &:active,
      &.active {
        background-image: linear-gradient($blue-800, $blue-800), linear-gradient($purple-100, $purple-100);
      }
    }

    &.is-center {
      display: block;
      margin: 0 auto;
    }

    &.in-promo {
      width: 224px;
    }
  }

  &-violet {
    background-color: $violet-600;
    background-image: linear-gradient($violet-600, $violet-600), linear-gradient($violet-300, $violet-300);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px $black-25, 0 4px 0 0 rgba(104, 0, 183, 1), 0 0 14px #c77dff;

    &:hover {
      box-shadow: inset 0 0 3px $black-25, 0 4px 0 0 #6704b3, 0 0 14px #c77dff;
      background-image: linear-gradient(#800cd9, #5e07a0), linear-gradient($violet-300, $violet-300);
    }

    &:active,
    &.active {
      background-image: linear-gradient(#800cd9, #5e07a0), linear-gradient($violet-300, $violet-300);
      box-shadow: inset 0 0 3px $black-25, 0 0 14px #c77dff;
    }
  }

  &-red {
    background-color: #f13316;
    background-image: linear-gradient(#f03316, #f13316), linear-gradient(#fa8472, #fa8472);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px $black-25, 0 4px 0 0 #af1700, 0 0 20px #fa8472;

    &:hover {
      box-shadow: inset 0 0 3px $black-25, 0 4px 0 0 #b11c05, 0 0 14px #fa8472;
      background-image: linear-gradient(#e2280b, #b11c05), linear-gradient(#fa8472, #fa8472);
    }

    &:active,
    &.active {
      background-image: linear-gradient(#e2280b, #b11c05), linear-gradient(#fa8472, #fa8472);
      box-shadow: inset 0 0 3px $black-25, 0 0 0 0 #b11c05, 0 0 14px #fa8472;
    }
  }

  &-yellow {
    color: #262626;
    text-shadow: none;
    background-color: $yellow-800;
    background-image: linear-gradient($yellow-800, $yellow-800), linear-gradient($yellow-300, $yellow-300);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px $black-25, 0 4px 0 0 #e8a701, 0 0 20px rgba(255, 253, 216, 0.6);

    &:hover {
      box-shadow: inset 0 0 3px $black-25, 0 4px 0 0 #ffb800, 0 0 14px $yellow-300;
      background-image: linear-gradient($yellow-800, #ffb800), linear-gradient($yellow-300, $yellow-300);
    }

    &:active,
    &.active {
      background-image: linear-gradient($yellow-800, #ffb800), linear-gradient($yellow-300, $yellow-300);
      box-shadow: inset 0 0 3px $black-25, 0 0 0 0 #e8a701, 0 0 20px rgba(255, 253, 216, 0.6);
    }
  }

  &-green {
    --green-shadow-depth: 4px;
    background-color: $green-500;
    background-image: linear-gradient($green-500, $green-500), linear-gradient($green-400, $green-200);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px 0 $black-25, 0 var(--green-shadow-depth) 0 0 $green-600, 0 2px 15px 3px $green-400;

    &:hover {
      background-image: linear-gradient(to top, $green-700, $green-500), linear-gradient($green-300, $green-100 95%);
    }

    &:active,
    &.active {
      box-shadow: inset 0 0 3px 0 $black-25, 0 2px 10px 2px $green-400;
      background-image: linear-gradient(to top, $green-500, $green-700), linear-gradient($green-300, $green-100 95%);
    }

    &.no-glow {
      box-shadow: inset 0 0 3px 0 $black-25, 0 var(--green-shadow-depth) 0 0 $green-600;

      &:active,
      &.active {
        box-shadow: inset 0 0 3px 0 $black-25;
      }
    }

    &.is-small {
      @include text(14px, 26px);
      height: 32px;
      padding: 0 15px;
      border: 4px solid transparent;
      --green-shadow-depth: 3px;
    }
  }

  &-blue {
    background-color: $blue-500;
    background-image: linear-gradient($blue-500, $blue-500), linear-gradient(#a3deff, #00eded);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $blue-600, 0 2px 15px 3px $blue-500;

    &:hover {
      background-image: linear-gradient(to top, $blue-700, $blue-500), linear-gradient($blue-100, $blue-300 95%);
    }

    &:active,
    &.active {
      box-shadow: inset 0 0 3px 0 $black-25, 0 2px 10px 2px $blue-500;
      background-image: linear-gradient(to top, $blue-500, $blue-700), linear-gradient($blue-100, $blue-300 95%);
    }
  }

  &-brown-icon {
    background-color: $orange-1000;
    background-image: linear-gradient(#8C5F51, #8C5F51), linear-gradient(180deg, #D09A8D 0%, #A56B56 95.03%);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px 0 $black-25, 0 3px 0 0 #3F1A11;

    &:hover {
      background-image: linear-gradient($brown-200, $brown-200), linear-gradient(#B57862, #D09A8D 95%);
    }

    &:active,
    &.active {
      box-shadow: inset 0 0 3px 0 $black-25;
    }

    &.is-small {
      @include text(14px, 26px);
      height: 32px;
      padding: 0 15px;
      border: 4px solid transparent;
    }
  }

  &-orange {
    background-color: $orange-1000;
    background-image: linear-gradient($orange-1000, $orange-1100), linear-gradient($orange-700, $orange-500);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $orange-1200;

    &:hover {
      background-image: linear-gradient(to top, #ff4d00, $orange-1000), linear-gradient($orange-600, $orange-400 95%);
    }

    &:active,
    &.active {
      box-shadow: inset 0 0 3px 0 $black-25;
      background-image: linear-gradient(to top, $orange-1000, #ff4d00), linear-gradient($orange-600, $orange-400 95%);
    }
  }

  &-pink {
    background-color: $pink-300;
    background-image: linear-gradient($pink-200, $pink-300), linear-gradient($pink-100, #fddcfa);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $pink-400;

    &:hover {
      box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $pink-400, 0 1px 10px 0 $pink-500;
    }
  }

  &-brown {
    box-shadow: inset 0 0 10px $black-25;
    background-color: $orange-100;
    color: $brown-300;
    border: 0;

    &:hover,
    &.active {
      background-color: $brown-300;
      color: $white;
    }
  }

  &-brown-stripe {
    color: $brown-300;
    border: 2px solid $brown-300;
    background: #e7c37f;
    font-family: Montserrat, sans-serif;

    &.is-funid {
      background-image: var(--icon-btn-funid);
      background-repeat: no-repeat;
      background-position: 19px 50%;

      &.is-center {
        margin: 0 auto;
        display: block;
      }

      &.in-promo {
        line-height: 42px;
      }
    }

    &:hover {
      box-shadow: inset 0 0 3px 0 $black-25, 0 2px 0 0 $brown-300, 0 1px 10px 0 $brown-300;
    }
  }

  &-80 {
    @include text(24px, 60px);
    padding: 0 30px;
    height: 80px;
    border: 10px solid transparent;

    &:active {
      transform: translateY(4px);
    }
  }

  &-60 {
    @include text(18px, 60px);
    letter-spacing: 0.05em;
    height: 60px;
  }

  &-56 {
    @include text(24px, 46px);
    height: 56px;
    letter-spacing: 0.05em;
  }

  &-46 {
    @include text(18px, 36px);
    height: 46px;
    padding: 0 15px;
    letter-spacing: 0.9px;

    &.is-funid {
      padding: 0 8px;
    }
  }

  &-38 {
    @include text(16px, 32px);
    height: 38px;
    padding: 0 15px;
    border: 4px solid transparent;
    font-family: Montserrat, sans-serif;
  }

  &-32 {
    @include text(16px, 26px);
    height: 32px;
    padding: 0 15px;
    border: 4px solid transparent;
  }

  &-varh {
    @include l {
      @include text(24px, 60px);
      padding: 0 30px;
      height: 80px;
      border: 10px solid transparent;

      &:active {
        transform: translateY(4px);
      }
    }
  }

  &-med {
    @include xl {
      @include text(24px, 60px);
      padding:0 30px;
      height: 80px;
      border: 10px solid transparent;

      &:active {
        transform: translateY(4px);
      }
    }
  }

  &-med-l{
    @include xxl{
      @include text(22px, 60px);
      padding:0 30px;
      height: 80px;
      border: 10px solid transparent;

      &:active {
        transform: translateY(4px);
      }
    }
  }

  &-full {
    width: 100%;
    display: block;
  }

  &-ico {
    width: 45px;
    height: 45px;
    padding: 0;

    &.g-btn-green {
      box-shadow: inset 0 0 3px 0 $black-25, 0 2px 0 0 $green-600;

      &:active {
        box-shadow: inset 0 0 3px 0 $black-25;
      }
    }

    &.g-btn-blue {
      box-shadow: inset 0 0 3px 0 $black-25, 0 2px 0 0 $blue-600;

      &:active {
        box-shadow: inset 0 0 3px 0 $black-25;
      }
    }

    &.g-btn-orange {
      box-shadow: inset 0 0 3px 0 $black-25, 0 2px 0 0 $orange-1200;

      &:active {
        box-shadow: inset 0 0 3px 0 $black-25;
      }

      .ng-pending & {
        filter: none;
      }
    }

    &.g-btn-pink {
      box-shadow: inset 0 0 3px 0 $black-25, 0 2px 0 0 $pink-400;

      &:hover {
        box-shadow: inset 0 0 3px 0 $black-25, 0 2px 0 0 $pink-400, 0 1px 10px 0 $pink-500;
      }

      &:active {
        box-shadow: inset 0 0 3px 0 $black-25, 0 1px 10px 0 $pink-500;
      }

      &.f_active {
        box-shadow: inset 0 0 3px 0 $black-25, 0 1px 0 0 $pink-400, 0 1px 10px 2px $pink-500;
      }
    }

    & svg {
      fill: $white;
      width: 100%;
      height: 100%;
    }
  }

  &-ico-90d svg {
    transform: rotate(90deg);
  }

  &-ico-180d svg {
    transform: rotate(180deg);
  }

  &-ico-270d svg {
    transform: rotate(-90deg);
  }

  &-ico-r {
    border-radius: 3px;
    height: 32px;
    border: 4px solid transparent;

    &:active {
      transform: translateY(1px);
    }
  }

  &-ico-r48 {
    height: 48px;
    border: 6px solid transparent;
  }

  &-ico-23 {
    width: 23px;
    height: 23px;
    padding: 0;
    border-width: 3px;
    border-radius: 50%;
    overflow: visible;
  }

  &-ico-33 {
    width: 33px;
    height: 33px;
    padding: 0;
    border-width: 3px;
    border-radius: 50%;
    overflow: visible;
  }

  &-refresh.linda-has-preloader svg {
    animation: rotate360 1s infinite;
  }

  &-link {
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold;
  }

  &-br-5 {
    border-radius: 5px;
  }

  &.disabled,
  &.is-disable,
  &:disabled,
  .ng-pending & {
    filter: grayscale(1);
    cursor: default;

    &.swiper-button-disabled {
      display: inline-block;
    }
  }
  

  &-f15{ font-size: 15px; }
}

.g-btn.g-btn-brown-stripe.is-funid {
  font-size: 16px;
  font-weight: 700;
  padding-left: 58px;
  padding-right: 20px;
}

.g-btn-completed {
  pointer-events: none;
}

.g-btn-adaptive {
  @include text(18px, 36px);
  height: 46px;
  font-family: Montserrat, sans-serif;
  padding: 0 15px;
  letter-spacing: 0.9px;
  min-width: 130px;

  @include xxl {
    @include text(24px, 43px);
    height: 52px;
    letter-spacing: 1.2px;
  }
}

.g-btn-fav {
  background-color: $orange-1000;
  background-image: var(--icon-btn-big-star), linear-gradient(#8C5F51, #8C5F51), linear-gradient(180deg, #D09A8D 0%, #A56B56 95.03%);
  box-shadow: inset 0 0 3px 0 $black-25, 0 2px 0 0 #3F1A11;
  background-clip: padding-box, padding-box, border-box;
  background-size: 72%, cover, calc(100% + 20px) calc(100% + 20px);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: center, center top, left -10px top -10px;

  &:hover {
    background-image: var(--icon-btn-big-star), linear-gradient($brown-200, $brown-200), linear-gradient(#B57862, #D09A8D 95%);
  }

  &:active {
    box-shadow: inset 0 0 3px 0 $black-25;
  }

  &.f_active {
    background-color: $pink-300;
    background-image: var(--icon-btn-big-star-checked), linear-gradient($pink-200, $pink-300), linear-gradient(#ffe5f6, #fccaf8);
    box-shadow: inset 0 0 3px 0 $black-25, 0 2px 0 0 $pink-400;

    &:hover {
      background-image: var(--icon-btn-big-star-checked), linear-gradient(#ef67f5, #ed33f1), linear-gradient(#fff1f9, #f8ecf5 95%);
    }

    &:active {
      box-shadow: inset 0 0 3px 0 $black-25;
    }
  }
}
