$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
*:not(:is(.perspective))::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: transparent
}

*:not(:is(.perspective))::-webkit-scrollbar {
  width: 0px;
  background-color: transparent
}
