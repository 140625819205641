$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.autobonus-popup {
  display: flex;
  flex-direction: column;
  gap: 0;
  text-align: center;
  width: 312px;
  margin: 0 auto;

  &__activate-btn {
    margin-inline: auto;
  }

  &__activate-btn,
  &__expritation,
  &__date {
    z-index: 2;
  }

  &__date {
    direction: ltr;
  }

  .description-container {
    position: relative;
    z-index: 2;
  }

  &__bonus-single {
    margin-bottom: 16px;
    margin-top: 8px;

    .autobonus-popup {
      &__expritation {
        @include text( 14px, 17px, 800, $white );
        margin-top: 14px;
        order: 2;
        margin-bottom: 0;
      }

      &__date {
        @include text( 16px, 19px, 800, $white );
        margin-top: 4px;
        margin-bottom: 0;
        order: 2;
      }

      &__activate-btn {
        margin-top: 48px;
        order: 3;
      }
    }
  }

  &__bonus-multiple {
    margin-bottom: 6px;

    .autobonus-popup {
      &__expritation {
        @include text( 12px, 14px, 800, rgba(255, 255, 255, .6) );
        margin-top: 17px;
        order: 3;
        margin-bottom: 4px;
      }
      &__date {
        @include text( 14px, 17px, 800, rgba(255, 255, 255, .9) );
        margin-top: 0;
        order: 3;
      }
      &__activate-btn {
        order: 2;
        margin-top: 20px;
      }
    }
  }
}

.has-orange-btn {
  .autobonus-popup__bonus-multiple {
    .autobonus-popup__activate-btn {
      @extend .g-btn-orange;
    }
  }
}
