$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.league-faq {
	.league__wrap {
		max-width: 534px;
		margin: 0 auto 120px;
		padding: 20px 32px;
		position: relative;
		z-index: 1;
		transform: translateX(-88px);

		background: rgba(0, 12, 23, 0.3);
		border-radius: 16px;
	}

	h1 {
		@include text(20px, 24px, 800);
		text-align: center;
		letter-spacing: 0.05em;
		color: #ffd467;
		margin: 0;
		padding: 16px 0 12px 0;
	}

	h2 {
		@include text(16px, 27px, 700);
		color: #ffd467;
		margin: 0;
		text-align: start;
		position: relative;
		padding: 12px 0 6px 0;
		& > span {
			display: inline-block;
			position: static;
			width: 30px;
		}

		&.league-faq-coins {
			align-items: center;
			background-color: rgba(255, 255, 255, 0.12);
			border-radius: 8px;
			display: inline-flex;
			margin-top: -8px !important;
			margin-bottom: 24px !important;
			padding: 0;
			&::before {
				content: '';
				width: 47px;
				height: 42px;
				background-image: url(#{$cdn}/wazamba/leagues/terms/icon.png);
				@media (-webkit-max-device-pixel-ratio: 2) {
					background-image: url(#{$cdn}/wazamba/leagues/terms/icon@2.png);
				}
				@media (-webkit-max-device-pixel-ratio: 3) {
					background-image: url(#{$cdn}/wazamba/leagues/terms/icon@3.png);
				}
				display: inline-block;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				position: absolute;
				inset-inline-start: 16px;
				top: 50%;
				margin-top: -23px;
			}
		}

		&.league-faq-cash {
			align-items: center;
			justify-content: flex-end;
			display: inline-flex;
			margin-top: -8px !important;
			margin-bottom: 8px;
			padding: 0;

			&:before {
				inset-inline-start: 18px;
			}
		}
	}
	p {
		@include text(16px, 20px, 500);
		color: #ffffff;
		display: block;
		text-align: start;
		margin: 0;
		padding-inline-start: 30px;
		padding-bottom: 20px;

		&.league-faq-qualification {
			box-sizing: border-box;
			position: relative;
			width: 226px;
			height: 46px;
			background: #5d6b77;
			border-radius: 4px;
			display: flex;
			align-items: center;
			@include text(12px, 16px, 700);
			color: #ffffff;
			margin-inline: 74px 0;
			margin-block: 0 24px;
			padding-block: 0;
			padding-inline: 32px 4px;

			.rtl-lang & {
				&:before {
					transform: scaleX(-1);
				}
			}

			&::before {
				content: '';
				width: 100px;
				height: 100px;
				background-image: url(#{$cdn}/wazamba/leagues/icon-league/closed.png);
				@media (-webkit-max-device-pixel-ratio: 2) {
					background-image: url(#{$cdn}/wazamba/leagues/icon-league/closed@2x.png);
				}
				@media (-webkit-max-device-pixel-ratio: 3) {
					background-image: url(#{$cdn}/wazamba/leagues/icon-league/closed@3x.png);
				}
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				inset-inline-start: -60px;
				top: 50%;
				margin-top: -50px;
			}
			&::after {
				content: '?';
				@include text(30px, 30px, 700);
				text-align: center;
				color: #d0d4d8;
				width: 100px;
				height: 100px;
				position: absolute;
				inset-inline-start: -60px;
				top: 50%;
				margin-top: -50px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-inline-start: 5px;
			}

			.rtl-lang & {
				&:after {
					transform: scaleX(-1);
				}
			}
		}
	}

	ul {
		@include text(16px, 20px, 500);
		padding-inline: 30px 0;
		padding-block: 0 12px;
		li {
			display: flex;
			padding-bottom: 4px;
		}
	}
	a {
		color: #ffd467;
		display: inline;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.screen.has-game-bg {

		background: center / 298px url($cdn + '/wazamba/renovation/league-promo/bg-pattern.png'), linear-gradient(90deg, #181A3D 0%, #1E214C 54.17%, #181A3D 100%);
		@media (-webkit-max-device-pixel-ratio: 2) {
			background: center / 298px url($cdn + '/wazamba/renovation/league-promo/bg-pattern@2x.png'), linear-gradient(90deg, #181A3D 0%, #1E214C 54.17%, #181A3D 100%);
		}
		@media (-webkit-max-device-pixel-ratio: 3) {
			background: center / 298px url($cdn + '/wazamba/renovation/league-promo/bg-pattern@3x.png'), linear-gradient(90deg, #181A3D 0%, #1E214C 54.17%, #181A3D 100%);
		}
		background-size: 298px, cover;
	}
}
