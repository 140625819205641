$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.g-or{
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #6E3923;
  margin: 20px 0 15px 0;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  span{
    padding: 0 10px;
  }
    &:after,
    &:before{
      background: #6E3923;
      width: 100%;
      height: 1px;
      content: " ";
    }
}
