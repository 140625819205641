$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
$imgLinks : url('data:image/svg+xml;base64,PHN2ZyAgdmlld0JveD0iMCAwIDM3NiA0OCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMDkuNjY3IDAuNjgxOTE4TDE1MC45NzggMC42ODE5MTZMMTUwLjc4OSAwTDE2Ni43OCAwLjY4MTkxNUwxODUuNDYyIDAuNjgxOTE0TDE5MC41MDEgMC4yMzgxNjdMMTkxLjg1MyAwLjY4MTkxNEgxOTIuNzIxVjIuODk5MzZMMTkzLjY2NyAxLjE5MDg0TDE5NS42NDYgMC43MTQ1MDJIMjQxLjk1NUwyNDMuNTM4IDEuMTkwODRIMjQ0Ljg1OEwyNDYuMTc3IDAuNzE0NTAySDI0OC4xNTZMMjUwLjAwMyAxLjkwNTM0SDI1MS44NUwyNTUuOTQgMS4xOTA4NEwyNTcuMzkxIDAuNDc2MzM1TDMwMC43OTggMC43MTQ1MDJMMzAzLjA0MSAxLjkwNTM0TDMwNC44ODggMi42MTk4M0wzMDUuNTQ4IDMuNTcyNUgzMDcuMTMxQzMwOC4wOTggNC42ODM5NSAzMTAuMDA3IDYuODU5MjEgMzA5LjkwMSA2LjY2ODY4QzMwOS43OTYgNi40NzgxNCAzMDcuNTcxIDMuNDEzNzMgMzA2LjQ3MSAxLjkwNTM0VjAuNzE0NTAyTDMwNy4yNjMgMC40NzYzMzVMMzU2Ljc2MyAwLjIzODE2N0wzNzYgMjkuNjgyNlYzNy4yTDM3MS45OTQgMzUuNEwzNzYgNDAuMlY0OEgwLjE2NDUyM0wwLjE4NjYzOSA0MS40MzY1TDEuMTE5ODMgMzguNTc0NEwxLjY3OTc1IDM2LjIxNzNMMi40MjYzIDM1LjM3NTZWMzMuMzU1M0MzLjI5NzI4IDMyLjEyMDYgNS4wMDE5MSAyOS42ODUgNC44NTI2IDI5LjgxOTdDNC43MDMyOSAyOS45NTQ0IDIuMzAxODcgMzIuNzk0MSAxLjExOTgzIDM0LjE5NzFIMC4xODY2MzlMMCAzMy4xODY5VjI2LjYyMzZMMTYuOTkzMyAxLjUzNTMxTDU0LjQyMTcgMC42ODE5MjFMNTUuNzI4MiAxLjE4N0w2MC4zOTQyIDEuNjkyMDhMNjIuMDczOSAxLjY5MjA4TDYxLjcwMDYgMC42ODE5MjFMMTAyLjAxNSAwLjY4MTkxOUwxMDMuNjk0IDEuMTg3TDEwNS41NjEgMS42OTIwOEwxMDcuNDI3IDEuNTIzNzFMMTA4LjkyIDEuMTg3TDEwOS42NjcgMC42ODE5MThaIiAvPgo8L3N2Zz4K') ;


.home-games{
  padding:50px 250px 50px 20px;
  background-image: url( $cdn + "/wazamba/page-bg-2.jpg");
  background-repeat: no-repeat;
  &__top{
    display:flex;
    justify-content: center ;
    align-items: center;
    flex-wrap: wrap;
  }
  &__category{
    flex-shrink: 0;
    order:2;
  }
  &__search{
    width: 746px;
    order:1;

  }
  &__btn{
    text-align: center;
    padding:30px 0;
  }
  &__gamebox{
    margin-top:20px;
    display:block;
  }



  @include l{
    &__top{ flex-wrap: nowrap; }
    &__category{ order:1;}
    &__search{
      max-width: 400px;
      order:2;
    }
  }
}





.home-events{
  margin:-12px 0 40px;
  display:block;
}



.home-links2 {
  margin:0 auto;
  display: flex;
  padding-top: 20px;
  max-width: 1210px;
  justify-content: center;

  &__item {
    position: relative;
    width: calc(33.334% - 20px);
    padding-top:23%;
    margin: 0 10px;
    &::before,
    &::after{
      position:absolute;
      content:'';
      display:block;
      width: 100%;
      height: 100%;
      left: 0;
      mask: url( $cdn + '/wazamba/desktop/img/mask_home1.svg') 100% 100% no-repeat;
    }
    &::before{ background-color: $blue-1000; z-index: 10; top: 5px;}
    &::after{ background-color: #10294f;  z-index: 11; top: 0;}
    &:hover{
      &::after{ background-image: radial-gradient(circle at 50% 50%, $violet-500, $violet-700); }
      &::before{ background-color:#2A0935;}
    }
  }
  &__ico{
    position:absolute;
    width: 81px;
    height: 81px;
    top: -30px;
    left: calc(50% - 40px);
    background-image: url( $cdn + "/wazamba/sprite-icons-2.png") ,  url( $cdn + "/wazamba/sprite-icons-2.png");
    background-size: 217px , 217px;
    transition:0.2s;
    z-index: 25;
    &_1{ background-position: -1px -2px ,  -3px -255px; }
    &_2{ background-position: 0 -87px ,  -3px -255px; }
    &_3{ background-position: -2px -171px ,  -3px -255px; }
    .home-links2__item:hover &{ transform: scale(1.3);}
  }
  &__content{
    position:absolute;
    top: 60px;
    height: calc(100% - 60px);
    padding:0 20px 20px;
    z-index: 21;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title{
    font-size: 18px;
    font-weight: 800;
    color: $yellow-700;
    text-align: center;
  }
  &__text{
    padding-top:15px;
    font-weight: 500;
    line-height: 1.52;
    letter-spacing: 0.7px;
    color: $white;
    font-size: 13px;
  }

  @include l{
    &__item {
      padding-top:11.5%;
      &::before,
      &::after{ mask: url( $cdn + '/wazamba/desktop/img/mask_home3.svg') 100% 100% no-repeat; }
    }
    &__ico{
      left: 10px;
      top: calc(50% - 50px);
      width: 98px;
      height: 98px;
      background-size: 267px , 267px;
      transition:0.2s;
      z-index: 20;
      &_1{ background-position: -1px -5px ,  -3px -315px; }
      &_2{ background-position: 0 -105px ,  -3px -315px; }
      &_3{ background-position: -2px -210px ,  -3px -315px; }

    }
    &__content{
      top: 0;
      left:100px;
      padding: 15px 20px;
      height: 100%;
    }
    &__title{ text-align: left}
    &__text{ padding-top:5px; }
  }

}
