$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.is-leaderbord {
  width: 526px;
}

.leaderbord-popup {
  padding: 45px 0 40px;
  margin: 0 auto;
  width: 406px;

  .rtl-lang & {
    direction: rtl;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.03em;
    color: $white;

    span {
      display: inline-block;
      margin: 0 2px;
    }
  }

  &__wrap {
    display: grid;
    gap: 12px;
    padding-top: 24px;
    margin-inline-start: -28px;
    padding-inline-start: 28px;

    &.is-sport {
      height: 780px;
      overflow-y: auto;
    }
  }

  &__item {
    display: grid;
    align-items: center;
    grid-template-columns: 30px 90px 1fr 1fr;

    background: $black-25;
    box-shadow: inset 0 4px 4px $black-25;
    border-radius: 5px;
    padding: 0 16px 0 12px;
    height: 60px;

    &.position,
    &.is-self {
      margin-top: 12px;
      box-shadow: 0 0 6.84883px rgba(222, 224, 61, 0.5);
      background: url('#{$cdn}/wazamba/tournament/self_bg.png') #F4CA33 center center no-repeat;

      .leaderbord-popup__num,
      .leaderbord-popup__prize,
      .leaderbord-popup__info{
        color: #725500;
      }
    }

    &.is-self {
      .leaderbord-popup__num {
        color: unset;
      }
    }

    &.is-0,
    &.is-1,
    &.is-2{
      height: 76px;

      .leaderbord-popup__num {
        font-size: 40px;
        line-height: 49px;

        &::before {
          content: "";
          position: absolute;
          width: 120px;
          height: 60px;
          transform: rotate(-7deg);
          background-image: url($cdn + "/wazamba/wazamba-v01/tournaments/tournament_label_top3.svg");
          inset-inline-start: -45px;
          top: -4px;
          z-index: -1;
          background-size: cover;
          background-repeat: no-repeat;
        }

        .rtl-lang & {
          &::before {
            transform: scale(-1, 1) rotate(7deg);
          }
        }
      }
      .leaderbord-popup__prize {
        color: $white;
      }
      .leaderbord-popup__img {
        margin-top: -30px;
        margin-inline-start: -5px;
        width: 108px;
        height: 108px;
        object-fit: cover;
      }
    }
  }

  &__info {
    font-size: 14px;
    line-height: 17px;
    color: $white;
  }

  &__name {
    direction: ltr;
    font-weight: 700;

    .rtl-lang & {
      text-align: end;
    }
  }

  &__score {
    font-weight: 800;
    padding-top: 5px;
  }

  &__num {
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    display: flex;
    align-items: center;
    color: $white;
    text-shadow: 0 1px 0 $orange-900;
    position: relative;
    z-index: 0;
  }

  &__prize {
    margin-inline-start: auto;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-shadow: 0 1px 0 $orange-900;
    color: $yellow-200;
    position: relative;

    &.has-coins {
      padding-inline-end: 18px;

      &:after {
        content: '';
        width: 16px;
        height: 16px;
        background-image: url($cdn + "/wazamba/sprite-tournament-page.png");
        background-size: 115px;
        background-position: 0 -33px;
        position: absolute;
        inset-inline-end: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__img {
    position: relative;
    margin-top: -28px;
    margin-inline-start: -6px;
  }
}
