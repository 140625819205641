$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.price-tag {
  height: 31px;
  min-width: 84px;
  position: relative;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: $white;
  padding: 5px 5px 5px 25px;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;


  &--violet {
    background-image: url( $cdn + '/wazamba/shop/icon-el-new.svg');

    .rtl-lang & {
      background-image: url( $cdn + '/wazamba/shop/icon-el-new-rtl.svg');
    }
  }

  &--gray {
    background-image: url( $cdn + '/wazamba/shop/icon-el-new.svg');

    .rtl-lang & {
      background-image: url( $cdn + '/wazamba/shop/icon-el-new-rtl.svg');
    }
  }

  &.has-coins {
    padding-inline-end: 15px;
    text-align: end;

    & span{
      position: relative;

      .rtl-lang & {
        direction: ltr;

        &::after {
          content: '';
          display: inline-block;
          margin-inline-end: 5px;
          transform: translateY(2px);

          width: 15px;
          height: 15px;

          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url( $cdn + '/wazamba/shop/coins.svg');
        }

        &:before {
          content: none;
        }
      }

      &::before {
        content: '';
        display: inline-block;
        margin-inline-end: 5px;
        transform: translateY(2px);
  
        width: 15px;
        height: 15px;
  
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( $cdn + '/wazamba/shop/coins.svg');
      }
    }
  }
}
