$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-win {
  display:flex;
  margin-inline: -10px;
  position:relative;

  &__item {
    height: 211px;
    width: calc(50% - 20px);
    margin-inline: 10px;
    position: absolute;
    text-align: center;
    background: url($cdn + '/wazamba/sprite-profile.png') no-repeat 100% top;
    background-size: 199%;

    &:last-child {
      inset-inline-end: 0;
    }
  }

  &__img {
    width: 126px;
    height: 84px;
    margin: 0 auto 8px;
    background-position: center;
    background-size: contain;

    @include l {
      width: 160px;
      height: 107px;
    }

    @include xl {
      width: 180px;
      height: 120px;
    }

    @include xxl {
      width: 120px;
      height: 80px;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: url($cdn + "/wazamba/game-item.png") 50% 50% no-repeat;
      background-size: 100% 100%;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 4px;

    @include xl {
      margin-bottom: 8px;
    }
  }

  &__btn {
    min-width: 148px;
  }

  &__empty {
    text-shadow: 0 0 10px $black-25;
    font-size: 18px;
    font-weight: 800;
    padding: 15px 15px 0;
    text-align: center;
  }

  &__sum {
    @include text(26px, 26px, 800);
    margin-bottom: 2px;
    direction: ltr;

    @include xl {
      margin-bottom: 16px;
    }

    @include xxl {
      margin-bottom: 8px;
    }
  }

  &__game {
    width: 217px;
    margin: 0 auto;
    padding: 20px 0 0;
    text-align: center;

    & a {
      font-weight: bold;
    }
  }

  &__stub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  @include l {
    &__item{ height: 243px;}
    &__empty{ font-size: 24px; }
  }

  @include xl {
    &__item{ height: 276px;}

  }
  @include xxl {
    &__item{ height: 210px;}
    &__empty{ font-size: 18px; padding:40px 25px 0; }
  }
}
