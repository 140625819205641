$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
//- TOURNAMENT-CARD is in linda-tournaments.scss

.euro {

  &-page {
    padding: 10px 0;
    max-width: 1240px;
    margin: 0 auto;

    &__container {
        padding: 20px;

        @include l {
            max-width: 962px;
            margin: 0 auto;
        }
    }

    &__banner {
        padding: 0 24px;
    }
  
    &__how {
      @include text(24px, 29px, 800, $white);
      text-align: center;
      letter-spacing: 0.05em;
      padding: 20px 0;
    }
  
    &__wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-gap: 26px;
      grid-template-areas:
      'promo board'
      'steps board'
      '. board'
      '. board';
      margin-top: 20px;
    }

    & .tournament-board {
      grid-area: board;
    }
  
    &__back {
      position:absolute;
      top: 44px;
      left: 20px;
      z-index: 60;
    }
  
    @include xl{
      &__baner{ padding:0 30px; }
    }
  
    .euro-card {
      height: 328px;
  
      &__hero {
        left: 30px;
      }
  
      &__content {
        margin: auto 40px auto auto;
      }
    }
  
    .info-wrap {
      max-width: 1000px;
      margin: 0 auto;
    }
  
    .info-item {
      &__img {
        width: 82px;
      }
  
      &__text {
        @include text(16px, 140%, 700, $white);
      }
    }
  }

  &-info {
    text-align: center;
    padding: 24px 0;
    border: 50px solid transparent;
    border-image: url('../../img/euroborder.svg') 50 50 stretch;
    background-image: url($cdn + '/wazamba/tournament/wood.png');
    background-size: 118% 115%;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;

    &__subtitle {
        font-size: 28px;
        font-weight: 900;
        letter-spacing: 1.4px;
        color: $white;
    }
  }

  &-slider {
    max-width: 100%;
    overflow: hidden;
    grid-area: promo;

    // & .slick-slide{
    //   &:not(:first-child) {
    //     .promos__btns {
    //       display: none;
    //     }

    //     .promos__label {
    //       display: block;
    //     }
    //   }
    // }

    & .slick-next {
        position: absolute;
        right: 0;
        top: 50%;
        z-index: 1;

        &.slick-disabled {
          filter: grayscale(1);
          pointer-events: none;
        }
    }

    & .slick-prev {
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 1;

        &.slick-disabled {
          filter: grayscale(1);
          pointer-events: none;
        }
    }
  }

  &-slide {
    padding: 80px 25px 0;
  
    & .promos__sum {
      max-width: 300px;
    }
  
    &.is-coming {
      & .promos__label {
        display: block;
      }
  
      & .promos__btns {
        display: none;
      }
    }
  }

  &-steps {

    grid-area: steps;
  
      &__list {
          display: grid;
          grid-gap: 16px;
          border-radius: 10px;
          border: 1px solid rgba(255, 243, 120, 0.50);
          background: rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
          padding: 16px;
          height: max-content;
      }
  
      &__img {
          width: 82px;
          height: 90px;
      }
  
      &__text {
          font-size: 16px;
          font-weight: 700;
          line-height: 140%;
      }
  
      &__item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
      }
  }

  &-table {
    max-width: 480px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 6px;

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 12px;
        border-radius: 6px;
        background: rgba(50, 33, 29, 0.50);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.42px;
    }

    &__val {
        font-weight: 700;
    }
  }

  &-card {
    position: relative;
    height: 312px;
    border-bottom: solid 4px $blue-1000;
    margin: 36px 0;
    display: flex;
    background-size: cover;
  
    &__bg {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  
    &__status {
      position: absolute;
      top: -10px;
      right: -20px;
      width: 115px;
      height: 45px;
      transform: rotate(15deg);
    }
  
    &__hero {
      position: absolute;
      bottom: 0;
      left: -26px;
      width: 268px;
      height: 324px;
      background-size: contain !important;
      background-repeat: no-repeat;
      background-position: left center;
    }
  
    &__content {
      margin: auto 10px auto auto;
      width: 485px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      position: relative;
    }
  
    &__date {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      color: $yellow-700;
  
      .timer,
      .date-title {
        @include text(14px, 1.43, bold, $yellow-700);
        text-shadow: 0 2px 10px $black-25;
        letter-spacing: 0.4px;
        margin: 0;
      }
    }
  
    &__name {
      @include text(36px, null, 800, $white);
      text-shadow: 0 0 10px $black-25;
      letter-spacing: 1.8px;
      margin: 0;
    }
  
    &__prize {
      font-size: 48px;
      font-weight: 900;
      letter-spacing: 2.4px;
      margin: 0 auto;
      display: flex;
      background: linear-gradient(#fff 13.03%, #fcfe7e 18.73%, #f4d642 96.19%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  
      &.has-coins::before {
        content: "";
        margin-right: 7px;
        width: 37px;
        height: 37px;
        background-image: url($cdn + "/wazamba/sprite-tournament-page.png");
        background-position: 0 -77px;
        background-size: 266px;
        flex-shrink: 0;
        margin-top: 10px;
      }
    }
  
    &__btn {
      margin: 15px 0;
    }
  
    &__link {
      @include text(14px, 17px, bold);
      letter-spacing: 0.05em;
    }
  
    &__places {
      display:none;
      position:absolute;
      right: 0;
      width: 250px;
      height: 100%;
      flex-direction: column;
      justify-content: center;
    }
  
    &__place {
      height: 65px;
      display: flex;
      align-items: flex-end;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0.9px;
      color: $white;
      margin: 5px 0;
    }
  
    &__place-ico {
      height: 53px;
      width: 40px;
      border-radius: 10px;
      margin-right: 10px;
      background-image: url( $cdn + "/wazamba/sprite-tournament-page.png");
      text-align: center;
      padding-top: 20px;
      font-size: 24px;
  
      &_1{ background-position: -4px -46px; }
      &_2{ background-position: -50px -46px; }
      &_3{ background-position: -95px -46px; }
    }
  
  
    @include l {
      &__places { display:flex; }
      &__content { margin: 0 0 0 auto; }
    }
    @include xl {
        &__btn {
            margin: 25px auto;
        }
    }

    @include xxl {
      &__content { margin: 0 80px 0 auto; }
    }
  
    @include full {
      max-width: 1200px;
      margin-inline: auto;
    }
  
    &__leaderboard {
      display: none;
      align-items: center;
      padding-right: 24px;
      position: relative;
  
      @include l { display: flex; }
  
      &-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
      }
  
      &-price {
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.05em;
        color: $white;
        text-shadow: 0 1px 0 $orange-900;
        position: relative;
        text-align: right;
  
        &.has-coins {
          width: fit-content;
          margin-left: auto;
          padding-left: 18px;
  
          &:after {
            content: '';
            width: 16px;
            height: 16px;
            background-image: url($cdn + "/wazamba/sprite-tournament-page.png");
            background-size: 115px;
            background-position: 0 -33px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
  
      &-item {
        align-items: center;
        justify-content: space-between;
        width: 278px;
        background: $black-25;
        box-shadow: inset 0 4px 4px $black-25;
        border-radius: 5px;
        padding: 0 10px;
        display: grid;
        grid-template-columns: .2fr 0.3fr .5fr .5fr;
  
        &.is-0,
        &.is-1,
        &.is-2 {
          .tournament-card__leaderboard-num {
            &::before {
              content: "";
              position: absolute;
              width: 76px;
              height: 40px;
              transform: rotate(-7deg);
              background-image: url($cdn + "/wazamba/wazamba-v01/tournaments/tournament_label_top3.svg");
              left: -30px;
              top: -4px;
              z-index: -1;
              background-size: cover;
            }
          }
        }
  
        &.is-current-user {
          background: #F4CA33;
          box-shadow: 0 0 6.84883px rgba(222, 224, 61, 0.5);
  
          .tournament-card__leaderboard-info {
            color: #725500;
          }
        }
  
        &.is-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          padding: 0 10px;
  
          .tournament-card__leaderboard-img {
            margin-left: 0;
          }
        }
      }
  
      &-info {
        @include text(12px, 15px, 800, $white);
      }
  
      &-num {
        @include text(24px, 29px, 800, $white);
        position: relative;
        z-index: 1;
      }
  
      &-img {
        width: 48px;
        position: relative;
        z-index: 1;
        transform: scale(1.2);
        margin-left: -20px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 48px;
      }
    }
  }
}
  
.tournament-timer {
    width: 364px;
    height: 65px;
    margin: 8px auto 20px;
    background-image: url( $cdn + "/wazamba/sprite-tournament-page.png");
    background-position: 0 67px;
}
  