$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.sport-events {
  display: block;
  margin-bottom: 40px;

  &__slider {
    .slick-track {
      display: flex;

      .slick-slide {
        display: flex;
        height: auto;

        margin-left: 10px;
        margin-right: 10px;

        & > div {
          width: 100%;
        }
      }
    }
  }

  &__slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    &:active:not(.g-btn-noclick){ transform: translateY(calc(-50% + 2px)); }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }

    &[aria-disabled="true"] {
      pointer-events: none;
      filter: grayscale(1);
      cursor: default;
    }
  }

  &__item {
    position: relative;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    // background-image: url($cdn + '/wazamba/sport/event-stone.png');

    background-image: url(#{$cdn}/wazamba/renovation/mask-collection/wood-mask-lg.png);

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url(#{$cdn}/wazamba/renovation/mask-collection/wood-mask-lg@2x.png);
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url(#{$cdn}/wazamba/renovation/mask-collection/wood-mask-lg@3x.png);
    }

    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 25px 20px;
    z-index: 1;
  }

  &__badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    background: $red-200;
    box-shadow: 0 2px 0 $red-300;
    border-radius: 4px;
    font-weight: 900;
    font-size: 10px;
    line-height: 1.2;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    transform: translateY(-50%);

    &::before {
      content: '';
      display: block;
      flex-shrink: 0;
      margin-right: 2px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $white;
    }
  }

  // &__mask {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   pointer-events: none;
  //   // mask-image: url( $cdn + '/wazamba/desktop/img/stone-mask-horizontal.svg');
  //   // mask-size: contain;
  //   background-image: url($cdn + '/wazamba/sport/event-stone.png');
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   background-position: center;
  //   z-index: -1;
  // }

  &__champ {
    margin-bottom: 4px;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: $white;
  }

  &__event {
    display: grid;
    grid-template-columns: 88px min-content 88px;
    place-content: center;
    column-gap: 16px;
    margin-bottom: 3px;
  }

  &__event-date {
    padding-top: 12px;
  }

  &__logo {
    margin-bottom: 6px;
    width: 64px;
    height: 64px;
    margin-left: auto;
    margin-right: auto;
  }

  &__logo-i {
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &__key {
    font-weight: bold;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    color: $white;
    height: 28px;
    @include text-lines-clip;
  }

  &__date {
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $white;
  }

  &__time {
    font-weight: 800;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    color: $white;
  }

  &__rate {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 8px;
    width: 100%;
  }

  &__coef {
    padding-left: 0;
    padding-right: 0;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0.05em;
    box-shadow: inset 0 0 3px 0 $black-25, 0 2px 0 0 $orange-1200;

    span {
      margin-right: 8px;
      opacity: .7;
    }
  }
}
