$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
halloween-promo {
    .halloween-main {
        &__bottom,
        &__info {
            z-index: 500;
        }
        &__info {
            padding-top: 15px;
            @media (min-width: 1440px) {
                padding-top: 30px;
            }
            @media (min-width: 1920px) {
                padding-top: 45px;
            }
        }
    }
}