$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.auth-submit-btn {
  background: linear-gradient(180deg, $green-400 0%, $green-200 95.03%);
  box-shadow: 0 2px 0 #38B751;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 0;
  outline: 0;
  width: fit-content;
  min-width: 288px;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;

  &:after {
    content: attr(name);
    white-space: nowrap;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 15px;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    color: $white;
    text-shadow: 0 2px 0 #1F6B06, 0 2px 2px $black-40;
    background: $green-500;
    box-shadow: inset 0 0 3px $black-25;
    border-radius: 11px;
    width: 100%;
    overflow: hidden;
  }

  &.is-emty {
    background: #D2D5D9;
    opacity: 0.4;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.82);
    cursor: unset;


    &:after {
      background: #A0B4C5;
      box-shadow: inset 0 0 3px $black-25;
    }
  }
}

.quick-accept-btn {
  background: linear-gradient(180deg, $green-400 0%, $green-200 95.03%);
  box-shadow: 0 2px 0 #38B751;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 0;
  outline: 0;
  width: fit-content;
  min-width: 84px;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;

  &:after {
    content: attr(name);
    white-space: nowrap;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: $white;
    background: $green-500;
    box-shadow: inset 0 0 3px $black-25;
    border-radius: 40px;
    width: 100%;
    overflow: hidden;
  }

  &:disabled {
    opacity: 0.3;
  }
}

//-- quick input promo code
.quick-promo-input {
  background: $white;
  border: 1px solid #22567C;
  box-shadow: inset 1px 1px 3px $black-23;
  border-radius: 8px;
  text-indent: 12px;
  width: 169px;
  height: 48px;
  font-family: 'Montserrat', sans-serif;

  &::placeholder {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3C3C3C;
  }
}

//--quick registration input
.is-reg-amount {
  -moz-appearance: none;
  font-family: 'Montserrat', sans-serif;
  -webkit-appearance: none;
  width: 100%;
  height: 48px;
  font-weight: 800;
  font-size: 20px;
  color: #FFCF01;
  text-shadow: 0 1px 0 #CC2500, 0 4px 4px $black-35;
  line-height: 20px;
  background: rgba(77, 144, 191, 0.63);
  border: 1px solid #143251;
  box-shadow: inset 0 2px 1px $black-35;
  border-radius: 14px 0 0 14px;
  text-indent: 20px;

  &::placeholder {
    font-weight: 600;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.4);
    text-shadow: none;
  }
}

.form-item-drop {
  display: block;
  z-index: 9;
  position: relative;

  &:after {
    position: absolute;
    width: 10px;
    height: 7px;
    content: '';
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC40OTc5MjUgMS42MzQzNEMwLjQ5NzkyNSAxLjkyNTg0IDAuNjExNjI2IDIuMjE3NTQgMC44MzkxMjYgMi40MzkwNEw1LjAwMDM0IDYuNTAwMDZMOS4xNjEyNSAyLjQzOTA0QzkuNjEzNTUgMS45OTc3NCA5LjYxNTc1IDEuMjc5NTQgOS4xNjY0NSAwLjgzNTAzM0M4LjcxNzE1IDAuMzkwNDMxIDcuOTg2NTQgMC4zODgwMzEgNy41MzM3NCAwLjgyOTYzM0w1LjAwMDM0IDMuMzAyNTVMMi40NjU3MyAwLjgyOTYzM0MyLjAxMzEzIDAuMzg4MDMxIDEuMjgyMzMgMC4zOTA0MzEgMC44MzI5MjYgMC44MzUwMzNDMC42MDk3MjYgMS4wNTY1MyAwLjQ5NzkyNSAxLjM0NTI0IDAuNDk3OTI1IDEuNjM0MzRaIiBmaWxsPSIjZmZmZmZmIi8+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    opacity: .9;
    pointer-events: none;
  }
}

//--quick registration btn
.is-reg-btn {
  border-radius: 0 14px 14px 0;
  font-weight: 800;
  font-size: 16px;
  height: 52px;
  width: 111px;
  font-family: 'Montserrat', sans-serif;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: $white;
  background: linear-gradient(0deg, $black-3, $black-3), linear-gradient(180deg, rgba(16, 84, 133, 0.66) 0%, rgba(21, 60, 88, 0.66) 100%);
  border: 1px solid #143251;
  box-shadow: inset 0 2px 1px $black-35;
  padding: 12px 30px 12px 20px;
  -webkit-appearance: none;
  cursor: pointer;

  option {
    background: #214B6A;
    font-weight: 800;
    line-height: 20px;
    font-size: 16px;
  }

  &:disabled {
    background: linear-gradient(0deg, #487493, #487493), linear-gradient(180deg, rgba(16, 84, 133, 0.66) 0%, rgba(21, 60, 88, 0.66) 100%);
    box-shadow: -2px 0 2px rgba(5, 27, 43, 0.35);
    color: rgba(255, 255, 255, 0.32);
  }

  &:focus {
    border-color: #143251;
    background: linear-gradient(0deg, $black-3, $black-3), linear-gradient(180deg, rgba(16, 84, 133, 0.66) 0%, rgba(21, 60, 88, 0.66) 100%);
  }
}

.form-buttons {
  &.register-btn {
    margin-top: 36px;
  }

  button {
    width: 100%;
  }
}


