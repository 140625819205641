$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
@keyframes rotate360 {
  100%{ transform: rotate(360deg); }
}


@keyframes wobbleUp {
  0%, 100% {
    transform: scale(-1, 1) translateY(0);
  }
  50% {
    transform: scale(-1, 1) translateY(-10px);
  }
}

@keyframes wobbleUpRtl {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes wobbleDown {
  50%{ transform: translateY(5px);}
}

