$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  &__gamification {
    height: 100%;
    position: relative;

    &-scroll-wrapper {
      height: calc(100% - var(--header-height) - 31px);
      position: relative;
      margin-top: 20px;
    }

    &-content {
      position: absolute;
      inset: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-block: 10px;
      scroll-padding-block: 10px;
      mask-image: linear-gradient(to bottom, transparent 0%, black 10px, black calc(100% - 20px), transparent 100%);
    }
  }

  &__filter-btns {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    padding: 0 var(--shop-margin-inline);

    &::-webkit-scrollbar {
      display: none;
    }

    &-wrap {
      margin: 20px var(--shop-margin-inline-negative) 0;
      padding-inline: var(--shop-margin-inline);
    }

    button:first-child { margin-inline-start: auto; }
    button:last-child { margin-inline-end: auto; }

    &--tournaments,
    &--shop {
      position: relative;
      padding: 6px 24px;
      background-color: #4a2b1c;
      border: none;
      border-radius: 10px;
      box-shadow: 0 3px 3px 0 $black-20, 0 -1px 1px 0 $black-10 inset;
      cursor: pointer;
      min-width: max-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      text-wrap: nowrap;

      &:hover {
        background-color: #6b422e;
      }

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        border: 2px solid rgba(131, 82, 56, 0.66);
        border-radius: 10px;
      }

      &.is-active {
        background: linear-gradient(180deg, #A66C57 0%, #844E3E 100%);
        outline: none;

        &:hover {
          background: linear-gradient(180deg, #CD8D75 0%, #9A5C49 119.32%);
        }

        &::after {
          content: none;
        }
      }

      img {
        width: 30px;
        height: 30px;

        .rtl-lang & {
          transform: scaleX(-1);
        }
      }

      span {
        @include text(16px, 20px, 700, $white);
        font-family: "Montserrat", sans-serif;
      }
    }
  }
}
