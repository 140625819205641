$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.login-funid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  text-align: center;
  color: rgba(110, 57, 35, 1);

  &__or {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    height: 24px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.02em;

    &::before,
    &::after {
      content: "";
      width: 65px;
      height: 1px;
      background-color: rgba(184, 129, 40, 1);
      margin: 0 14px;
    }
  }
  &__link {
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: rgba(184, 129, 40, 1);
    transition: color .1s;

    &:hover {
      color: rgba(110, 57, 35, 1);
    }
  }

  &::after {
    content: '';
    margin-top: 20px;
    width: 173px; 
    height: 1px; 
    background-color: $black-20;
  }
}
