$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.infopage {
  h1 {
    @include text(36px, normal, 800, $yellow-200);
    padding-top: 58px;
    text-align: center;
    text-shadow: 0 3px 0 $orange-1000;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.8px;
    margin: 0 0 34px 0;
    background: url( $cdn + '/wazamba/page-title.png') 50% top no-repeat;
  }

  &__wrap {
    @include text(14px, 24px, 500);
    max-width: 940px;
    margin: 0 auto;
    padding: 0 6% 30px 6%;

    h2 {
      @include text(16px, 140%, 800);
      background: linear-gradient(180deg, $yellow-400 -5.33%, $orange-200 93.92%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: flex;
      letter-spacing: 0.05px;
      margin: 0;
      
      &:hover {
        background: none;
        color: $yellow-100;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
      }
    }

    p {
      display: inline-block;
      margin: 0;
      padding: 0 0 20px 0;
    }

    ul {
      li {
        display: flex;
        padding-bottom: 4px;

        &:last-child {
          padding: 0;
        }

        &:before {
          background-color: $white;
          border-radius: 50%;
          content: "";
          display: inline-block;
          height: 8px;
          margin-block: 9px 0;
          margin-inline: 0 12px;
          min-width: 8px;
          width: 8px;
        }
      }
    }

    a {
      color: $yellow-700;
      display: contents;
      text-decoration: underline;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }

    & > div > div,
    & > div {
      & > p {
        background: linear-gradient(180deg, $yellow-400 -5.33%, $orange-200 93.92%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding: 0;
      }
    }

    & > .payments-page__description {
      & > p {
        color: #ffffff;
        background: none;
        -webkit-background-clip: inherit;
        -webkit-text-fill-color: inherit;
      }
    }

    table {
      min-width: 660px;
      width: 100%;
      text-align: center;

      thead td,
      thead th {
        background-color: $white-5;
        border: 1px solid rgba(255, 255, 255, 0.18);
        padding: 6px 8px 7px;
      }

      td {
        border: 1px solid rgba(255, 255, 255, 0.18);
        padding: 10px 8px;
        vertical-align: middle;
      }
    }

    section {
      border-bottom: 1px solid #2E5C74;
    }

    &.no-accordion {
      section {
        margin-bottom: 30px;

        h2 {
          padding-bottom: 12px;
        }
      }
    }

    &.accordion {
      section {
        counter-increment: section;
        margin-bottom: 0;

        h2 {
          cursor: pointer;
          padding-block: 20px;
          padding-inline: 0 60px;
          position: relative;

          &:before {
            content: counter(section) ".";
            display: inline-block;
            margin-inline-end: 8px;
            min-width: 36px;
          }

          &:after {
            content: '';
            background: url( $cdn + '/wazamba/icon/icon-accord-btn.svg') no-repeat 0 0;
            position: absolute;
            inset-inline-end: 0;
            top: 20px;
            width: 24px;
            height: 25px;
            transition: background 0.3s ease;
          }

          & ~ * {
            display: none;
          }

          &.open {
            padding-bottom: 10px;

            &:after {
              background: url( $cdn + '/wazamba/icon/icon-accord-btn1.svg') no-repeat 0 0;
            }

            & ~ * {
              display: block;
              padding-bottom: 12px;

              &:last-child {
                padding-bottom: 20px;
              }
            }

            & ~ p,
            & ~ ul p {

              & > span:first-child {
                font-weight: 700;
                margin-inline-end: 12px;
              }
            }
          }
        }
      }
    }

    &.no-accordion {
      & > div {
        & > div {
          display: flex;
          gap: 16px;
          justify-content: center;
          margin-block: 34px 28px;
          margin-inline: 0;

          & > a {
            @include text(18px, null, 700, $blue-900);
            align-items: center;
            background-color: $orange-100;
            border-radius: 8px;
            box-sizing: border-box;
            cursor: default;
            display: flex;
            flex-direction: column;
            min-height: 123px;
            justify-content: center;
            padding: 10px 0 8px;
            text-decoration: none;
            width: 49%;

            &:first-child {
              &:hover {
                background-color: #FFE1B4;
              }
            }

            &:before {
              content: "";
              display: inline-block;
            }

            img {
              display: none;
            }

            & > div {
              line-height: 25px;
              text-align: center;
            }

            &:first-child {
              &:before {
                background: url( $cdn + '/wazamba/icon/icon-chat.svg') no-repeat 0 0;
                background-size: cover;
                height: 34px;
                width: 40px;
              }

              & > div > div:first-child {
                background: linear-gradient(180deg, $violet-100 0%, $blue-600 95.03%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                cursor: pointer;
                font-size: 20px;
                padding: 10px 0 7px;
              }
            }

            &:last-child {
              padding-top: 16px;

              &:before {
                background: url( $cdn + '/wazamba/icon/icon-mail.svg') no-repeat 0 0;
                background-size: cover;
                height: 23px;
                width: 35px;
              }

              & > div > div:first-child {
                color: $orange-1000;
                cursor: pointer;
                font-size: 20px;
                padding: 15px 0 7px;
                text-decoration: underline;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .change-cookies-link {
    @include text(14px, 140%, 600, $yellow-400);
    text-shadow: 0 0 10px $black-25;
    letter-spacing: 0.7px;
    display: inline-block;
    text-decoration: underline;

    &:hover {
      color: $yellow-100;
    }
  }

  .rtl-lang & {
    direction: rtl;

    &__wrap {
      @include text(14px, 24px, 400);
    }
  }
}

//- info-table-withdraw

.info-table-withdraw {
  max-width: 100%;
  overflow-x: auto;

  &__table {
    margin: 10px 0;
    border-collapse: collapse;
    border-spacing: 1px;

    td {
      white-space: nowrap;
      text-align: center;

      &:not(:last-child) {
        border-right: groove;
      }
    }

    .info-table-withdraw__name {
      font-size: 14px;
      text-align: start;
    }
  }

  & tr:nth-child(even) td {
    background: $black-20;
  }

  &__name {
    font-size: 14px;
  }

  &__span {
    display: block;
  }
}

//- CONTACTS

.contacts-page {
  & h2 {
    margin: 0;
  }

  & section:not(:last-child) {
    margin-bottom: 30px;
  }

  &__block {
    text-align: center;
    padding: 30px 0 50px 0;
    margin-bottom: 20px;
    background-image:  url( $cdn + '/wazamba/board/board2_top.png') , url( $cdn + '/wazamba/board/board2_bottom.png') , url( $cdn + '/wazamba/board/board2_body.png');
    background-position: center top, center bottom, center center;
    background-size: 100% auto, 100% auto, 100% calc(100% - 30px);
    background-repeat: no-repeat;
  }

  &__block-item {
    display: flex;
    justify-content: space-between;
    width: 640px;
    margin: 0 auto;

    & div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 290px;
      background: rgba(34, 8, 2, .3);
      border-radius: 10px;
      padding: 15px 13px;
      letter-spacing: 0.05em;
      margin: 25px 0;

      & span {
        @include text(13px, null, 500, $orange-100);
      }

      &:last-of-type {
        & a {
          text-decoration: none;
        }
      }

      & a {
        @include text(14px, null, bold, $white);
      }
    }
  }

  & button {
    @include text(22px, 1, 800, $white);
    @include ellipsis;
    width: 200px;
    height: 48px;
    padding: 0;
    background-color: $green-500;
    background-image: linear-gradient($green-500, $green-500), linear-gradient($green-400, $green-200);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $green-600, 0 2px 15px 3px $green-400;
    box-sizing: border-box;
    display: inline-block;
    border-radius: 80px;
    cursor: pointer;
    max-width: 100%;
    border: 5px solid transparent;
    text-shadow: 0 0 10px $black-25;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.2px;
    text-align: center;

    &:hover {
      background-image: linear-gradient(to top, $green-700, $green-500), linear-gradient($green-300, $green-100 95%);
    }
  }

  &__section-top {
    & li {
      display: flex;
      line-height: 32px;
      padding-bottom: 5px;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        background: $white;
        margin-inline-end: 15px;
        border-radius: 50%;
        margin-top: 15px;
      }
    }
  }

  &__section-tel {
    & ul {
      column-count: 2;
      padding-top: 15px;
      line-height: 32px;
    }
  }
}
