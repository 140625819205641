$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-offer {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding-top: 110px;
  text-align: center;

  &__text {
    text-wrap: balance;
    text-shadow: 0 3px 0 #081A2B;
    @include text(18px, 1, 800, #7DD4EE);
  }

  &__big-text {
    margin: 16px 0 8px;
    text-wrap: balance;
    @include text(42px, 1.2, 800);
    @include title-gold-shadow(2px, 0);
  }

  &__btn.g-btn.g-btn-green {
    width: 328px;
    height: 60px;
    margin-top: 24px;
    line-height: 52px;
  }

  @include xxl {
    max-width: 430px;
    padding-top: 130px;

    &__big-text {
      font-size: 50px;
    }
  }

  .rtl-lang & {
    direction: rtl;
  }
}
