$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-section{
  //border:solid 1px red;
  width: 748px;
  margin: 40px auto 0;

  &__header{
    display:flex;
    padding-bottom:10px;
  }
  &__title{
    flex-shrink: 0;
    background-image: linear-gradient(to bottom, $white, $yellow-200 7%, $yellow-500);
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 1.8px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

  }
  &__title-text{
    padding-top:19px;
    font-size: 16px;
    font-weight: 500;
    padding-inline-start: 15px;
  }
  &__name{
    font-size: 16px;
    font-weight: 800;
    padding: 4px 0;
  }
  &__box{
    display:block;
    //-&:not(:nth-child(2)){ border-top:solid 1px #203c60; }
  }
  @include l{
    width: 972px;

    &__name{ padding:15px 0;}
    &__big{
      margin-inline-end: 26px;
    }
  }

}

.ach-section__box + .ach-section__box{ border-top:solid 1px #203c60; }
