$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.completed-block {
  display: none;
  &__label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 29px;
    font-weight: 800;
    font-size: 9px;
    line-height: 12px;
    text-transform: uppercase;
    color: $white;
    text-shadow: 0 1px 0 $black-50;
    background-size: 95px;
    background-image: url($cdn + '/wazamba/renovation/mask-collection/plate-completed.png');
    filter: drop-shadow(0 4px 2px $black-25);
    margin: 0 auto;
    margin-bottom: 10px;
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/wazamba/renovation/mask-collection/plate-completed@2x.png');
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url($cdn + '/wazamba/renovation/mask-collection/plate-completed@3x.png');
    }

    &:before {
      content: '';
      width: 10px;
      height: 7px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url($cdn + '/wazamba/renovation/mask-collection/icon-completed.svg');
      margin-right: 3px;
    }
  }
  @include xxl {
    &__label {
      background-size: 130px;
      width: 130px;
      height: 40px;
      font-size: 12px;
      &:before {
        width: 13px;
        height: 10px;
      }
    }
  }
}
