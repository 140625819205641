$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
desc-easter-promo-popup.popup {
  padding: 0;

  .easter-popup {
    margin: 20vh auto;

    &__list li {
      white-space: pre-wrap;
    }
  }
}
