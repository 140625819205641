$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
$labels: 'bronze','silver','gold','platinum';
@each $label in $labels {
    .label-#{$label} {
      background-image: url('#{$cdn}/wazamba/renovation/mask-collection/plate/#{$label}.png');
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url('#{$cdn}/wazamba/renovation/mask-collection/plate/#{$label}@2x.png');
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url('#{$cdn}/wazamba/renovation/mask-collection/plate/#{$label}@3x.png');
      }
    }
  }