$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.page-article {
  overflow: hidden;
  position: relative;
  z-index: 10;
  padding: 20px 250px 0 20px;
  background-image: url($cdn + "/wazamba/page-bg-1.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  transition: .2s padding;

  body.linda-hide-sidebar & {
    padding: 20px 125px 0;
  }

  //@include l {
    //padding-top: 30px;
  //}

  &::before {
    content: "";
    background: linear-gradient(to bottom, $blue-900 0%, rgba(7, 31, 62, 0) 89.66%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 97px;
    z-index: -1;
  }

  &.is-promos {
    margin-top: 32px;

    @include xxl {
      margin-top: 48px;
    }
  }

  &.is-achievements {
    margin-top: 60px;
  }

  &__container {
    width: 100%;
    margin: 0 auto 20px;
    position: relative;
    z-index: 1;
    padding-bottom: 20px;
  }

  &__accordion {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  &__title {
    @include text(24px, 32px, 800, $yellow-200);
    text-align: center;
    flex-grow: 1;
    margin: 0;
    padding: 0 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    flex-shrink: 0;
    filter: grayscale(0);
    transition: filter .3s ease-in-out;

    & svg {
      transition: transform .3s ease-in-out;
      transform: rotate(270deg);
    }
  }

  & img {
    float: inline-start;
    margin-inline-end: 57px;
    margin-bottom: 29px;

    .rtl-lang & {
      transform: scale(-1, 1);
    }
  }

  & p {
    @include text(14px, 24px, 500, $white);
    margin: 0 0 10px 0;
  }

  & a {
    color: #f9d600;
    font-weight: 600;
  }

  & main {
    position: relative;
    padding: 24px 0;
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease;

    &.is-category {
      max-height: fit-content;
    }
  }

  &__text {
    display: none;
  }

  section:not(:last-child) {
    border-bottom: 1px solid #21728A;
  }

  ul {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 24px;
    list-style: '-';
    padding-inline-start: 5px;

    li {
        padding-inline-start: 7px;
        margin-bottom: 10px;
    }
  }

  @include l{
    &__container {
      max-width: 1020px;
    }
  }

  &__btn {
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 35px;
      height: 22px;
      background-image: url( $cdn + '/wazamba/desktop/img/icon-arrow_bottom.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      z-index: 200;
      top: 50%;
      inset-inline-start: 0;
      margin-top: -11px;
      transform: rotate(0deg);
      transition: transform .3s ease-in-out;
    }
  }

  &__providers {
    background-image: url($cdn + '/wazamba/wazamba_seo_providers.png');
    background-repeat: no-repeat;
    background-size: 174px 160px;
    background-position: top left;
    padding-inline-start: 195px;
    min-height: 160px;

    @include l {
      padding-inline-start: 260px;
      background-position: left 33px top;
      margin-bottom: 10px;
    }
  }

  &__games {
    background-image: url($cdn + '/wazamba/wazamba_seo_gamehall.png');
    background-repeat: no-repeat;
    background-size: 174px 147px;
    background-position: top left;
    padding-inline-start: 195px;
    min-height: 147px;
    margin-bottom: 10px;

    @include l {
      padding-inline-start: 260px;
      background-position: left 33px top;
    }
  }

  .rtl-lang & {
    direction: rtl;

    &__providers {
      background-position: 100% 0;
    }

    .seo__tournaments {
      background-position: calc(100% - 21px) 0;

      @include l {
        background-position: calc(100% - 64px) 0;
      }
    }

    &.is-promos {
      direction: rtl;
    }

    &__games {
      background-position: top right;

      @include l {
        background-position: right 33px top;
      }
    }
  }
}

.page-article__accordion.open {
  .page-article__icon {
    filter: grayscale(1);
  }

  .page-article__icon svg {
    transform: rotate(90deg);
  }

  + .page-article__content {
    max-height: 10000px;
  }
}

.page-article__top-wrap.is-open {
  .page-article__icon {
    filter: grayscale(1);
  }

  .page-article__btn {
    &::after {
      transform: rotate(-180deg);
    }
  }

  & ~ .page-article__text {
    display: block;
  }
}
