$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.euro-popup {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        color: $white;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        font-size: 36px;
        font-weight: 800;
        letter-spacing: 1.8px;
        margin-bottom: 4px;
        margin-top: 10px;
    }

    &__text {
        color: #6E3923;
        font-size: 16px;
        font-weight: 800;
        line-height: 140%;
        letter-spacing: 0.8px;
        text-align: center;
        max-width: 270px;

        &--big{
            font-size: 42px;
            letter-spacing: 2.1px;
        }
    }

    &__img {
        margin-top: 20px;
    }

    &__btn {
        margin: 24px auto 20px;
    }

    &__link {
        font-size: 14px;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: 0.7px;
        padding-bottom: 20px;
    }


}