$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.bonus-funid{
  margin: 18px 0 14px 0;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: $brown-300;
  text-align: center;
  &_login {
    margin: 13px 0 0;
    font-size: 16px;
  }
}
