$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
div.spins-layout {
  display: grid;

  &.spins-layout--grid {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  &.spins-layout--row {
    display: block;
  }
}

.shop-fs-item {
  background-image: url($cdn + '/wazamba/shop/bonus-backgrounds/freespin_wood-pattern.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow:
          inset 0 2px 3px 0 rgba(255, 162, 109, 0.3),
          inset 0 -4px 12px 0 rgba(39, 11, 5, 0.2),
          inset 0 0 0 1px $white-10,
          0 4px 8px 0 $black-10;

  border-radius: 12px;
  position: relative;
  z-index: 1;
  height: auto;
  scroll-snap-align: start;
  order: 1;

  &.ela {
    order: 0;
  }

  &::after {
    content: '';
    position: absolute;
    inset-inline: 0;
    bottom: 0;
    height: 38px;
    background-image: url($cdn + "/wazamba/shop/bonus-backgrounds/freespin_art-pattern.png");
    background-repeat: no-repeat;
    background-size: 1300px 55px;
    background-position: center bottom;
  }

  &--show-more {
    display: flex;
    place-items: center;
    align-items: center;
    justify-content: center;
    height: 300px;
    background-color: rgb(19, 66, 97);
    background-image: url($cdn + '/wazamba/league/bg-pattern.png');
    background-size: 200%;
    border: 1px solid $white-20;
    border-radius: 12px;
    box-shadow: 0 2px 3px 0 rgba(109, 159, 255, 0.4) inset, 0 -4px 12px 0 rgba(5, 12, 39, 0.2) inset, 0 4px 8px 0 $black-20;
    order: 2;

    a {
      @include text(14px, 16px, 800, rgb(255, 230, 0));
      padding-block: 8px;
      padding-inline-end: 22px;
      padding-inline-start: 8px;
      position: relative;
      background-color: rgba(6, 26, 46, 0.8);
      border-radius: 12px;
      width: fit-content;
      cursor: pointer;
      margin: 0;
      display: block;
      transition: .3s;

      &::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: 5px;
        background-image: url($cdn + "/wazamba/shop/shop_arrow-right.svg");
        background-repeat: no-repeat;
        background-size: contain;
        filter: invert(10%) sepia(58%) saturate(888%) hue-rotate(320deg) brightness(112%) contrast(93%);
      }

      &:hover {
        background: rgba(6, 26, 46, 0.40);
        box-shadow: 0px -4px 12px 0px rgba(5, 12, 39, 0.20) inset;
      }

      .rtl-lang & {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__button {
    font-family: Montserrat, 'sans-serif';
    position: relative;
    bottom: 4px;
  }

  &--vertical {
    padding: 0 8px 20px;
    max-height: 300px;
    min-width: 180px;

    @include l {
      min-width: 192px;
    }

    @include xl {
      min-width: 184px;
    }

    .shop-item-money-bet__bonus-name {
      margin-bottom: -24px;
      z-index: 2;
    }

    .shop-fs-item {
      &__fs-price {
        margin: 0;
      }

      &__info {
        padding-top: 164px;
        text-align: center;
      }

      &__img {
        height: 164px;
        transform: translateX(-50%);
        inset-inline-start: 50%;
        width: calc(100% - 16px);

        .rtl-lang & {
          transform: translateX(50%);
        }
      }

      &__fs-name {
        display: inline-grid;
        align-content: center;
        height: 36px;
        margin: 2px 0;
        font-size: 14px;
      }
    }
  }

  &--horizontal {
    padding: 8px;
    min-height: 136px;

    .shop-fs-item {
      box-sizing: border-box;

      &__fs-price {
        margin: 0;
      }

      &__fs-name {
        height: 36px;
        text-align: start;
        margin: 6px 0 12px;
        font-size: 14px;
      }

      &__info {
        margin-inline-start: 140px;
        align-items: flex-start;
      }

      &__img {
        height: 120px;
        width: 120px;
      }

      &__button {
        height: 36px;
        line-height: 1;
        position: relative;
        bottom: 2px;
      }
    }
  }

  &__info {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: 100%;
    align-items: center;
  }

  &__fs-name {
    @include shop-title-white(16px, 18px);
    flex-grow: 1;
  }

  &__fs-price {
    @include text(10px, 16px, 700, $white);
    padding: 0 8px 2px;
    border-radius: 16px;
    background: linear-gradient(180deg, rgba(255,0,0,1) 0%, rgba(153,1,1,1) 100%);
    box-shadow: 0 -2px 1px 0 rgba(96, 13, 34, 0.4) inset, 0 3px 3px 0 rgba(96, 13, 34, 0.25), 0 1px 2px 0 $white-20 inset;

    span {
      display: inline-block;
      padding-inline-start: 4px;
    }
  }

  &__img {
    z-index: 1;
    position: absolute;
    top: 8px;
    inset-inline-start: 8px;
    width: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    border: 1px solid rgba(69, 9, 9, 0.4);
    border-radius: 6px;
    box-shadow: 0 4px 24px 0 rgba(142, 14, 14, 0.2) inset, 0 2px 3px 0 rgba(255, 162, 109, 0.2);

    img {
      border-radius: 6px;
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
