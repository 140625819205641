$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.masks-page {
  position: relative;
  background-position: left top;
  background-size: 549px;
  background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-bg.jpg');

  @media (-webkit-max-device-pixel-ratio: 2) {
    background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-bg@2x.jpg');
  }
  @media (-webkit-max-device-pixel-ratio: 3) {
    background-image: url($cdn + '/wazamba/renovation/mask-collection/wood-bg@3x.jpg');
  }

  &__item-wrapper {
    position: relative;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url($cdn + '/wazamba/masks-collection/mask-bg.png');

    &.is-worn {
      background-image: url($cdn + "/wazamba/masks-collection/mask-bg-yellow.png");
      background-repeat: no-repeat;
    }
  }

  &.status-1 {
    .header-collection__logo2 {
      background-size: 0, cover, 0;
    }
  }
  &.status-2 {
    .header-collection__logo2 {
      background-size: 0, 0, cover;
      filter: drop-shadow(0 0 4px $blue-500) drop-shadow(0 0 4px $blue-500) drop-shadow(0 0 2px $blue-500)
        drop-shadow(0 0 2px $blue-500);
    }
    .masks-page__button {
      display: flex;
    }
    .masks-page__subtitle {
      display: none;
    }
  }
  &.status-3 {
    .header-collection__logo2 {
      background-size: 0, 0, cover;
    }
    .masks-page__subtitle {
      display: none;
    }
    .masks-page__subtitle.is-complete {
      display: block;
    }
  }

  &__price {
    width: 148px;
    height: 40px;
    inset-inline-start: 50%;
    margin-inline-start: -74px;
    top: -15px;
    z-index: 1;
    position: absolute;
  }
  &__coin {
    height: 40px;
    border-radius: 36px;
    background-image: linear-gradient(to top, $violet-100, $blue-600 95%);
    padding: 10px 20px;
    align-items: center;
    font-size: 26px;
    font-weight: 800;
    border: solid 1px transparent;
    background-clip: padding-box;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    &:before {
      content: '';
      width: 30px;
      height: 30px;
      background-image: url($cdn + '/wazamba/sprite-tournament-page.png');
      background-position: -137px -62px;
      background-size: auto;
      margin-inline-end: 5px;
    }
  }

  &__wrap {
    padding: 0 230px 0 0;
    position: relative;
  }
  &__labels {
    position: absolute;
    top: 1px;
    inset-inline-start: -4px;
  }
  &__container {
    margin: 0 auto;
    position: relative;
    padding-top: 40px;
    padding-bottom: 100px;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 174px);
    gap: 40px 28px;
    justify-content: center;
    grid-template-rows: 217px;
    margin-top: 30px;
  }

  &__bar-block {
    width: 240px;
    margin: 0 auto;
    div {
      &:before {
        position: relative;
      }
    }
  }
  &__subtitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.05em;
    text-shadow: 0 1px 0 #ff7a00;
    margin-bottom: 11px;

    span {
      display: inline-block;
    }
    &.is-complete {
      display: none;
    }
  }
  &__button {
    position: relative;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    z-index: 1;
    display: none;
    img {
      width: 68px;
      height: 68px;
      z-index: -1;
      transform: scale(1.2);
    }
    button {
      position: relative;
      transform: translateX(-10px) !important;

      span {
        display: inline-block;
      }
    }
  }
  &__img {
    object-fit: contain;
    width: 190px;
    height: 190px;
    margin-top: 10px;
  }
  &__status-0,
  &__status-1 {
    .masks-page__img {
      filter: url(#svgTint);
    }
    .masks-page__coin {
      display: flex;
    }
    .mask-popup__buy {
      display: inline-block;
      width: auto;
    }
    .g-btn-completed {
      display: none;
    }
  }
  &__status-2 {
    .mask-popup__wear {
      display: inline-block;
      width: auto;
    }

    .mask-popup__buy,
    .g-btn-completed {
      display: none;
    }

    .is-worn & {
      .mask-popup__wear,
      .mask-popup__buy {
        display: none;
      }
      .g-btn-completed {
        display: inline-block;
      }
    }
  }
  &__status-btn {
    min-height: 38px;
  }

  @include m {
    &__container {
      width: 578px;
    }
  }
  @include l {
    &__container {
      width: 683px;
    }
    &__img {
      width: 228px;
      height: 228px;
    }

    &__grid {
      grid-template-columns: repeat(3, 209px);
      gap: 40px;
      grid-template-rows: 260px;
    }
  }
  @include xl {
    &__container {
      width: 788px;
    }
    &__grid {
      grid-template-columns: repeat(3, 244px);
      gap: 36px 28px;
      grid-template-rows: 304px;
    }
    &__img {
      width: 266px;
      height: 266px;
    }
  }
  @include xxl {
    &__container {
      width: 812px;
    }
    &__grid {
      gap: 40px;
    }
  }
  &__shining {
    position: absolute;
    inset: -40px;
    background-image: url($cdn + "/wazamba/masks-collection/mask-bg-shining.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 140%;
    z-index: 0;
  }

  .rtl-lang & {
    direction: rtl;
  }
}

svg.mask-filter{
  width: 0;
  height: 0;
}
