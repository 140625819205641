$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.datepicker-body-reg{
    .ui-datepicker{
        top: -16px !important;
        background: $white;
        border-radius: 24px;
        th{
            color: $grey-300
        }
        td{
            a{
                color: $grey-300
            }
        }
        .ui-datepicker-title{
            display: flex;
        }
        .ui-datepicker-month, .ui-datepicker-year{
            box-shadow: 0 0 8px rgba(58,20,4,.4);
        }
        .ui-datepicker-month{
            margin-inline-end: 10px;
        }

        td a.ui-state-active, td a:hover {
            background: rgba(58,20,4,.7);
            color: $white;
        }
    }
}

.auth-with-deposit-registration{
    .datepicker-body {
        position: absolute;
        top: 40px;
    }
}
