$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.timer{

  font-size: 36px;
  font-weight: 800;
  letter-spacing: 1.8px;
  color: $white;


  line-height: 63px;
  text-align: center;
  width: 100%;
  height: 100%;
  text-shadow: 0 1px 3px $black-20;
  direction: ltr;
}
