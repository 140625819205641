$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.page-nav{
  display:flex;
  justify-content: center;
  position:relative;
  &_padding{
    padding-top:20px;
  }
  &__item{
    font-size: 18px;
    font-weight: bold;
    margin:0 24px;

    & svg{
      width: 20px;
      height: 20px;
      margin-right:5px;
    }


    &.is-yellow{
      color:$yellow-700;
      & svg{ fill: $yellow-700;}
    }
  }
}
