$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.shop-not-enough-coins {
  &__text {
    @include text( 14px, 17px, 700, $white );

    padding: 146px 16px 16px;
    border-radius: 12px;
    text-align: center;
    width: 280px;

    background-color: rgba(14, 51, 88, 1);
    background-image: url($cdn + '/wazamba/shop/pot-empty.png');
    background-repeat: no-repeat;
    background-position: center top 16px;
    background-size: 120px 120px;
    margin: 0;
  }

  &__btn{
    min-width: 180px;
  }
}
