$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.main-banner {
  height: 256px;
  border-radius: 15px;
  width: 100%;
  padding: 6px;
  background-image: linear-gradient(180deg, #f2b600 -5.33%, $orange-1000 93.92%);
  box-shadow: inset 0 0 9px $black-40;
  margin-bottom: 40px;

  &__wrap {
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 15px;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    max-height: 100%;
  }

  &__text-wrap {
    width: 100%;
    text-align: center;
    position: relative;
  }

  &__title {
    @include text(20px, 24px, 800);
    text-shadow: 0 2px 5px $black-70;
  }

  &__text {
    @include text(48px, 59px, 800, $yellow-600);
    text-shadow: 0 3px 0 $orange-900;
  }

  &__btn {
    min-width: 170px;
    margin-top: 16px;
  }

  @include l {
    height: 290px;

    &__title { @include text(24px, 29px); }
    &__text { @include text(58px, 1.2); }
  }

  @include xl {
    height: 340px;

    &__title { @include text(28px, 34px); }
    &__text { @include text(64px, 1.2); }
  }

  &.is-euro {

    .main-banner__logo {
      margin-bottom: -15px;
      height: 230px;
      max-height: unset;
      z-index: 1;
    }

    .main-banner__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      filter: blur(1px);
      z-index: 0;
    }

    .main-banner__text-wrap {
      z-index: 1;
    }

    .main-banner__wrap{
      &:after {
        content: '';
        position: absolute;
        z-index: 0;
        pointer-events: none;
        background: linear-gradient(0deg, rgba(23, 14, 5, 0.25) 0%, rgba(23, 14, 5, 0.25) 100%);
        border-radius: 10px;
        width: 100%;
        height: 100%;
        filter: blur(1px);
      }
    }

    @include l {
      .main-banner__logo {
        height: 280px;
        margin-bottom: 2px;
      }

      .main-banner__title {
        font-size: 28px;
      }

      .main-banner__text {
        font-size: 64px;
        line-height: 64px;
      }
    } 

    @include xl {
      .main-banner__logo {
        height: 310px;
        margin-bottom: -18px;
      }

      .main-banner__title {
        font-size: 36px;
      }

      .main-banner__text {
        font-size: 72px;
      }
    }

    @include xxl {
      .main-banner__logo {
        height: 340px;
        margin-bottom: 12px;
      }

      .main-banner__wrap {
        padding-right: 60px;
      }

      .main-banner__text-wrap {
        width: 55%;
      }
    }

  }

  &.is-mask {
    .main-banner__title {
      margin: 0 auto;
      width: 265px;
    }

    @include l {
      .main-banner__logo {
        height: 315px;
        max-height: inherit;
        margin-top: -50px;
      }

      .main-banner__title {
        font-size: 32px;
        line-height: 39px;
        width: 340px;
      }

      .main-banner__text-wrap {
        padding-right: 40px;
      }
    }

    @include xl {
      .main-banner__logo {
        height: 364px;
      }

      .main-banner__title {
        @include text(36px, 44px);
      }
    }

    @include xxl {
      .main-banner__wrap {
        padding-inline: 60px;
      }
    }
  }

  &.is-shop {
    .main-banner__wrap {
      padding-top: 0;
      padding-bottom: 0;
      position: relative;
      justify-content: flex-end;
    }

    .main-banner__text-wrap {
      width: inherit;
      padding-inline-start: 18px;
      padding-right: 18px;
      min-width: 263px;
      box-sizing: content-box;
    }

    .main-banner__text {
      @include text(24px, 29px);
      margin-top: 8px;

      span {
        position: relative;
        display: block;
      }
    }

    .main-banner__logo {
      width: 100%;
      height: calc(100% + 30px);
      max-height: inherit;
      position: absolute;
      bottom: 0;
      inset-inline-start: 0;
      overflow: hidden;
      border-radius: 15px;

      &__layer {
        position: absolute;

        &.layer1 {
          inset-inline-start: 218px;
          top: 0;
          width: 247px;
        }

        &.layer2 {
          width: 140px;
          top: 70px;
          inset-inline-start: 70px;
        }

        &.layer3 {
          width: 178px;
          top: 140px;
          inset-inline-start: 205px;
        }

        &.layer4 {
          top: 45px;
          inset-inline-start: -55px;
          width: 420px;
        }

        &.layer5 {
          width: 230px;
          inset-inline-start: 295px;
          top: 210px;
        }

        &.layer6 {
          width: 120px;
          inset-inline-end: -100px;
          top: 110px;
        }
      }
    }

    @include l {
      .main-banner__text { @include text(24px, 29px); }
      .main-banner__title { @include text(28px, 34px); }

      .main-banner__text-wrap {
        padding-inline: 28px;
        min-width: 360px;
      }

      .main-banner__logo {
        height: calc(100% + 38px);

        &__layer {
          &.layer1 {
            inset-inline-start: 343px;
            top: 0;
            width: 302px;
          }

          &.layer2 {
            width: 140px;
            top: 70px;
            inset-inline-start: 170px;
          }

          &.layer3 {
            width: 217px;
            top: 170px;
            inset-inline-start: 324px;
          }

          &.layer4 {
            top: 71px;
            inset-inline-start: 0;
            width: 460px;
          }

          &.layer5 {
            width: 230px;
            inset-inline-start: 508px;
            top: 243px;
          }

          &.layer6 {
            width: 120px;
            inset-inline-end: -100px;
            top: 130px;
          }
        }
      }
    }

    @include xl {
      .main-banner__logo {
        &__layer {
          &.layer6 {
            width: 120px;
            right: -95px;
            top: 190px;
          }
        }
      }

      .main-banner__text-wrap {
        padding-inline: 18px;
        min-width: 504px;
      }

      .main-banner__text { @include text(28px, 34px); }
      .main-banner__title { @include text(36px, 44px); }
    }

    @include xxl {
      .main-banner__text-wrap {
        margin-inline-end: 120px;
      }

      .main-banner__logo {
        &__layer {
          &.layer1 {
            inset-inline-start: 409px;
            top: 0;
            width: 302px;
          }

          &.layer2 {
            width: 245px;
            top: 70px;
            inset-inline-start: 250px;
          }

          &.layer3 {
            width: 217px;
            top: 170px;
            inset-inline-start: 458px;
          }

          &.layer4 {
            top: 40px;
            inset-inline-start: -48px;
            width: 550px;
          }

          &.layer5 {
            width: 230px;
            inset-inline-start: 640px;
            top: 243px;
          }

          &.layer6 {
            width: 196px;
            inset-inline-end: -123px;
            top: 90px;
          }
        }
      }
    }

    @include full {
      .main-banner__text-wrap {
        margin-inline-end: 240px;
      }
    }
  }

  &.is-achievements {
    .main-banner__title {
      @include text(30px, 37px);
      width: 290px;
      margin: 0 auto;
    }

    .main-banner__wrap {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .main-banner__logo {
      width: calc(100% + 6px);
      height: calc(100% + 24px);
      max-height: inherit;
      position: absolute;
      bottom: 0;
      inset-inline-start: -6px;
      overflow: hidden;
      border-radius: 15px;

      &__layer {
        position: absolute;

        &.layer1 {
          width: 211px;
          top: 127px;
          right: 93px;
        }

        &.layer2 {
          width: 203px;
          top: 37px;
          right: 47px;
        }

        &.layer3 {
          width: 495px;
          top: 152px;
          right: -90px;
        }

        &.layer4 {
          width: 193px;
          top: 70px;
          right: -50px;
        }

        &.layer5 {
          width: 365px;
          top: 2px;
          left: -60px;
        }

        &.layer6 {
          width: 120px;
          left: 130px;
          top: 10px;
        }

        &.layer7 {
          width: 136px;
          top: 110px;
          left: 175px;
        }

        &.layer8 {
          width: 110px;
          top: 54px;
          left: 152px;
        }

        &.layer9 {
          width: 122px;
          top: 122px;
          left: 94px;
        }

        &.layer10 {
          width: 154px;
          top: 65px;
          left: -3px;
        }
      }
    }

    @include l {
      .main-banner__title {
        @include text(32px, 39px);
      }

      .main-banner__logo {
        &__layer {
          &.layer1 {
            width: 211px;
            top: 127px;
            right: 142px;
          }

          &.layer2 {
            width: 203px;
            top: 28px;
            right: -10px;
          }

          &.layer3 {
            width: 495px;
            top: 152px;
            right: -90px;
          }

          &.layer4 {
            width: 297px;
            top: -12px;
            right: -222px;
          }

          &.layer5 {
            width: 365px;
            top: -14px;
            left: -43px;
          }

          &.layer6 {
            width: 120px;
            left: 223px;
            top: 8px;
          }

          &.layer7 {
            width: 136px;
            top: 127px;
            left: 275px;
          }

          &.layer8 {
            width: 145px;
            top: 40px;
            left: 251px;
          }

          &.layer9 {
            width: 161px;
            top: 122px;
            left: 152px;
          }

          &.layer10 {
            width: 204px;
            top: 52px;
            left: 14px;
          }
        }
      }
    }

    @include xl {
      .main-banner__title {
        @include text(36px, 44px);
      }

      .main-banner__logo {
        &__layer {
          &.layer1 {
            width: 211px;
            top: 127px;
            right: 192px;
          }

          &.layer2 {
            width: 203px;
            top: 28px;
            right: 40px;
          }

          &.layer3 {
            width: 495px;
            top: 152px;
            right: -40px;
          }

          &.layer4 {
            width: 297px;
            top: 0;
            right: -172px;
          }
        }
      }
    }

    @include xxl {
      .main-banner__logo {
        &__layer {
          &.layer1 {
            width: 211px;
            top: 127px;
            right: 282px;
          }

          &.layer2 {
            width: 203px;
            top: 28px;
            right: 130px;
          }

          &.layer3 {
            width: 495px;
            top: 152px;
            right: 50px;
          }

          &.layer4 {
            width: 297px;
            top: 0;
            right: -82px;
          }

          &.layer5 {
            width: 365px;
            top: -14px;
            left: -43px;
          }

          &.layer6 {
            width: 136px;
            left: 324px;
            top: 8px;
          }

          &.layer7 {
            width: 162px;
            top: 160px;
            left: 425px;
          }

          &.layer8 {
            width: 202px;
            top: 20px;
            left: 408px;
          }

          &.layer9 {
            width: 225px;
            top: 105px;
            left: 256px;
          }

          &.layer10 {
            width: 284px;
            top: 0;
            left: 66px;
          }
        }
      }
    }

    @include full {
      .main-banner__logo {
        &__layer {
          &.layer1 {
            width: 211px;
            top: 127px;
            right: 432px;
          }
          &.layer2 {
            width: 203px;
            top: 28px;
            right: 200px;
          }
          &.layer3 {
            width: 495px;
            top: 152px;
            right: 200px;
          }
          &.layer4 {
            width: 297px;
            top: 0;
            right: -12px;
          }

          &.layer5 {
            width: 365px;
            top: -14px;
            left: -43px;
          }
          &.layer6 {
            width: 136px;
            left: 324px;
            top: 8px;
          }
          &.layer7 {
            width: 162px;
            top: 160px;
            left: 425px;
          }
          &.layer8 {
            width: 202px;
            top: 20px;
            left: 408px;
          }
          &.layer9 {
            width: 225px;
            top: 105px;
            left: 256px;
          }
          &.layer10 {
            width: 284px;
            top: 0;
            left: 66px;
          }
        }
      }
    }
  }

  &.is-crypto {
    height: auto;

    & .main-banner__wrap {
      background-image: url('#{$cdn}/wazamba/cta/crypto/main-cta-crypto-1024.jpg');
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-inline-start: 30px;
      padding-inline-end: 18px;
      position: relative;
      overflow: hidden;

      @include l {
        background-image: url('#{$cdn}/wazamba/cta/crypto/main-cta-crypto.jpg');
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
      }

      @include xl { padding-inline-start: 105px; }
      @include xxl { padding-inline-start: 183px; }

      & .main-banner__text {
        @include text(32px, 24px);
        margin-bottom: 25px;
        margin-top: 0;

        @include l { margin: 0; }
      }

      & .main-banner__action {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: column;

        @include l {
          flex-flow: row;
          align-items: center;
          justify-content: flex-end;
        }

        & .payments-system {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-inline-end: 32px;
          gap: 16px;
          margin-bottom: 16px;

          @include l {
            margin-bottom: 0;
          }

          & .payment-item {
            display: block;
            background-repeat: no-repeat;
            background-position: center;
            width: 59px;
            height: 44px;

            &.is-apple-pay { background-image: url('#{$cdn}/wazamba/cta/crypto/apple_pay.svg'); }
            &.is-google-pay { background-image: url('#{$cdn}/wazamba/cta/crypto/gpay.svg'); }
            &.is-mastercard { background-image: url('#{$cdn}/wazamba/cta/crypto/mastercard.svg'); }
            &.is-visa { background-image: url('#{$cdn}/wazamba/cta/crypto/visa.svg'); }
          }
        }

        button {
          margin: 0;
        }
      }

      &:after {
        content: '';
        background-image: url('#{$cdn}/wazamba/cta/crypto/main-cta-crypto-coins-1024.png');
        position: absolute;
        inset-inline-end: 33px;
        bottom: 0;
        display: block;
        height: 144px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -1;
        background-position: right;

        @include l {
          content: none;
        }
      }

      &:before {
        content: none;
        position: absolute;
        inset-inline-start: 0;
        top: -4px;
        display: block;
        height: 92px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -1;

        @include xl {
          content: '';
          background-image: url('#{$cdn}/wazamba/cta/crypto/main-cta-crypto-coins.png');
          inset-inline-start: -60px;
        }

        @include xxl {
          inset-inline-start: 0;
        }
      }
    }
  }

  &--summer {
    position: relative;

    .main-banner__bg-wrapper {
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border-radius: 15px;
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      overflow: hidden;

      &::after {
        content: "";
        width: 70%;
        height: 100%;
        background: linear-gradient(90deg, #064459 0%, #064459 59.76%, rgba(6, 68, 89, 0.00) 100%);
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    .main-banner__bg-right {
      width: 57%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    .main-banner__bg-left {
      display: none;
      width: 524px;
      height: 346px;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      z-index: 2;
      transform: translateY(-50%);
    }

    .main-banner__logo {
      width: 248px;
      height: 286px;
      max-height: unset;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(50%, -56%);
    }

    .main-banner__inner {
      width: 100%;
      max-width: 786px;
      height: 100%;
      margin: 0 auto;
      padding: 0 50px;
    }

    .main-banner__text-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 400px;
      height: 100%;
      text-align: start;
    }

    .main-banner__title {
      @include text(36px, 1.1, 800, $white);
      max-width: fit-content;
      margin-bottom: 14px;
      font-family: Jost, sans-serif;
      text-shadow: none;
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 8px;
        background: $black-60;
        border-radius: 27px;
        opacity: 0.4;
        filter: blur(2px);
        position: absolute;
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .main-banner__text {
      @include text(18px, 1.2, 600, $white);
      margin-bottom: 12px;
      font-family: Jost, sans-serif;
      text-shadow: none;
    }

    .main-banner__label {
      @include text(14px, 1, 700, $white);
      padding: 8px 12px;
      background: #D12924;
      border-radius: 22px;
      font-family: Jost, sans-serif;
      text-transform: uppercase;
      position: absolute;
      top: -38px;
      inset-inline-start: 0;
      z-index: 1;
    }

    .main-banner__btn {
      display: inline-flex;
      margin: 0;
      min-width: unset;
    }

    @include l {
      .main-banner__title {
        margin-bottom: 8px;
        font-size: 44px;
      }

      .main-banner__text {
        margin-bottom: 23px;
      }
    }

    @include xl {
      .main-banner__bg-left {
        display: block;
        inset-inline-start: -208px;
      }

      .main-banner__logo {
        width: 318px;
        height: 367px;
      }
    }

    @include xxl {
      .main-banner__bg-left {
        inset-inline-start: 0;
      }

      .main-banner__logo {
        transform: translate(83%, -56%);
      }

      .main-banner__title {
        margin-bottom: 16px;
      }

      .main-banner__text {
        margin-bottom: 36px;
      }
    }
  }

  .rtl-lang & {
    direction: rtl;

    &.is-crypto {
      & .main-banner__wrap {
        &:after {
          background-position: 0%;
        }
        &:before {
          transform: scale(-1, 1);
        }
      }
    }
  }
}
