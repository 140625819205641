$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-popup-win {
    font-family: Montserrat;
    width: 345px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__image {
        width: 100%;
        margin-bottom: -8px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(.5) translateY(200px);
        opacity: 0;
        animation: startWinnerClawImgBox .8s forwards;
        img {
            width: 282px;
            height: 282px;
            object-fit: contain;
            position: relative;
            animation: startWinnerClawImg .5s forwards;
        }
        &::before {
            content: '';
            width: 500px;
            height: 500px;
            background: url($cdn + '/wazamba/crab/explosion-min.png') no-repeat center / contain;
            position: absolute;
            transform: translate(-50%, -50%);
            animation: spin 160s linear infinite;
        }

        .rtl-lang & {
            &:before {
                transform: translate(50%, -50%);
            }
        }
    }
    &__body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transform: translateY(300px);
        opacity: 0;
        animation: startWinnerClawBody .4s forwards;
        animation-delay: .4s;
        &.no-delay {
            animation-delay: 0s;
        }
        width: 345px;
        position: relative;
        &::before {
            content: '';
            display: block;
            position: absolute;
            height: 140px;
            background: url($cdn + "/wazamba/leaf.png") center bottom no-repeat;
            background-size: contain;
            top: -85px;
            inset-inline-start: -50px;
            width: calc(100% + 100px);
            z-index: 1100;
        }
        &.hide-before::before {
            display: none !important;
        }
    }
    &__name {
        font-weight: 800;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        letter-spacing: 0.05em;
        color: $white;
        text-shadow: 0 0 10px $black-25;
        padding: 20px 0 35px 0;
    }
    &__amount {
        display: flex;
        align-items: center;
        flex-direction: column;
        span {
            background: -webkit-linear-gradient($white, $yellow-200, $yellow-500);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            filter: drop-shadow(0 3px 11px $black-36);
            &.prize {
                font-size: 90px;
                line-height: 70px;
                letter-spacing: -0.01em;
                font-weight: 800;
            }
            &.coins {
                font-size: 28px;
                line-height: 28px;
                letter-spacing: 0.05em;
                font-weight: 900;
                padding: 15px 0 20px 0;
            }
        }
    }
    &__btn {
        width: 180px;
        margin: 0 auto 20px;
        a {
            width: 100%;
            cursor: pointer;
        }
    }
    .has-margin-bottom-16 {
        margin-bottom: 16px !important;
    }
}

@keyframes spin {
    from {
        transform: translate(-50% -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes startWinnerClawImgBox {
    0% {
        transform: scale(.5) translateY(200px);
        opacity: 0;
    }
    50% {
        transform: scale(1) translateY(200px);
        opacity: 1;
    }
    100% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}

@keyframes startWinnerClawImg {
    0% {
        transform: scale(.5);
    }
    100% {
        transform: scale(1);
    }

}

@keyframes startWinnerClawBody {
    0% {
        transform: translateY(300px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
