$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  &__tournament-games-title {
    @include title-gold-shadow(1px, 0px);
    @include text(16px, 19px, 700);
    padding-bottom: 20px;
    margin: 0 26px;
    filter: none;
    text-transform: capitalize;
  }

  &__tournament-game-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    gap: 16px 28px;
    margin-inline: 26px;
  }

  &__tournament-game {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-pic {
      aspect-ratio: 2/3;
      width: 100%;
      height: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      border-radius: 4px;

      &:hover {
        & .game-page__tournament-game-over { opacity: 1; }
        & .game-item__fav:not(.f_active) { display: block; }
      }
    }

    &-name {
      @include text(12px, 17px, 500, $white-80);
      margin: 0;
      text-align: left;
    }

    &-over {
      position: absolute;
      inset: 0;
      z-index: 5;
      background-color: #FE8316FF;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      opacity: 0;
      border-radius: 4px;
    }
  }
}
