$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  &__nav {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    width: calc(100vw - 1px);
    height: var(--header-height);
    min-height: var(--header-height);
    padding-inline: 12px;
    background-image: url($cdn + '/wazamba/game-page/header-pattern-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150% 200%;
    border: 1px solid $white-10;
    position: fixed;
    top: 0;
    left: 0;

    &:before {
      content: none;
      position: absolute;
      width: 100vw;
      height: 1px;
      right: 0;
      bottom: 3px;
      background-color: #674138;
      z-index: 1;
    }

    &-back {
      .rtl-lang & {
        svg {
          transform: scaleX(-1);
        }
      }
    }
  }

  &__title {
    z-index: 3;
    text-align: center;
    flex-grow: 1;

    & p { margin: 0; }
  }

  &__game-name {
    @include text(14px, 18px, 800);
    @include ellipsis;
    background: linear-gradient(180deg, #FFE600 34.5%, #FFA800 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0 2px 0px #CC2500) drop-shadow(0 6px 4px $black-35);
  }

  &__brand {
    @include text(12px, 14px, 500, $white-70);
  }

  &__btn {
    &-cup,
    &-search,
    &-dep {
      position: relative;

      &-wrap {
        position: relative;

        &::before,
        &::after {
          content: none;
          position: absolute;
          background: var(--icon-game-header-bg-side);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          z-index: 1;
          width: 34px;
          height: 44px;
          top: -4px;
        }

        &::before {
          left: -17px;
        }

        &::after {
          right: -17px;
          transform: scale(-1, 1);
        }
      }
    }

    &-cup,
    &-search {
      .icon-img { visibility: visible }
      .cross-img { visibility: hidden }
    }

    &-search {
      .rtl-lang & {
        svg {
          transform: scaleX(-1);
        }
      }
    }

    &-dep {
      min-width: 105px;

      &-bg {
        display: none;
        position: absolute;
        inset-block: -4px;
        inset-inline: 16px;
        background-color: #37211a;
        border-top: 1px solid #674138;
      }
    }
  }
}
