$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dashboard-play {
  background: $black-40;
  border-radius: 12px;
  max-width: 580px;
  width: 100%;
  margin-inline: auto;
  display: flex;
  align-items: center;
  min-height: 73px;
  @media screen and (min-width: 1280px) {
    max-width: 660px;
  }
  &__avatar {
    background-size: contain;
    background-repeat: no-repeat;
    width: 102px;
    height: 102px;
    margin-top: -33px;
    transform: scaleX(-1);
    flex-shrink: 0;
    cursor: pointer;
  }
  &__title {
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0.05em;
    color: #F9EB62;
    margin-inline-start: 14px;
    text-shadow: 0 0 4px $black-25, 0 2px 0 $orange-900;
  }
  &__btn.g-btn {
    margin-inline-start: auto;
    margin-inline-end: 20px;
    flex-shrink: 0;
  }

  .rtl-lang & {
    &__avatar {
      transform: scaleX(1);
    }
  }
}
