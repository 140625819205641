$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.payments-page-selects{
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  &__item{
    position: relative;
    width: 100%;
    & select{
      padding-inline-start: 60px;
      text-indent: revert;
      font-family: inherit;
      line-height: 44px;
      font-weight: 500;
    }
    &::before {
      content: '';
      position: absolute;
      display: block;
      background-size: cover;
      filter: invert(72%) sepia(3%) saturate(356%) hue-rotate(210deg) brightness(96%) contrast(92%);
    }
    &.has-country{
      &::before {
        background: var(--icon-form-icon_country) no-repeat center;
        width: 20px;
        height: 24px;
        top: 12px;
        inset-inline-start: 24px;
      }
    }
    &.has-currency{
      &::before {
        background: var(--icon-currency);
        width: 28px;
        height: 17px;
        top: 15px;
        inset-inline-start: 20px;
      }
    }

  }
}