$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.funid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    max-width: 352px;
    font-weight: 800;
    font-size: 24px;
    line-height: 1.2;
    color: #6E3923;
    text-align: center;
  }

  &__frame {
    width: 300px;
    height: 300px;
  }
}
