$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.payments-page{
  &__description{
    text-align: center;
    font-weight: 300;
    padding-bottom: 20px;
  }

  & h1 {
    color: #ffffff;
    text-shadow: none;
  }

  .payments-page-tabs {
    margin: 33px auto 10px;
  }
}
