$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.restoration {
  width: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background-image: url('#{$cdn}/wazamba/regisrtation-page/wood-bg.png');
  background-position: top;
  background-size: cover;
  background-repeat: repeat-y;
  border-radius: 0 0 20px 20px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: -118px;
    left: -8px;
    width: 416px;
    height: 130px;
    background-image: url('#{$cdn}/wazamba/regisrtation-page/deposit-logo.png');

    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url('#{$cdn}/wazamba/regisrtation-page/deposit-logo@2x.png');
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url('#{$cdn}/wazamba/regisrtation-page/deposit-logo@3x.png');
    }
  }

  &-form {
    padding: 30px 48px 32px;
    width: 100%;

    &__header-title {
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      background: linear-gradient(180deg, #8F5738 0%, $brown-300 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      padding-bottom: 12px;
    }

    &__header-description {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      color: #8A593D;
      padding-bottom: 12px;
    }
  }

  &-form__close {
    position: absolute;
    top: -72px;
    inset-inline-end: 0;
  }

  .registration-with-deposit {
    &__form {
      padding: 0;

      button.g-btn {
        box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $blue-600;
      }
    }

    &__form-item {
      margin-bottom: 12px;
    }
  }
}
