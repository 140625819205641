$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.sidebar {
  width: 236px;
  transition: all 0.2s ease;
  z-index: 500;
  background-image: url($cdn + '/wazamba/renovation/sidebar/sidebar-bg-up.svg'),
    linear-gradient(to right, #ba6618 0%, #ba6618 4px, $orange-900 4px, $orange-900 100%),
    url($cdn + '/wazamba/renovation/sidebar/sidebar-bg-bottom.svg');
  background-repeat: no-repeat;
  background-position: top left, top 167px left 8px, bottom left;
  background-size: 236px 172px, 236px calc(100% - 340px), 236px 177px;
  margin-top: 16px;
  margin-bottom: 10px;
  min-height: 640px;
  max-height: 100%;
  position: fixed;
  right: 0;
  left: auto;

  &::after {
    display: block;
    width: 100%;
    height: 112px;
    background-image: url($cdn + '/wazamba/desktop/img/pattern-sidebar.svg');
    background-repeat: no-repeat;
    background-size: 100% 96%;
    position: absolute;
    top: 91px;
    left: 4px;
  }

  & &__icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    left: 9px;
    top: 105px;
    padding: 0;
    z-index: 501;
    transition: all 0.2s ease;

    &::after {
      content: '';
      color: transparent;
      display: block;
      width: 36px;
      height: 35px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url($cdn + '/wazamba/desktop/img/burger-close.svg');
    }
  }

  &__logo {
    display: block;
    width: 100%;
    z-index: 10;
    position: relative;
  }

  &__logo-img {
    &.show-guest {
      width: 164px;
      height: 86px;
    }

    &.show-user {
      width: 176px;
      height: 27px;
	  filter: drop-shadow(1px 1px 3px $black-50);
    }

    margin-left: 54px;
  }

  &__scroll {
    position: relative;
    overflow-y: auto;

    &.scrolled {
      overflow-y: auto;

	  .sidebar__line{
		  margin-left: 0;
		  margin-right: 0;
	  }
    }

    &__border {
      position: relative;
      display: flex;
      overflow: hidden;

      &.top {
        &::before {
          content: '';
        }
      }

      &.bottom {
        &::after {
          content: '';
        }
      }

      &:before {
        width: 100%;
        height: 40px;
        background: linear-gradient(180deg, $orange-900 0%, rgba(255, 121, 1, 0) 100%);
        position: absolute;
        top: 0;
        left: 4px;
        z-index: 1;
      }

      &:after {
        width: 100%;
        height: 20px;
        background: linear-gradient(0deg, $orange-900 0%, rgba(255, 121, 1, 0) 100%);
        position: absolute;
        bottom: -1px;
        left: 4px;
        z-index: 1;
      }
    }

    &__wrap {
      width: 100%;
      padding-top: 4px;
    }
  }

  &__content {
    width: 228px;
    margin-left: 8px;
    padding-bottom: 56px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative;
	max-height: calc(100vh - 175px);
	min-height: 485px;
    
    &.small-font .sidebar__link,
    &.small-font .g-btn {
      font-size: 16px;
      letter-spacing: 0;
    }

    .rtl-lang & {
      direction: rtl;

      .sidebar__link {
        background-position: 100% 50%;

        &::before {
          transform-origin: 100% 50%;
        }
      }
    }
  }

  &__content-top {
    position: relative;
    flex-shrink: 0;
  }

  &__avatar {
    position: relative;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;

    &--guest {
      position: relative;
      margin: 0 auto 30px;
      width: 100%;
      height: 150px;
      background-image: url($cdn + '/wazamba/wazamba-v01/sidebar-hero/unlog_hero.png');
      background-position: center;

      &:before {
        display: block;
        content: '';
        width: 232px;
        height: 1px;
        background-color: $black-10;
        position: absolute;
        left: -10px;
        bottom: -14px;
      }
    }

    &--user {
      position: relative;
      margin: 0 auto 10px;
      width: 160px;
      height: 160px;
    }
  }

  &__progress {
    position: absolute;
    bottom: 50px;
    right: 20px;
  }

  &__progress-line {
    padding: 2px;
    height: 26px;
    background-color: #393939;
    background-image: linear-gradient(
      0deg,
      transparent 0%,
      transparent 50%,
      $white-10 50%,
      $white-10 100%
    );
    border-radius: 0 20px 20px 0;
    overflow: hidden;
  }

  &__progress-line-item {
    position: relative;
    background-image: linear-gradient(180deg, #1dcaea 0%, #009cb9 100%);
    box-shadow: 0 2px 7px $black-60;
    border-radius: inherit;
    height: 100%;
    min-width: 12.5%;

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      background-image: linear-gradient(
        0deg,
        transparent 0%,
        transparent 50%,
        $white-30 50%,
        $white-30 100%
      );
      border-radius: inherit;
    }
  }

  &__lvl {
    @include text(24px, 1, 800, $white);
    right: 0;
    bottom: 0;
    width: 55px;
    height: 51px;
    background-image: url($cdn + '/wazamba/desktop/img/progres-star.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.03em;
    text-shadow: 0 0 10px $black-25;
  }

  &__buttons {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 22px;

    *:not(:last-child):not(.sidebar__funid-avatar) {
      margin-bottom: 14px;
    }

    .sidebar__funid-log.is-funid {
      display: none;
    }
  }

  &__login {
    border-top: 1px solid $black-10;
  }

  &__links {
    border: 1px solid $black-10;
    border-left: none;
    border-right: 0;
    padding: 8px 12px 8px 8px;
    margin-left: 4px;
  }

  &__funid-avatar {
    width: 129px;
    height: 102px;
    background-image: url($cdn + '/wazamba/wazamba_funid.png');
    margin: 0 auto -16px;
  }

  &__funid-promo {
    @include text(18px, 20px, 800);
    position: relative;
    max-width: 200px;
    text-align: center;
    letter-spacing: 0.05em;
    background: linear-gradient(180deg, $white 13.03%, $yellow-200 18.73%, $yellow-500 96.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    margin: -4px auto 0;
    padding-bottom: 8px;

    &::after {
      display: block;
      content: '';
      width: 232px;
      height: 1px;
      background-color: $black-10;
      position: absolute;
      left: -10px;
      bottom: 0;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 12px 0;
  }

  &__line{
	border-top: 1px solid $black-10;
	margin-left: -8px;
    margin-right: -12px;
	margin-bottom: 8px;
  }

  &__link {
    @include text(18px, 22px, 600, $white);
    position: relative;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-inline-start: 40px;
    letter-spacing: 0.05em;
    text-shadow: 0 0 10px $black-25;
    text-transform: capitalize;
    background-repeat: no-repeat;
    background-size: auto 32px;
    background-position: left center;
    white-space: break-spaces;

    &.is-lang {
      letter-spacing: .02em;
    }

    &.is-new {
      box-sizing: border-box;
      width: 100%;
      display: block;

      & span {
        word-break: break-word;
        display: inline-block;
      }
    }

    &.is-count {
      box-sizing: border-box;
      width: 100%;
      display: block;
    }

    &.is-games.sidebar__link--bold {
      margin-bottom: 12px;
    }

    &::before {
      content: '';
      position: absolute;
      inset-inline-start: 40px;
      inset-inline-end: 0;
      bottom: 0;
      height: 2px;
      background-color: $white;
      transform-origin: 0 50%;
      transform: scaleX(0);
      transition: transform 350ms;
    }

    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &.indent-down {
      margin-bottom: 20px;
    }

    &--bold {
      font-weight: 700;
    }

    &--halloween {
      margin-bottom: 8px;
    }

    &--seasons{
      margin-bottom: 6px;
    }

    &--euro {
      a {
        text-transform: uppercase;
      }
    }

    &-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;

      &--info {
        svg {
          width: 20px;
          height: 20px;
          fill: $white;
        }
      }
    }
  }

  &__toplink {
    @include text(16px, 20px, 800, $white);
    display: inline-flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 13px;
    letter-spacing: 0.05em;
    text-overflow: ellipsis;
    max-width: 170px;
    overflow: hidden;

    &:hover {
      text-decoration: underline;
    }

    svg {
      width: 6px;
      height: 9px;
      fill: currentColor;

      .rtl-lang & {
        transform: rotate(180deg);
      }
    }
  }

  &__btn-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: -5px;
    gap: 20px;
  }

  &__logout-btn {
    position: relative;
    margin-bottom: -20px;
    text-align: center;
    z-index: 10;
    margin-left: 20px;
  }

  &__chat-btn,
  &__line-btn {
    svg {
      width: 28px !important;
    }
  }

  &__new {
    @include text(8px, 10px, 900, $white);
    position: absolute;
    bottom: -5px;
    left: 0;
    background: $red-200;
    box-shadow: 0 2px 0 $red-300;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    padding: 2px 3px;
  }

  &__count {
    @include text(10px, 12px, 900, $white);
    position: absolute;
    bottom: -5px;
    inset-inline-start: 0;
    background: $red-200;
    box-shadow: 0 2px 0 $red-300;
    text-align: center;
    text-transform: uppercase;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    word-break: inherit !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tabs {
    display: flex;
    background: #d76b0c;
    box-shadow: inset 0 0 3px $black-25;
    border-radius: 50px;
    height: 36px;
    margin-inline: 12px;
    margin-bottom: 12px;

    &__content {
      margin-inline: 12px;
    }
  }

  &__tab {
    @include text(18px, 22px, 700, $brown-300);
    background-color: $orange-100;
    background-image: linear-gradient($orange-100, $orange-100), linear-gradient($orange-700, $orange-500);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px 0 $black-25, 0 2px 0 0 $orange-1200;
    border: 4px solid transparent;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-style: normal;
    letter-spacing: 0.05em;
    cursor: pointer;

    &:first-child {
      border-radius: 50px 1px 1px 50px;
    }

    &:last-child {
      border-radius: 1px 50px 50px 1px;
    }

    &.active {
      color: $white;
      background: none;
      box-shadow: none;
      pointer-events: none;
    }

    &__content {
      display: none;

      &.active {
        display: block;
      }

      &.hide {
        .sidebar__link {
          display: none;

          &:nth-child(1) { display: block; }
          &:nth-child(2) { display: block; }
          &:nth-child(3) { display: block; }
        }
      }

      &:not(.hide) {
        .sidebar__more {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjAwMDA0IDAuMjVDNS43OTkyNiAwLjI1IDUuNjI1NTggMC4zMTkzOTggNS40Nzg4NCAwLjQ1ODMwNkwwLjIyMDEzMiA1LjQzNjE5QzAuMDczMzg5OCA1LjU3NTI1IDAgNS43Mzk4MiAwIDUuOTI5NjFDMCA2LjExOTY4IDAuMDczMzg5OCA2LjI4NDQ3IDAuMjIwMTMyIDYuNDIzTDEuNDAxNTMgNy41NDE2OUMxLjU0ODI4IDcuNjgwNDkgMS43MjIwNCA3Ljc1IDEuOTIyODYgNy43NUMyLjEyMzY0IDcuNzUgMi4yOTczNyA3LjY4MDQ5IDIuNDQ0MDcgNy41NDE2OUw1Ljk5OTg4IDQuMTc1NEw5LjU1NjAyIDcuNTQxNjlDOS43MDI1MSA3LjY4MDQ5IDkuODc2NDggNy43NSAxMC4wNzcyIDcuNzVDMTAuMjc3OSA3Ljc1IDEwLjQ1MTUgNy42ODA0OSAxMC41OTg0IDcuNTQxNjlMMTEuNzc5OSA2LjQyM0MxMS45MjY3IDYuMjg0NDMgMTIgNi4xMTk2NCAxMiA1LjkyOTYxQzEyIDUuNzM5NzggMTEuOTI2NSA1LjU3NTI1IDExLjc3OTkgNS40MzYxOUw2LjUyMTIgMC40NTgzMDZDNi4zNzQ4MyAwLjMxOTM5OCA2LjIwMDgyIDAuMjUgNi4wMDAwNCAwLjI1WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');
        }
      }
    }
  }

  &__more {
    @include text(14px, 17px, 700, $white);
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01Ljk5OTk2IDcuNzVDNi4yMDA3NCA3Ljc1IDYuMzc0NDIgNy42ODA2IDYuNTIxMTYgNy41NDE2OUwxMS43Nzk5IDIuNTYzODFDMTEuOTI2NiAyLjQyNDc1IDEyIDIuMjYwMTggMTIgMi4wNzAzOUMxMiAxLjg4MDMyIDExLjkyNjYgMS43MTU1MyAxMS43Nzk5IDEuNTc3TDEwLjU5ODUgMC40NTgzMDhDMTAuNDUxNyAwLjMxOTUxMiAxMC4yNzggMC4yNSAxMC4wNzcxIDAuMjVDOS44NzYzNiAwLjI1IDkuNzAyNjMgMC4zMTk1MTIgOS41NTU5MyAwLjQ1ODMwOEw2LjAwMDEyIDMuODI0NkwyLjQ0Mzk4IDAuNDU4MzA4QzIuMjk3NDkgMC4zMTk1MTIgMi4xMjM1MiAwLjI1IDEuOTIyNzggMC4yNUMxLjcyMjEyIDAuMjUgMS41NDg0OCAwLjMxOTUxMiAxLjQwMTU4IDAuNDU4MzA4TDAuMjIwMDUzIDEuNTc3QzAuMDczMzA5OSAxLjcxNTU3IDAgMS44ODAzNiAwIDIuMDcwMzlDMCAyLjI2MDIyIDAuMDczNDcxMSAyLjQyNDc1IDAuMjIwMDUzIDIuNTYzODFMNS40Nzg4IDcuNTQxNjlDNS42MjUxNyA3LjY4MDYgNS43OTkxOCA3Ljc1IDUuOTk5OTYgNy43NVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-position: 13px center;
    cursor: pointer;

	&.open {
		background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjAwMDA0IDAuMjVDNS43OTkyNiAwLjI1IDUuNjI1NTggMC4zMTkzOTggNS40Nzg4NCAwLjQ1ODMwNkwwLjIyMDEzMiA1LjQzNjE5QzAuMDczMzg5OCA1LjU3NTI1IDAgNS43Mzk4MiAwIDUuOTI5NjFDMCA2LjExOTY4IDAuMDczMzg5OCA2LjI4NDQ3IDAuMjIwMTMyIDYuNDIzTDEuNDAxNTMgNy41NDE2OUMxLjU0ODI4IDcuNjgwNDkgMS43MjIwNCA3Ljc1IDEuOTIyODYgNy43NUMyLjEyMzY0IDcuNzUgMi4yOTczNyA3LjY4MDQ5IDIuNDQ0MDcgNy41NDE2OUw1Ljk5OTg4IDQuMTc1NEw5LjU1NjAyIDcuNTQxNjlDOS43MDI1MSA3LjY4MDQ5IDkuODc2NDggNy43NSAxMC4wNzcyIDcuNzVDMTAuMjc3OSA3Ljc1IDEwLjQ1MTUgNy42ODA0OSAxMC41OTg0IDcuNTQxNjlMMTEuNzc5OSA2LjQyM0MxMS45MjY3IDYuMjg0NDMgMTIgNi4xMTk2NCAxMiA1LjkyOTYxQzEyIDUuNzM5NzggMTEuOTI2NSA1LjU3NTI1IDExLjc3OTkgNS40MzYxOUw2LjUyMTIgMC40NTgzMDZDNi4zNzQ4MyAwLjMxOTM5OCA2LjIwMDgyIDAuMjUgNi4wMDAwNCAwLjI1WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');
	}

    span {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 40px;
      display: block;
    }
  }

  &__more-info {
    &.hide {
      display: none;
    }

    &__link {
      @include text(14px, 17px, 700, $white);
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      cursor: pointer;

      span {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 40px;
        display: block;
      }
    }
  }

  &__timer-label {
    @include text(10px, 12px, 900, $white);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background: #941D10;
    box-shadow: 0 2px 0 #560A02;
    border-radius: 4px;
    text-align: center;
    padding: 4px;
  }

  &__league-gift {
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    background-position: center;
    background-size: 45px;

    &:before {
      content: '';
      background-image: url('#{$cdn}/wazamba/leagues/shining.png');
      width: 113px;
      height: 80px;
      background-size: 113px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -56px;
      margin-top: -40px;
    }

    &:after {
      content: '';
      width: 40px;
      height: 40px;
      background-image: url('#{$cdn}/wazamba/leagues/icon-league/gift.png');
      background-size: 54px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -27px;
      margin-top: -27px;
    }
  }
}

body:not(.linda-has-user) {
  .sidebar {
    &__logo {
      margin-top: 45px;
      margin-bottom: 15px;
    }

    &__content {
      margin-top: 0;
    }

    &__icon {
      top: 87px;
    }
  }
}

.sidebar-scroll-wrap {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 236px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 500;
}

.sidebar-balance {
  margin-bottom: 20px;
  margin-inline-start: 14px;
  margin-inline-end: 10px;
  padding-inline-start: 20px;
  padding-inline-end: 10px;

  &__item {
    @include text(16px, 34px, 800, $yellow-700);
    width: 100%;
    height: 34px;
    background-color: $white-26;
    text-shadow: 0 0 10px $black-25;
    letter-spacing: 0.7px;
    padding-top: 0;
    padding-bottom: 0;
    padding-inline-start: 20px;
    padding-inline-end: 28px;
    text-align: center;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10px;

      &.small-font {
        font-size: 14px;
      }
    }
  }

  &__icon {
    position: absolute;
    top: -1px;
    inset-inline-start: -22px;
    width: 36px;
    height: 36px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &_1 {
      background-image: url($cdn + '/wazamba/icon/icon-money.png');
      width: 48px;
      height: 48px;
      top: -8px;
      left: -26px;
    }

    &_2 {
      background-image: url($cdn + '/wazamba/sprite-tournament-page.png');
      background-size: 250px;
      background-repeat: no-repeat;
      background-position: 0 -71px;
    }
  }

  &__btn {
    position: absolute;
    width: 34px;
    height: 34px;
    top: -1px;
    inset-inline-end: -10px;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__btn-label {
    @include text(10px, 12px, 900, $white);
    position: absolute;
    z-index: 10;
    top: -6px;
    right: -6px;
    text-transform: uppercase;
    background-color: $red-200;
    padding: 3px 2px 2px;
    border-radius: 4px;
    box-shadow: 0 2px 0 $red-300;
  }
}

body:not(.linda-has-remembered-login) .sidebar.show-btn {
  .sidebar__reg {
    background-color: $green-500;
    background-image: linear-gradient($green-500, $green-500), linear-gradient($green-400, $green-200);
    background-clip: padding-box, border-box;
    background-size: cover, calc(100% + 20px) calc(100% + 20px);
    background-repeat: no-repeat, no-repeat;
    background-position: center top, left -10px top -10px;
    box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $green-600, 0 2px 15px 3px $green-400;

    &:hover {
      background-image: linear-gradient(to top, $green-700, $green-500), linear-gradient($green-300, $green-100 95%);
    }
  }
}

.linda-has-remembered-login {
  .sidebar.show-btn {
    .sidebar__login {
      background-color: $blue-500;
      background-image: linear-gradient($blue-500, $blue-500), linear-gradient(#a3deff, #00eded);
      background-clip: padding-box, border-box;
      background-size: cover, calc(100% + 20px) calc(100% + 20px);
      background-repeat: no-repeat, no-repeat;
      background-position: center top, left -10px top -10px;
      box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $blue-600, 0 2px 15px 3px $blue-500;

      &:hover {
        background-image: linear-gradient(to top, $blue-700, $blue-500), linear-gradient($blue-100, $blue-300 95%);
      }
    }
  }

  .sidebar__buttons {
    .sidebar__funid-reg {
      display: none;
    }

    .sidebar__funid-log {
      display: block;
    }
  }
}

body:not(.is-first-screen).linda-has-remembered-login .sidebar__login {
  background-color: $blue-500;
  background-image: linear-gradient($blue-500, $blue-500), linear-gradient(#a3deff, #00eded);
  box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $blue-600, 0 2px 15px 3px $blue-500;

  &:hover {
    background-image: linear-gradient(to top, $blue-700, $blue-500), linear-gradient($blue-100, $blue-300 95%);
  }

  &:active,
  &.active {
    box-shadow: inset 0 0 3px 0 $black-25, 0 2px 10px 2px $blue-500;
    background-image: linear-gradient(to top, $blue-500, $blue-700), linear-gradient($blue-100, $blue-300 95%);
  }
}

body:not(.is-first-screen):not(.linda-has-remembered-login) .sidebar__reg {
  background-color: $green-500;
  background-image: linear-gradient($green-500, $green-500), linear-gradient($green-400, $green-200);
  box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $green-600, 0 2px 15px 3px $green-400;

  &:hover {
    background-image: linear-gradient(to top, $green-700, $green-500), linear-gradient($green-300, $green-100 95%);
  }

  &:active,
  &.active {
    box-shadow: inset 0 0 3px 0 $black-25, 0 2px 10px 2px $green-400;
    background-image: linear-gradient(to top, $green-500, $green-700), linear-gradient($green-300, $green-100 95%);
  }
}

.linda-hide-sidebar {
  .sidebar {
    transform: translateY(0) translateX(212px);
  }

  .sidebar__icon {
    left: -24px;

    &::after {
      width: 35px;
      background-image: url($cdn + '/wazamba/desktop/img/burger.svg');
    }
  }
}

body.linda-has-user {
  .sidebar__link.is-new span {
    &.show-guest {
      display: none;
    }
  }

  .sidebar__content{
	max-height: calc(100vh - 35px);
  }

  .sidebar__logo{
	position: absolute;
    z-index: 101;
    right: 0;
    top: -12px;
  }
}

.sidebar__link{
	&.is-bonus{
		.sidebar__count{
			bottom: 2px;
		}
	}
}

.santa-block {
  background-image: url( $cdn + "/wazamba/promo-santa/promo-item-menu_x2.png" );
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  padding: 8px 6px 10px 38px;
  transition: background-image .25s ease-in-out;
  cursor: pointer;
  margin: -8px 10px 14px 14px;

  .rtl-lang & {
    direction: ltr;
    scale: -1 1;
  }

  &:hover {
    background-image: url( $cdn + "/wazamba/promo-santa/promo-item-menu-hover_x2.png" );

    .santa-block__btn {
      &.g-btn-orange {
        background-image: linear-gradient(to top, #ff4d00, #ff7800), linear-gradient(#ffad32, #fdc399 95%)
      }
    }
  }

  &__content {
    margin-bottom: 0;

    .rtl-lang & {
      text-align: end;
      scale: -1 1;
    }
  }

  &__title {
    @include text(10px, 1.3, 800, #FFF);

    text-shadow: 0 2px 5px rgba(141, 12, 12, 0.70);
    margin: 0;
  }

  &__subtitle {
    @include text(11px, 1.3, 800);
    @include title-gold-shadow(2px, 1px);

    letter-spacing: 0.55px;
    margin: 0;
  }

  &__btn {
    &.g-btn {
      width: 24px;
      height: 25px;
      border: 3px solid #0000;

      svg {
        rotate: 180deg;
      }
    }
  }
}
