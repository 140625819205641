$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  height: 100vh;
  width: 100%;
  min-width: 1024px;
  //- overflow has to be 'clip' to prevent page scroll on 'scrollIntoView'
  overflow: clip;

  .rtl-lang & {
    direction: rtl;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 0;
    height: 100%;
  }

  &__game-block-layout {
    position: fixed;
    top: var(--header-height);
    bottom: 0;
    display: flex;
    flex-direction: row;
    gap: 0;
    width: calc( 100% + var(--sidebar-width) );
    transition: width .25s ease-in-out;
  }

  &__game-wrapper {
    height: 100%;
    min-width: calc(100% - var(--sidebar-width));
    display: inline-flex;
    flex-direction: column;
    gap: 0;
    flex-grow: 1;
  }
}

//- Open sidebars

//- Show overlay to block inactive elements and show horizontal line
.is-cup-opened,
.is-dep-opened,
.is-search-opened {
  .game-page {
    &__overlay {
      z-index: 0;
      opacity: 1;
    }

    &__nav {
      border: 1px solid #32201a;
      &::before { content: ""; }
    }
  }
}

//- Show sidemenu
.is-cup-opened,
.is-dep-opened {
  .game-page__game-block-layout {
    width: 100%;
  }
}

//- Change 'Cup' icon to 'X' icon show and background svg element
.is-cup-opened {
  .game-page__btn-cup {
    z-index: 2;

    &-wrap {
      z-index: 2;

      &::before,
      &::after { content: ''; }
      .icon-img { visibility: hidden }
      .cross-img { visibility: visible }
    }
  }
}

//- Change 'Search' icon to 'X' icon show and background svg element
.is-search-opened {
  .game-page {
    &__title {
      z-index: 0;
    }

    &__search {
      z-index: 1;
      opacity: 1;
    }

    &__btn-search {
      z-index: 2;

      &-wrap {
        z-index: 2;

        &::before,
        &::after { content: ''; }
        .icon-img { visibility: hidden }
        .cross-img { visibility: visible }
      }
    }
  }
}

//- Change 'Deposit' btn style show and background svg element
.is-dep-opened {
  .game-page__btn-dep {
    z-index: 2;

    &-wrap {
      z-index: 2;

      &::before,
      &::after { content: ''; }
    }

    &-bg {
      display: block;
    }
  }
}

//- Show overlay for crab page
.is-crab-page .is-dep-opened {
  .game-page {
    &__title {
      z-index: 0;
    }

    &__overlay-crab {
      opacity: 1;
      z-index: 1;
    }

    &__nav::before { z-index: 2; }
  }
}
