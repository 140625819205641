$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.shop-title--white {
  font-weight: 800;
  letter-spacing: 0.05em;
  color: $white;
}

.shop-subtitle--white {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: $white;
}

.shop-title--yellow {
  font-weight: 800;
  letter-spacing: 0.05em;
  color: #FDDD75;
}

.shop-title--gold-blur {
  @include title-gold-shadow(2px, 1px);
}

.shop-title--gold-sharp {
  @include title-gold-shadow(1px, 0);
}

.shop-title--gold-no-shadow {
  @include title-gold-shadow();
}
