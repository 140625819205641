$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-banner{

  position:relative;
  box-shadow: 0 4px 14px 0 rgba(202, 177, 54, 0.35);
  border-radius: 10px;
  background-image:  linear-gradient(to top, $yellow-400, $orange-200);
  margin:20px 0;
  color:$brown-300;
  &::before{
    content: '';
    position:absolute;
    top: 0;
    inset-inline-start: 50%;
    transform: translateX(-50%) skew(-30deg) ;
    background-color: #fefca7;
    width: 60%;
    height: 100%;
  }

  .rtl-lang & {
    &:before {
      transform: translateX(50%) skew(30deg) ;
    }
  }

  &__content{
    position:relative;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0;



  }
  &__img{
    width: 340px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    background-size: contain;
    background-repeat: no-repeat;
    &_island{
      height: 312px;
      //width: 314px;
      //background-image: url( $cdn + '/wazamba/islands/3.png' );
      background-position: 50%;
    }
    &_leaf{
      height: 228px;
      //background-image: url( $cdn + '/wazamba/achievment-first-deposit-banner.png' );
      background-position: right center;

      .rtl-lang & {
        transform: scale(-1, 1);
      }
    }
  }
  &__info{
    padding: 0 20px;
    max-width: calc(100% - 340px);
  }
  &__title{
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 1.6px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &_purple{ background-image: radial-gradient(circle at 50% 50%, $violet-500, $violet-700); }
    &_orange{ background-image: linear-gradient(to bottom, #ff7a00, $orange-1200 95%);}
  }
  &__subtitle{
    font-size: 18px;
    font-weight: 800;
    margin-top: 16px;
  }
  &__text{
    margin-top: 12px;
    line-height: 1.88;
    //font-size: 16px;
    font-weight: 500;
  }
  &__btns{
    padding-top:8px;
  }
  @include l{
    &__info{
      padding-block: 0;
      padding-inline-start: 60px;
      padding-inline-end: 20px;
      max-width: calc(100% - 350px);
    }
    &__img{
      &_island{
        background-size: 314px 314px;
        background-position: 50%;
      }
    }
  }
}
