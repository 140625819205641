$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.registration-with-deposit {

  &__form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    max-width: 304px;
  }

  &__form-body {
    min-height: 165px;
  }

  .form-buttons {
    display: flex;
    justify-content: center;

    .registration-btn {
      box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $green-600;
    }
  }

  &__current-hero {
    width: 52px;
    height: 59px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: -76px;
    right: 0;
  }

  &__have-account {
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #905E3A;
  }

  &__block-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $brown-300;
    margin: 40px 0 8px;
  }
}
