$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.shop-fs-popup {
  padding: 16px 0 0;
  min-width: 312px;
  max-width: fit-content;
  margin: 0 auto;

  &__title {
    @include shop-title-white(18px, 22px);

    text-align: center;
    margin-top: 16px;
  }

  &__fs-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: url($cdn + "/wazamba/shop/bonus-backgrounds/freespin_wood-pattern.png");
    background-size: 100% 100%;
    outline: 1px solid $white-10;
    outline-offset: -1px;
    box-shadow: 0 2px 3px 0 rgba(255, 162, 109, 0.40) inset, 0 -4px 12px 0 rgba(39, 11, 5, 0.20) inset;
    border-radius: 8px;
    padding: 10px 15px;

    &-price {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 6px;
      background-color: #482B2A;
      box-shadow: 0 4px 24px 0 rgba(112, 20, 20, 0.40) inset, 0 1px 2px 0 rgba(255, 162, 109, 0.20);

      min-width: 74px;
      max-width: 120px;
      padding: 8px;
      z-index: 1;

      span {
        @include title-gold-shadow(2px, 0);
        font-size: 16px;
        line-height: 20px;
      }
    }

    .price-tag {
      position: relative;
      left: -2px;
      z-index: 0;
    }

    &-btn {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;

      button {
        min-width: 93px;
        width: fit-content;
        box-shadow: 0 2px $green-600;

        &::after {
          height: 30px;
        }
      }
    }

    &.is-disabled {
      background: rgba(137, 105, 82, 0.9);

      .shop-fs-popup__fs-item-price {
        background: #B59989;
        box-shadow: 0 0 3px 0 $black-25 inset;

        span {
          filter: grayscale(1) drop-shadow(0 2px #6E706E);
        }
      }

      .price-tag {
        filter: grayscale(0.9) brightness(1.4) hue-rotate(115deg);

        &::after {
          filter: brightness(0.7);
        }
      }

      .shop-fs-popup__fs-item-btn {
        pointer-events: none;
      }
    }
  }

  &__coins {
    &-balance {
      @include text(16px, 20px, 700, $white);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px;
      background-color: rgba(193, 157, 102, 0.7);
      border: 1px solid $white-20;
      border-radius: 8px;
      margin-top: 12px;
    }

    &-amount {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
      font-weight: 800;

      .rtl-lang & {
        direction: ltr;
      }

      .icon-coin {
        height: 17px;
      }
    }
  }
}
