$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.swiper-slide {
    width: 400px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
