$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.shop-deposit{
  @include text(18px, 1.52, bold);
  margin:60px 0 30px;
  border-radius: 10px;
  box-shadow: inset 0 4px 4px 0 $black-25;
  background-image: radial-gradient(circle at 0 0, $violet-500, $violet-700);
  padding: 80px 20px 20px;
  letter-spacing: 0.9px;
  text-align: center;
  position:relative;
  min-height: 94px;

  & p { margin:0;}

  &__island{
    position: absolute;
    width: 168px;
    height: 147px;
    background: url( $cdn + "/wazamba/islands/7.png") 50% 50% no-repeat;
    background-size: contain;
    top: -75px;
    left: calc(50% - 85px);
  }

  &__coin{
    font-size: 36px;
    font-weight: 900;
    margin: 3px 0;

    &::before{
      content: '';
      display: inline-block;
      width: 34px;
      height: 35px;
      background-image: url( $cdn + "/wazamba/sprite-icons.png");
      background-size: 230px;
      background-position: 0 -57px;
      background-repeat: no-repeat;
      margin: 0 10px -5px 0;
    }
  }
}
