$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.easter-wrapper {
  min-height: 100vh;
}

.sidebar__link-easter {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  line-height: 30px;
  gap: 10px;
  margin-bottom: 10px;
  padding: 0;
  position: relative;

  .sidebar__link-img {
    height: 40px;
    width: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    margin-block: auto;
    flex-shrink: 0;
  }

  span {
    text-transform: capitalize;
  }
}

//- .sidebar__scroll__border--has-holiday {
//-   overflow: visible;

//-   .sidebar__scroll {
//-     overflow-y: visible;
//-   }
//- }
