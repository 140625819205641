$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.crypto-payment {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: $white;
  letter-spacing: 0.05em;

  &__img {
    height: 84px;
    width: 136px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(110, 57, 35, 0.456);
    background-image: linear-gradient(180deg, $yellow-400 -5%, $orange-200 93%);

    & img {
      height: auto;
      max-width: 100%;
      object-fit: cover;
    }
  }

  &__title {
    @include text(16px, 20px, 800);
    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
  }

  &__list {
    border-radius: 8px;
    border: 1px solid rgba(186, 145, 93, 0.45);
    background: #FEDC9F;
    box-shadow: 0 1px 0 0 #AA8C5B;
    padding: 16px 16px 16px 0;
    width: 588px;
    margin: 20px auto;

    ol {
      margin: 0;
      display: grid;

      li {
        @include text(14px, normal, 600, $brown-300);
        font-style: normal;
        letter-spacing: 0.7px;
        position: relative;
        z-index: 1;
      }
    }
  }

  &__info {
    line-height: 1.4;
    font-weight: 500;
    text-align: center;
    margin-top: 4px;
  }

  button { font-family: inherit; }
}
