$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.cookie-banner {
  position:fixed;
  bottom: 12px;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 644px;

  .rtl-lang & {
    direction: rtl;
    transform: translateX(50%);
  }

  &__wrap {
    display: flex;
    justify-content: center;
    padding: 12px 16px;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: linear-gradient(180deg, #7622B3 0%, #62158F 100%);
    box-shadow: 0 4px 4px 0 $black-50;
  }

  &__text {
    @include text(12px, normal, 600, $white);
    max-width: 496px;
    padding-inline-end: 30px;

    a {
      text-decoration-line: underline;
    }
  }

  &__btn {
    font-size: 12px !important;
    line-height: normal !important;
    font-weight: 800 !important;
    height: 34px !important;
    padding: 4px 12px !important;
    letter-spacing: 0.6px !important;
  }
}
