$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.poster-gameitem {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;

  //@for $i from 1 through 14 {
  //  &:nth-of-type(14n + #{$i}) {
  //    transform: rotate(random(3) - 2 + deg);
  //  }
  //}

  &__pic {
    width: 100%;
    background-size: calc(100% - 6px) calc(100% - 2px);
    background-position: 50% 50%;
    background-repeat: no-repeat;

    &:before {
      content: '';
      display: block;
      padding-top: 150%;
      background: url($cdn + '/wazamba/wazamba-v01/game-item/game-icon-poster.png') 50% 50% no-repeat;
      background-size: 100% 100%;
      position: relative;
      z-index: 1;
    }
  }
  &__over {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: url($cdn + '/wazamba/wazamba-v01/game-item/game-icon-poster-hover.png') 50% 50% no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: 0.3s;
    opacity: 0;
  }
  &__play {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: calc(100% - 20px);
    background: #FF983E;
    border-radius: 44px;
    padding: 6px;
    transition: .3s;
    @include l {
      padding: 12px 14px;
    }
    &:hover {
      background: #FFA657;
    }
  }
  &__play-text {
    font-size: 14px;
    font-weight: 800;
    color: $white;
    max-width: 100%;
    padding-inline-start: 2px;
    letter-spacing: 0.1px;
    @include xl {
      font-size: 24px;
      padding-inline-start: 6px;
      letter-spacing: 0.05em;

      .is-small & {
        font-size: 14px;
        padding-inline-start: 2px;
        letter-spacing: 0.1px;
      }
    }
  }

  &__play-ico {
    flex-shrink: 0;
  }
  &__demo {
    font-size: 12px;
    font-weight: 800;
    color: $white;
    margin-top: 8px;
    max-width: 90px;
    white-space: nowrap;
    padding-top: 5px;
    @include l {
      padding-top: 26px;
      font-size: 14px;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  &__fav {
    position: absolute;
    right: 8%;
    bottom: 6%;
  }
}

.game-box__wrap {
  display: grid;
  margin-bottom: 20px;

  &.grid-6 {
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
  }

  &.grid-5 {
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }
}
