$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-wrapper {
  .rtl-lang & {
    direction: rtl;
  }
}

.ach-info {
  padding: 30px 0 90px;
  display: flex;
  align-items: center;

  &__img {
    width: 465px;
    height: 465px;
    background: url( $cdn + "/wazamba/islands/5.png") 50% 50% no-repeat;
    background-size: contain;
    flex-shrink: 0;

    .rtl-lang & {
      transform: scale(-1, 1);
    }
  }

  &__content {
    flex-grow: 1;
    padding-inline-start: 20px;
  }

  &__title {
    @include text(36px, null, 800, $white);
    letter-spacing: 1.8px;
    text-align: center;
  }

  &__text {
    @include text(16px, 1.52, 500);
    padding-top: 20px;
  }

  &__btns {
    padding-top: 20px;
    text-align: center;
  }

  &__chat {
    padding-top: 10px;

    div {
      width: max-content;
      margin-inline: auto;
    }
  }

  &__hero {
    display: none;
    width: 376px;
    height: 376px;
    background: url( $cdn + "/wazamba/hero/1_0.png") 50% 50% no-repeat;
    background-size: contain;
    flex-shrink: 0;
  }

  @include xl {
    &__hero { display:block; }
  }
  @include xxl {
    &__img{ width:456px; height:456px; }
    &__hero{ width:453px; height:453px; }
  }
}
