$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.paymentcb{
  min-height: 100vh;

  .rtl-lang & {
    direction: rtl;
  }

  &.success {
    .show-fail,
    .show-processing {
      display: none;
    }
  }

  &.fail {
    .show-success,
    .show-processing {
      display: none;
    }
  }

  &.processing {
    .show-fail,
    .show-success {
      display: none;
    }
  }

  &__wrap {
    margin:0 auto;
    width: 383px;
    display:flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    min-height: 700px;
    text-align: center;
  }

  &__logo img {
    width:100%;
  }

  &__title {
    font-size: 28px;
    font-weight: 800;
    margin-top: 60px;
  }

  &__text {
    margin-top:25px;
  }

  &__text2 {
    margin-top:45px;
  }

  &__link{
    margin:0 3px;
    font-weight: bold;
  }

  &__timer{
    margin:20px auto 0;
    width: 364px;
    height: 65px;
    background-image: url( $cdn + '/wazamba/sprite-tournament-page.png');
    background-size: 364px;
    background-position: 0 -179px;

    font-size: 36px;
    font-weight: 800;
    letter-spacing: 2.8px;
    color: $white;
    line-height: 65px;

    display: flex;
    align-items: center;
    justify-content: center;

    direction: ltr;

  }
  &__timer-dots{
    font-size: 18px;
    line-height: 65px;
    padding:0 6px;
  }
  &__btn{ margin-top:35px;}
  &__or{ margin-top:35px;}
  &__close{ margin-top:25px;}

  &__info-wrap {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }
  &__content-wrap {
    text-align: left;
  }
  &__content-wrap + &__content-wrap {
    margin-left: 40px;
  }
  &__key {
    font-size: 16px;
    letter-spacing: 0.05em;
    color: $white;
    margin-bottom: 5px;
  }
  &__value {
    font-weight: 800;
    line-height: 1.2;
    font-size: 14px;
    letter-spacing: 0.05em;
  }
  &__item {
    margin-bottom: 10px;
  }

}




