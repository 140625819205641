$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.sitemap {
  width: 562px;
  margin:0 auto;
  padding: 0;

  h2 {
    @include text(18px, 22px, 800);
    background: linear-gradient(180deg, $white 13.03%, $yellow-200 18.73%, $yellow-500 96.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    text-shadow: 0 0 10px $black-25;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 20px;
  }

  &__item {
    @include text(16px, 32px, 500);
    width: fit-content;
  }

  @include l{ width: 751px; }
  @include xl{ width: 871px; }
  @include xxl{ width: 1171px; }
  @include full{ width: 1234px; }
}
