$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-item {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;

  &:hover {
    & .game-item__over, & .poster-gameitem__over { opacity: 1; }
    & .game-item__fav:not(.f_active) { display: block; }
  }

  &__pic {
    width: 100%;
    background-size: calc(100% - 6px) calc(100% - 2px);
    background-position: 50% 50%;
    background-repeat: no-repeat;

    &:before {
      content: '';
      display: block;
      padding-top: 72%;
      background: url($cdn + '/wazamba/game-item.png') 50% 50% no-repeat;
      background-size: 100% 100%;
      position: relative;
      z-index: 1;
    }

    @include l {
      &:before {
        padding-top: 72%;
      }
    }
  }

  &__over {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    z-index: 18;
    background: url($cdn + '/wazamba/game-item_hover.png') 50% 50% no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    opacity: 0;
  }

  &__play {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: calc(100% - 20px);
    background: #FF983E;
    border-radius: 44px;
    padding: 6px;
    margin-bottom: 10px;
    direction: ltr;

    &:hover {
      background: #FFA657FF;
    }
  }

  &__play-text {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 1.2px;
    color: $white;
    max-width: calc(100% - 20px);
    white-space: nowrap;
    margin-inline-start: 5px;
  }

  &__play-ico {
    flex-shrink: 0;
  }

  &__demo {
    @include text(14px, null, 800, $white);
    @include ellipsis;
    margin-top: 8px;
    max-width: 90px;
  }

  &.poster-gameitem {
    & .game-item__fav {
      inset-inline-end: 10%;
      bottom: 6%;
      width: 24px;
      height: 24px;
      border-width: 3px;

      @include l {
        width: 34px;
        height: 34px;
        border-width: 4px;
      }
    }
  }

  &.game-item_dashboard {
    & .game-item__fav {
      inset-inline-end: 8%;
      bottom: 6%;
    }
  }

  &__label {
    @include text(10px, 16px, 800, $white);
    display: none;
    position: absolute;
    top: -4px;
    right: -4px;
    z-index: 19;
    border-radius: 3px;
    border: solid 4px transparent;
    box-sizing: border-box;
    height: 24px;
    padding: 0 8px;
    text-shadow: 0 0 10px $black-50;
    letter-spacing: 0.5px;
    transform: translate3d(0, 0, 0);

    &--new,
    &--exclusive,
    &--fav {
      display: block;
      background-clip: padding-box, border-box;
      background-size: cover, calc(100% + 20px) calc(100% + 20px);
      background-repeat: no-repeat, no-repeat;
      background-position: center top, left -10px top -10px;
    }

    &--new,
    &--fav {
      background-color: $green-500;
      background-image: linear-gradient($green-500, $green-500), linear-gradient($green-400, $green-200);
      box-shadow: inset 0 0 3px 0 $black-25, 0 3px 0 0 $green-600;
    }

    &--exclusive {
      background-image: linear-gradient(0, #d11717 0%, #ce1616 100%), linear-gradient(0, #dc1c1c 0%, #fe0201 100%);
      box-shadow: 0 3px 0 0 $red-300;
    }
  }

  //- FLEX GAME BOX
  &_flex {
    width: calc(25% - 20px);
    margin: 10px;
    flex-shrink: 0;
  }

  @include l {
    &_flex {
      width: calc(16.6667% - 20px);
    }
  }

  @include full {
    &_flex {
      width: calc(12.5% - 20px);
    }
  }

  &_flex {
    .landing-games & {
      width: calc(25% - 20px) !important;
    }

    .home-games & {
      width: calc(25% - 20px) !important;
    }
  }

  //- DEMO GAME
  &_demogame {
    width: calc(20% - 20px);
    margin: 10px;
    flex-shrink: 0;
  }

  //- Tournament games
  &_half {
    width: calc(50% - 20px);
    margin: 0 10px 20px;
  }

  .rtl-lang & {
    &__demo {
      align-self: start;
      margin-inline-start: 10px;

      @include xl {
        align-self: inherit;
        margin-inline-start: 0;
      }
    }
  }
}

.games-scroll-wrap {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-x: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &--landing {
    position: relative;
    margin-right: -250px;
    margin-left: -20px;
    padding-left: 20px;
    margin-bottom: 10px;

    &::after {
      flex-shrink: 0;
      content: '';
      display: block;
      width: 250px;
    }
  }

  .game-item {
    flex-shrink: 0;
    width: 16%;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}


.is-drops-label {
  position: absolute;
  border: 40px solid transparent;
  border-left: 40px solid $black;
  border-top: 40px solid $black;
  width: 71px;
  height: 64px;
  top: 0;
  left: 0;

  &:before {
    position: absolute;
    top: -34px;
    inset-inline-start: -26px;
    content: '';
    background-image: url($cdn + '/wazamba/game-label/drops_wins-logo.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 38px;
    height: 46px;
  }
}

//- Moved here to increase specificity
a.game-item__fav {
  width: 30px;
  height: 30px;
  position: absolute;
  inset-inline-end: 8%;
  bottom: 10%;
  z-index: 18;
  display: none;
  border-width: 3px;

  &.f_active {
    display:block;
  }
}
