$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.dash-fs {
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
  padding: 24px 16px 20px;
  color: $white;
  text-align: center;
  background-image: url($cdn + "/wazamba/dashboard/Stone_gray.png");
  align-items: center;
  width: 180px;
  height: 220px;

  &__game {
    width: 123px;
    height: 82px;
    margin: 0 auto 7px;
    position: relative;
    background-size: contain;

    &.mb-0 {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: url($cdn + "/wazamba/game-item.png") 50% 50% no-repeat;
      background-size: 100% 100%;
    }
  }

  &__key {
    @include text(12px, 14px, 800);
    margin-top: auto;

    &.is-bold {
      font-weight: 800;
      line-height: 16px;
      margin-top: auto;
    }
  }

  &__value {
    @include text(26px, 26px, 800);
    @include ellipsis;
    margin-bottom: auto;
    max-width: 100%;
  }

  &__text {
    @include text(12px, 16px, 800);
    margin-bottom: auto;
    text-align: center;
  }
}
