$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.home-search {
  position: relative;

  &__header {
    display: flex;
    margin: 0 -4px;
    padding: 4px;
    border-radius: 23px;

    .has-result & { border-radius: 23px 23px 0 0; }
    .filled-field & { background-color: $blue-900; }
  }

  &__input {
    width: calc(100% - 53px);
    display: block;
    height: 45px;
    border-radius: 23px;
    background: $black-50;
    border: none;
    box-sizing: border-box;
    margin-right: 8px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: $brown-300;

    &::placeholder { color: $white-40; }
    &:hover {
      &::placeholder{ color: $white; }
    }

    &:focus,
    .filled-field & { background: $white; }
  }

  &__close {
    display: none !important;
    .filled-field & { display:block !important; }
  }

  &__label {
    .filled-field & { display: none; }
  }

  &__result {
    position: absolute;
    display: none;
    top: 100% ;
    left: 0;
    margin: 0 -4px;
    width: calc(100% + 8px);
    background: $blue-900;
    z-index: 50;
    border-radius: 0 0 23px 23px;
    padding: 5px 4px 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.7);

    .has-result & {
      display:flex;
      flex-wrap: wrap;
    }
  }

  &__noresult {
    font-size: 13px;
    font-weight: 600;
    padding: 5px 20px 5px;
  }
}
