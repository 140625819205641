$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-popup {
  width: 912px;
  margin:0 auto;
  background-image: url( $cdn + '/wazamba/board/cashbox_top.png')  , url( $cdn + '/wazamba/board/cashbox_bottom.png')  , url( $cdn + '/wazamba/board/cashbox_body.png')  ;
  background-size: 100% 278px, 100% 250px, 100% calc(100% - 500px);
  background-position: top , bottom , center center ;
  background-repeat: no-repeat;
  position: relative;

  .rtl-lang & {
    direction: rtl;
  }

  &__back {
    position: absolute;
    inset-inline-start: 0;
    top: 50px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #FFEDCF;
    cursor: pointer;

    .rtl-lang & {
      &:before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
      transition: opacity .35s ease-in-out;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("#{$cdn}/wazamba/icon/arrow-left.svg");
    }

    &:hover {
      &:before {
        opacity: .7;
      }
    }
  }

  &__header {
    @include text(36px, null, 800, $white);
    width: 398px;
    height: 243px;
    position:absolute;
    top: -80px;
    left:calc( 50% - 199px);
    z-index: -1;
    background: url( $cdn + '/wazamba/sprite-cashbox.png' ) -3px -75px no-repeat;
    text-align: center;
    padding-top:25px;
    letter-spacing: 1.8px;
  }

  &__close {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    z-index: 1110;
    transform: translateY(-40px);
  }

  &__content {
    min-height: 500px;
    padding: 30px 0 60px;
    z-index: 1020;
    position: relative;
    max-width: 732px;
    margin: 0 auto;
  }

  &__contact {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
}

.cashbox-withdraw {
  &__header {
    position: relative;
    display: flex;
  }

  &__balance {
    @include text(24px, 32px, 800, $yellow-700);
    margin: 3px auto 0;
    width: 240px;
    height: 32px;
    background-color: $white-26;
    text-shadow: 0 0 10px $black-25;
    letter-spacing: 0.7px;
    padding:0 28px 0 20px;
    text-align: center;
    position:relative;
  }

  &__balance-icon {
    position: absolute;
    top: -12px;
    inset-inline-start: -22px;
    width: 47px;
    height: 52px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url( $cdn + '/wazamba/icon/icon-money.png');
  }

  &__balance-btn {
    position: absolute;
    width: 48px;
    height: 48px;
    top: -10px;
    inset-inline-end: -10px;

    &.linda-has-preloader svg{ animation: rotate360 1s infinite }
  }

  &__link {
    position: absolute;
    inset-inline-end: 0;
    top: 17px;
    font-size: 14px;
    font-weight: bold;

    & svg {
      width: 6px;
      height: 9px;
      line-height: 20px;
      vertical-align: center;
      margin-top: 0;
      margin-bottom: 0;
      margin-inline: 7px 0;
    }

    .rtl-lang & {
      & svg {
        transform: scale(-1, 1);
      }
    }
  }

  &__message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 588px;
    margin: 30px auto 0;
    padding: 12px 32px;
    background: $white;
    border-radius: 10px;
  }

  &__message-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.7px;
    color: $blue-900;
  }
  
  &__message-btn {
    flex-shrink: 0;
  }
}

