$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.icons{
  display:block;

  &__list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }

  &__item{
    width: 80px;
    height: 30px;

    & img {
      width: 100%;
      height: 100%;
      font-size: 10px;
      word-break: break-all;
    }
  }

  &__link {
    align-self: center;
    display: flex;
    align-items: center;
    gap: 6px;

    @include text(14px, 17px, 400, #21728A);
    text-decoration: none;

    &:hover {
      color: $blue-200;
    }

    svg {
      width: 6px;
      height: 9px;
      fill: currentColor;

      .rtl-lang & {
        transform: rotate(180deg);
      }
    }
  }
}
