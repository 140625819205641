$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-guide {
    align-self: baseline;
    position: relative;
    background: $blue-900;
    box-shadow: 0 4px 20px $black-10;
    border-radius: 20px;
    padding: 56px 20px;
    margin: 40px auto;
    width: calc(100% - 80px);
    min-width: 944px;
    max-width: 1240px;

    &__close{
        position: absolute;
        inset-inline-end: -20px;
        top:-20px;
    }

    &__title {
        @include text(28px, 34px, 800, $white);
        text-align: center;
        letter-spacing: 0.05em;
        text-shadow: 0 0 10px $black-25;
    }

    &__prizes {
        display: flex;
        justify-content: center;
        margin-top:56px;
    }

    &__section {
        display: flex;
        justify-content: center;
        margin: 20px auto;
    }

    &__box {
        @include text(16px, 150%, 500, $white);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        text-shadow: 0 1px 0 $black-25;
        margin: 0 60px;

        &.is-1{ width: 420px; }
        &.is-2{ width: 520px; }
        &.is-3{ width: 770px; }
        img {
            //margin: 0 20px 0 0;
            margin-block: 0;
            margin-inline: 0 20px;
        }
        a { font-weight: 700; }

        &.has-column {
            flex-direction: column;
            img { margin: 0 0 3px; }
        }
    }
}
