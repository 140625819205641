$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
//- TOURNAMENT-CARD is in linda-tournaments.scss

.tournament-page {
  padding: 10px 0;
  max-width: 1240px;
  margin: 0 auto;

  &__how {
    @include text(24px, 29px, 800, $white);
    text-align: center;
    letter-spacing: 0.05em;
    padding: 20px 0;
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    background: $black-25;
    border: 1px solid rgba(255, 243, 120, 0.5);
    box-shadow: inset 0 0 4px $black-25;
    border-radius: 10px;
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 0 16px;
  }

  &__info-item {
    display: flex;
    grid-gap: 8px;
    align-items: center;
    padding: 16px 0 ;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 26px;
    padding: 20px;
  }

  &__back {
    position:absolute;
    top: 44px;
    left: 20px;
    z-index: 60;
  }

  &__baner {
    padding:0 20px;
  }

  @include xl{
    &__baner{ padding:0 30px; }
  }

  .tournament-card {
    height: 328px;

    &__hero {
      left: 30px;
    }

    &__content {
      margin-top: auto;
      margin-bottom: auto;
      margin-inline: auto 40px;
    }
  }

  .tournament-table {
    max-width: 340px;
    margin: 0 auto;
  }

  .info-wrap {
    max-width: 1000px;
    margin: 0 auto;

    .rtl-lang & {
      direction: rtl;
    }
  }

  .info-item {
    &__img {
      width: 82px;
    }

    &__text {
      @include text(16px, 140%, 700, $white);
    }
  }

  .rtl-lang & {
    &__back {
      top: 22px;
    }
  }
}

.st-ligue {
  width: 100%;

  &__group {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  &__logo {
    width: 34px;
    height: 34px;
    margin-inline-end: 14px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__name {
    @include text(16px, 140%, 700, $white);
  }

  &__list {
    margin-top: 10px;
    display: grid;
    grid-template-columns: .5fr .5fr;
    grid-gap: 12px;

    @include l {
      grid-template-columns: .5fr .5fr .5fr;
    }
  }

  &__item {
    padding: 0 10px;
    position: relative;
    display: flex;
    height: 64px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0 1px 5px $black-20);
    background-repeat: repeat;
    background-color: $orange-100;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(105, 43, 142, 0.28);
    align-items: center;
    justify-content: center;
  }

  &__img {
    width: 100%;
    height: 36px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.tournament-subtitle {
  @include text(18px, 22px, 800, $white);
  text-align: center;
  letter-spacing: 0.03em;
  padding-bottom: 16px;
}

.tournament-timer {
  width: 364px;
  height: 65px;
  margin:0 auto;
  background-image: url( $cdn + "/wazamba/sprite-tournament-page.png");
  background-position: 0 67px;
}

.tournament-table {
  @include text(14px, 20px, 500);
  letter-spacing: 0.03em;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  &__item {
    display: flex;
  }

  &__key {
    @include ellipsis;
  }

  &__dots {
    border-bottom: $white 2px dotted;
    flex-grow: 2;
    height: 14px;
    margin:0 4px;
    align-items: center;
    min-width: 5px;
  }

  &__val {
    flex-shrink: 0;
    font-weight: 700;
  }
}
