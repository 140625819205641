$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.registration-dropdown {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	margin-bottom: 30px;

	&__item {
		position: relative;
		width: 280px;
		background-color: rgba(193, 157, 102, 0.7);
		box-shadow: inset 0 0 6px 1px $black-20;
		border-radius: 15px;
		display: none;
		align-items: center;
		margin: 0 auto 12px;

		&.active {
			order: -1;
			display: flex;
			background-color: rgba(58, 20, 4, 0.7);
			box-shadow: inset 0 0 6px 1px $black;
		}
	}

	.registration-promo {
		position: relative;
		display: none;
		width: 340px;
    	min-height: 84px;
		height: fit-content;

		&.is-active {
			order: -1;
			display: flex;

			&:after {
				width: 10px;
				height: 7px;
				content: '';
				right: 12px;
				top: 0;
				bottom: 0;
				background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC40OTc5MjUgMS42MzQzNEMwLjQ5NzkyNSAxLjkyNTg0IDAuNjExNjI2IDIuMjE3NTQgMC44MzkxMjYgMi40MzkwNEw1LjAwMDM0IDYuNTAwMDZMOS4xNjEyNSAyLjQzOTA0QzkuNjEzNTUgMS45OTc3NCA5LjYxNTc1IDEuMjc5NTQgOS4xNjY0NSAwLjgzNTAzM0M4LjcxNzE1IDAuMzkwNDMxIDcuOTg2NTQgMC4zODgwMzEgNy41MzM3NCAwLjgyOTYzM0w1LjAwMDM0IDMuMzAyNTVMMi40NjU3MyAwLjgyOTYzM0MyLjAxMzEzIDAuMzg4MDMxIDEuMjgyMzMgMC4zOTA0MzEgMC44MzI5MjYgMC44MzUwMzNDMC42MDk3MjYgMS4wNTY1MyAwLjQ5NzkyNSAxLjM0NTI0IDAuNDk3OTI1IDEuNjM0MzRaIiBmaWxsPSIjZmZmZmZmIi8+Cjwvc3ZnPgo=');
				background-repeat: no-repeat;
			}
		}

		&.is-selected {
			pointer-events: none;

			&::after {
				display: none;
			}
		}
	}

	&__bonus {
		width: 100%;
		padding: 5px 12px;
		display: flex;
	}

	&__bind {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}

	&.open {
		.registration-promo {
			display: flex;

			&.is-active {
				&:after {
					transform: scale(1, -1);
				}
			}
		}
	}
}

.promo-group {
    &.promocode-input {
        margin-top: 12px;

		&.hide {
			display: none;
		}
    }
}
