$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-tooltip {
  position: absolute;
  z-index: 510;
  top: 50%;
  inset-inline-start: 100%;
  padding-inline-start: 13px;
  transform: translateY(-50%);

  &::before {
    content: '';
    position: absolute;
    inset-inline-start: 3px;
    top: calc(50% - 10px);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-inline-end: 10px solid $white;
    border-bottom: 10px solid transparent;
  }

  &__content {
    box-shadow: 0 3px 13px 0 $black-20;
    background-color: $white;
    min-width: 215px;
    border-radius: 20px;
    padding:15px 25px;
    text-align: center;
    font-size: 13px;
    color: $brown-300;
  }

  &__title {
    font-weight: 600;
  }

  &__prize {
    margin: 8px 0 6px;
    background-image: linear-gradient(to top, #ff7a00, $orange-1200 95%);
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0.7px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__btns { margin-top:10px; }
}
