$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.seo {
  padding-bottom: 40px;
  width: 100%;

  & h1 {
    text-align: center;
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 1.8px;
  }

  & h2 {
    text-align: center;
    font-size: 28px;
    font-weight: 800;
  }

  & p {
    @include text(14px, 24px, 500, $white);
    margin: 0 0 10px;
    text-shadow: 0 0 10px $black-25;
  }

  & ul {
    padding-inline-start: 30px;
    list-style: disc;
    font-weight: 500;
    line-height: 24px;
  }

  a {
    color: #f9d600;
  }

  .seo__title {
    @include text(24px, 32px, 800);
    letter-spacing: 0.05em;
    background: linear-gradient(180deg, $yellow-400 -5.33%, $orange-200 93.92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: transparent;
    position: relative;
    margin: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-inline-end: 54px;
    padding-inline-start: 0;
    text-align: center;
    transition: .3s;

    &:hover {
      cursor: pointer;
      background: none;
      color: $yellow-100;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;
    }

    .page-article__btn {
      position: absolute;
      top: 50%;
      inset-inline-end: 0;
      margin-top: -23px;
      filter: grayscale(0);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      -ms-transform: rotate(180deg) scale(-1, 1);
      transform: rotate(180deg) scale(-1, 1);
    }
  }

  .seo__list-image {
    width: 72px;
    height: 72px;
    background-image: url($cdn + '/wazamba/sprite-categories.png');
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
    margin-bottom: 6px;
  }

  .seo__list-category {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 17px;
    grid-row-gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    list-style: none;
    padding-left: 0;

    div {
      display: flex;
      align-items: center;
      flex-direction: column;

      &:first-child .seo__list-image { background-position: -292px 0; }
      &:nth-child(2) .seo__list-image { background-position: -219px 0; }
      &:nth-child(3) .seo__list-image { background-position: -365px 0; }
      &:nth-child(4) .seo__list-image { background-position: -73px 0; }
      &:nth-child(5) .seo__list-image { background-image: url($cdn + '/wazamba/icon-category-blackjack.png'); }
      &:nth-child(6) .seo__list-image { background-position: top left; }
      &:nth-child(7) .seo__list-image { background-position: -438px 0; }
      &:nth-child(8) .seo__list-image { background-position: -146px 0; }
    }

    li {
      @include text(12px, 18px, bold, $white);
      text-align: center;
    }
  }

  .seo__list-styled {
    background-image: url($cdn + '/wazamba/islands/4.png');
    background-repeat: no-repeat;
    background-size: 240px auto;
    min-height: 240px;
    padding-inline-start: 270px;
    list-style: '-';
    font-weight: normal;

    li {
      @include text(12px, 18px, 500);
      padding-inline-start: 5px;
      margin-top: 10px;
    }
  }

  .seo__list-bonuses {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 21px;
    margin: 12px 0;
    list-style: none;

    li {
      @include text(12px, 18px, bold);
      letter-spacing: 0.05em;
      text-shadow: 0 0 10px $black-25;
      background: linear-gradient(180deg, $yellow-400 -5.33%, $orange-200 93.92%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 12px;
      text-align: center;
    }

    .seo__image-bonuses {
      background-image: url($cdn + '/wazamba/sprite-icons-2.png');
      width: 134px;
      height: 85px;
      background-size: auto 344px;
      background-repeat: no-repeat;
      margin: 0 auto;
    }

    div:first-child .seo__image-bonuses { background-position: -86px 0; }
    div:nth-child(2) .seo__image-bonuses { background-position: -86px -85px; }
    div:nth-child(3) .seo__image-bonuses { background-position: -86px -171px; }
    div:nth-child(4) .seo__image-bonuses { background-position: -86px -257px; }
  }

  .seo__loyality {
    background-image: url($cdn + '/wazamba/islands/3.png');
    background-repeat: no-repeat;
    background-size: 240px 223px;
    padding: 0 265px 0 0;
    background-position: right center;
    min-height: 223px;
  }

  & section {
    margin-bottom: 10px;
  }

  section:first-child .seo__info p:first-child {
    padding-inline-start: 265px;
    min-height: 240px;
    background-image: url($cdn + '/wazamba/islands/1.png');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 240px auto;
  }

  section:nth-child(2) .seo__info {
    background-image: url($cdn + '/wazamba/islands/5.png');
    background-repeat: no-repeat;
    background-size: 240px auto;
    background-position: top right;
    min-height: 240px;
    padding-inline-end: 250px;
  }

  section:nth-child(4) .seo__info .seo__list-styled {
    background-image: url($cdn + '/wazamba/islands/2.png');
    background-position: top left;
    background-size: 240px auto;
    background-repeat: no-repeat;
    min-height: 240px;
    padding-left: 265px;
  }

  section:not(:first-child) .seo__title {
    border-top: 1px solid rgba(33, 114, 138, 0.75);
  }

  .seo__content,
  .seo__info {
    display: none;
  }

  .seo__title.is-open ~ .seo__content,
  .seo__title.is-open ~ .seo__info {
    display: block;
  }

  .page-article__btn {
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 35px;
      height: 22px;
      background-image: url($cdn + '/wazamba/desktop/img/icon-arrow_bottom.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      z-index: 200;
      top: 50%;
      left: 0;
      margin-top: -11px;
      transform: rotate(-180deg) scale(-1, 1);
      transition: transform .3s ease-in-out;
    }
  }

  .seo__title.is-open button.page-article__btn {
    filter: grayscale(1);
    transition: transform .3s ease-in-out;

    &::after {
      transform: rotate(0deg) scale(-1, 1);
      transition: transform .3s ease-in-out;
    }
  }

  &__tournaments {
    background-image: url($cdn + '/wazamba/wazamba_trophy_seo.png');
    background-repeat: no-repeat;
    background-size: 200px 240px;
    background-position: top left 21px;
    padding-inline-start: 261px;
    min-height: 240px;

    @include l {
      background-position: top left 64px;
      padding-inline-start: 346px;
    }
  }

  &__promotions {
    background-image: url($cdn + '/wazamba/articles/promos/wazamba-seo-text-image-promos.png');
    background-size: 240px 241px;
    background-repeat: no-repeat;
    background-position: top left;
    min-height: 241px;
    padding-inline-start: 255px;

    @include l {
      padding-inline-start: 340px;
      background-position: top left 43px;
    }
  }

  @include l {
    max-width: 1020px;
    margin: 0 auto;
  }

  .rtl-lang & {
    direction: rtl;

    section:first-child .seo__info p:first-child,
    section:nth-child(4) .seo__info .seo__list-styled,
    .seo__list-styled {
      background-position: 100% 0;
    }

    section:nth-child(2) .seo__info {
      background-position: 0 0;
    }

    .seo__tournaments {
      background-position: calc(100% - 21px) 0;

      @include l {
        background-position: calc(100% - 64px) 0;
      }
    }

    &__promotions {
      background-position: top right 43px;
    }
  }
}
