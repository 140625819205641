$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.registration-with-deposit {
  position: relative;

  &__back {
    width: 42px;
    height: 42px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALhSURBVHgBzZm/T1NRFMe/5xU1OjVRkMTBFhZgkbEyFdCETfgLgL/Awj9A2Zzs+w+A0UnYJArUiW52giYm8EYTGHDCRPqO5z4qSl5pe+69JHySpk3zXt+n9+c55xI8UpoZydFvKsnHN/LKJV8S6sRUj+/Fq+GnRgRLCJ5Ymh59K29hp2tEuPx+92AVFmTggdKrkRUCvet6IaE4Mfw0u390sg0lzi2aSEpLae7hJk+G1UZVc08AB2wkDZShFSjpgyVL0yPzsJBsMQ4lVi2aSILWYU8WStSipddj446SMkipDiUqUSNJMfbgCFN8e6LJYp5IsrrbrsGI0IR6Le1JtLXjuEsKHNOcLE0RlHSd9f8kW1uiA8y8GFYP1d1u6Ljge5fcbazDkhu73qekzJ5VF0lDW9FSMZelC/oIT5KV3YMyHEl1fSLZ93BPZqd690jhSdKQatEg82jtrkkarokuT4+tMXgWrniWNFx1/fLUaIUJJbizUdk5XIBnkhY14dpdljT02caUKRh1js99/Nm2mBb1kzc5heDdydSOTquF4SckOU8RbgwGwf3B/eOTLdwCSXJnZF8O9ecB52VpfGJoIHcbsldZaO34dNObrGWm2Ylr6XJLtgjnrZMLE/kBSMt+hSdSU4CbD+ZsUoXU7xCXl6fG1NnmTaQKELXox6/C82cfiOIZWQ8G4YIpOHhq2baVkkvZx9tENCsPc4vqRbaQ7/8pw6oGBzoHzkWJSQOJSclDuAdeqOw0NmBJ18X+rsj2tCt5lD3j4GIy/PxdPVl73j4vZYNvUjt0zUTPuHmeD6vRmeamnndok+JyjElZd1QPaEOWgkfq4EUVSoTVg3oi6wrxPJSoY55EVlJfuJGDEqvgzKS+jrLq4WMdRSayxFb1eEE9651q+LaxrJTGF2vRaaS5x/mwQS1rqiZ7h+tQ4uVUpGdZhzTa2zmTIdkUMlRmxgux/huAR/LakvG8GX7RnYT8zx/ZgS4D0EtnMQAAAABJRU5ErkJggg==');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px;
    cursor: pointer;
  }

  &__title {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    background: linear-gradient(180deg, #8F5738 0%, $brown-300 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .auth-with-deposit__close {
    position: unset;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 12px;
    margin-top: 16px;

    .button {
      position: absolute;
      left: 80px;

    }
  }

  &__steps {
    transition: 0.3s all;
  }
  &__step {
    transition: none;
  }

  .button:not(.registration-slider__arrow_prev):not(.registration-slider__arrow_next):not(.slick-arrow) {
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .to-back .ng-enter {
    position: absolute;
    transform: translate(-150%, 0%);
  }
  .to-back .ng-enter-active {
    transform: translate(0%, 0%);
  }
  .to-back .ng-leave {
    position: absolute;
    transform: translate(0%, 0%);
  }
  .to-back .ng-leave-active {
    transform: translate(150%, 0%);
  }

  &__fastdep {
    div {
      text-decoration: none;
      letter-spacing: 0.7px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  //-Promotions
  &__promo-text {
    font-weight: 900;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: $brown-300;
    text-align: center;
  }

  &__promotions {
    margin: 30px auto 0;
  }

}

//todo slider
.registration-with-deposit-slider {
  $root: &;

  &__slick {
    position: relative;
    height: 320px;

    & .slick-track {
      min-width: 400px;
    }

    &:before {
      position: absolute;
      content: '';
      background-image: url('#{$cdn}/wazamba/regisrtation-page/auth-registration-bg-name.png'), url('#{$cdn}/wazamba/regisrtation-page/auth-registration-bg.png');
      background-size: 170px 73px, contain;
      background-repeat: no-repeat;
      background-position: 50% 109%, center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url('#{$cdn}/wazamba/regisrtation-page/auth-registration-bg-name@2x.png'), url('#{$cdn}/wazamba/regisrtation-page/auth-registration-bg@2x.png');
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url('#{$cdn}/wazamba/regisrtation-page/auth-registration-bg-name@3x.png'), url('#{$cdn}/wazamba/regisrtation-page/auth-registration-bg@3x.png');
      }
    }

    &-arrow {
      position: absolute;
      width: 46px;
      bottom: 80px;
      z-index: 10;
      cursor: pointer;
      box-sizing: border-box;
      display:inline-block;
      height: 46px;
      border-radius:80px;
      border: 5px solid transparent;
      overflow:hidden;
      background-color: $orange-1000;
      background-image: linear-gradient($orange-1000, $orange-1100), linear-gradient(  $orange-700, $orange-500);
      background-clip: padding-box, border-box;
      background-size: cover, calc(100% + 20px) calc(100% + 20px);
      background-repeat: no-repeat, no-repeat;
      background-position: center top, left -10px top -10px;
      box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $orange-1200;

      &:hover{  background-image: linear-gradient(to top, #ff4d00 , $orange-1000 ), linear-gradient($orange-600, $orange-400 95%); }

      &:before {
        position: absolute;
        content: '';
        width: 21px;
        height: 18px;
        background-image: url( $cdn + '/wazamba/desktop/img/icon-arrow_bottom.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        top: 50%;
        left: 50%;
      }

      &.styles-next {
        left: 50%;
        margin-left: -154px;

        &:before  {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }

      &.styles-prev {
        right: 50%;
        margin-right: -154px;

        &:before {
          transform: translate(-50%, -50%) rotate(-90deg);
        }
      }
    }

    .slick-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      gap: 6px;

      li {
        width: 6px;
        height: 6px;
        background: rgba(91, 37, 32, 0.29);
        border-radius: 50%;

        button {
          border: none;
          background-color: transparent;
          font-size: 0;
          line-height: 0;
        }

        &.slick-active {
          background: rgba(91, 37, 32, 0.89);
        }
      }
    }
  }

  &__item {

    height: 246px;
    background-image: url($cdn + '/wazamba/hero/island.png');
    background-size: 186px;
    background-repeat: no-repeat;
    background-position: bottom;
    margin: 26px auto 0;
    min-width: 400px;

    &.swiper-slide-active {
      transform: scale(1);
      z-index: 1;
    }
  }

  &__img {
    height: 100%;
    background-size: auto 186px;
    background-repeat: no-repeat;
    background-position: top;
    transform: scale(0.63);
    transition: 0.5s all;
    width: 186px;
    margin: 0 auto;
  }

  &__name {
    width: fit-content;
    text-align: center;

    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin: 0 auto;
    color: #FFCF01;
    text-shadow: 0 1px 0 #CC2500, 0 4px 4px $black-35;

    background-position: center 6px;
    background-repeat: no-repeat;
    background-size: 162px 60px;
    padding: 10px 48px;
  }

  &__btn {
    padding-top: 36px;

    .chose-avatar-btn {
      box-shadow: inset 0 0 3px 0 $black-25, 0 4px 0 0 $green-600;
      width: fit-content;
      min-width: 304px;

      font-family: 'Montserrat';
      font-size: 18px;
      line-height: 22px;

    }

    .has-text-centered {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

.slick-current {
  .registration-with-deposit-slider__img {
    transform: scale(1);
    z-index: 1;
  }
}

