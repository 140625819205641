$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.popup {
  $this: &;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: $brown-400;
  z-index: 999;
  padding: 150px 0 50px 0;
  max-height: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  .rtl-lang & {
    direction: rtl;
  }

  &.is-blue-bg {
    background: rgba(7, 31, 62, .7);
  }

  &.is-transparent-bg {
    background: transparent;
  }

  &_info {
    padding: 50px 0 50px;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 1024px;
  }

  &__wrap {
    width: 450px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 140px;
      background: url($cdn + "/wazamba/leaf.png") center bottom no-repeat;
      background-size: contain;
      top: -85px;
      inset-inline-start: -50px;
      width: calc(100% + 100px);
    }

    &.hide-before::before {
      display: none !important;
    }

    &.is-350 {
      width: 350px;
    }
  }

  &__logo {
    position: absolute;
    width: 409px;
    height: 83px;
    top: -40px;
    inset-inline-start: 20px;

    background: url($cdn + '/wazamba/desktop/img/logo.svg') 50% 0 / contain no-repeat;
    filter: drop-shadow(0 0 5px $black-70);
  }

  &__close {
    position: absolute;
    inset-inline-end: -50px;
    top: 57px;

    &.is-top {
      top: -20px;
      inset-inline-end: -20px;
    }
  }

  &__content {
    width: 340px;
    margin: 0 auto;
    padding: 45px 0 40px;

    &.is-optin {
      padding: 45px 0 20px;

      .popup__btns {
        padding-top: 28px;
      }

      .g-btn {
        width: fit-content;
        min-width: 236px;
      }
    }

    &:not(.nohidden) {
      overflow: hidden;
    }
  }

  &__title {
    @include text(28px, null, 800, $white);
    padding: 12px 0 20px;
    text-align: center;
    text-shadow: 0 0 10px $black-25;
    letter-spacing: 1.4px;
    max-width: 100%;
    overflow: hidden;

    &.is-28 {
      @include text(28px, 34px, 800);
      padding: 12px 20px 20px 20px;
    }
  }

  &__links {
    text-align: center;
  }

  &__link {
    @include text(14px, 1.52, bold, $brown-300);
    letter-spacing: 0.7px;
    text-align: center;
  }

  &__btns {
    padding-top: 20px;
    text-align: center;

    button {
      font-family: Montserrat, sans-serif;
    }
  }

  &__text {
    @include text(14px, normal, 800, $white);
    padding-bottom: 20px;
    text-align: center;
    text-shadow: 0 0 10px $black-25;
    letter-spacing: 0.7px;

    &_first {
      padding-top: 20px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    counter-reset: list;
  }

  &__list-item {
    display: flex;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .05em;
    margin-top: 23px;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      counter-increment: list;
      content: counter(list)'.';
      flex-shrink: 0;
      width: 28px;
    }
  }

  &__btm-text {
    @include text(14px, 22px, 700, $brown-300);
    letter-spacing: .05em;
    text-align: center;
    margin-top: 20px;

    &+#{$this}__btm-text {
      margin-top: 15px;
    }

    a {
      text-decoration: underline;
      color: inherit;
    }
  }

  h1, h2, h3 {
    margin: 0;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: $brown-400;
  z-index: 999;
  padding: 50px 0;
  max-height: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  .rtl-lang & {
    direction: rtl;
  }

  &.is-zimpler-reg {
    display: block;
  }

  &_lang-version-notification {
    background-color: $black-50;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 1024px;
  }

  &__wrap {
    width: 450px;
  }

  &__content {
    padding: 40px 55px 40px;
  }

  &__title {
    @include text(36px, null, 800, $white);
    text-shadow: 0 0 10px $black-25;
    letter-spacing: 1.8px;
    text-align: center;

    &.is-lang {
      font-size: 25px;
    }
  }

  &__text {
    @include text(14px, 1.52, 500);
    padding-top: 20px;
    letter-spacing: 0.7px;

    &.is-brown {
      color: $brown-300;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.8px;
      text-align: center;
    }
  }

  &__sums {
    display: flex;
    flex-flow: column;
    gap: 8px;
    margin-top: 20px;
  }

  &__sum {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    background: rgba(110, 57, 35, 0.80);

    & span{
      &:last-child {
        width: 35%;
        text-align: end;
      }
    }
  }

  &__form {
    margin-top: 30px;
  }

  &__btns {
    width: 225px;
    margin: 0 auto;
  }

  &__btns-item {
    margin-top: 20px;
    text-align: center;
  }

  &__btnrow {
    display: flex;
    justify-content: center;
  }

  &__btnrow-small {
    width: 44px;
    flex-shrink: 0;
    margin-inline-end: 20px;
  }

  &__btnrow-normal {
    max-width: calc(100% - 70px);
  }
}

.popup-link {
  font-weight: 800;
  margin-top: 16px;
}
