$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  &__shop {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;

    &-back-btn {
      cursor: pointer;
      display: inline-flex;
      gap: 8px;
      flex-direction: row;
      align-items: center;
      width: fit-content;
      order: 0;
      margin-inline-start: var(--shop-margin-inline);

      span {
        @include text(16px, 20px, 700, $white);
      }

      button svg {
        .rtl-lang & {
          transform: scaleX(-1);
        }
      }
    }

    &-info {
      order: 2;
      margin-inline: var(--shop-margin-inline);
    }

    &-subtitle {
      @include text(16px, 20px, 500, $white);
      text-align: start;
      margin: 0;
    }

    &-filters {
      order: 3;
      margin-inline: var(--shop-margin-inline-negative);
      padding-inline: var(--shop-margin-inline);
      width: 100%;

      &.is-tab-opened {
        order: 1;
      }

      .filter-btns {
        position: relative;
        padding-inline: var(--shop-margin-inline);
      }
    }

    &-main {
      order: 4;

      .game-page__shop-title {
        @include text(18px, 29px, 700);
        text-align: left;
        margin: 0;
      }
    }

    &-coins {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
      gap: 6px;

      &-balance {
        @include text(16px, 20px, 700, $white);
        order: 1;
      }

      &-amount {
        @include text(20px, 24px, 800, #ffe600);
        order: 3;

        .rtl-lang & {
          order: 2;
        }
      }

      & .icon-coin {
        width: 20px;
        order: 2;

        .rtl-lang & {
          order: 3;
        }
      }
    }

    &-card-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 1fr;
      margin-inline: var(--shop-margin-inline);
      gap: 24px 12px;

      .shop-item-money-bet__card{
        @include l {
          min-width: 184px;
        }
      }
    }

    &-header {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-inline: var(--shop-margin-inline);
      margin-bottom: 16px;
    }

    &-scroll {
      &-wrap {
        position: relative;
        margin-inline: var(--shop-margin-inline-negative);
        padding-inline: var(--shop-margin-inline);

        &::before,
        &::after {
          content: "";
          position: absolute;
          inset-block: 0;
          width: 20px;
          z-index: 2;
        }

        &::before {
          left: var(--shop-margin-inline);
          background: linear-gradient(90deg, rgba(60, 37, 34, 1) 0%, rgba(60, 37, 34, 0) 100%);
        }

        &::after {
          right: var(--shop-margin-inline);
          background: linear-gradient(270deg, rgba(60, 37, 34, 1) 0%, rgba(60, 37, 34, 0) 100%);
        }
      }
    }

    &-instructions {
      margin-inline: var(--shop-margin-inline);
    }

    //- Full class names are used to increased selectors specificity

    .game-page__shop-scroll-swiper {
      padding: 10px 20px 0;
    }

    .game-page__shop-card-item {
      width: 190px;

      .shop-fs-item--show-more {
        width: 184px;
        min-width: 184px;
      }
    }

    .game-page__shop-card-content {
      width: 184px;
      min-width: 184px;
      display: block;
    }

    &-arrows {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }
  }
}
