$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  &__freespin {
    position: relative;

    &-filters-wrap {
      display: block;
      margin-inline: var(--shop-margin-inline-negative);
      padding-inline: var(--shop-margin-inline);
    }

    //- Full class names are used to increased selectors specificity

    .game-page__freespin-filters {
      padding-inline: var(--shop-margin-inline);

      .game-page__freespin-filters-item {
        padding-inline: 8px;
        flex-basis: 50px;
      }
    }

    &-arrows-prev {
      .rtl-lang & {
        svg {
          transform: scaleX(-1);
        }
      }
    }

    &-arrows-next svg {
      transform: rotate(180deg);

      .rtl-lang & {
        transform: none;
      }
    }
  }
}
