$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.landing {
  &__wrapper {
    position: relative;
    background-color: $blue-900;
    background-image: url($cdn + "/wazamba/page-bg-2.jpg");
    background-repeat: no-repeat;
    background-position: 50% top;
    background-size: 100% auto;
    z-index: 30;
  }

  &__group_1 {
    height: 395px;

    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      width: 100%;
      height: 246px;
      z-index: 2;
      background: linear-gradient(0deg, $black-60 0.17%, rgba(54, 54, 54, 0.326618) 61.61%, rgba(196, 196, 196, 0) 100%);
    }

    @include l {
      height: 425px;
    }

    @include xl {
      height: 500px;
    }

    @include xxl {
      height: 545px;
    }

    @include full {
      height: 590px;
    }
  }

  &__chest.g-hr {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &__footer-content {
    padding-right: 270px;
    padding-left: 20px;
    background-image: linear-gradient(180deg, rgba(7,31,62,1) 20%, rgba(7,31,62,0.7021183473389356) 50%, rgba(7,31,62,0) 100%),
                      url($cdn + "/wazamba/page-bg-1.jpg");
    background-size: 100% 390px, 100% auto;
    background-repeat: no-repeat, no-repeat;
    background-position: center top, center top;

    & .seo {
      transition: .2s transform;
    }

    body.linda-hide-sidebar & .seo{
      transform: translateX(125px);
    }
  }
}

.landing-games {
  padding: 20px 250px 0 20px;
  display: block;
  transition: .2s ;
  body.linda-hide-sidebar & {
    transform: translateX(125px);

    &.search-block {
      transform: unset;
      padding: 20px 125px;
    }
  }
  &__gamebox {
    display: block;
    padding-bottom: 20px;
    margin:0 auto;
  }

  &__btns {
    text-align: center;
    padding:0 0 35px;
  }

  .rtl-lang & {
    direction: rtl;
  }

  &.is-sport-main {
    direction: ltr;
  }
}

//- following styles are uses for new masks, jackpot, events, gameboxes

.landing-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 230px;
  transition: .2s all;

  body.linda-hide-sidebar & {
    transform: translate(120px);
  }

  @include xxl {
    grid-template-columns: 47% 1fr 230px;
  }

  @include full {
    grid-template-columns: 42% 1fr 230px;
  }

  &__content {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;

    &.no-overflow{
      overflow: inherit;
    }
  }

  &__full {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  &__first {
    overflow: hidden;

    @include xxl {
      grid-row-start: 1;
      grid-column-start: 1;
      grid-column-end: 2;
      padding-right: 10px;
    }
  }
  &__second {
    overflow: hidden;

    @include xxl {
      grid-row-start: 1;
      grid-column-start: 2;
      grid-column-end: 3;
      padding-left: 10px;
      margin: auto 0;
    }
  }
}
