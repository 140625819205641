$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.shop-slider {
  position: relative;

  &.is-block {
    .shop-slider__wrap {
      display: none;
    }

    .shop-slider__list {
      display: grid;
    }
  }

  &__arrows-prev,
  &__arrows-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    z-index: 10;
    background: none;
    border: none;

    &.swiper-button-disabled {
      display: none;
    }
  }

  &__arrows-prev {
    inset-inline-start: 0;

    .rtl-lang & {
      transform: rotate(180deg) translateY(50%);

      .g-btn-ico.prev {
        box-shadow: inset 0 0 3px #00000040, 0 -2px #b75637;
      }
    }
  }

  &__arrows-next {
    inset-inline-end: 0;
    transform: rotate(180deg) translateY(50%);

    .g-btn-ico.next {
      box-shadow: inset 0 0 3px #00000040, 0 -2px #b75637;
    }

    .rtl-lang & {
      transform: rotate(0deg) translateY(-50%);

      .g-btn-ico.next {
        box-shadow: inset 0 0 3px #00000040, 0 2px #b75637;
      }
    }
  }

  &__list {
    display: none;
    grid-template-columns: repeat(4, 1fr);
    height: 100%;
    gap: 12px;
    padding-top: 20px;
    padding-bottom: 10px;

    &.shop-fs-item-list {
      grid-template-columns: repeat(2, 1fr);

      @include l {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
