$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-league {
	height: 100%;
	display: flex;
	flex-direction: column;
    align-items: center;
	padding: 200px 0 50px;

	.how-btn {
		 position: absolute;
		 top: -102px;
		inset-inline-end: 0;
	}

	&__wrap {
		width: 541px;
		margin: 0 auto;
		position: relative;
	}
}

//- popup how to work

.league-header-element {
	position: absolute;
	top: -190px;
	inset-inline-start: 50%;
	width: 541px;
	height: 186px;
	transform: translateX(-50%);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/wide.png);

	@media (-webkit-max-device-pixel-ratio: 2) {
		background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/wide@x2.png);
	}

	@media (-webkit-max-device-pixel-ratio: 3) {
		background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/wide@x3.png);
	}

	.rtl-lang & {
		transform: translateX(50%);
	}


}

.league-how-it-works {
	width: 492px;
	margin: 0 auto;
	background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/bg-element-midl.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (-webkit-max-device-pixel-ratio: 2) {
		background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/bg-element-midl@x2.png);
	}

	@media (-webkit-max-device-pixel-ratio: 3) {
		background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/bg-element-midl@x3.png);
	}

	&:before,
	&:after {
		position: absolute;
		content: '';
		inset-inline-start: 50%;
		width: 541px;
		transform: translateX(-50%);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		z-index: -1;
	}

	&:before {
		top: -102px;
		height: 167px;
		background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/bg-element-top.png);

		@media (-webkit-max-device-pixel-ratio: 2) {
			background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/bg-element-top@x2.png);
		}

		@media (-webkit-max-device-pixel-ratio: 3) {
			background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/bg-element-top@x3.png);
		}
	}

	&:after {
		bottom: -102px;
		height: 165px;
		background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/bg-element-down.png);

		@media (-webkit-max-device-pixel-ratio: 2) {
			background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/bg-element-down@x2.png);
		}

		@media (-webkit-max-device-pixel-ratio: 3) {
			background-image: url(#{$cdn}/wazamba/leagues/popup/how-it-works/bg-element-down@x3.png);
		}
	}

	.rtl-lang & {
		&:before,
		&:after {
			transform: translateX(50%);
		}
	}

	&__title {
		@include text(18px, 20px, 700, $orange-300);
		text-align: center;
		text-shadow: 0 3px 4px rgba(0, 0, 0, 0.43);
		margin-bottom: 14px;
	}

	&__step {
		display: flex;
		align-items: center;
		margin-top: 4px;
		margin-bottom: 20px;
		position: relative;

		&.step1 {
			.league-how-it-works__icon {
				background-image: url(#{$cdn}/wazamba/leagues/how-step-1.png);

				@media (-webkit-max-device-pixel-ratio: 2) {
					background-image: url(#{$cdn}/wazamba/leagues/how-step-1@2x.png);
				}

				@media (-webkit-max-device-pixel-ratio: 3) {
					background-image: url(#{$cdn}/wazamba/leagues/how-step-1@3x.png);
				}
			}
		}

		&.step2 {
			.league-how-it-works__icon {
				background-image: url(#{$cdn}/wazamba/leagues/how-step-2.png);

				@media (-webkit-max-device-pixel-ratio: 2) {
					background-image: url(#{$cdn}/wazamba/leagues/how-step-2@2x.png);
				}

				@media (-webkit-max-device-pixel-ratio: 3) {
					background-image: url(#{$cdn}/wazamba/leagues/how-step-2@3x.png);
				}
			}
		}

		&.step3 {
			margin-bottom: 0;

			.league-how-it-works__icon {
				background-image: url(#{$cdn}/wazamba/leagues/how-step-3.png);

				@media (-webkit-max-device-pixel-ratio: 2) {
					background-image: url(#{$cdn}/wazamba/leagues/how-step-3@2x.png);
				}

				@media (-webkit-max-device-pixel-ratio: 3) {
					background-image: url(#{$cdn}/wazamba/leagues/how-step-3@3x.png);
				}
			}
		}
	}

	&__icon {
		width: 100px;
		height: 100px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	&__description {
		@include text(14px, 18px, 700, $white);
		letter-spacing: 0.05em;
		margin-inline-start: 15px;
		width: 205px;

		ul{
			list-style: disc;
			padding-inline-start: 22px;
		}
	}

	&__arrow {
		width: 100px;
		height: 25px;
		position: absolute;
		bottom: -24px;
		background-image: url(#{$cdn}/wazamba/leagues/how-arr.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;

		@media (-webkit-max-device-pixel-ratio: 2) {
			background-image: url(#{$cdn}/wazamba/leagues/how-arr@2x.png);
		}

		@media (-webkit-max-device-pixel-ratio: 3) {
			background-image: url(#{$cdn}/wazamba/leagues/how-arr@3x.png);
		}
	}

	&__link {
		@include text(14px, 18px, 700, $orange-300);
		text-align: center;
		letter-spacing: 0.05em;
		text-decoration-line: underline;
		cursor: pointer;

		&:hover {
			color: #D8AC39;
		}
	}

	.is-custom {
		margin-top: 28px;
		margin-bottom: 20px;
		width: fit-content;
		min-width: 200px;
	}
}
