$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.ach-dashboard{
  position:relative;
  &::after{
    content:'';
    position:absolute;
    bottom: -170px;
    left: -20px;
    right: -250px;
    //width: 100% ;
    height: 390px;
    //border:solid 1px red;
    background-image: linear-gradient(to bottom, rgba(7, 31, 62, 0), $blue-900);

    @include xl {
      bottom: -300px;
    }

    @include xxl {
      bottom: -450px;
    }
  }


  &__title{
    padding:60px 0 40px;
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 1.8px;
    color: $white;
    text-align: center;
  }
  &__content{
    height: 454px;
    width: 756px;
    background: url( $cdn + "/wazamba/shop_unloged_items.png") no-repeat;
    background-size: auto 100%;
    margin:0 auto;

    .rtl-lang & {
      background: url( $cdn + "/wazamba/shop_unloged_items_ar_lang.png") no-repeat;
      background-size: 100% auto;
    }
  }
  &__card{
    width: 464px;
    position:relative;
    margin: 0 auto;
    top: 50%;
    z-index: 50 ;
    transform: translateY(-50%);

  }

  @include l{
    &__content{ width: 1012px;
      height: 503px;}
  }
}
